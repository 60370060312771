.uploadFile-detail-modal {
  font-size: 0.75rem;
  .detail-modal-container {
    max-width: fit-content !important;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    padding-top: 40px;
    background: #fff;
    @include getData(box-shadow, btns1Shadow);
    border-radius: 4px;
    border: none;
    position: relative;
  }
  .detail-modal-header {
    display: flex;
    justify-content: center;

    &-title {
      position: absolute;
      top: 0.6rem;
      margin: 0 auto;
      font-weight: 500;
      font-size: 1rem;
    }

    .modal-close {
      top: 0.3rem;
    }
  }
  .detail-modal-content {
    max-height: 60vh;
    max-width: 100vw;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.2);
    }

    &-row {
      display: grid;
      padding: 1rem;
      width: 100%;

      &.address {
        .grid-item:nth-child(5) {
          order: 3;
        }
        .grid-item:nth-child(7) {
          order: 1;
        }
        @media screen and (min-width: 831px) {
          .grid-item:nth-child(2) {
            grid-row: 1 / 3;
            grid-column: 2 / 3;
          }
        }
      }
      .grid-item {
        display: flex;
        padding-right: 3rem;
        &-title {
          white-space: nowrap;
          margin-right: 0.7rem;
          color: #828282;
        }
      }
      &:nth-child(2n-1) {
        background-color: #fafafa;
      }
      &:nth-child(2n) {
        background-color: #f2f2f2;
      }
      & > div {
        margin: 0.25rem 0;
      }
      &.column-3 {
        grid-template-columns: repeat(3, 256px);
        @media screen and (max-width: 830px) {
          grid-template-columns: repeat(2, 256px);
        }
        @media screen and (max-width: 580px) {
          grid-template-columns: repeat(1, 256px);
        }

        .grid-item {
          @media screen and (max-width: 830px) {
            &:nth-child(1) {
              order: 1;
            }
            &:nth-child(2) {
              order: 7;
            }
            &:nth-child(3) {
              order: 4;
            }
            &:nth-child(4) {
              order: 3;
            }
            &:nth-child(5) {
              order: 2;
            }
            &:nth-child(6) {
              order: 6;
            }
            &:nth-child(7) {
              order: 5;
            }
          }
          @media screen and (max-width: 580px) {
            &:nth-child(1) {
              order: 1;
            }
            &:nth-child(2) {
              order: 4;
            }
            &:nth-child(3) {
              order: 6;
            }
            &:nth-child(4) {
              order: 2;
            }
            &:nth-child(5) {
              order: 5;
            }
            &:nth-child(6) {
              order: 7;
            }
            &:nth-child(7) {
              order: 3;
            }
          }
        }
      }

      &.column-2 {
        grid-template-columns: repeat(2, 256px);
        @media screen and (max-width: 580px) {
          grid-template-columns: repeat(1, 256px);
        }

        .grid-item {
          @media screen and (max-width: 580px) {
            &:nth-child(1) {
              order: 1;
            }
            &:nth-child(2) {
              order: 4;
            }
            &:nth-child(3) {
              order: 2;
            }
            &:nth-child(4) {
              order: 5;
            }
            &:nth-child(5) {
              order: 3;
            }
          }
        }
      }
    }
  }

  .detail-modal-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1rem;

    span.result {
      display: inline-block;
      margin-left: 4px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

.modal-open {
  overflow: hidden;
}
