.a-gs-main {
  width: 100%;
  @include getData(height, a-gs-main-height);
  @include getData(padding, a-gs-main-padding);

  @include getData(background-color, bg);
  .a-gs-success {
    @include getData(height, a-gs-main-height);
    width: 900px;
    padding: 20px 30px;

    @include getData(background-color, light);
    @include getData(box-shadow, pagesShadow, !important);
    img {
      @include getData(width, a-gs-success-width, !important);
      @include getData(height, a-gs-success-height, !important);
      @include getData(padding, a-gs-success-img-padding, !important);
      @include getData(margin-bottom, a-gs-success-img-margin-bottom, !important);
      margin-top: 38px;
    }
    h2 {
      font-style: normal;
      font-weight: bold;
      line-height: 35px;
      @include getData(font-size, a-gs-success-h2-font-size);
      @include getData(color, a-gs-success-h2-color);
    }
    .a-gs-ba {
      width: 816px;
      height: 291px;
      margin-top: 36px;
      border-radius: 10px;
      background: linear-gradient(180deg, rgba(255, 109, 98, 0.7) 0%, rgba(244, 40, 58, 0.7) 100%);

      .a-gs-ba-bank {
        width: 281px;
        height: 251px;
        padding: 14px 18px 16px 18px;
        margin-right: 60px;
        border-radius: 10px;
        background-color: white;

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 8px;
          @include getData(color, black);
        }

        img {
          width: 75px;
          height: 22px;
          margin-top: 9px;
          margin-bottom: 13px;
        }

        h3 {
          width: 244px;
          height: 45px;
          font-size: 16px;
          line-height: 45px;
          border-radius: 8px;
          margin-bottom: 13px !important;
          text-align: center;
          @include getData(color, color2);
          @include getData(background-color, bg);
        }
      }
      .a-gs-ba-payment {
        width: 229px;
        height: 251px;
        padding: 16px 0;

        h3 {
          width: 100%;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          color: white;
        }

        p {
          font-size: 14px;
          margin: 0;
          color: white;
        }

        h1 {
          font-size: 24px;
          font-weight: bold;
          color: white;
        }

        h4 {
          font-size: 14px;
          font-weight: bold;
          text-align: center;
          color: white;
        }
      }
    }
    .a-gs-reminder {
      width: 816px;
      height: 259px;
      margin: 36px 0 40px 0;
      @include getData(box-shadow, btns1Shadow);
      border-radius: 10px;
      img {
        width: 259px;
        height: 259px;
        margin: 0;

        border-radius: 10px 0 0 10px;
      }
      aside {
        width: 557px;
        height: 259px;
        padding: 0 62px 0 72px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include getData(background-color, light);
        border-radius: 10px;

        position: relative;
        h3 {
          margin: 0 0 16px 0 !important;

          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
          @include getData(color, gray3);
        }
        h4 {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          margin-bottom: 16px !important;
        }
        ul {
          padding: 0 0 0 21px;
          li {
            margin: 0 0 16px 0;

            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, gray1);
            strong {
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 16px;
              @include getData(color, gray1);
            }
            em {
              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 18px;
              @include getData(color, color2);
            }
            &:last-of-type {
              margin: 0;
            }
          }
        }
        &::before {
          content: '';
          width: 51px;
          height: 51px;

          @include getData(background-color, light);

          position: absolute;
          top: 50%;
          left: -7.75%;
          transform: rotate(45deg) translateY(-50%);
        }
        button {
          width: 200px;
          height: 40px;
          margin: 0;
        }
      }
    }
    .a-gs-btn-container {
      display: flex;
      flex-direction: column;
      button {
        @include getData(width, a-gs-btn-container-btn-width);
        @include getData(border-radius, a-gs-btn-container-btn-border-radius);
        height: 55px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        &:first-of-type {
          margin: 32px 0 10px 0;
          @include btnFilled;
        }
        &:last-of-type {
          @include getData(background-color, light);
          @include getData(border, btn, null, 1px solid);

          @include getData(color, btn);
        }
      }
    }
    h1 {
      font-style: normal;
      font-weight: bold;
      font-size: 30px;
      line-height: 35px;
      @include getData(color, black);
    }
    article {
      margin: 20px 0 40px 0;
      padding: 20px;
      ul {
        margin: 0 16px 0 0;
        &:last-of-type {
          margin: 0;
        }
        li {
          margin: 0 0 20px 0;

          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, gray2);
          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
    section {
      width: 840px;
      height: max-content;
      padding: 20px;

      @include getData(background-color, light);
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 4px;

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
    }
  }
}
