.a-template-selector {
  width: 643px;
  margin: 12px 0 0 0;
  .a-ts-tab {
    height: 40px;
    padding: 7px 0 0 16px;
    @include getData(background, light);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);

    a {
      @include btnClear;
      padding: 5px 6px 10px 6px;
      margin: 0 12px 0 0;

      border-radius: 0;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      @include getData(color, gray2);

      cursor: pointer;

      img {
        width: 13.53px;
        height: 13.53px;
        margin: 0 0 0 10px;
      }
    }
  }

  .a-ts-tab-list-item {
    width: 70%;

    li {
      a {
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .a-ts-tab-more {
    width: 30%;
    display: flex;
    justify-content: flex-end;

    i {
      width: 1px;
      height: 20px;
      margin: 5px 22px 8px 0;
      @include getData(background-color, gray5);
      display: flex;
    }

    .dropdown {
      margin-right: 16px;

      .dropdown-toggle {
        width: max-content;
        height: max-content;
        padding: 5px 6px 8px 6px;

        background-color: transparent;
        border: none;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, gray2);

        display: flex;
        align-items: center;
        span {
          width: 13.53px;
          height: 13.53px;
          margin: 0 0 0 10px;
          background-image: url(/img/main_page/icon/arrow/down.svg);
          transition: all 0.2s linear;
        }
        &.a-ts-tab-active {
          span {
            background-image: url(/img/main_page/icon/arrow/down_active.svg);
          }
        }
        &:after {
          display: none;
        }
        &:hover,
        &:focus,
        &:active {
          background-color: transparent;
          box-shadow: none !important;
          @include getData(color, gray2);
        }
      }
      &.show {
        .dropdown-toggle {
          span {
            transform: rotate(180deg);
          }
        }
      }
      .dropdown-menu {
        width: 174px;
        @include getData(max-height, templateMoreDropdownMaxHeight);
        padding: 6px 0;

        overflow-y: auto;
        overflow-x: hidden;
        @include getData(background-color, light, !important);
        @include getData(box-shadow, btns1Shadow);
        border-radius: 10px;
        border: none;

        @include customScrollbar;
        &::-webkit-scrollbar-track {
          @include getData(background-color, light);
          border-radius: 0 10px 10px 0;
        }

        // transform: translate3d(-90px, 33px, 0) !important;
        .dropdown-item {
          width: 100%;
          padding: 4px 16px;
          margin: 0;

          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          @include getData(color, gray1);

          display: flex;

          &:hover {
            @include getData(background-color, color2, !important);
            @include getData(color, light, !important);
          }

          &.active {
            @include getData(background-color, bg);
            @include getData(color, color2);
          }
        }
      }
    }
  }

  .a-ts-photos {
    padding: 24px 16px 28px 16px;

    @include getData(background, light);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    .a-ts-select {
      margin: 0 0 40px 0;
      z-index: 1;
      .a-ts-customize-cost {
        width: 100%;
        position: absolute;
        padding: 9px 12px 0 5px;
        h4 {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, light);

          display: block;
          align-items: center;
          em {
            display: block;
            margin: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, light);
          }
        }
        div {
          margin: 14px 0 0 0;

          display: flex;
          align-items: center;

          h5 {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, light);
          }

          span {
            width: 4px;
            min-width: 4px;
            height: 34px;
            margin: 0 6px 0 0;

            @include getData(background-color, light);
            border-radius: 4px;

            display: block;
          }
        }
      }
      .a-ts-customize-card {
        @include btnNone;
        width: 120px;
        min-width: 120px;
        height: 169.73px;
        margin: 0 20px 0 0;

        border-radius: 4px !important;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        z-index: 1;
        overflow: hidden;

        &:before {
          content: '';
          width: 100%;
          height: 100%;
          border-radius: 4px;

          @include bgImage(url('https://picsum.photos/124/80/?=random2'));
          opacity: 0.8;
          filter: blur(2px);
          background-size: 500%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }

        img {
          width: 124px;
          height: 77.83px;

          border-radius: 4px;
          z-index: 1;
        }
      }

      .a-ts-style {
        position: relative;
        @include btnNone;
        width: 120px;
        min-width: 120px;
        height: 170px;
        margin: 0 20px 0 0;

        border-radius: 4px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        position: relative;

        img {
          max-height: 170px;
          border-radius: 4px;
        }

        &:hover {
          border-radius: 4px;
          overflow: hidden;
        }

        &:last-of-type {
          margin: 0;
        }
      }

      .a-ts-select-btns {
        width: max-content;
        height: 28.29px;
        padding: 4px !important;

        @include getData(background-color, gray1);
        border-radius: 4px;

        display: flex;
        align-items: center;

        position: absolute;
        right: 4.42px;
        bottom: 4.71px;

        button {
          @include btnClear;
          margin: 0 4px 0 0;

          img {
            width: 20.29px !important;
            height: 20.29px !important;
            margin: 0 !important;
          }
          &:last-of-type {
            margin: 0;
          }
          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            box-shadow: none !important;
          }
        }
      }
    }
    .a-ts-add {
      padding-left: 19px;
      padding-right: 19px;
      .a-ts-view {
        width: 287px;
        height: 406px;
        position: relative;
        border-radius: 4px 0 0 4px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .a-ts-text {
        width: 287px;
        height: 406px;
        padding: 17px 17.97px 21px 18.03px;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
        // @include bgImage(url(/img/buying-friend/Detail/photo/bg.svg));
        border-radius: 0 4px 4px 0;
        h5 {
          margin: 0 0 8px 0 !important;

          font-weight: bold;
          font-size: 16px;
          line-height: 91.19%;
          @include getData(color, gray1);

          span {
            margin: 0 0 0 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
          }
        }
        h6 {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, gray1);
          &:first-of-type {
            margin: 0 0 4px 0 !important;
          }
          &:nth-of-type(2) {
            margin: 0 0 6px 0 !important;
          }
          &:last-of-type {
            margin: 0 0 12px 0 !important;
          }
        }
        .a-ts-message {
          width: 245px;
          height: 90%;
          padding: 12.8px 13px 26.76px 11px;
          margin: 8px 0 14px 0;

          // background: rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          // border-radius: 4px;

          position: relative;
          textarea {
            width: 100%;
            height: 100%;

            @include btnNone;

            resize: none;
            cursor: text;

            font-style: normal;
            font-weight: normal;
            font-size: 28px;
            line-height: auto;
            @include getData(color, gray2);

            &::placeholder {
              font-style: normal;
              font-weight: normal;
              font-size: 28px;
              line-height: auto;
              @include getData(color, gray2);
            }
          }

          i {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray3);

            position: absolute;
            bottom: 5.82px;
            right: 13px;
          }
        }

        .a-ts-media {
          width: 250px;
          @include getData(height, a-ts-media_height);

          border-radius: 10px;
          @include getData(border, gray4, null, 2px dashed);

          .dropdown-toggle {
            &:after {
              display: none;
            }
          }

          .support-hint {
            font-size: 12px;
            margin-top: 15.7px;
            @include getData(color, gray3);
          }
        }
      }
    }
    .a-ts-add-btn {
      padding: 0;
      background-color: transparent;
      border: none;

      &:focus,
      &:active {
        box-shadow: none;
      }

      img {
        width: 92px;
        height: 92px;
      }
    }
    .a-ts-add-photo-btn {
      width: 80%;
      padding: 0;
      font-weight: bold;
      display: flex;
      align-items: center;
      padding: 10.2px;
      border-radius: 103px !important;
      // background-color: transparent;
      @include btnFilled;
      border: none;
      &:focus,
      &:active {
        box-shadow: none;
      }
      img {
        width: 33px;
        height: 33px;
      }

      div {
        margin: 0 auto;
      }
    }
    .a-ts-bg-image {
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &-none,
      &-none-carousel {
        display: none;
      }
    }
    .a-ts-fg-image {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &-none {
        height: 156px;

        &-carousel {
          height: 96px;
          margin: auto;
        }
      }
    }
    .a-ts-blur {
      filter: blur(2px);
    }
    // customize content
    .a-ts-customize-info {
      width: 258px;
      height: 162px;
      padding: 12px 12.5px 0 12.5px;
      position: relative;
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 4px;

      // @include bgImage(url("/img/buying_flow_1_3/pic/step02/customize.png"));
      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, gray2);
        text-align: end;
      }
      h6 {
        margin: 0 0 8px 0;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray2);
        &:last-of-type {
          margin: 0;
        }

        &.tc-img-placeholder {
          position: absolute;
          bottom: 16px;
          color: #000;
        }
      }
    }
    .a-ts-customize-text {
      width: 100%;
      height: max-content;
      padding: 20px;
      margin: 8px 0 0 0;

      @include getData(background-color, light);
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 4px;
      h5 {
        margin: 0 0 8px 0 !important;

        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 91.19%;
        @include getData(color, gray1);
        span {
          margin: 0 0 0 8px !important;

          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, gray1);
        }
      }
      h6 {
        margin: 0 0 4px 0 !important;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray1);
        &:last-of-type {
          margin: 0 0 12px 0 !important;
        }
      }
      .a-form-control {
        margin: 0 0 12px 0;
        input {
          height: 44px;
        }
      }
    }
    .a-ts-customize-cost-content {
      width: 100%;
      padding: 0 41.5px;
      .a-ts-customize-cost-media {
        width: 258px;
        height: 162px;
        margin: 0 12px 0 0;

        @include getData(border, gray3, null, 1px dashed);
        border-radius: 4px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .a-ts-customize-cost-add {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .a-ts-customize-cost-crop {
          height: 100%;

          @include getData(background-color, black);

          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .a-ts-crop-btns {
            margin: 0 6.5px 7px 6.5px;
          }
        }
        .a-load-container {
          .a-load {
            height: 162px !important;
          }
        }
        .a-ts-customize-cost-upload {
          width: 100%;
          height: 100%;

          display: flex;
          align-items: flex-end;
        }
        .a-ts-remove-btn {
          margin: 0 6.5px 7px 6.5px !important;
          @include btnFilled;

          @include getData(background-color, btn, !important);
          border: none !important;

          @include getData(color, light, !important);
        }
        h6 {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, gray1);
          &:first-of-type {
            margin: 17px 0 0 0;
          }
          &:nth-of-type(2) {
            margin: 4px 0 6px 0;
          }
        }
      }
      .d-flex {
        &:last-of-type {
          width: 100%;
          height: max-content;
          padding: 20px;
          margin: 8px 0 0 0;

          @include getData(background-color, light);
          @include getData(box-shadow, pagesShadow, !important);
          border-radius: 4px;
          .a-ts-customize-cost-image {
            min-width: 230px;
            h5 {
              margin: 0 0 12px 0 !important;

              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 91.19%;
              @include getData(color, gray1);
            }
            h6 {
              margin: 0 0 4px 0 !important;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray2);
              &:first-of-type {
                margin: 0 0 10px 0 !important;
              }
              &:last-of-type {
                margin: 0 !important;
              }
            }
            em {
              margin: 12px 0 0 0 !important;

              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, color2);

              display: block;
            }
          }
          .a-ts-customize-text {
            padding: 0;
            margin: 0;

            @include getData(background-color, light);
            box-shadow: none;
          }
          hr {
            width: 1px !important;
            height: 239px !important;
            margin: 0 16px;

            @include getData(background-color, gray6);
          }
        }
      }
    }
    .a-ts-customize-card-content {
      width: 100%;
      padding: 0 41.5px;
      .d-flex {
        .a-ts-customize-card-media {
          width: 258px;
          height: 162px;
          margin: 0 12px 0 0;

          @include bgImage(url('https://picsum.photos/124/80/?=random2'));
          border-radius: 4px;
        }
        .a-ts-customize-text {
          width: 226px;
          height: max-content;
          padding: 0;
          margin: 0;
          box-shadow: none;
        }
        &:last-of-type {
          width: 100%;
          height: max-content;
          padding: 20px;
          margin: 8px 0 0 0;

          @include getData(background-color, light);
          @include getData(box-shadow, pagesShadow, !important);
          border-radius: 4px;
        }
      }
    }
  }

  .a-ts-terms {
    text-align: justify;
    width: 643px;
    min-height: 106px;
    margin: 14.5px 0 16px 0;
    padding: 15.5px 37px 15px 36px;

    @include getData(background, light);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    h6 {
      margin-bottom: 3.5px !important;

      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      @include getData(color, gray2);
    }
    p {
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray2);
    }
  }

  // multi-carousel
  .react-multi-carousel-item {
    width: 136px !important;
  }

  .react-multiple-carousel__arrow {
    @include btnClear;
    width: 83px;
    height: 83px;

    background-image: url('/img/account/icon/arrow_right_normal.svg');
    background-position: center center;
    background-size: contain;

    opacity: 1;
    &:before {
      display: none;
    }
    &::after {
      background-image: none;
    }
    &:hover {
      background-image: url('/img/account/icon/arrow_right_hover.svg') !important;
      box-shadow: none !important;
      opacity: 1;
    }
    &:focus,
    &:active {
      @include getData(background-image, arrowRightPressed, !important);
      box-shadow: none !important;
      opacity: 1;
    }
  }

  .react-multiple-carousel__arrow--left {
    // transform: rotate(180deg);
    left: -10px;
  }
  .react-multiple-carousel__arrow--right {
    right: -10px;
  }

  // cropper
  .cropper-container {
    border-radius: 4px;
    overflow: hidden;
    .cropper-view-box {
      outline: 1px solid #fff;
    }
    .cropper-point {
      display: none;
    }
  }

  .a-load-container {
    .a-load {
      width: 100%;
      height: 140px !important;
      padding: 0 !important;

      @include getData(background-color, black);
      border-radius: 4px;
    }
  }
}

.a-ts-upload {
  width: 300px !important;
  height: max-content !important;
  margin: 0 !important;
  padding: 20px !important;

  @include getData(background-color, gray4, !important);
  border-radius: 10px !important;
  border: none !important;

  position: absolute;
  top: -154px !important;
  left: -30px !important;
  transform: none !important;
  &::after {
    content: '';
    position: absolute;
    bottom: -16.5px;
    left: 50%;
    transform: translateX(-50%);

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 6.06px 0 6.06px;
    @include getData(border, gray1, transparent transparent transparent);
  }
  h5 {
    margin: 0;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, light, !important);
  }
  h6 {
    margin: 0 0 12px 0 !important;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, light, !important);
    &:first-of-type {
      margin: 6px 0 4px 0 !important;
    }
  }
  i {
    margin: 12px 0 0 0;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, light);

    display: block;
  }
  .a-ts-upload-file-btn {
    width: 260px !important;
    height: 39px;
    margin: 0;

    @include getData(background-color, color2, !important);
    border-radius: 10px;
    border: none !important;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, light, !important);
    &:hover {
      @include getData(background-color, btnHover);
      @include getData(color, light);
    }
    &:focus,
    &:active {
      @include getData(background-color, btnPressed);
      box-shadow: none !important;
      @include getData(color, light);
    }
  }
}

.a-ts-upload-horizontal {
  width: 100%;
  height: max-content;

  border: none;
  background-color: transparent;
  img {
    width: 25px;
    height: 25px;
  }
}

.a-ts-fullscreen {
  width: 100%;
  height: 140px;
  padding: 10px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover !important;

  border-radius: 10px !important;
  border: none;

  overflow: hidden !important;
  img {
    width: 25px;
    height: 25px;
    @include btnNone;
  }
}

.a-ts-crop-btns {
  display: flex;
  button {
    width: 100%;
    height: 24px;
    margin: 8px 0 0 0 !important;
    padding: 0 !important;

    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;

    display: flex;
    align-items: center;
    justify-content: center;
    &:focus,
    &:active {
      box-shadow: none !important;
    }
    &:first-of-type {
      margin-right: 8px !important;

      background-color: transparent !important;
      @include getData(border, color2, !important, 1px solid);

      @include getData(color, color2, !important);
    }
    &:last-of-type {
      @include getData(background-color, color2, !important);
      border: none !important;

      @include getData(color, light, !important);
    }
  }
}

.a-ts-remove-btn {
  width: 100%;
  height: 24px;
  margin: 8px 0 0 0 !important;
  padding: 0 !important;

  background-color: transparent !important;
  @include getData(border, color2, !important, 1px solid);

  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  @include getData(color, color2, !important);

  display: flex;
  align-items: center;
  justify-content: center;
  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

.a-ts-upload-file-btn-loading {
  background: rgba(255, 109, 98, 0.4) !important;

  display: flex;
  align-items: center;
  img {
    width: 20px !important;
    height: 20px !important;
    margin: 0 0 0 12px;
  }
}

// special state

.a-ts-style-active {
  @include getData(border, color2, !important, 3px solid);
  @include getData(box-shadow, light, !important, inset 0px 0px 0px 2px);
  border-radius: 4px !important;

  overflow: hidden;
  position: relative;
  .a-ts-style-check {
    width: 38px;
    height: 38px;

    position: absolute;
  }
  .a-ts-customize-card-img {
    width: 96.5% !important;
  }
}

.a-ts-tab-active {
  @include getData(color, btn, !important);

  @include getData(border-bottom, btn, !important, 2px solid);
  border-radius: 0px !important;
  span {
    background-image: url(/img/main_page/icon/arrow/down_pressed.svg);
  }
}

// rwd

.a-a-template-selector {
  @media screen and (min-width: 992px) and (max-width: 1399px) {
    width: 469px;
    .react-multi-carousel-item--active {
      width: 117.52px !important;
    }
    .a-ts-style {
      width: 99.31px;
      min-width: 99.31px;
      height: 140.47px;
    }
    .a-ts-view {
      width: 209.57px;
      height: 296.46px;
    }
    .a-ts-text {
      width: 208.84px;
      height: 296.46px;
      h5 {
        margin: 0 0 5.84px 0 !important;

        font-style: normal;
        font-weight: bold;
        font-size: 11.6831px;
        line-height: 91.19%;
        @include getData(color, gray1);
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 8.76232px;
          line-height: 10px;
        }
      }
      h6 {
        font-style: normal;
        font-weight: normal;
        font-size: 8.76232px;
        line-height: 10px;
        @include getData(color, gray1);
        &:first-of-type {
          margin: 0 0 2.92px 0 !important;
        }
        &:nth-of-type(2) {
          margin: 0 0 4.38px 0 !important;
        }
        &:last-of-type {
          margin: 0 0 8.76px 0 !important;
        }
      }
    }
    .a-ts-message {
      width: 178.9px;
      height: 88.35px;

      margin: 5.84px 0 10.22px 0;
      textarea {
        &::placeholder {
          font-style: normal;
          font-weight: normal;
          font-size: 28px;
          line-height: auto;
          @include getData(color, gray2);
        }
      }
      i {
        font-style: normal;
        font-weight: normal;
        font-size: 8.76232px;
        line-height: 10px;
        @include getData(color, gray3);
      }
    }
    .a-ts-media {
      width: 182.55px;
      height: 89.81px;
    }
  }
}

.a-qc-block-yes-btn-fontColor {
  color: #fff !important;
}
