.a-a-mobile-content {
  .a-a-mobile-detail {
    > section {
      display: flex;
      flex-direction: column;

      gap: 16px;
      margin: 0 !important;

      .a-card-display {
        margin: 0;
        backdrop-filter: none;

        .a-card-row {
          backdrop-filter: blur(2px);
        }
      }

      .a-card-list {
        margin: 0;

        h4 {
          margin: 0 !important;
        }

        > div {
          overflow: hidden !important;
          margin-left: -16px;
          margin-right: -16px;
          padding: 0 !important;

          .a-card-all {
            overflow: auto;
            margin-right: 16px;
            padding: 10px 16px 16px !important;

            .a-card-select {
              height: auto;

              #block-popup-toggle {
                margin-top: auto;
              }
            }
          }
        }
      }
    }

    .a-a-mobile-importGift-btn {
      width: max-content;
      height: 40px;
      padding: 10px 10px 10px 15px;

      @include getData(background-color, light);
      @include getData(box-shadow, btns1Shadow);
      border-radius: 4px;
      border: none;

      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      @include getData(color, btn);

      display: flex;
      justify-content: space-between;
      align-items: center;

      position: absolute;
      top: -60px;
      right: 0;
      img {
        width: 20px;
        height: 20px;
        margin-left: 36px;
      }
      &:hover,
      &:focus,
      &:active {
        @include getData(color, btn);
        @include getData(background, light);
        border: none !important;
        @include getData(box-shadow, btns1Shadow, !important);
      }
    }

    .a-card-list {
      .a-card-all {
        .a-card-select {
          width: 100%;
          max-width: 180px;

          font-family: Ubuntu;
          align-items: center;
          padding: 10px !important;

          > div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 4px;

            margin-bottom: 10px;

            span {
              width: 100%;
              max-width: max-content;
              font-size: 12px;
              @include getData(color, gray2);
            }
            h5 {
              margin: 0 !important;

              // overflow: hidden;
              // text-overflow: ellipsis;
              // display: -webkit-box;
              // -webkit-line-clamp: 2; /* number of lines to show */
              //         line-clamp: 2;
              // -webkit-box-orient: vertical;
            }
          }

          &.a-card-select-active {
            @include getData(border, btn, !important, 1px solid);

            > div.d-flex {
              .form-check {
                label {
                  @include getData(color, btn, !important);
                  margin: 0 !important;
                }
              }
            }
          }

          > div.d-flex {
            // width: 100%;

            align-items: center;

            margin: 0 10px 10px auto;

            h4 {
              height: 18px;
              margin: 0 !important;

              &.label-locked {
                display: flex;
                justify-content: center;
                align-items: center;

                padding: 4px;

                background-color: #fff3f2;
                border-radius: 100px;

                @include getData(color, formControlError);
                font-size: 12px;
                font-weight: 700;
              }
            }
          }

          .form-check {
            align-items: center;
            justify-content: center;

            input {
              &:checked[type='radio'] {
                border: 0 !important;
                @include getData(background-image, mobileRadioBg, !important);
              }
            }
          }
        }
      }
    }

    .a-card-content {
      padding-bottom: 60px;

      > #cardTabs {
        margin: 0 -16px;
        padding: 0 16px;

        > .a-card-tab > .dropdown > .dropdown-menu {
          padding: 10px;

          @include media(mobile_xs, mobile_sm) {
            left: 10px !important;
          }

          > :not(li:last-child) {
            padding-bottom: 10px;
            @include getData(border-bottom, gray5, null, 1px solid);
          }
        }
      }

      .a-card-pay {
        mark {
          max-width: 248px;

          margin-left: 0;
          margin-bottom: 0;

          @include getData(color, btn);
        }

        > div.position-relative {
          margin-left: -16px;
          margin-right: -16px;

          .a-card-pay-barcode,
          .a-card-pay-qrcode {
            flex-direction: row;
            gap: 24px;

            padding-left: 16px;

            .a-card-pay-common {
              .a-card-barcode-top {
                gap: 10px;
              }

              .a-qc-barcode-pin {
                width: 100%;

                div.d-flex {
                  @include getData(background-color, light);
                  width: 100%;
                  height: 100%;
                  padding: 0;
                }
              }

              .card-locked {
                align-items: center;

                @include getData(color, formControlError);
                font-size: 12px;
                @include getData(font-weight, normal-font-wieght);

                img {
                  width: 12px;
                  height: 12px;
                }
              }
            }

            .a-card-topup {
              margin-left: 0;
            }
          }
        }
      }

      .a-card-history {
        width: 100vw;

        margin: 0 -16px;
        padding: 0 16px 16px 16px;

        > div {
          .a-card-history-common {
            max-width: 343px;
            width: calc(100% - 8px);

            margin: auto !important;

            .a-card-history-top {
              > div.d-flex {
                &:first-child {
                  gap: 12px;

                  > h6 {
                    display: flex;
                    flex-direction: column !important;

                    em {
                      margin: 0 !important;
                    }
                  }
                }
              }
            }

            .a-card-history-middle {
              h2 {
                font-size: 14px;
              }
            }

            .a-card-history-bottom,
            > hr {
              display: none !important;
            }
          }
        }
      }

      .a-card-tnc {
        p {
          margin: 0;
          width: 100% !important;
        }
      }
    }

    .a-card-row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      @include media(mobile_lg, tablet, desktop) {
        grid-template-columns: 224.69px minmax(140px, 280px) minmax(120px, 206px);
      }

      > .a-card-info {
        margin-right: 0;
        height: 100px;
        width: 100%;
        min-width: 170.22px;
        height: auto;
        grid-column: 1/span 2;

        > img {
          width: 100%;
          border-radius: 8px !important;
        }

        @include media(mobile_lg, tablet, desktop) {
          width: 224.69px;
          min-width: 224.69px;
          height: 132px;

          > img {
            width: 224.69px;
          }
        }
      }

      > .a-card-info-modal-btn {
        height: 100px;
        width: 100% !important;
        max-width: none !important;
        min-width: 0;

        @include getData(box-shadow, btns1Shadow, !important);
        border-radius: 20px 20px 20px 0px !important;

        div {
          width: 100% !important;
          min-width: 112px;

          font-size: 14px;
          font-weight: 700;
          line-height: 16px;

          padding: 10px 18px !important;
          border-radius: 100px !important;

          @include media(mobile_xs, mobile_sm) {
            backdrop-filter: none !important;
            background: none !important;
          }
        }

        @include media(mobile_lg, tablet, desktop) {
          order: 3;
          height: 132px;
        }

        @include media(tablet) {
          max-width: 206px !important;
        }
      }

      > .a-card-detail {
        grid-column: 1 / span 2;
        max-width: none;
        min-width: 0;
        width: 100%;
        margin-right: 0;

        @include media(mobile_lg, tablet, desktop) {
          grid-column: 2 / 2;
          order: 2;
          max-width: 280px;
        }

        div {
          h5 {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          &:last-child {
            display: flex;
            flex-direction: column;

            h3 {
              @include getData(color, btn, !important);
              margin: 0 !important;
            }
          }
        }
      }
    }
  }

  .modal-block-content {
    text-align: center;

    gap: 10px;

    h5 {
      font-family: Source Sans Pro, Ubuntu;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }

    h6 {
      font-family: Source Sans Pro, Ubuntu;
      @include getData(color, color2);
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
    }
  }

  .a-card-pay-barcode > .a-card-barcode.a-card-pay-common {
    .a-card-code {
      button {
        padding: 0 !important;

        img {
          margin: auto;
        }
      }
    }
    .a-card-btn-set {
      > em {
        width: 100vw;
        height: 100vh;

        position: fixed !important;
        z-index: 10;
        left: 0 !important;
        top: 0 !important;
        background: rgba(255, 255, 255, 0.7);

        .mobile-success {
          margin: auto;

          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;

          padding: 14px;

          font-size: 14px;
          text-decoration: normal;
          font-style: normal;

          @include getData(color, light);

          @include getData(background-color, gray1);
          border-radius: 100px;

          > img {
            width: 24px !important;
            height: 24px !important;
            margin-right: 0 !important;
          }
        }

        &::after {
          display: none;
        }
      }
    }
  }

  .a-card-pay-qrcode > .a-card-qrcode.a-card-pay-common {
    .a-card-code {
      button {
        padding: 0 !important;

        img {
          margin: auto;
        }
      }
    }
    .a-card-btn-set {
      > em {
        width: 100vw;
        height: 100vh;

        position: fixed !important;
        z-index: 10;
        left: 0 !important;
        top: 0 !important;
        background: rgba(255, 255, 255, 0.7);

        .mobile-success {
          margin: auto;

          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;

          padding: 14px;

          font-size: 14px;
          text-decoration: normal;
          font-style: normal;

          @include getData(color, light);

          @include getData(background-color, gray1);
          border-radius: 100px;

          > img {
            width: 24px !important;
            height: 24px !important;
            margin-right: 0 !important;
          }
        }

        &::after {
          display: none;
        }
      }
    }
  }
}
