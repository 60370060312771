.a-bf-main {
  height: max-content;
  margin-top: 40px;
  padding-top: 36px;
  padding-bottom: 33px;

  @include getData(background-color, bg);

  .container {
    padding: 0;

    .a-bf-steps {
      padding: 0 8px 0 0;

      @media screen and (min-width: 1400px) {
        padding: 0 16px 0 0;
      }

      .a-bf-detail {
        width: 865px;
        height: max-content;
        margin-top: 30px;
        padding: 20px;

        @include getData(background-color, light);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

        :lang(fr) & {
          width: 953px;
        }

        .a-bf-hr {
          width: 1px;
          margin: 0 36px 0 25px;
          @include getData(background-color, gray6);
          display: block;
          align-self: stretch;
        }

        hr {
          width: 100%;
          height: 1px;
          @include getData(background-color, gray4);
        }

        @media screen and (min-width: 992px) and (max-width: 1199px) {
          margin: 8px 0 0 0;
        }

        .a-bf-amount {
          .a-bf-amount-title {
            padding: 10px !important;

            img {
              width: 30px;
              height: 30px;
              margin-right: 16px;
            }

            h4 {
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
            }

            h6 {
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray3);
            }
          }

          .a-bf-amount-content {
            padding: 0 !important;
          }
        }

        .a-bf-personalize {
          section {
            &:first-of-type {
              padding: 10px;

              img {
                width: 30px;
                height: 30px;
                margin-right: 16px;
              }

              h4 {
                font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                @include getData(color, gray4);
              }
            }
          }
        }

        .a-bf-undone {
          section {
            padding: 10px;

            img {
              width: 30px;
              height: 30px;
              margin-right: 16px;
            }

            h4 {
              @include getData(color, gray4);
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }

  .default-address {
    @include getData(background-color, bg);
    padding: 10px;
    border-radius: 4px;
    opacity: 0.4;

    &.check-active {
      opacity: 1;
    }

    p {
      margin: 0 0 4px;
      font-size: 12px;
      font-weight: 500;

      &:last-of-type {
        margin-bottom: 0;
      }

      &.address {
        @include getData(color, color2, !important);
      }
    }
  }
}

#uploadModal {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

#imageModal,
.imageModal {
  background-color: rgba(255, 255, 255, 0.8) !important;

  .modal-dialog {
    width: max-content;
    max-width: none;
  }

  .modal-content {
    width: max-content;
    height: max-content;
    padding: 0;

    position: relative;

    button {
      width: 30px;
      height: 30px;
      padding: 0;

      @include btnNone;
      position: absolute;
      top: 10px;
      right: 10px;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.imageModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
}

.a-bf-step1 {
  width: 643px;
  height: max-content;
  padding: 20px;
  margin: 20px 0;

  @include getData(background-color, light);
  @include getData(box-shadow, pagesShadow, !important);

  .a-bf-letter {
    margin-top: 12px;
  }

  .customerId-selector {
    position: relative;

    .customerId-list {
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25) !important;
      border-radius: 10px !important;
      position: absolute;
      width: 100%;
      z-index: 2;
      @include getData(background, light);
      max-height: 415px;
      overflow-x: hidden;
      overflow-y: scroll;
    }

    .select-down-icon {
      position: absolute;
      right: 12px;
      top: 50%;

      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  h5 {
    margin: 0 0 4px 0;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, gray2);
  }

  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-top: 4px;
    @include getData(color, gray3);
  }

  i {
    width: 528px;
    height: 1px;
    margin: 25px 10px;

    @include getData(background-color, gray6);
  }

  input {
    width: 150px;
    height: 44px;
    margin: 0 16px 0 0;
    padding: 0;
    margin-bottom: 14px;

    @include getData(background-color, gray6, !important);
    border-radius: 10px;
    border: none;
    outline: none;
    box-shadow: none;

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    @include getData(color, gray2);
    text-align: center;

    &.select-search {
      width: 100%;
      text-align: left;
      padding: 0 12px;
      @include getData(background-color, light, !important);
      font-size: 1rem;
      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25) !important;
      border-radius: 10px !important;
      cursor: pointer;
      margin-bottom: 6px;
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none !important;
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .a-bf-step1-btns {
    &.mt-6 {
      margin-top: 6px;
    }

    &.mt-15 {
      margin-top: 15px;
    }

    button {
      min-width: 42px;
      height: 40px;
      margin: auto 16px auto 0;
      padding: 10.5px 12px;
      margin-bottom: 15px;

      @include getData(background-color, light);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      border-radius: 4px;

      @include getData(color, gray1);
      font-size: 16px;
      line-height: 19px;

      &:last-of-type {
        margin-right: 0;
      }

      img {
        width: 10px;
        height: 10px;
      }

      &:hover,
      &:focus,
      &:active {
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      }
    }

    button:disabled {
      opacity: 0.2;
    }

    &:first-child {
      button {
        &:first-of-type {
          margin: 0;
        }
      }
    }

    // &.amount {
    //     button {
    //         margin: 0;
    //     }
    // }
    // &.qty {
    //     button {
    //         margin: 0;
    //     }
    // }
    &.btns-qty,
    &.btns-amount {
      button {
        width: 155px;
      }
    }
  }

  .a-b2b-bf-step1-redeem {
    h5 {
      margin: 0 0 15px 0 !important;
    }

    button {
      &:first-of-type {
        margin: 0 16px 0 0;
      }
    }
  }

  .a-b2b-bf-step1-uploadFile {
    h6 {
      margin: 0 0 6px 0 !important;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray2);

      &:last-of-type {
        margin: 0 !important;
      }

      span {
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, color2);
        cursor: pointer;
      }
    }

    .a-b2b-bf-step1-uploadFile-description {
      width: 100%;
      height: max-content;
      padding: 10px;
      margin: 0 0 12px 0;

      @include getData(background-color, bg);
      border-radius: 4px;

      h6 {
        margin: 0 0 4px 0 !important;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
      }

      p {
        margin: 0 !important;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray2);
      }
    }

    section {
      height: 39px;
      padding: 0 10px;

      @include getData(background-color, light);
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 10px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        @include btnClear;

        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, color2);
      }
    }

    .a-b2b-bf-step1-uploadFile-download {
      margin: 12px 0 0 0;

      display: flex;

      section {
        flex: 1;
        margin: 0 12px 0 0;

        &:last-of-type {
          margin: 0;
        }

        h5 {
          margin: 0 !important;

          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, gray2);

          display: flex;
          align-items: center;

          img {
            width: 24px;
            height: 24px;
            margin: 0 12px 0 0;
          }
        }

        .dropdown {
          .dropdown-menu {
            width: 200px;
            height: 104px;
            padding: 20px;

            background-color: #4c4c4c;
            border-radius: 10px;
            border: none !important;

            transform: translate3d(-35%, 33%, 0) !important;

            button {
              width: 100%;
              height: 27px;

              @include getData(background-color, light);
              @include getData(box-shadow, btns1Shadow);

              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, gray1);

              display: flex;
              align-items: center;
              justify-content: center;

              &:first-of-type {
                margin: 0 0 10px 0;
              }

              &:hover,
              &:focus,
              &:active {
                @include getData(background-color, color2, !important);
              }
            }

            &:before {
              content: '';

              width: 0;
              height: 0;
              border-style: solid;
              border-width: 0 8.66px 12px 8.66px;
              border-color: transparent transparent #ff6c62 transparent;

              position: absolute;
              left: 50%;
              top: -12px;
              transform: translateX(-50%);

              z-index: -1;
            }
          }
        }
      }
    }

    .a-b2b-bf-step1-uploadFile-media {
      width: 100%;
      height: max-content;
      min-height: 123px;
      padding: 0 0 8.71px 0;
      margin: 12px 0 0 0;

      @include getData(border, gray3, null, 1px dashed);
      border-radius: 4px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .a-b2b-bf-step1-uploadFile-media-add-btn {
        padding: 0;
        background-color: transparent;
        border: none;

        &:focus,
        &:active {
          box-shadow: none;
        }

        img {
          width: 92px;
          height: 92px;
        }
      }

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray3);

        display: block;
      }
    }

    .a-b2b-bf-step1-uploadFile-file {
      h5 {
        margin: 0 0 8px 0 !important;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
      }

      a {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
        text-decoration: underline !important;

        display: flex;
        align-items: center;
      }

      .a-b2b-bf-step1-uploadFile-errorSummary {
        height: max-content;
        padding: 20px;

        border-radius: 4px;

        display: block;

        h5 {
          margin: 0 0 20px 0 !important;

          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, gray2);
        }

        table {
          width: 100%;

          thead {
            th {
              padding: 0 0 10px 0;

              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              @include getData(color, gray2);

              @include getData(border-bottom, gray6, null, 1px solid);
            }
          }

          tbody {
            tr {
              h6 {
                margin: 0 0 4px 0 !important;

                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray2);

                span {
                  margin: 0 0 0 4px;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, btn);
                }
              }

              td {
                padding: 16px 20px 0 0 !important;

                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray2);
                vertical-align: top;

                &:first-of-type {
                  max-width: 116px;
                  padding-right: 20px !important;

                  h6 {
                    padding: 0 0 0 15px;

                    &:first-of-type {
                      padding: 0;
                    }
                  }
                }

                &:last-of-type {
                  width: 0 !important;
                  padding: 0 !important;
                }
              }

              &:first-of-type {
                td {
                  padding-top: 10px !important;
                }
              }

              &:last-of-type {
                td {
                  padding-bottom: 26px !important;
                  @include getData(border-bottom, gray6, null, 1px solid);
                }
              }
            }
          }
        }

        h4 {
          margin: 12px 0 0 0;

          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, gray2);
          text-align: end;

          strong {
            margin: 0 0 0 4px;

            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, gray2);
          }
        }
      }
    }
  }
}

.redeemable-place {
  &.isUploadFile {
    margin-top: 25px;
  }

  &.last {
    margin-bottom: 0;
  }

  input {
    width: 100%;
    margin: 8px 0 0;
  }

  .or {
    width: 247px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #bdbdbd;
    font-weight: 500;
    margin: 16px 0;

    &::after,
    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 109px;
      background: #bdbdbd;
    }
  }

  .a-bf-step1-shelf {
    width: 350px !important;
    margin-top: 6px;

    .MuiOutlinedInput-root {
      width: 100%;
    }
  }

  .redeemable-place-input {
    margin-top: 12px;

    div {
      margin-top: 8px !important;
      margin-bottom: 0px !important;
    }

    input {
      height: 44px;
    }
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 12px;
    font-weight: 400;
  }

  hr {
    margin: 30px 0;
  }
}

.b2b-urassaf-selector {
  width: 100%;

  &.isLast {
    padding-bottom: 30px;
  }

  i {
    position: absolute;
    background-color: transparent !important;
    @include getData(color, gray4, !important);
    font-size: 14px !important;
    font-weight: 500 !important;
    left: 16px;
    top: 7px;
    z-index: 1;
  }

  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, gray3);
  }

  .MuiFormControl-root {
    margin-top: 6px;

    .MuiSelect-root {
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      @include getData(color, gray1, !important);
    }

    &:first-of-type {
      .MuiInputBase-root {
        width: 350px !important;
      }
    }
  }
}

.invoicing-address-selector {
  .padding {
    padding-top: 25px;
  }

  .form-check {
    padding: 10px 10px 10px 0px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    input {
      width: 14px;
      height: 14px;
      margin: 0 12px 0 0;
      background-color: #fff !important;
      @include getData(border, gray5, null, 1px solid);
    }

    label {
      width: 80%;
      margin: 0;

      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, gray3);
    }

    &.check-active {
      input {
        @include getData(border, color2, !important, 1px solid);

        &:checked[type='radio'] {
          background-image: url('/img/B2B_loginAndRegister/icon/radius.svg') !important;
        }
      }

      label {
        @include getData(color, gray2);
      }
    }
  }

  .default-address {
    margin-right: 10px;
    @include getData(background-color, bg);
    padding: 10px;
    border-radius: 4px;
    opacity: 0.4;

    &.check-active {
      opacity: 1;
    }

    p {
      margin: 0 0 4px;
      font-size: 12px;
      @include getData(color, gray7);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .form-control {
    width: 100%;
    margin: 0;
  }

  .a-form-control {
    margin-bottom: 15px;

    &.invoicing-country {
      margin-bottom: 0;
    }
  }

  .invoicing-address-phone {
    input {
      width: 100%;
      margin-left: 16px;
    }
  }
}

.a-bf-detail-done h4 {
  @include getData(color, gray2, !important);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.a-bf-detail-active {
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    @include getData(color, color2, !important);
  }

  h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, color2, !important);
  }
}

.a-bf-detail-done {
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    @include getData(color, gray2, !important);
  }

  h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, gray2, !important);
  }
}

.a-bf-amount-active {
  @include getData(background-color, color2, !important);

  border-radius: 4px;
  border: none;
  box-shadow: none !important;

  @include getData(color, light, !important);
}

.a-bf-tab-active {
  padding: 4px 21.5px 10px 21.5px !important;

  @include getData(color, btn, !important);
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  @include getData(border-bottom, btn, !important, 3px solid);
}

.a-bf-select-active {
  @include getData(border, color2, !important, 3px solid);
  @include getData(box-shadow, light, !important, inset 0px 0px 0px 2px);
  border-radius: 4px !important;

  img {
    width: 23.71px;
    height: 23.42px;
  }
}

.a-b2b-bf-step1-uploadFile-file-error {
  a {
    @include getData(color, btn, !important);

    span {
      width: 24px;
      height: 24px;
      margin: 0 12px 0 0;

      @include bgImage(url('/img/B2B_buyingFlow/step1/error_doc.svg'));
    }
  }
}

.check-from-loading,
.import-card-loading,
.loading-full-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}
