.a-a-main {
  width: 100%;
  height: max-content;
  padding: 0 0 200px 0;

  overflow-x: hidden;
  .a-a-content {
    width: 869px;
    height: max-content;
    padding: 40px 0 0 0;
    h3 {
      margin: 28px 0 20px 44px !important;

            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;
            @include getData(color, black);
        }
        article {
            width: 100%;
            height: max-content;
            margin: 0 0 0 44px;
            @media screen and (min-width: 992px) and (max-width: 1399px) {
                width: 600px;
            }
            section {
                .a-form-control {
                    margin: 0 0 12px 0;
                    .receiverCity-selector {
                        position: relative;
                        input {
                            width: 100%;
                            outline: none;
                        }
                        .receiverCity-list {
                            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25) !important;
                            border-radius: 10px !important;
                            position: absolute;
                            width: 100%;
                            z-index: 2;
                            @include getData(background, light);
                            max-height: 415px;
                            overflow-x: hidden;
                            overflow-y: scroll;
                        }
                    
                        .select-down-icon {
                            position: absolute;
                            right: 12px;
                            top: 50%;
                    
                            &.rotate {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    input {
                        height: 44px;

            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, gray2);
            &.form-control-disable {
              @include getData(color, gray4, !important);
            }
            &::placeholder {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, gray4);
            }
          }
          label {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, gray2);
          }
          &:last-of-type {
            margin: 0;
          }
          &.password {
            margin: 0;
            div {
              margin: 0;
              position: relative;
              input {
                height: 44px;
              }
              button {
                width: max-content;
                height: max-content;
                margin: 0;
                padding: 0;
                @include btnNone;

                position: absolute;
                bottom: 10px;
                right: 25px;
                img {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }
        }
      }
      hr {
        width: 691px;
        height: 1px;
        margin: 10px;

        @include getData(background-color, gray4);
      }
      .a-a-btn {
        width: 100%;
        height: 40px;
        padding: 11.5px 0;

        @include getData(background-color, btn);
        border-radius: 4px;

        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, light);
        @include btnFilled;
      }
    }
    .a-a-corporate {
      padding: 20px;

      @include getData(background-color, light);
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 6px;
      hr {
        width: calc(100% - 20px);
        height: 1px;
        margin: 10px;
        @include getData(background-color, gray4);
      }
      section {
        margin: 0 0 20px 0;
        h5 {
          margin: 0 0 8px 0 !important;

          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, gray2);
        }
        .form-check {
          width: 260px;
          height: 39px;
          padding: 10px 10px 10px 10px;
          margin: 0 12px 0 0;

          @include getData(box-shadow, pagesShadow, !important);
          border-radius: 10px;
          input {
            width: 16px;
            height: 16px;
            margin: 0 12px 0 0;

            @include getData(border, gray5, null, 1px solid);
          }
          label {
            width: 80%;
            margin: 0;

            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, gray2);
          }
          &:last-of-type {
            margin: 0;
          }

          &.flex-1 {
            flex: 1;
          }
        }
      }
      .a-a-corporate-phone {
        select {
          width: 158px;
          height: 44px;
          margin: 0 8px 0 0;

          @include getData(background-color, light);
          @include getData(box-shadow, pagesShadow, !important);
          border-radius: 10px;
        }
      }
    }
    .a-a-profile-nav {
      padding: 0 20px;
      @media screen and (min-width: 992px) and (max-width: 1399px) {
        width: 601px;
        .a-card-tab {
          width: 100%;
          margin-left: 0 !important;
        }
      }
    }
    .a-a-profile {
      padding: 20px;

      @include getData(background-color, light);
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 6px;
      .phoneNumber {
        margin-bottom: 12px;
        .a-bf-form-phone-input {
          flex: 1;
          &.disabled {
            input {
              color: #bdbdbd !important;
            }
          }
        }
      }
      section {
        margin: 0 0 20px 0;
        h4 {
          margin: 0 0 20px 0 !important;

          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 21px;
          @include getData(color, gray2);
          &.with-hint {
            margin-bottom: 8px !important;
          }
        }
        i {
          display: block;
          width: calc(100% - 20px);
          height: 1px;
          margin: 25px 10px;
          @include getData(background-color, gray6);
        }
        .hint {
          font-size: 14px;
          line-height: 16px;
          @include getData(color, gray3);
          font-weight: 400;
          margin-bottom: 20px !important;
        }
        .swtich-changeable-row {
          margin: 8px 0 0 0;

          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          color: #696f79;

          display: inline-block;
          .btn-clear {
            @include btnClear;
            margin: 0 0 0 4px;
            cursor: pointer;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;

            background: linear-gradient(180deg, #00a6eb 0%, #0085ff 100%);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
          }
        }
      }

      .address-info {
        background-color: #fafafa;
        border-radius: 4px;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
          margin: 2px 0;
          color: #282828;
          font-size: 14px;
          line-height: 16px;
          &.address {
            font-size: 16px;
            font-weight: 700;
          }
        }
        .edit {
          width: 140px;
          height: 44px;
          border: 1px solid #4c4c4c;
          font-size: 16px;
          line-height: 19px;
          font-weight: 700;
          border-radius: 4px;
          background-color: #fafafa;
        }
      }
    }
    .a-a-profile-btns {
      margin-top: 20px;
      margin-left: 44px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      button {
        width: calc(50% - 10px);
        padding: 22px;
        box-sizing: border-box;
        line-height: 19px;
        box-sizing: border-box;
        border-radius: 6px;
        height: 63px;
        &.w-full {
          width: 100%;
        }
        &.a-a-cancel-btn {
          @include getData(background-color, light);
          @include getData(color, gray7);
          @include getData(border, gray7, null, 1px solid);
        }
        &.a-a-save-btn,
        &.a-a-save-btn-active {
          border-radius: 6px;
          font-style: normal;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, light);
          @include getData(background-color, btn);
          @include btnFilled;
        }
        &.a-a-save-btn:disabled {
          @include getData(background-color, gray5, !important);
          &:hover,
          &:focus,
          &:active {
            @include getData(background-color, gray5, !important);
            @include getData(background-color, gray5);
            box-shadow: none;
          }
        }
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1399px) {
      width: 601px;
      hr {
        width: 512px;
      }
    }
  }

  .account-update-profile {
    h3 {
      margin: 32px auto 12px !important;
    }
  }
}

// special state

.a-a-corporate-active {
  @include getData(background-color, bg, !important);
  box-shadow: none !important;
  input {
    @include getData(border, color2, !important, 1px solid);
    &:focus,
    &:active,
    &:checked {
      background-color: transparent !important;
      box-shadow: none !important;
    }
    &:checked[type='radio'] {
      @include getData(background-image, chooseOnePressed, !important);
    }
  }
  label {
    @include getData(color, color2, !important);
  }
}

.PasswordVerifyInfo {
  margin-top: 28px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;

  &__description {
    @include getData(color, gray2);
  }

  &__rules {
    margin-top: 2px;
    display: flex;
    flex-wrap: wrap;
    width: 560px;
  }

  &__rule {
    margin-top: 14px;
    width: 240px;
    @include getData(color, gray4);
    display: flex;
    align-items: center;

    > img {
      margin-right: 12px;
    }
  }

  &__rule--checked {
    @include getData(color, historyConfirmed);
  }
}
