.a-selector-with-default {
  position: relative;
  > i {
    position: absolute;
    background-color: transparent !important;
    @include getData(color, gray4, !important);
    font-size: 14px !important;
    font-weight: 500 !important;
    left: 16px;
    top: 7px;
    z-index: 1;
  }
}
