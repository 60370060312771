.mobile-menu-container {
  position: fixed;
  z-index: 999;
  gap: 24px;

  width: 100%;
  height: calc(100% - 64px);

  @include getData(background-color, light);

  padding: 24px 20px;
  margin-top: 64px;

  overflow: auto;
  .account-container {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .navigation-back {
      width: max-content;
      height: 44px;

      align-items: center;

      gap: 12px;
      font-size: 14px;
      line-height: 14px;
      @include getData(color, gray2);
      @include getData(font-weight, normal-font-wieght);

      img {
        width: 34px;
        transform: rotate(180deg);
      }
    }

    .account-overview {
      width: 100%;
      max-width: 350px;

      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      gap: 6px;

      padding: 10px;
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 4px;

      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      @include getData(color, gray2);
      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
        @include getData(color, gray3);
      }
    }
  }

  button.btn-primary {
    @include btnMobilePrimary;

    width: 100%;
    height: 44px;
    // max-width: 334px;

    font-size: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    margin-bottom: 10px;

    &.languageBtn {
      @include getData(background-color, light, !important);
      gap: 8px;
      @include getData(color, gray1);
      @include getData(box-shadow, btns1Shadow);
      margin-bottom: 0;

      img {
        margin-top: 2px;
      }
    }
  }

  a,
  h4 {
    @include getData(color, gray2);
    font-size: 16px;
    line-height: 16px;
    @include getData(font-weight, normal-font-wieght);

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: inherit;
    }

    &.logout-btn {
      h4 {
        @include getData(color, btn);
        padding-bottom: 10px;
      }
    }
  }

  .mobile-container-contact-us {
    padding: 18px 0 24px;
    @include getData(border-top, gray6, null, 1px solid);
    @include getData(border-bottom, gray6, null, 1px solid);

    div.d-flex {
      &.open {
        h4 {
          @include getData(color, btn);
        }
      }
    }

    .contact-us-content-container {
      width: 100%;
      gap: 12px;
      margin-top: 12px;
      align-items: stretch;

      .contact-us-content {
        width: 100%;

        display: flex;
        flex-direction: column;

        .upper-content {
          @include getData(background-color, btn);
          border-radius: 20px 20px 0px 0px;

          display: flex;
          justify-content: center;
          text-align: center;

          padding: 10px 0;
        }
        .bottom-content {
          height: 100%;

          display: flex;
          justify-content: center;
          align-items: center;

          border-bottom-right-radius: 20px;
          box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);

          @include getData(color, btnDisable);
          text-align: center;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 100%;

          padding: 12px;
        }
      }
    }
  }

  img {
    &.corporate-banner {
      width: 100%;
      border-radius: 4px;
      max-width: 350px;
    }
  }
}

.modal-warn > .container-languange-selector {
  @include getData(background-color, light);
  @include getData(box-shadow, popupShadow);

  gap: 16px;
  padding: 36px 18px 28px;
  border-radius: 20px;

  .title {
    text-align: center;
    font-size: 20px;

    margin-bottom: 8px;
    @include getData(font-weight, normal-font-wieght);
  }

  .container-selects {
    gap: 10px;

    padding: 8px 12px 8px 8px;

    border-radius: 20px;
    @include getData(box-shadow, pagesShadow, !important);

    cursor: pointer;

    .container-selects__radio {
      height: 20px;
      width: 20px;
      border: 1px solid #e3e5e8;
      border-radius: 100%;

      &.selected {
        @include getData(border-color, btn);
        @include getData(background-color, btn);
        background-image: url('/img/navigation/icon/radio_check.svg');
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    .container-selects__description {
      gap: 4px;

      font-size: 14px;
      @include getData(color, gray1);
      @include getData(font-weight, normal-font-wieght);
    }
  }
}
