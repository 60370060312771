@import 'styles/_variables';

.a-dgt-container {
  .a-dgt-show {
    opacity: 1;
  }

  .a-dgt-hide {
    opacity: 0;
  }
}

.duplicategifttoast-blur {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: white;
  opacity: 0.8;
  z-index: 2000;
}

.duplicategifttoast-region {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2001;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s;

  .a-dgt-inner-container {
    width: 476px;
    padding: 20px;
    border-radius: 10px;
    @include getData(background-color, gray1);
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }

  .a-dgt-title {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 28px;
  }

  .duplicategifttoast-title-text {
    flex: auto;
    text-align: center;
  }
}
.duplicategifttoast-title-text span {
  @include getData(color, light);
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  order: 0;
}

.duplicategifttoast-content {
  display: flex;
  justify-content: center;
}
.duplicategifttoast-container .duplicategifttoast-content,
.duplicategifttoast-b2b-container .duplicategifttoast-content {
  align-items: flex-start;
}
.duplicategifttoast-physical-container .duplicategifttoast-content {
  align-items: center;
}
.duplicategifttoast-left-content {
  width: 210px;
  height: 188.42px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.duplicategifttoast-product-name,
.duplicategifttoast-product-price,
.duplicategifttoast-product-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.duplicategifttoast-product-price {
  margin-top: 2px;
}
.duplicategifttoast-product-img {
  margin-top: 12px;
}
.duplicategifttoast-product-name span,
.duplicategifttoast-product-price span {
  font-family: AvenirNextLTPro;
  font-style: normal;
  font-size: 12px;
  line-height: 14px;
  @include getData(color, light);
}
.duplicategifttoast-product-name span {
  font-weight: bold;
}
.duplicategifttoast-product-price span {
  font-weight: normal;
}

.duplicategifttoast-right-content {
  width: 210px;
  display: flex;
  flex-direction: column;
}
.duplicategifttoast-right-content hr {
  width: 100%;
  margin: 16px 0 16px 0;
  @include getData(border, gray6, null, 1px solid);
}
.duplicategifttoast-right-content > div:nth-child(1) {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.duplicategifttoast-right-content > div:nth-child(1) > div:nth-child(1) {
  margin-top: 0;
}
.duplicategifttoast-detail-user,
.duplicategifttoast-detail-email,
.duplicategifttoast-detail-physical,
.duplicategifttoast-detail-package,
.duplicategifttoast-detail-letter,
.duplicategifttoast-detail-sms,
.duplicategifttoast-detail-date,
.duplicategifttoast-content-email,
.duplicategifttoast-content-phone,
.duplicategifttoast-content-sendwith {
  display: flex;
  align-items: center;
  justify-content: center;
}
.duplicategifttoast-detail-email,
.duplicategifttoast-detail-physical,
.duplicategifttoast-detail-package,
.duplicategifttoast-detail-letter,
.duplicategifttoast-detail-sms,
.duplicategifttoast-detail-date {
  margin-top: 4px;
}
.duplicategifttoast-content-email,
.duplicategifttoast-content-phone,
.duplicategifttoast-content-sendwith {
  align-items: flex-start;
  flex-direction: column;
}
.duplicategifttoast-content-info > div:not(:first-child) {
  margin-top: 16px;
}
.duplicategifttoast-detail-user img,
.duplicategifttoast-detail-email img,
.duplicategifttoast-detail-physical img,
.duplicategifttoast-detail-package img,
.duplicategifttoast-detail-letter img,
.duplicategifttoast-detail-sms img,
.duplicategifttoast-detail-date img {
  display: flex;
  width: 20.29px;
  height: 20.29px;
  background-repeat: no-repeat;
}
.duplicategifttoast-detail-user span,
.duplicategifttoast-detail-email span,
.duplicategifttoast-detail-physical span,
.duplicategifttoast-detail-package span,
.duplicategifttoast-detail-letter span,
.duplicategifttoast-detail-sms span,
.duplicategifttoast-detail-date span {
  margin-left: 4px;
  font-family: AvenirNextLTPro;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 14px;
  @include getData(color, light);
}
.duplicategifttoast-content-email span,
.duplicategifttoast-content-phone span,
.duplicategifttoast-content-sendwith span {
  display: flex;
  font-family: AvenirNextLTPro;
  font-style: normal;
  font-weight: normal;
  @include getData(color, light);
}
.duplicategifttoast-content-email span:nth-child(1),
.duplicategifttoast-content-phone span:nth-child(1),
.duplicategifttoast-content-sendwith span:nth-child(1) {
  font-size: 12px;
  line-height: 14px;
}
.duplicategifttoast-content-email span:nth-child(2),
.duplicategifttoast-content-phone span:nth-child(2),
.duplicategifttoast-content-sendwith span:nth-child(2) {
  margin-top: 4px;
  font-size: 14px;
  line-height: 16px;
}
.duplicategifttoast-physical-content-info {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}
.duplicategifttoast-physical-content-info > span:nth-child(1),
.duplicategifttoast-physical-content-info > span:nth-child(2) {
  display: flex;
  font-family: AvenirNextLTPro;
  font-style: normal;
  font-weight: normal;
  @include getData(color, light);
}
.duplicategifttoast-physical-content-info > span:nth-child(1) {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
}
.duplicategifttoast-physical-content-info > span:nth-child(2) {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 16px;
}
.duplicategifttoast-physical-content-info > div:nth-child(3) {
  width: 210px;
  height: 56px;
  @include getData(background-color, gray6);
  border-radius: 10px;
  padding: 10px;
  display: flex;
}
.duplicategifttoast-physical-content-info > div:nth-child(3) > div:nth-child(1),
.duplicategifttoast-physical-content-info > div:nth-child(3) > div:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.duplicategifttoast-physical-content-info > div:nth-child(3) > div:nth-child(2) {
  width: 150px;
  height: 36px;
  flex-direction: column;
}
.duplicategifttoast-physical-content-info > div:nth-child(3) > div:nth-child(1) img {
  width: 36px;
  height: 36px;
  background-repeat: no-repeat;
  margin-right: 4px;
}
.duplicategifttoast-physical-content-info > div:nth-child(3) > div:nth-child(2) span {
  width: 150px;
  display: flex;
  font-family: AvenirNextLTPro;
  font-style: normal;
  font-weight: normal;
  @include getData(color, gray1);
}
.duplicategifttoast-physical-content-info > div:nth-child(3) > div:nth-child(2) > span:nth-child(1) {
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
}
.duplicategifttoast-physical-content-info > div:nth-child(3) > div:nth-child(2) > span:nth-child(2) {
  font-size: 14px;
  line-height: 16px;
}

.ProductPreviewImage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 145px;

  &__physical-img {
    border-radius: 4px;
    width: 90px;
    height: auto;
  }

  &__img-with-template {
    border-radius: 4px;
    width: 90px;
    height: auto;
    position: absolute;
    z-index: 2;
    bottom: 30px;
    left: 10px;
  }

  &__template {
    border-radius: 4px;
    width: 66px;
    height: auto;
    position: absolute;
    transform: matrix(0.98, 0.2, -0.19, 0.98, 0, 0);
    right: 20px;
    bottom: 30px;
  }
}
