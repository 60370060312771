.footerMain {
  padding-top: 50px;
  padding-bottom: 27px;
}

.footerMain-Layout {
  display: flex;
  width: 75vw;
  max-width: 1100px;
  margin: auto;
  justify-content: space-between;
}

.footerMain-Column,
.footerMain-Support,
.footerMain-DownloadAndFollow {
  h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-bottom: 6px !important;
  }
}

.footerMain-Column {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #000000;
    font-size: 14px;
  }

  span,
  a {
    color: #000000;
  }

  .about-bigFont {
    font-size: 20px;
  }

  li {
    line-height: 35px;
  }
}

.footerSupport-TimeText {
  margin-top: 10px;
  margin-bottom: 0;
}

.footerSupport-Time {
  font-size: 14px;
  font-weight: 500;
}

.footerSupport-Icon {
  p {
    font-size: 15px;
    font-weight: 500;
    display: inline;
  }
}

.footerSupport-Phone {
  margin-bottom: 15px;
}

.footerSupport-Email {
  p {
    text-decoration: underline;
  }
}

.footerMain-DownloadAndFollow {
  flex: 0 0 22%;
}

.footerMain-Download {
  margin-bottom: 24px;
}

.footer-DownlodIcon {
  margin-top: 20px;

  img {
    width: 150px;
  }
}

.footerMain-Follow {
  display: flex;
  flex-wrap: wrap;
}

.footerMain-FollowIcon {
  width: 60px;
  height: 60px;
}

.footerBottom-Container {
  padding: 20.5px 0;
  background-color: #000000;
}

.footerBottom {
  display: flex;
  width: 75vw;
  max-width: 1100px;
  margin: auto;
  justify-content: space-between;
}

.footerBottom-Copyright {
  p {
    font-size: 13px;
    color: #ffffff;
    margin: 0;
    line-height: 37px;
  }
}

.footerBottom-Payment {
  display: flex;
}

.footerBottom-Payment {
  width: 360px;
  div {
    margin-right: 28px;
  }
}

.d-flex.flex-column.flex-xl-row {
  h3 {
    font-weight: 400;
  }
}

.MenuItem_fontFamily {
  // font-family: 'AvenirNextLTPro' !important
  font-family: 'Ubuntu' !important;
}

.MuiOutlinedInput-root,
.MuiInputBase-root {
  @include getData(font-family, bu-font-family, !important);
}

.statement-block__container {
  position: relative;
}

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 10px;
  top: 0;
  right: 0;
  font-size: 18px;
  line-height: 21px;
  color: $navBtn !important;
  box-shadow: $btns1Shadow;
  border: none;
  border-radius: 4px;
  background-color: $light;

  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    padding-bottom: 2px;
    content: url(../img/footerPagesImg/bx_home_normal.svg);
  }
  &:hover {
    color: $color2 !important;
    img {
      content: url(../img/footerPagesImg/bx_home_hover.svg);
    }
  }
  &:active {
    color: $light !important;
    background-color: $color2;
    img {
      content: url(../img/footerPagesImg/bx_home_press.svg);
    }
  }
}

.title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 16px;
}

.about {
  position: relative;
  font-weight: 500;
  .help {
    text-decoration: underline !important;
    color: $historyCancelled !important;
    margin-left: 4px;
  }
  .caption {
    font-size: 18px;
    color: $gray3;
    font-weight: 400;
    margin: 12px 0 20px;
  }
  .subtitle {
    margin: 20px 0;
  }
}

.general-condition {
  position: relative;
  font-size: 12px;
  hr {
    margin-bottom: 2em;
  }
  .prelude {
    font-weight: 500;
  }

  .subtitle {
    margin-top: 43px;
    text-decoration: underline;
    margin-bottom: 1rem;
    font-weight: 500;
  }
  ul {
    padding-left: 0.6rem !important;
  }
  li {
    padding-left: 4em;
    margin-bottom: 1em;
  }

  // .article3 {
  //   &__li {
  //     margin-left: 1.5em;
  //     text-decoration: underline;
  //   }

  //   .text-stronger {
  //     font-size: 1.33em;
  //     margin: 1rem 0 1.5rem;
  //   }
  // }
  // .article4 {
  //   .text-underline {
  //     text-decoration: underline;
  //   }
  // }

  // .article14 {
  //   margin-bottom: 4em;
  // }
}

.personal-data {
  position: relative;

  .subtitle {
    font-weight: 700;
  }

  a {
    color: black;
  }

  .mt-3 {
    margin-top: 3em !important;
  }
}

.legal-notices {
  position: relative;

  ul {
    padding-left: 0.2rem !important;
    list-style: '•';
  }
  li {
    padding-left: 0.4rem;
  }
}

.delivery-info {
  position: relative;
}
