.a-gs-main-mobile {
  @include wh100;

  @include getData(background-color, bg);

  padding-top: 64px;

  .a-gs-success {
    @include wh100;
    gap: 16px;
    padding: 16px;
    @include getData(background-color, a-gs-success-bg);

    > h2 {
      font-size: 20px;
      font-weight: 700;
      @include getData(color, a-gs-success-h2-color);
    }

    > img {
      padding: 16px 0;
    }

    > .a-gs-btn-container {
      @include wh100;
      background-color: #fff;
      max-width: 383.5px;

      padding: 24px;
      gap: 16px;

      box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
      border-radius: 20px;

      button {
        font-size: 16px;

        &:first-of-type {
          @include btnMobilePrimary;
          height: 100%;
          width: 100%;
          min-height: 40px;
        }
        &:last-of-type {
          @include btnMobileSecondary;
          height: 100%;
          width: 100%;
          min-height: 40px;
        }
      }
    }
  }
}
