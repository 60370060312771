#a-m-banner {
  height: 240px;
  padding-top: 32px;

  @include getData(background-color, color2);
  img {
    width: 179px;
    height: 184px;
    margin-right: 60px;
  }
  h2,
  h3 {
    margin-bottom: 0;

    @include getData(color, light);
    font-size: 24px;
    line-height: 34px;
  }
  h2 {
    margin-bottom: 12px !important;

    font-weight: bold;
  }
  a {
    width: 200px;
    height: 48px;
    margin-top: 24px;
    padding: 15px 24px 14px 25px;

    background-color: transparent;

    @include getData(color, light);
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    &:first-of-type {
      margin-right: 16px;
    }
    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }

  .btn_banner_googlePlay {
    background-color: #c1372c;
  }
  @media screen and (max-width: 767px) {
    height: max-content;
    padding-bottom: 32px;
    img {
      width: 155px;
      height: 159.33px;
      margin-right: 0;
      margin-bottom: 20px;
    }
    h2,
    h3 {
      text-align: center;
      font-size: 18px;
      line-height: 21px;
    }
    h2 {
      margin-bottom: 4px !important;
    }
    h3 {
      margin-bottom: 40px !important;
    }
    a {
      padding: 16px 24px 15px 25px;

      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      &:first-of-type {
        margin: 0 0 16px 0;
      }
      &:last-of-type {
        margin: 0;
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    height: max-content;
    padding-bottom: 32px;
    h2,
    h3 {
      font-size: 24px;
      line-height: 34px;
    }
    h2 {
      margin-bottom: 3.71px !important;
    }
    a {
      margin-top: 24px;
      padding: 13px 16px 13px 17px;

      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }
  }
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    img {
      margin-right: 56.6px;
    }
    h2 {
      margin-bottom: 11px !important;
    }
    section {
      margin-right: 100px;
    }
    a {
      margin-top: 0;
      &:first-of-type {
        margin: 0 0 16px 0;
      }
    }
  }
  @media screen and (min-width: 1400px) {
    img {
      margin-right: 56.6px;
    }
    h2 {
      margin-bottom: 11px !important;
    }
    section {
      margin-right: 100px;
    }
    a {
      margin-top: 0;
    }
  }
}
