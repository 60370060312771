.m-PaymentForm {
  width: 100vw;

  .a-bf-mobile-coupon-summary {
    padding: 16px;
    @include getData(background-color, light);

    > .d-flex {
      gap: 4px;
      padding: 10px 26px;
      @include getData(box-shadow, pagesShadow, !important);

      h4 {
        font-size: 14px;
        line-height: 17.6px;
        color: #000;
        @include getData(font-weight, normal-font-wieght);
      }

      h5 {
        font-weight: 400;
        font-size: 12px;
        line-height: 15.08px;
        @include getData(color, gray2);
      }

      .coupon-navigation {
        margin-top: 6px;
        align-items: center;
        gap: 12px;

        @include btnNone;
        padding: 0;

        img {
          display: flex;
          align-items: center;
        }

        h4 {
          @include getData(color, btn);
          display: flex;
          align-items: center;

          font-weight: 400;
          font-size: 12px;
          line-height: 15.08px;
        }
      }
    }
  }

  .a-bf-mobile-divider {
    gap: 24px;
    justify-content: center;

    hr {
      width: 89px !important;
    }
    h4 {
      display: flex;
      align-items: center;

      color: #838994;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .a-bf-mobile-payment {
    padding: 0 16px;
    .a-bf-form {
      display: flex;
      flex-direction: column;

      gap: 12px;

      .form-check {
        @include getData(background-color, light);

        width: 100%;
        height: 44px;

        display: flex;
        gap: 18px;

        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

        border-radius: 10px;
        padding: 12px 12px 12px 36px;
      }

      .form-check-label {
        @include getData(color, gray2);
        font-weight: 700;
      }

      input[type='radio'] {
        @include getData(accent-color, btn);
      }
    }

    .a-credit-form-control {
      .a-form-control {
        margin-bottom: 5px;
        .a-credit-form-img {
          margin: 0 10px 0 0;
        }
        .a-credit-form-text {
          line-height: 0;
          padding-top: 14px;
        }
        #number1 {
          height: 40px;
        }
        #cvc {
          height: 40px;
        }
      }
    }
    .available-text {
      display: flex;
    }

    .expiryDate {
      display: flex;
    }

    .expiry {
      margin-right: 8px;
    }

    .rccs {
      margin: 20px auto;
    }
    .divid-sym {
      font-size: 20px;
      margin: 0 6px;
    }
  }

  //usp-widget modification
  .widget-payment-selection {
    .usp-mt-4 {
      margin-top: 0;
    }

    .usp-widget-content {
      .usp-grid-cols-2 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
      .usp-items-stretch {
        width: 100% !important;
      }
      .usp-line-item,
      .protocol-item,
      .protocol-grid-item {
        width: 100% !important;
        height: 44px;

        display: flex;

        width: 100%;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;

        border-radius: 10px;
        padding: 12px;

        .form-check-input {
          margin: 0 !important;
        }

        span {
          font-weight: 700 !important;
          @include getData(color, gray2);
        }
      }
    }
  }

  #ccexp {
    @include getData(background-color, light, !important);
  }

  .a-bf-mobile-paymentform-btn {
    width: 100%;
    position: fixed;
    z-index: 99;
    bottom: 0;
    padding: 16px;
    @include getData(background-color, light);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    .checkout-total {
      p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 700;
      }
    }

    button {
      font-size: 16px;
      line-height: 16px;

      &:focus {
        outline: none;
      }
      &.btn-primary {
        width: 100%;
        height: 44px;
        outline: none;
        @include btnMobilePrimary;
      }
      &.btn-secondary {
        width: 100%;
        height: 44px;
        outline: none;
        @include btnMobileSecondary;
      }
      margin: auto;
    }
  }
}
