@mixin flexColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

$popup-width: 316px;
$popup-content-width: 300px;

// force mobile style common popup by adding class *mobile-style*
// on className CommonPopup component
.mobile-style.common-popup {
  .common-popup {
    &__container {
      border-radius: 20px;
    }

    &__title {
      @include getData(font-weight, normal-font-wieght);
    }

    &__buttons {
      > .common-button {
        &--primary {
          @include btnMobilePrimary;
        }

        &--primary-outline {
          @include btnMobileSecondary;
        }
      }
    }
  }
}

.common-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  &__container {
    @include flexColumnCenter;
    position: relative;
    width: $popup-width;
    max-height: 600px;
    @include getData(background, light);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 40px 0px 16px;

    @include media(tablet, mobile) {
      border-radius: 20px;
    }
  }

  &__close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }

  &__scrollable {
    @include customScrollbar(4px, rgba(0, 0, 0, 0.2), 10px, 0px);
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 339px;
    width: 100%;
  }
  &__scrollable-inner {
    @include flexColumnCenter;
    width: $popup-content-width;
    margin-left: ($popup-width - $popup-content-width) / 2;
    &--md {
      padding: 0 10px;
    }

    &--sm,
    &--sm-center {
      padding: 0 17px;
    }

    &--sm-center {
      text-align: center;
    }
  }
  &__image {
    width: 266px;
    height: 130px;
    margin-bottom: 16px;
  }

  &__title {
    @include getData(color, gray1);
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 16px;

    @media screen and (max-width: 767px) {
      font-size: 16px;
    }

    @include media(tablet, mobile) {
      @include getData(font-weight, normal-font-wieght);
    }
  }

  &__content {
    width: 100%;
  }

  &__buttons {
    @include flexColumnCenter;
    margin-top: 32px;
    width: 280px;

    gap: 10px;
  }

  &__button {
    padding: 0;
    width: 100%;
    height: 44px;
    &--outline {
      @include btnOutlined;
      @include getData(color, btn);
      @include getData(background-color, light);
    }
  }

  &__line {
    margin-top: 16px;
    width: 280px;
    height: 1px;
    @include getData(background, gray4);
  }

  &__faqs {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 50px;
    font-weight: 500;
    line-height: 22.5px;
    img {
      margin-right: 10px;
    }

    @include btnTextWithIcon;
  }

    &__topup-context{
        font-size: 14px;
        text-align: center;
        >p.margin-bottom-none{
            margin-bottom: 0;
        }
        .link{
            text-decoration: underline !important;
        }
    }
}
