@import './variables';

.unlock {
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  font-size: 24px;

  @media screen and (min-width: 551px) and (max-width: 767px) {
    & {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 550px) {
    & {
      font-size: 14px;
    }
  }

  .loading-process {
    display: grid;
    place-items: center;
    height: 100%;
    text-align: center;

    h3 {
      font-size: 1.666em !important;
      margin-top: 0.9em;
      //@include getData(color, navBtn);
      @include getData(color, navBtn);
    }

    img {
      max-width: 200px;
    }
  }

  .unlock-result {
    background-image: url('/img/Unlock/unlock-order-bg.png');
    background-size: cover;
    background-position: center;
    min-height: 100vh;

    &-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      min-height: 100vh;

      img.lock-icon {
        width: 324px;
      }

      img.unlock-icon {
        width: 20vw;

        @media screen and (min-width: 767px) {
          width: 15vw;
        }
      }

      img.unlock-icon,
      img.lock-icon {
        @media screen and (min-width: 1400px) {
          margin-top: 90px;
          margin-bottom: 62px;
        }
      }

      @media screen and (min-width: 551px) and (max-width: 767px) {
        img.lock-icon {
          width: calc(324px * 0.666);
        }

        img.unlock-icon {
          width: calc(383px * 0.666);
        }
      }

      @media screen and (max-width: 550px) {
        img.lock-icon {
          width: calc(324px * 0.511);
        }

        img.unlock-icon {
          width: calc(383px * 0.511);
        }

        img.unlock-icon,
        img.lock-icon {
          margin-top: 58px;
          margin-bottom: 24px;
        }
      }

      .result-card {
        margin-top: 5vh;
        display: flex;
        align-items: center;
        padding: 0.8em;
        width: 900px;
        max-width: 94.8%;
        background: #333333;
        border-radius: 10px;
        margin-bottom: 30px;

        @media screen and (max-width: 1400px) {
          max-height: 40vh;
        }

        @media screen and (max-width: 550px) {
          & {
            display: block;
            text-align: center;
            margin-bottom: 24px;
          }
        }

        img {
          width: 75.38px;
          height: 75.38px;

          @media screen and (max-width: 767px) {
            & {
              width: 40px;
              height: 40px;
            }
          }

          @media screen and (max-width: 550px) {
            & {
              margin-bottom: 12px;
            }
          }
        }

        .content {
          margin-left: 2.083em;
          color: white;

          @media screen and (max-width: 550px) {
            & {
              margin: 0;
            }
          }

          .title {
            font-size: 32px;
            font-weight: 700;
            margin-bottom: 16.75px;
          }

          p {
            color: white;
            font-size: 16px;
            font-weight: 400;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      a {
        font-size: 16px;
        @include getData(color, link);
        margin-bottom: 90px;

        &:hover {
          @include getData(color, linkHover);
        }

        &:active {
          @include getData(color, linkPressed);
        }
      }
    }
  }

  .popup-confirmation {
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;

      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;

      @media screen and (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}

//61.25
