.m-QueryPage {
  width: 100%;
  height: 100%;

  position: relative;
  // z-index: 10;

  margin-top: 64px;
  &.navbar-hide {
    margin-top: 0px;
  }

  .a-qc-mobile-main {
    width: 100%;
    height: 100%;

    position: fixed;
    z-index: 10;

    @include getData(background-color, light);

    form {
      overflow: auto;

      height: 100%;

      @include getData(box-shadow, popupShadow);
      border-radius: 20px 20px 0px 0px;

      .a-qc-mobile-querycard {
        width: 100%;
        height: 100%;
        padding: 20px;

        section {
          width: 100%;

          label {
            margin: 0;

            font-size: 16px;
            font-weight: 400 !important;
            line-height: 20px;

            @include getData(color, gray2);

            &.error {
              @include getData(color, formControlError, !important);
            }
          }
          input {
            @include inputMobileDefault;
            @include getData(border-color, light, !important);
            width: 100%;
            @include getData(background-color, bg, !important);

            border-radius: 10px !important;

            &:focus,
            &:active {
              @include getData(background-color, bg, !important);
              @include getData(border-color, light, !important);

              &.error {
                @include getData(border-color, formControlError, !important);
              }
            }

            &::placeholder {
              @include getData(color, gray2);
            }
          }
          div {
            &:first-of-type {
              margin: 0 0 12px 0;
              input {
                height: 44px;
              }
            }
            &:last-of-type {
              div {
                margin: 0;
                position: relative;
                input {
                  height: 44px;
                }
                button {
                  width: max-content;
                  height: max-content;
                  margin: 0;
                  padding: 0;
                  @include btnNone;

                  position: absolute;
                  bottom: 10px;
                  right: 25px;
                  img {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }

        .error {
          i {
            @include getData(color, formControlError, !important);
          }
        }

        .popup-btn {
          width: 100%;

          position: fixed;
          bottom: 0;
          left: 0;
          z-index: 11;

          padding: 18px 16px;

          @include getData(box-shadow, popupShadow);
          border-radius: 20px 20px 0px 0px;

          .a-qc-mobile-query-btn {
            width: 100%;
            height: 44px;

            @include btnMobilePrimary;

            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, light);
          }

          #g-recaptcha-query-form > div > div {
            margin: auto;
          }
        }
      }

      #g-recaptcha-query-form {
        @media screen and (max-width: 344px) {
          width: 100%;
        }

        > div > div {
          margin: auto !important;

          @media screen and (max-width: 344px) {
            position: absolute;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

// resued elements
.a-qc-mobile-title {
  margin: 0 0 20px 0;
  h3 {
    margin: 0;

    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    @include getData(color, black);
  }
  h4 {
    margin: 12px 0 20px 0;

    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    @include getData(color, gray3);
    a {
      width: max-content !important;
      height: max-content !important;
      margin: 0 0 0 4px;
      padding: 0;

      background-color: transparent !important;
      @include getData(color, btn, !important);
      text-decoration: underline !important;
      @include btnText;
    }
  }
}

.a-qc-mobile-back-navigation {
  width: max-content;

  display: flex;
  gap: 12px;
  align-items: center;

  padding: 16px;

  img {
    margin-top: auto;
    margin-bottom: auto;
  }

  h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 12px;
    @include getData(color, gray2);
  }
}

// special state
.a-qc-mobile-bar-active {
  @include getData(background-color, bg, !important);

  @include getData(color, color2, !important);
}
.a-qc-mobile-querycard-bar {
  width: 840px !important;
}
.a-qc-mobile-faqs-bar {
  width: 1200px !important;
}
