// reused items
.a-a-tabs {
  width: 340px;
  height: max-content;
  padding: 30px 40px 40px 40px;
  margin: 0 20px 0 0;

  @include getData(background-color, bg);
  border-radius: 0px 0px 20px 20px;
  .a-a-type {
    width: 100%;
    height: 60px;
    padding: 10px;
    margin: 0 0 4px 0;

    @include getData(background-color, light);
    border-radius: 4px;
    h6 {
      margin: 0 0 6px 0 !important;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray3);
    }
    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, gray2);
    }
  }
  a {
    @include btnClear;
    img {
      width: 260px;
      height: 58px;
    }
  }
  hr {
    width: 100%;
    height: 1px;
    margin: 20px 0;

    @include getData(background-color, gray5);
  }
  .a-a-settings {
    a,
    button {
      @include btnClear;
      padding: 3px 0 4px 0;
      margin: 20px 0 0 0;

      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      @include getData(color, gray3);
      &::after {
        display: none;
      }
      &:first-of-type {
        margin: 0;
      }
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray3);
      }
    }
    .accordion {
      button {
        margin: 20px 0 0 0;
        box-shadow: none;
      }
      .accordion-item {
        background-color: transparent;
        border: none;
      }
      .accordion-collapse {
        border: none;
      }
      .accordion-body {
        padding: 20px 10px 10px 10px;
        a {
          @include btnClear;

          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, color2);
        }
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    width: 300px;
    padding: 30px 20px 40px 20px;
  }
}

// special state
.a-a-tabs-active {
  @include getData(color, color2, !important);
}

.a-a-info-corporate {
  display: none;
}
