.a-a-mobile-import {
  .a-form-control label {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 20px;
    @include getData(color, gray2, !important);

    margin-bottom: 4px !important;

    &.error {
      @include getData(color, formControlError, !important);
    }
  }

  .a-form-control input {
    height: 44px;
    @include getData(background-color, bg, !important);

    &.error {
      @include getData(border-color, formControlError, !important);
    }
  }
  .a-form-control button {
    width: 100%;
    height: 44px;

    padding: 11.5px 0;
    margin: 8px 0 0 0;

    display: flex;
    justify-content: center;
    align-items: center;
    @include btnMobilePrimary;
  }
}
