.a-card-history {
  width: 100%;
  height: max-content;
  // max-height: 812px;
  margin: 0 0 10px 0;

  overflow-y: auto;
  overflow-x: hidden;
  @include customScrollbar;
}
.a-card-history-common {
  width: calc(100% - 32px);
  height: max-content;
  // min-height: 189px;
  margin: 0 0 16px 16px !important;
  padding: 10px !important;

  @include getData(background-color, light);
  @include getData(box-shadow, btns1Shadow);
  border-radius: 0px 0px 4px 4px;
  border-top: none;
  .a-card-history-top {
    margin: 0 0 4px 0 !important;
    padding: 10px !important;
    h6 {
      margin: 0 0 4px 0 !important;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      @include getData(color, gray2);
      em {
        margin: 0 0 0 4px !important;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, gray2);
      }
    }
    h4 {
      margin: 0 !important;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray3);
      em {
        margin: 0 4px !important;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray3);
      }
    }
    h5 {
      margin: 0 0 4px 0 !important;

      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      @include getData(color, gray2);
    }

    @media screen and (min-width: 992px) and (max-width: 1399px) {
      h6 {
        margin: 0 0 12px 0 !important;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray2);
        em {
          margin: 4px 0 0 0 !important;

          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          @include getData(color, gray2);

          display: block;
        }
      }
      h4 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray3);
      }
      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .unlock-bar {
    @include getData(background, lightBg);
    @include getData(color, color2);
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    border-radius: 4px;
    font-size: 12px;
    margin-bottom: 4px;
  }

  .a-card-history-middle {
    margin: 0 !important;
    .a-card-history-left {
      flex: 1;
      padding: 10px;
      img {
        width: 68.8px;
        height: 43px;
        margin: 0 12px 0 0;

        border-radius: 4px;

        object-fit: cover;
      }
      h2 {
        margin: 0 4px 4px 0 !important;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
        &:last-of-type {
          margin: 0 !important;
        }
      }
      div {
        &:first-of-type {
          flex: 1;
        }
      }
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray2);
      }
      h3 {
        margin: 4px 0 0 0 !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, gray2);
      }
      .a-card-history-left-bold {
        font-weight: bold;
      }
      .unlock-btn {
        border: none;
        background: transparent;
        box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);
        font-size: 14px;
        @include getData(color, historyCancelled);
        font-weight: bold;
        padding: 10px;
        border-radius: 4px;
        margin-right: 20px;
        img {
          width: 13.3px;
          height: auto;
          margin: 0 0 0 11px;
        }
      }
      @media screen and (min-width: 992px) and (max-width: 1399px) {
        margin: 0 0 4px 0;
        h2 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          @include getData(color, gray2);
        }
      }
    }
    .a-card-history-right {
      .d-flex {
        @media screen and (min-width: 992px) and (max-width: 1399px) {
          max-width: 264px;
        }
        div {
          padding: 10px;
          span {
            margin: 0 0 10px 0 !important;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray2);
          }
          h3 {
            margin: 0 !important;

            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, gray2);

            display: flex;
            align-items: center;

            &.attached {
              cursor: pointer;
            }
            img {
              width: 16px;
              height: 16px;
              margin: 0 0 0 4px;
            }
          }
        }
      }
      .a-card-history-left-half {
        flex: 1;

        &:last-of-type {
          cursor: pointer;
        }

        @media screen and (min-width: 992px) {
          flex: auto;
        }

        .a-card-history-download {
          display: flex;
          padding: 0;
          img {
            margin-left: 4px;
          }
        }
      }
      .a-card-history-divider {
        border-left: 2px solid rgba(0, 0, 0, 0.1);
        height: 35px;
      }
      i {
        width: 1px;
        height: 32px;
        margin: 10px;

        @include getData(background-color, gray5);
      }
    }
  }
  .a-card-resend-btn {
    width: max-content;
    height: 37px;
    padding: 10px;
    margin: 0 0 0 12px;

    @include getData(background-color, light);
    @include getData(box-shadow, btns1Shadow);
    border-radius: 4px;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    @include getData(color, color2);
    img {
      width: 16px;
      height: 16px;
      margin: 0 0 0 4px;
    }
    &:hover {
      @include getData(background-color, light, !important);

      @include getData(color, color2, !important);
    }
    &:focus,
    &:active {
      @include getData(box-shadow, btns1Shadow);

      @include getData(color, color2, !important);
    }
    @media screen and (min-width: 992px) and (max-width: 1399px) {
      width: 100%;
      margin: 22px 0 0 0;
    }
  }
  hr {
    width: calc(100% - 20px) !important;
    height: 1px;
    margin: 2px 10px 2px 10px !important;

    @include getData(background-color, gray6);
  }
  .a-card-history-bottom {
    padding: 10px !important;
    a {
      margin: 0 !important;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray4, !important);
    }
    .a-card-history-detail-btn {
      width: max-content;
      height: max-content;
      padding: 0;

      background-color: transparent;
      border: none;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, color2);
      &:focus,
      &:active {
        box-shadow: none;
      }
      img {
        width: 16px;
        height: 16px;
        margin: 0 0 0 4px;
        transition: all 0.3s ease-in;
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }
  }
  .a-card-history-collapse {
    .card-body {
      width: 100%;
      padding: 10px;
      border: none;

      h5 {
        margin: 0 0 12px 0 !important;

        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, gray2, !important);
      }
      h6 {
        margin: 0 0 4px 0 !important;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray1);
        &:last-of-type {
          margin: 0;
        }
      }
      section {
        &:last-of-type {
          margin: 36px 0 0 0;
        }
      }

      hr {
        width: 100% !important;
        margin: 2px 0px !important;
        @include getData(border-bottom, gray4, null, 1px dashed);
        background: none;
      }
    }
  }
}
.a-card-history-cancelled {
  @include getData(border-top, historyCancelled, !important, 2px solid);
  h5 {
    @include getData(color, historyCancelled, !important);
  }
}

.a-card-history-requesting {
  @include getData(border-top, historyRequesting, !important, 2px solid);
  h5 {
    @include getData(color, historyRequesting, !important);
  }
}

.a-card-history-pending {
  @include getData(border-top, historyPending, !important, 2px solid);
  h5 {
    @include getData(color, historyPending, !important);
  }
}

.a-card-history-confirmed {
  @include getData(border-top, a-card-history-confirmedborder-top, !important);
  h5 {
    @include getData(color, historyConfirmed, !important);
  }
}

.a-card-history-wallet {
  .a-card-history-middle {
    .a-card-history-left {
      img {
        width: 42px;
        height: 42px;
      }
    }
  }
}
