.a-a-cards {
  .no-card {
    @include getData(color, formControlError);
    font-size: 1.3rem;
  }
  .a-form-control {
    margin: 0 0 40px 0;
    select {
      width: 250px;
      height: 43px;
      padding: 10px 37px 10px 20px;

      @include getData(background-color, light);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      border: none;

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 140.62%;
      @include getData(color, gray4);
    }
    input {
      height: 43px;
      margin: 0 0 0 12px;
      padding: 10px 20px;

      @include getData(background-color, light, !important);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      border: none;

      @include getData(color, gray4);
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: start !important;
      &::placeholder {
        @include getData(color, gray4);
        font-weight: bold;
        text-align: start !important;
      }
      &:focus {
        border: none !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
      }
    }
  }
  a {
    @include btnClear;
    display: inline;
  }
  .a-a-card-row {
    margin: 0;
    a {
      height: auto;
      margin: 0 0 36px 0;

      display: block;
    }
    .a-a-card {
      display: flex;
      flex-direction: column;
      flex: 1;

      width: 250px;
      height: 100%;
      padding: 20px;
      margin: 0 16px 0 0;

      @include getData(background-color, light);
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 10px;
      img {
        width: 210px;
        height: 132px;

        object-fit: cover;
        border-radius: 10px;

        &.productImg__no-img {
          margin: 18px auto;
          width: 96px;
          height: 96px;
        }
      }
      h1 {
        margin: 12px 0 2px 0 !important;
        min-height: 42px;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        @include getData(color, gray1);
      }
      h2 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, gray3);
      }
      // i {
      //     padding: 2px 10px;
      //     margin: 2px 0 0 0 !important;

      //     @include getData(border, gray4, null, 1px solid);
      //     border-radius: 6px;

      //     font-style: normal;
      //     font-weight: normal;
      //     font-size: 12px;
      //     line-height: 14px;
      //     @include getData(color, gray3);

      //     display: inline-block;
      // }
      .a-a-card-expiry {
        padding-bottom: 5px;
        width: fit-content;
        button {
          @include btnClear;
          padding: 2px 10px;
          margin: 2px 0 0 0;

          @include getData(border, gray4, null, 1px solid);
          border-radius: 6px;

          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, gray3);
        }
        .dropdown-toggle {
          &::after {
            display: none;
          }
        }
        .a-a-card-expiry-dropdown {
          width: 278px;
          height: max-content;
          max-height: 472px;

          padding: 20.73px 10px 20.27px 20px;

          @include getData(background-color, gray1);
          border-radius: 10px;
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

          position: absolute;
          // top: 9px !important;
          &:before {
            content: '';

            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 7px 9px 7px;
            border-color: transparent transparent #333333 transparent;

            position: absolute;
            top: -9px;
            left: 28px;
          }
          .a-a-card-expiry-top {
            margin: 0 0 20px 0;

            display: flex;
            align-items: center;
            justify-content: space-between;
            h5 {
              font-style: normal;
              font-weight: bold;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, light);
            }
            button {
              @include btnClear;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
          .a-a-card-expiry-list {
            width: 100%;
            height: max-content;
            max-height: 110px;
            padding-right: 14px;

            overflow-x: hidden;
            overflow-y: visible;
            @include customScrollbar;
            &::-webkit-scrollbar {
              width: 12px;
            }
            &::-webkit-scrollbar-thumb {
              background: rgba(255, 255, 255, 0.05);
            }
            li {
              h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                @include getData(color, light);
              }
              span {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                @include getData(color, gray6);
                text-align: right;

                display: block;
              }
              hr {
                width: 100%;
                height: 0;
                margin: 16px 0;

                @include getData(border, gray2, null, 1px dashed);
              }
            }
          }
        }
      }

      h3 {
        margin: auto 0 0 0 !important;
        padding: 12px 0 0 0 !important;

        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, gray1);
        text-align: end;
        em {
          margin: 0 0 0 4px;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          @include getData(color, gray1);
        }
      }
    }
  }
}
