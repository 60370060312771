$_nav__shadow--bg: 0px 0px 10px rgba(0, 0, 0, 0.25);
$_nav__shadow--btns1: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);
$_nav__shadow--btns2: 0px 0px 8px rgba(0, 0, 0, 0.25);

.dropdown-toggle::after {
  display: none;
}
._nav {
  width: 100%;
  height: 64px;
  padding: 0;
  @include getData(background-color, light);
  box-shadow: $_nav__shadow--bg;
  &__container {
    width: 100%;
    padding: 0 40px;
    margin: 0;
    display: flex;
    justify-content: flex-end !important;
  }
}

._dropdown {
  &__toggle {
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    &--hidden {
      display: none;
      &:checked ~ ._dropdown__menu {
        display: block;
      }
    }
  }
  &__menu {
    width: 180px;
    padding: 20px !important;
    height: max-content;
    @include getData(background, light);
    border-radius: 10px;
    box-shadow: $_nav__shadow--btns1;
    position: absolute;
    right: -16px;
    top: 15px !important;
    display: none;
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8.66px 12px 8.66px;
      @include getData(border-color, color2, transparent, transparent transparent);
      position: absolute;
      top: -12px;
      right: 3px;
    }
  }
  &__item {
    padding: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    @include getData(color, gray2);
    cursor: pointer;
    &:hover {
      background: transparent;
      @include getData(color, gray2);
      text-decoration: none;
    }
  }
  &__login {
    display: none;
  }
  &__text {
    &:hover {
      @include getData(color, color2);
    }
  }
  &__hr {
    width: 100%;
    height: 1px;
    margin: 12px 0;
    @include getData(background, gray5);
  }
  &__heading {
    margin: 0 0 8px 0;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    @include getData(color, gray3);
    display: block;
  }
  &__menu {
    .dropdown-toggle {
      margin-bottom: 12px !important;
      img {
        display: inline-block;
        margin-right: 12px;
      }
      &:last-child {
        margin-bottom: 0 !important;
      }
    }
  }
}

#navBar {
  .a-n-nav-mobile {
    ._dropdown__menu {
      a {
        @include btnNone;
        display: flex;
        justify-content: center;
        margin-right: 0;
        width: 100%;
        margin-bottom: 12px;
        padding: 0;

        @include getData(color, gray2);
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;

        &.menu-btn {
          padding-top: 11px;
          padding-bottom: 10px;

          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
          // @include getData(background-color, btn, !important);
          border-radius: 4px;

          text-align: center;
          // @include getData(color, light);
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }
        &.menu-btn-last {
          margin-bottom: 0 !important;
        }
        &.menu-btn-2 {
          margin-bottom: 0;
          padding-top: 11px;
          padding-bottom: 10px;
          border-radius: 4px;
          @include getData(background-color, light, !important);

          @include getData(border, btn, null, 1px solid);
          box-sizing: border-box;

          @include getData(color, btn);
          text-align: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
        }
        &.menu-btn-3 {
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          justify-content: flex-start;
          img {
            margin-right: 8px;
          }
        }
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
}
