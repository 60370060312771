.a-m-feature-desktop {
    // height: 480px;
    @include getData(margin-top, a-m-feature-desktop-margin-top);

  @include getData(background-color, color2);
  h1 {
    margin: 22px 0 24px 60px !important;

    font-style: normal;
    font-weight: bold;
    @include getData(font-size, FeatureBannerH1FontSize);

    line-height: 130.12%;
    @include getData(color, light);
    &.a-m-title {
      &:lang(es) {
        margin: 30px 0 24px 68px !important;
        font-size: 40px;
      }
    }
  }
  .a-m-left {
    width: 487px;
    overflow: visible;
    @include getData(background, ekdrFeatureBannerBg);
  }
  .a-m-content {
    width: 505px;
    height: 240px;
    padding: 0 36.5px;
    position: absolute;
    bottom: 22px;
    left: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    p {
      margin-bottom: 32px;

      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      @include getData(color, light);
    }
    button {
      width: 200px;
      height: 60px;

      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      // &:last-of-type {
      // @include btnPrimary;
      background-color: transparent;
      @include getData(border, light, null, 1px solid);
      @include getData(color, light);
      &:focus,
      &:active {
        box-shadow: none;
      }
      // }
      &:first-of-type {
        margin-right: 32px;

                @include btnFilled;
            }
            &.w-full {
                width: 100%;
                margin-right: 0;
            }
        }
    }
    .a-m-card-blogs-wrap {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .a-m-media {
        width: calc(100vw - 487px);
        // padding: 22px 108px;
        padding-right: 0;

        @include bgImage(url(''));
        overflow: hidden;
        span {
            margin-top: 12px;
        }
        .a-m-card-blog {
            min-width: 270px;
            max-width: 270px;
            height: 436px;
            margin-left: 8px;
            margin-right: 8px;

      border-radius: 10px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      background-color: rgba(0, 0, 0, 0.7);

      &:first-of-type {
        margin-left: 0;
        padding-right: 10px;
        padding-bottom: 13px;
        padding-left: 11px;

        background-image: url(/img/mainPage/01_banner/pic/01.png);
        background-size: contain;
        p {
          height: 183px;
          margin: 0;
          padding: 4px 12px 9px 13px;
          @include getData(background, FeatureBannerBg);

          border-radius: 10px;

          @include getData(color, light);
          @include getData(font-size, FeatureBanner1PFontSize);
          line-height: 42px;

          &:lang(fr) {
            font-size: 37px;
          }

          &:lang(es) {
            width: 100%;
            font-size: 28px;
          }

          strong {
            @include font;
            @include getData(color, light);
            font-weight: bold;
            font-size: 30px;
            line-height: 43px;
          }
        }
      }
      &:nth-of-type(2) {
        @include getData(background-color, FeatureBanner2Bg);
      }
      &:nth-of-type(3) {
        @include getData(background-color, FeatureBanner3Bg);
      }
      &:last-of-type {
        margin-right: 0;
        @include getData(background-color, FeatureBanner4Bg);
      }
      img {
        border-radius: 10px 10px 0 0;
        width: 270px;
        height: 270px;
      }
      h6 {
        margin: 24px 24px 18px 24px;

        @include getData(color, light, !important);
        font-size: 14px;
        line-height: 16px;
      }
      h3 {
        margin-left: 24px;
        margin-bottom: 0 !important;

        @include getData(color, light);
        font-size: 24px;
        line-height: 28px;
      }
    }
    .a-m-indicator {
      width: 63px;
      height: 12px;
      margin: auto 10px;

      @include getData(background, black);
      mix-blend-mode: overlay;
      opacity: 0.4;
      border-radius: 100px;

            transform: rotate(180deg);
        }

        .a-m-banner-without-slider {
            position: relative;
            width: 100%;
            // margin-top: 64px;
        
            .container {
                width: 100%;
                max-width: 100%;
                margin: 0;
                padding: 0;
                @include getData(background-color, root-background-color);
                display: flex;
                justify-content: center;
        
                .center {
                    display: flex;
                    align-items: center;
                    max-height: 480px;
                    width: 100%;
                    position: relative;
                    justify-content: center;
        
                    img {
                        @include getData(width, a-m-banner-without-slider-img-width);
                        @include getData(border-radius, a-m-banner-without-slider-img-border-radius);
                    }
                }
            }
        }
    }
    .a-m-media_carrefour_container {
        height: 100%;
        width: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }
    .a-m-media_carrefour_bg {
        @include bgImage(url(/img/main_page/pic/ad_gift.png));
        height: 100%;
        width: 100%;
        filter: blur(3px);
        transform: scale(1.1);
    }
    .a-m-media_carrefour_center {
        position: absolute;
        top: 0;
        height: 100%;

        img {
            height: 100%;
        }
    }
    .a-m-media-login {
        width: 100vw;
        // padding: 22px 36px;
    }
    .a-scroll-btns {
        z-index: 1002;
        button {
            &:first-of-type {
                left: 40px;
            }
            &:last-of-type {
                right: 40px;
            }
        }
    }
    .a-m-card-blogs-scrollLeft {
        &.login,
        &.notLogin {
            transform: translateX(0);
            transition: 0.5s;
        }
    }
    .a-m-card-blogs-center {
        justify-content: center;
    }

  .a-m-card-blogs-scrollRight {
    &.login {
      transform: translateX(calc(100vw - 1200px));
      transition: 0.5s;
    }
    &.notLogin {
      transform: translateX(calc(100vw - 1759px));
      transition: 0.5s;
    }
  }
  @media screen and (min-width: 1199px) {
    .a-scroll-btns {
      &.login {
        display: none;
      }
    }
    .a-m-media-login {
      display: flex;
      justify-content: center;
    }
    .a-m-card-blogs-scrollRight {
      &.login {
        transform: none;
      }
    }
  }
  @media screen and (min-width: 1749px) {
    .a-scroll-btns {
      display: none !important;
    }
  }
}

.a-m-card-blog-action {
  cursor: pointer;
  margin-left: 24px;
  margin-bottom: 24px;
  @include getData(color, light);
  font-size: 14px;
  line-height: 19px;
}

.a-m-card-blog-underline {
  margin-right: 2px;
  text-decoration: underline !important;
}

.a-m-card-blog-seeMore {
  width: 500px;
  height: auto;
  padding: 20px;

  border-radius: 10px;
  @include getData(background-color, light);
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;

  right: 14px !important;
  img {
    width: 100%;
    object-fit: contain;
  }
}

.a-m-feature-mobile {
  width: 100%;
  height: 200px;
  margin-top: 64px;

  @include bgImage(url(/img/main_page/pic/W768px_below_size.png));
  border-radius: 0 0 10px 10px;
  filter: drop-shadow(0px 4.74074px 4.74074px rgba(0, 0, 0, 0.25));
  section {
    width: 100%;
    height: 100%;

    background: linear-gradient(180deg, rgba(255, 109, 98, 0.2) 0%, #f4283a 100%);
    border-radius: 0 0 10px 10px;
    div {
      max-width: 350px;
      height: 100%;
      padding: 17px 26px;
      margin: auto;
      p {
        margin-bottom: 12px;
        padding-left: 16px;
        padding-right: 16px;

        font-size: 32px;
        @include getData(color, light);
        line-height: 37px;
      }
      strong {
        @include font;
        @include getData(color, light);
        font-weight: bold;
        font-size: 32px;
        line-height: 38px;
      }
      button {
        width: 100%;
        height: 40px;

        @include btnNone;
        @include getData(border, light, null, 1px solid);
        box-sizing: border-box;
        border-radius: 4px;

        @include getData(color, light);
        font-size: 14px;
        font-weight: bold;
        &:hover,
        a-m-content &:focus,
        &:active {
          @include getData(border, light, null, 1px solid);
          border-radius: 4px;

          @include getData(color, light);
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    height: max-content;
    section {
      height: max-content;
      padding-top: 67px;
      padding-bottom: 67px;
    }
  }
}

.a-m-main {
  width: 100%;
  height: max-content;
  padding-bottom: 200px;

  @include getData(background-color, bg);
  h3 {
    margin-bottom: 49px !important;

    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
  }
  @media screen and (max-width: 767px) {
    padding: 32px 0 60px 0;
    h3 {
      margin-bottom: 28px !important;

      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    h3 {
      margin-bottom: 28px !important;

      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
    }
  }
  .a-m-slogan {
    width: 100%;

    @include getData(background-color, a-m-slogan-background-color);
    @include getData(height, a-m-slogan-height);
    @include getData(margin, a-m-slogan-margin);
    .a-m-slogan-content {
      @include getData(width, a-m-slogan-content-width-xl);
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (min-width: 992px) and (max-width: 1200px) {
        @include getData(width, a-m-slogan-content-width-l);
      }
      h3 {
        margin: 0 0 4px 0 !important;

        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 23px;
        @include getData(color, black);
        text-align: start;
      }
      h6 {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray3);
      }
      button {
        width: 184px;
        height: 40px;
        padding: 10px 15px;

        @include getData(background-color, light);
        @include getData(box-shadow, btns1Shadow);
        border-radius: 4px;

        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, btn);
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .a-m-filter {
    margin-bottom: 38px;
    input {
      height: 45px;
      margin: 0 0 0 12px;
      padding: 10px 20px;

      @include getData(background-color, light, !important);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
      border-radius: 10px;

      border: transparent 1px solid;

      @include getData(color, gray4);
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: start !important;
      &::placeholder {
        @include getData(color, gray4);
        font-weight: bold;
        text-align: start !important;
      }
      &:focus {
        border: transparent 1px solid;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .MuiInputBase-root,
    .MuiOutlinedInput-root {
      box-shadow: none !important;
    }

    .MuiSelect-root,
    .MuiSelect-select,
    .MuiSelect-selectMenu,
    .MuiSelect-outlined,
    .MuiInputBase-input,
    .MuiOutlinedInput-input {
      height: 43px !important;
      @include getData(font-family, bu-font-family, !important);
      @include getData(background-color, light, !important);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
      border: transparent 1px solid !important;
      &:focus,
      &:active {
        @include getData(background-color, light, !important);
        border: transparent 1px solid !important;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      box-shadow: none !important;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 20px;
      input {
        max-width: 450px;
        margin-top: 12px;
        padding: 10px auto 10px 24px;

        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
      }
      .MuiInputBase-root {
        width: 100%;
        max-width: 450px;
        height: 43px !important;
        margin: 0 auto !important;
      }
      .MuiSelect-root {
        width: 100%;
        height: 43px !important;
        margin: 0 auto !important;
      }
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      margin-bottom: 20px;
      input {
        max-width: 533px;
        margin-top: 12px;
        padding: 10px auto 10px 24px;

        font-weight: bold;
        font-size: 16px;
        line-height: 22px;
      }
      .MuiInputBase-root {
        width: 100%;
        max-width: 533px;
        height: 43px !important;
        margin: 0 auto !important;
      }
      .MuiSelect-root {
        width: 100%;
        height: 43px !important;
        margin: 0 auto !important;
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1200px) {
      div {
        &:first-child {
          width: 100%;
        }
      }
      .MuiSelect-root {
        width: 250px;
      }
      input {
        width: 520px;
      }
    }
    @media screen and (min-width: 1201px) {
      div {
        &:first-child {
          width: 100%;
        }
      }
      input {
        width: 786px;
      }
    }
  }

  .MAF_countrySelector {
    margin-right: 12px;
  }

  .a-m-card-container {
    perspective: 600px;
    padding-top: 2px;
    padding-bottom: 36px;

    @include btnNone;
    .a-m-card {
      width: 100%;
      height: 100%;

      background-color: transparent;

      position: relative;

      transition: transform 1s;
      transform-style: preserve-3d;
      &.a-m-card-hover {
        transform: rotateY(180deg);
        cursor: pointer;
      }

      .a-m-card-front {
        width: 100%;
        height: 100%;
        padding: 20px;

        @include getData(background-color, light);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 6px;

        text-align: center;

        backface-visibility: hidden;
        img {
          width: 100%;
          margin-bottom: 12px;

          border-radius: 10px;

          &.productImg__no-img {
            width: 96px;
            height: 132px;

            @include media(mobile, tablet) {
              width: 64px;
              height: 96px;
            }
          }
        }
        h4 {
          margin-bottom: 2px !important;
          min-height: 42px;
          font-weight: bold;
          font-size: 18px;
          line-height: 21px;
        }
        h6 {
          margin-bottom: 12px !important;

          @include getData(color, gray3);
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
        }
        span {
          padding: 2px 10px;
          margin: 0 3px;

          @include getData(border, gray4, null, 1px solid);
          border-radius: 100px;

          font-size: 12px;
          line-height: 14px;
          &:first-of-type {
            margin-bottom: 5px;
          }
        }
        .a-m-discount {
          margin: 0 0 12px 0;

          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, color2);

          display: flex;
          align-items: center;
          &:before {
            content: '';
            width: 0;
            height: 0;
            margin: 0 6px 0 0;
            border-style: solid;
            border-width: 0 6px 9.33px 6px;
            @include getData(border-color, color2, transparent, transparent transparent);
          }
        }

        .a-m-discount-big {
          margin: 0 0 12px 0;

          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          line-height: 14px;
          @include getData(color, discountFontColor);
          text-align: center;
          // display: flex;
          // align-items: center;
        }
      }

      .a-m-card-back {
        width: 100%;
        height: 100%;

        @include getData(background-color, light);
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        overflow: hidden;

        position: absolute;
        top: 0;
        left: 0;

        backface-visibility: hidden;
        transform: rotateY(180deg);
        transition: transform 1s;

        z-index: 0;
        .a-m-card-content {
          width: 100%;
          height: 100%;

          position: absolute;
          button {
            width: calc(100% - 40px);
            height: 40px;

            position: absolute;
            bottom: 20px;
            left: 20px;

            @include btnPrimary;

            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
    @media screen and (max-width: 767px) {
      max-width: 216px;
      padding-bottom: 10px;
      &:nth-of-type(odd) {
        padding-right: 5px;
      }
      &:nth-of-type(even) {
        padding-left: 5px;
      }
      .a-m-card {
        height: 100%;
        margin-bottom: 0;
        .a-m-card-front {
          padding: 8px;
          img {
            margin-bottom: 10px;

            border-radius: 5px;
          }
          h4 {
            margin-bottom: 2px !important;
            min-height: 32px;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
          }
          h6 {
            margin-bottom: 0 !important;

            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray3);
          }
        }
      }
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding: 0 6px 12px 6px;
      .a-m-card {
        width: 170px;
        max-width: 170px;
        height: 100%;
        margin-bottom: 0;
        .a-m-card-front {
          padding: 10px;
          h4 {
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
          }
          h6 {
            margin-bottom: 0 !important;

            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1200px) {
      width: 250px;
      max-width: 250px;
      &:nth-of-type(3n + 2) {
        margin: 0 16px;
      }

      .a-m-card {
        width: 250px;
      }
    }
    @media screen and (min-width: 1201px) {
      width: 250px;
      max-width: 250px;
      margin: 0 8px;
    }
  }
}

.a-m-indicator-active {
  width: 190px !important;
  opacity: 1 !important;
}

.freshdesk {
  position: fixed;
  bottom: 20px !important;
  right: 20px;
  @include btnFilled;
  z-index: 99999;
  padding: 0.92857rem 1.57143rem;
  border-radius: 999rem;
  bottom: 0;
  letter-spacing: 0.6;
  font-size: 1.07143rem;
  box-sizing: border-box;
  img {
    display: inline-block;
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
}

// .a-m-feature-mobile_Carrefour {
//     position: relative;
//     width: 100%;
//     // margin-top: 64px;

//     .container {
//         width: 100%;
//         max-width: 100%;
//         margin: 0;
//         padding: 0;

//         .center {
//             width: 100%;
//             position: relative;

//             img {
//                 width: 100%;
                
//             }

//             .mobileBannerText {
//                 position: absolute;
//                 bottom: 30px;
//                 left: 20px;
                
//                 @media screen and (max-width: 320px) {
//                     bottom: 20px;
//                 }

//                 p {
//                     @media screen and (max-width: 430px) {
//                         font-size: 16px;
//                         font-weight: 600;
//                         line-height: 0;
//                     }

//                     font-size: 24px;
//                     line-height: 10px;
//                     color: #fff;
//                     font-weight: 600;
//                 }
//             }

//         }
//     }
// }