.a-a-mobile-cards {
  margin-left: -16px;
  margin-right: -16px;

  .no-card {
    margin: auto;
    @include getData(color, formControlError);
    font-size: 1.3rem;
  }
  .input-container {
    padding: 0 16px;
    margin-bottom: 20px;
    position: relative;
    width: 100%;

    input {
      width: 100%;
      height: 44px;

      text-align: left !important;

      @include getData(background-color, light, !important);

      padding-left: 24px;
      padding-right: 72px;

      border: none;
      border-radius: 100px !important;
      @include getData(box-shadow, btns1Shadow, !important);

      &:focus,
      &:active {
        outline: none;
      }
    }

    > .icon-container {
      display: flex;
      gap: 8px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 32px;

      img {
        cursor: pointer;
      }
    }
  }
  a {
    @include btnClear;
    display: inline;
  }
  .a-a-mobile-card-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 16px;
    column-gap: 25px;

    margin: auto;
    width: max-content;

    &.single-item {
      grid-template-columns: repeat(1, 1fr) !important;
    }

    @include media(mobile_xs) {
      column-gap: 4px;
      grid-template-columns: repeat(2, 1fr);
    }
    @include media(mobile_sm) {
      column-gap: 14px;
      padding: 24px 0;
      grid-template-columns: repeat(2, 1fr);
    }
    @include media(mobile_md) {
      column-gap: 13px;
      padding: 24px 4px;
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(mobile_lg) {
      column-gap: 25px;
      grid-template-columns: repeat(3, 1fr);
    }

    @include media(tablet, desktop) {
      grid-template-columns: repeat(4, 1fr);
    }

    a {
      height: auto;

      position: relative;
      z-index: 0;
      display: block;

      margin: auto;
    }
    .a-m-card-container {
      h4 {
        &.amount-description {
          font-family: Ubuntu;
          font-weight: normal;
        }
      }

      button {
        @include btnClear;

        width: max-content;

        margin: auto;
        padding: 2px 10px;

        @include getData(border, gray4, null, 1px solid);
        border-radius: 6px;

        font-size: 12px;
        font-weight: 400;
        @include getData(color, gray3);
        line-height: 15px;

        &.a-a-mobile-card-expiry {
          max-width: 80%;
        }
      }
    }
  }
}

.modal-container-expiry-list {
  background-color: rgba(255, 255, 255, 0.8);

  .a-a-mobile-card-expiry-modal {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);

    width: 278px;
    height: max-content;

    padding: 20.73px 10px 20.27px 20px;

    @include getData(background-color, gray1);
    border-radius: 10px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));

    margin: 0;

    .a-a-mobile-card-expiry-top {
      margin: 0 0 20px 0;

      display: flex;
      align-items: center;
      justify-content: space-between;
      h5 {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, light);
      }
      button {
        @include btnClear;
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
    .a-a-mobile-card-expiry-list {
      width: 100%;
      height: max-content;
      max-height: 175px;
      padding-right: 14px;

      overflow-x: hidden;
      overflow-y: visible;
      @include customScrollbar;
      &::-webkit-scrollbar {
        width: 12px;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.05);
      }
      li {
        h6 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          @include getData(color, light);

          margin-bottom: 5px !important;
        }
        span {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          @include getData(color, gray6);
          text-align: right;

          display: block;
        }
        hr {
          width: 100%;
          height: 0;
          margin: 16px 0;

          @include getData(border, gray2, null, 1px dashed);
        }
      }
    }
  }
}
