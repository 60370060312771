$thumbWidth: 12px;

.a-m-card-modal {
  background-color: rgba(255, 255, 255, 0.8);
  .modal-dialog {
    width: 888px;
    max-width: none;
  }
  .modal-content {
    border-radius: 4px;
    border: none;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);
  }
  .modal-top {
    padding-left: 47px;
    padding-bottom: 20px;

    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
  .modal-header {
    height: 39px;
    padding: 0;

    border-bottom: none;
    button {
      padding: 0;
      margin-top: 15px;
      margin-right: 13px;
    }
  }
  .modal-body {
    padding: 0;
    section {
      h3,
      h6 {
        width: 733px;
      }
      h3 {
        margin-bottom: 4px !important;

        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        text-align: start;
      }
      h6 {
        margin-bottom: 40px !important;

        @include getData(color, gray3);
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .a-m-info {
    width: 494px;
    height: 314px;
    overflow-x: hidden;
    overflow-y: auto;
    h4 {
      margin-bottom: 5px !important;

      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
    }
    .a-m-img {
      width: 470px;
      height: 175px;
      margin-bottom: 24px !important;

      @include bgImage(url(/img/cardPopUp/Modal/photo/Img.svg));
      border-radius: 4px;
      background-size: cover !important;
    }
    .a-m-productDescription {
      width: 470px;
      text-align: justify;
      p {
        margin-bottom: 8px;

        &.a-m-productDescription-content {
          margin-bottom: 4px;
        }
      }
    }
    h6 {
      margin-bottom: 5px !important;

      @include getData(color, gray3);
      font-size: 14px;
      line-height: 16px;
    }
    a {
      @include getData(color, btn);
      font-size: 14px;
      line-height: 16px;
    }

    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      width: $thumbWidth;
      border: none;
      background: transparent;

      position: relative;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-track-piece,
    &::-webkit-scrollbar-corner,
    &::-webkit-resizer {
      display: none;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 2px;
    }
  }
  .a-m-side-card {
    width: 268px;
    min-height: 535px;
    padding: 20px 10px;

    @include getData(background-color, light);
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    position: absolute;
    top: 107px;
    right: 63px;
    .a-m-side-card-top {
      width: 210px;
      margin: auto;
      img {
        width: 210px;
        border-radius: 10px;
      }
      img,
      h6 {
        margin-bottom: 14px !important;

        @include getData(color, gray3);
        font-size: 14px;
        line-height: 16px;
      }
      h5 {
        margin-bottom: 24px !important;

        @include getData(color, gray1);
        font-size: 16px;
        line-height: 19px;
      }
      a {
        width: 100%;
        height: 40px;
        margin: 6px auto !important;
        padding: 10px 0;

        @include btnPrimary;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;

        position: relative;
        z-index: 1;
        img {
          width: 62.48px;
          position: absolute;
          top: -10px;
          right: -5%;
        }
      }
      a:nth-of-type(2) {
        margin-bottom: 24px !important;
        background-color: transparent;
        @include getData(border, btn, null, 1px solid);

        @include getData(color, btn);
      }
    }
    .a-m-side-card-bottom {
      padding: 16px;
      h6 {
        margin-bottom: 4px !important;

        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray3);
      }
      span {
        margin-top: 8px;

        font-size: 14px;
        line-height: 16px;

        display: flex;
        align-items: center;
        img {
          height: 24px;
          width: 24px;
          margin-right: 4px;
        }
      }
      hr {
        width: 1px;
        height: auto;
        margin: 0;
        margin-left: 20px;
        margin-right: 20px;

        @include getData(background-color, gray3);
      }
    }
  }
  .modal-footer {
    height: 362px;
    padding: 39px 0 40px 47px;

    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);

    &:lang(fr) {
      button {
        margin-right: 20px;
      }
    }
    button {
      margin-right: 40px;
      padding: 0;

      @include btnNone;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
    }
    .a-m-nav {
      margin-bottom: 25px !important;
      .active {
        padding: 0 6px 8px 6px;
        @include getData(border-bottom, btn, null, 3px solid);

        font-weight: 500;
        font-size: 18px;
        line-height: 21px;

        @include getData(color, btn, !important);
        &:hover,
        &:focus,
        &:active {
          @include getData(border-bottom, btn, null, 3px solid);
        }
      }
      .dropdown-toggle {
        &::after {
          display: none;
        }
        img {
          margin-left: 4px;
        }
      }
      .dropdown-menu {
        margin-top: 11.31px !important;
        padding: 10px 40px;

        @include getData(background, light);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        a {
          margin-bottom: 8px !important;
          padding: 0;

          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
        }
        li:last-of-type {
          a {
            margin-bottom: 0 !important;
          }
        }
      }
    }
    .tab-content {
      width: 470px;
      height: 216px;
      text-align: justify;

      overflow-x: hidden;
      overflow-y: auto;
      .tab-pane {
        @include getData(color, gray3);
        font-size: 14px;
        line-height: 16.41px;
      }

      &::-webkit-scrollbar,
      &::-webkit-scrollbar-thumb,
      &::-webkit-scrollbar-track {
        width: $thumbWidth;
        border: none;
        background: transparent;

        position: relative;
      }

      &::-webkit-scrollbar-button,
      &::-webkit-scrollbar-track-piece,
      &::-webkit-scrollbar-corner,
      &::-webkit-resizer {
        display: none;
      }

      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.05);
        border-radius: 2px;
      }
    }
    .active {
      font-size: 14px;
      @include getData(color, gray3);
    }
  }
}

.a-qc-block-yes-btn, .a-qc-block-no-btn {
  @include getData(border-radius, a-gs-btn-container-button-border-radius, !important);
}