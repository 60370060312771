// font-family 移至 _font.scss 裡指定
// _font.scss與fonts資料夾 會依照bu name切換
$linkDefault: #000000;
$linkHover: #000000;
$linkPressed: #000000;

@import './font';
@import './variables';
@import './utils/_media';

// multi-buid configuration (using egiftcard-maf template)
@import './buStyles';

@mixin getData($cssType, $params, $additionalPropBack: null, $additionalPropFront: null) {
  @each $buid, $file in $Setting {
    [data-buid='#{$buid}'] & {
      #{$cssType}: $additionalPropFront map-get($file, $params) $additionalPropBack;
    }
  }
}

@keyframes gradientMove {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}

body {
  margin: 0;
  padding: 0;

  #root {
    @include getData(background-color, root-background-color);
  }
}

.footer_version {
  margin: 0;
  text-align: center;
  background-color: #212529 !important;
  color: #fff !important;
}

@mixin inputMobileDefault {
  // @include getData(border, gray5, !important, 1px solid);

  &:focus,
  &:active {
    // @include getData(background-color, light, !important);
    // border-@include getData(color, gray2, !important);
  }

  &.error {
    @include getData(border-color, 'formControlError', !important);
  }
}

@mixin bgImage($url) {
  background-image: $url;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@mixin btnNone {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent !important;
  border-radius: 0;
  @include getData(color, gray1);
  cursor: pointer;
  &:hover,
  &:focus,
  &:active {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    @include getData(color, gray1);
  }
}

@mixin btnBack {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 125px;
  height: 50px;
  border-radius: 10px;
  padding: 0 10px;
  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  &:active {
    background: rgba(255, 255, 255, 0.2);
  }

  > img {
    width: 24px;
    height: 24px;
    margin-right: 14px;
  }
}

@mixin btnPrimary {
  // @include getData(color, light);
  @include getData(color, 'light');
  // @include getData(background-color, btn);
  @include getData(background-color, 'btn');
  border-radius: 4px;
  border: none;
  font-weight: bold;
  font-size: 16px;
}

@mixin linearGradient($cssType, $degree) {
  @each $buid, $file in $Setting {
    @if $buid != "UAE" {
      [data-buid='#{$buid}'] & {
        #{$cssType}: linear-gradient($degree, map-get($file, btn), map-get($file, btnHover), map-get($file, btn));
      }
    }
  }
}

@mixin btnPrimaryAnmiated {
  @include linearGradient(background-image, 270deg);

  background-size: 400%;
  animation: gradientMove 6s linear infinite;

  &:hover {
    @include getData(background, btnHover);
    animation: none;
  }

  &:active {
    @include getData(background, btnPressed);
    animation: none;
  }
}

@mixin wh100 {
  width: 100%;
  height: 100%;
}

@mixin font {
  font-family: 'Dancing Script', cursive !important;
}

@mixin btnMobilePrimary {
  border-radius: 100px;
  @include getData(background, btn);
  border: none;
  @include getData(color, light);
  @include getData(font-weight, normal-font-wieght);
  &:focus {
    box-shadow: none;
  }
  &:hover {
    @include getData(background, btnHover);
  }
  &:active {
    @include getData(background, btnPressed);
  }
  &:disabled,
  &.disabled,
  &.disable {
    @include getData(background, btnDisable, !important);
  }
}

@mixin btnMobileSecondary {
  border-radius: 100px;
  @include getData(font-weight, normal-font-wieght);
  // @include getData(border, gray5, null, 1px solid);
  @include getData(border, $gray5, null, 1px solid);
  @include getData(color, btnMobileSecondary-color);
  // @include getData(background, light);

  @include getData(background, light);
  &:focus {
    box-shadow: none;
  }
  &:hover {
    @include getData(border-color, btnMobileSecondary-hover-border-color);
  }
  &:active {
    // @include getData(background, gray4);
    @include getData(background, gray4);
    // border-@include getData(color, gray2);
    @include getData(border-color, gray3);
  }
  &:disabled,
  &.disabled,
  &.disable {
    @include getData(color, light);
    @include getData(background, gray4);
    @include getData(border-color, gray5);
  }
}

@mixin btnFilled {
  @include getData(background-color, btn, !important);
  @include getData(border-radius, a-gs-btn-container-btn-border-radius);
  @include getData(color, light);
  border: none;
  &:hover {
    @include getData(background-color, btnHover, !important);
  }
  &:focus,
  &:active {
    @include getData(background-color, btnPressed, !important);
    box-shadow: none;
  }
}

@mixin btnOutlined {
  border-radius: 4px;
  @include getData(border, btn, !important, 1px solid);
  &:hover {
    @include getData(border, btnHover, !important, 1px solid);
  }
  &:focus,
  &:active {
    @include getData(border, btnPressed, !important, 1px solid);
    box-shadow: none;
  }
}

@mixin btnTextWithIcon {
  @include getData(color, gray2);
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
    // use variable
    // @include getData(color, btn)Hover;
    @include getData(filter, filterVariableHover);
  }
  &:focus,
  &:active {
    background-color: transparent !important;
    box-shadow: none;
    // expected: $btnPressed
    // @include getData(color, btn)Pressed;
    // use variable
    @include getData(filter, filterVariablePressed);
  }
}

@mixin btnLoginShadow() {
  width: 100%;
  max-width: 100%;
  height: 54px;
  @include getData(box-shadow, btns1Shadow);
  border-radius: 6px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: max-content !important;
    height: max-content !important;
    margin: 0 15px 0 0;
    object-fit: contain;
  }
  &:focus,
  &:active {
    @include getData(box-shadow, btns1Shadow, !important);
  }
}

@mixin btnText {
  background-color: transparent !important;
  &:hover {
    background-color: transparent !important;
    @include getData(color, btnHover, !important);
  }
  &:focus,
  &:active {
    background-color: transparent !important;
    @include getData(color, btnPressed, !important);
    box-shadow: none;
  }
}


@mixin btnNavBar {
  @include getData(color, navBtn);
  &:hover {
    @include getData(color, color2);
  }
  &:focus,
  &:active {
    background-color: rgba(193, 163, 97, 0.05);
    @include getData(color, navBtn);
  }
}

@mixin btnUnderlined {
  &:hover {
    text-decoration: underline !important;
  }
}

@mixin customScrollbar($width: 22px, $thumbColor: rgba(0, 0, 0, 0.05), $thumbRadius: 30px, $thumbBorder: 10px) {
  /* firefox */
  scrollbar-color: $thumbColor;
  scrollbar-width: thin;

  /* width */
  &::-webkit-scrollbar {
    width: $width;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $thumbColor;
    border-radius: $thumbRadius;
    border: $thumbBorder solid transparent;
    background-clip: content-box;
  }
}

@mixin btnClear {
  width: max-content;
  height: max-content;
  padding: 0;
  margin: 0;

  background-color: transparent;
  border: none;
  &:focus,
  &:active {
    box-shadow: none !important;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
  }
}

@mixin hideScrollbar {
  // scrollable with scrollbar hidden
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@mixin btnDisabled {
  background-color: rgba(76, 76, 76, 0.2) !important;
  &:hover,
  &:focus,
  &:active {
    background-color: rgba(76, 76, 76, 0.2) !important;
  }
}

@mixin link {
  @include getData(color, linkDefault);
  &:hover {
    @include getData(color, linkHover);
  }
  &:focus,
  &:active {
    @include getData(color, linkPressed);
  }
}

@mixin truncateText($line: 2) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $line; /* number of lines to show */
  line-clamp: $line;
  -webkit-box-orient: vertical;
}

.mx-40 {
  margin: auto 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
strong {
  margin: 0;
  @include getData(color, gray1);
}

h1 {
  font-size: 48px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

a {
  text-decoration: none !important;
}

.backToHomePageLink {
  border-radius: 25px;
  @include getData(background-color, btn);
  @include getData(color, light);
  text-align: center;
}

.a-form-control {
  label {
    margin: 0 0 6px 0 !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    @include getData(color, gray2, !important);
  }

  .input-valid {
    &:focus {
      border: $gray3 1px solid !important;
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }

    &:not(:focus) {
      border: transparent 1px solid !important;
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  .input-error {
    &:focus {
      border: $formControlError 1px solid !important;
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }

    &:not(:focus) {
      border: $formControlError 1px solid !important;
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
  }

  input {
    @include getData(background-color, gray6, !important);
    border: transparent 1px solid !important;
    border-radius: 4px !important;

    font-weight: 500 !important;
    line-height: 19px !important;
    font-size: 16px !important;
    // @include getData(color, gray4, !important);
    text-align: center !important;
    &::placeholder {
      font-weight: 500 !important;
      font-size: 16px !important;
      line-height: 19px !important;
      @include getData(color, gray4, !important);
      text-align: center !important;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none !important;
      -webkit-appearance: none !important;
      margin: 0 !important;
    }

    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield !important;
    }

    &:focus {
      @include getData(border, gray3, !important, 1px solid);
      outline: none !important;
      outline-width: 0 !important;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;
    }
  }
  i {
    margin: 6px 0 0 0 !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    @include getData(color, gray2, !important);

    display: block;
  }
}

.a-form-control-error {
  margin: 0;
  padding: 0;
  &.error-select-kind {
    p {
      padding: 4px 61px;
    }
  }
  input {
    &:focus {
      @include getData(border, formControlError, !important, 1px solid);
      @include getData(caret-color, formControlError, !important);
    }
  }

  &__on {
    input {
      @include getData(border, formControlError, !important, 1px solid);
      @include getData(caret-color, formControlError, !important);
    }
  }

  p {
    margin: 6px 0 0 0 !important;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    font-style: normal !important;
    @include getData(color, formControlError, !important);

    &.custom-message {
      margin: 0px !important;
    }
  }

  &.import-loading {
    p {
      max-height: 16px;
      display: flex;
    }
    img {
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 8px;
    }
  }
}

.a-back-btn {
  @include btnClear;
  background-color: transparent !important;
  border: none !important;

  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 19px !important;
  @include getData(color, gray3, !important);
  img {
    width: 20px !important;
    height: 20px !important;
    margin: 0 9px 0 0 !important;
  }
  &:hover {
    @include getData(color, gray3, !important);
  }
}

.a-third-party-btns {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  button {
    @include btnLoginShadow;
    width: 388px;
    flex-grow: 1;
  }
  .a-third-party-btn-fb {
    @include getData(background-color, facebookColor);
    border: none !important;

    @include getData(color, light);
    &:hover,
    &:focus,
    &:active {
      @include getData(background-color, facebookColor);
      border: none;
      @include getData(color, light);
    }
  }
  .a-third-party-btn-google {
    @include getData(background-color, light);
    border: none !important;

    color: rgba(0, 0, 0, 0.54);
    &:hover,
    &:focus,
    &:active {
      @include getData(background-color, light);
      border: none;

      color: rgba(0, 0, 0, 0.54);
    }
  }
  .a-third-party-btn-apple {
    @include getData(background-color, black);
    border: none !important;

    @include getData(color, light);
    &:hover,
    &:focus,
    &:active {
      @include getData(background-color, black);
      border: none;

      @include getData(color, light);
    }
  }
  .a-third-party-btn-oauth {
    @include getData(background-color, btn);
    border: none !important;

    color: #fff;
    &:hover,
    &:focus,
    &:active {
      @include getData(background-color, btn);
      border: none;

      color: #fff;
    }
  }
}

.a-or-divider {
  margin: 20px 0 !important;
  height: 30px;
  @include getData(color, gray4);
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 30px;

  position: relative;
  &:before,
  &:after {
    content: '';
    width: 109px;
    height: 1px;
    @include getData(background-color, gray4);
    position: absolute;
    top: 50%;
    left: 41%;
    transform: translate(-50%, -50%);
  }
  &:after {
    left: 59%;
  }

  &.b2b-register {
    font-size: 18px;
    font-weight: 500;
    &:before,
    &:after {
      width: 270px;
      max-width: 40%;
    }
    &:before {
      left: 30%;
    }
    &:after {
      left: 70%;
    }
  }
}

.a-form-control-checkbox {
  margin: 20px 0 0 0;

  display: flex !important;
  align-items: center !important;
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, gray2);
  }
  input {
    width: 16.67px;
    height: 16.67px;
    margin: 0 14px 0 0;

    @include getData(border, gray3, !important, 2px solid);
    &:checked {
      @include getData(background-color, color2);
      box-shadow: none;
      @include getData(border, color2, !important, 2px solid);
    }
    &:focus,
    &:active {
      box-shadow: none;
      @include getData(border, color2, !important, 2px solid);
    }
  }
  a {
    margin-left: 8px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, color2, !important);
    @include getData(color, color2, !important);
  }
}

.a-page-btn {
  width: 100%;
  height: 63px;
  margin: 32px 0 0 0;

  @include btnFilled;
  @include getData(background-color, btn, !important);
  border-radius: 6px !important;
  border: none !important;

  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  @include getData(color, light, !important);
  @include getData(color, lighy, !important);

  display: flex !important;
  align-items: center;
  justify-content: center;
  &:hover {
    @include getData(color, light);
  }
  &:focus,
  &:active {
    @include getData(color, light);
    box-shadow: none !important;
  }

  &.font-larger {
    font-size: 1.5rem;
  }
}

.a-page-btn-disabled {
  @include btnDisabled;
}

.a-title {
  margin: 0 0 20px 0;
  h3 {
    margin: 0;

    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    @include getData(color, black);
  }
  h4 {
    margin: 12px 0 20px 0;

    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    @include getData(color, gray3);
    .a-subtitle-btn {
      width: max-content !important;
      height: max-content !important;
      margin: 0 0 0 4px;
      padding: 0;

      background-color: transparent !important;
      @include getData(color, btn, !important);
      text-decoration: underline !important;
      @include btnText;
    }
  }
}

.a-form-control-radio-active {
  label {
    @include getData(color, gray2, !important);
  }
  input {
    @include getData(border, color2, !important, 1px solid);
    &:focus,
    &:active,
    &:checked {
      background-color: transparent !important;
      box-shadow: none !important;
    }
    &:checked[type='radio'] {
      @include getData(background-image, chooseOnePressed, !important);
    }
  }
}

.a-load-container {
  width: 100%;
  height: max-content;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .a-load {
    width: 18vw;
    height: 200px;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    &:first-of-type {
      padding-top: 50px;
      padding-bottom: 50px;
      img {
        width: 100px;
        height: 100px;
      }
    }
    &:last-of-type {
      padding: 90px 38px 91px 37px;
      h6 {
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        @include getData(color, gray3);
        opacity: 0.9;
      }
    }
    @media screen and (max-width: 767px) {
      width: 176px !important;
      height: 60px;
      &:first-of-type {
        padding-top: 20px;
        padding-bottom: 20px;
        img {
          width: 35px;
          height: 35px;
        }
      }
      &:last-of-type {
        h6 {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
      width: 100vw;
      height: 80px;
      &:first-of-type {
        height: 120px;
        padding-top: 40px;
        padding-bottom: 40px;
        img {
          width: 40px;
          height: 40px;
        }
      }
      &:last-of-type {
        padding: 32px 45px 32px 44px;
        h6 {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.a-load-btn {
  min-width: 140px;
  height: 40px;

  @include getData(background, btnGradient);
  border-radius: 4px;

  font-style: normal !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 19px !important;
  @include getData(color, light, !important);
  &:hover {
    @include getData(color, light, !important);
  }
  &:focus,
  &:active {
    box-shadow: none !important;

    @include getData(color, light, !important);
  }
}

.a-load-message {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  @include getData(color, gray3);
  opacity: 0.9;
  text-align: center;
}

.a-scroll-btns {
  button {
    @include btnClear;

    position: absolute;
    top: 50%;

    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      box-shadow: none !important;
    }
    img {
      width: 83px;
      height: 83px;
    }
    &:first-of-type {
      left: -10px;
      transform: translateY(-50%) rotate(180deg);
    }
    &:last-of-type {
      right: -10px;
      transform: translateY(-50%);
    }
  }
}

.a-form-control-icon-success {
  margin: 0;
  position: relative;
  input {
    height: 44px;
  }
  button {
    width: max-content;
    height: max-content;
    margin: 0;
    padding: 0;
    @include btnNone;

    position: absolute;
    bottom: 10px;
    right: 16px;
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.a-form-control-icon-psw {
  margin: 0;
  position: relative;
  input {
    height: 44px;
  }
  button {
    width: max-content;
    height: max-content;
    margin: 0;
    padding: 0;
    @include btnNone;

    position: absolute;
    bottom: 10px;
    right: 25px;
    img {
      width: 24px;
      height: 24px;
    }
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}

.a-form-control-radio {
  display: flex;
  align-items: center;
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, gray3);
  }
  input {
    width: 13px;
    height: 13px;
    margin: 0 7px 0 0;

    @include getData(border, gray3, null, 1px solid);
  }
}

.a-form-control-radio-active {
  label {
    @include getData(color, gray2, !important);
  }
  input {
    @include getData(border, color2, !important, 1px solid);
    &:focus,
    &:active,
    &:checked {
      background-color: transparent !important;
      box-shadow: none !important;
    }
    &:checked[type='radio'] {
      @include getData(background-image, chooseOnePressed, !important);
    }
  }
}

.modal-open {
  .modal {
    @media screen and (max-width: 920px) {
      overflow-x: auto !important;
    }
  }
}

// scroll hidden
html {
    overflow: -moz-hidden-unscrollable;
    height: 100%;
}

body::-webkit-scrollbar {
    display: none;
}

// body, #productDetailModal {
//     -ms-overflow-style: none;
//     height: 100%;
// 	width: calc(100vw + 18px);
// 	overflow: auto;
// }

// Arabic
body.arabic {
  margin: 0;
  padding: 0;
  #root {
      transform: rotateY(180deg);
  }

  #productDetailModal {
      height: 100vh;
  }

  .modal-backdrop.show {
      z-index: -99 !important;
      opacity: 0 !important;
  }

  p, a, button, label, h1, h2 h3, h4, h5, h6 {
      transform: rotateY(180deg);
      direction: rtl;
      unicode-bidi: bidi-override;
  }

  #menu_modal_dropdownMenu {
      left: -155px !important;
  }


  // text tran back
  .align-items-center {
    h5 {
      direction: ltr;
      transform: rotateY(0deg);
    }
  }
  .MAF_countrySelector, .MuiListItem-root, .MuiSelect-root {
    p {
      direction: ltr;
    }
    img {
      transform: rotateY(180deg);
    }
  }
  .MuiButtonBase-root {
    direction: ltr;
  }
  .a-m-card-front{
    h4, h6 {
      direction: ltr;
    }
  }
  .yourOrderItem_cardName {
    direction: rtl;
    transform: rotateY(180deg);
  }
  .a-bf-cart-discount {
    display: flex;
    justify-content: end;
    direction: ltr;
    transform: rotateY(180deg);

    h5 {
      direction: ltr;
      transform: rotateY(360deg);
    }
  }
  .yourOrderItem_amount {
    display: flex;
    flex-flow: column;

    h6 {
      direction: ltr;
      transform: rotateY(180deg);
      text-align: end;
    }
  }
  .yourOrder_totalAmount {
    h5 {
      transform: rotateY(180deg);
    }
  }
  .a-bf-steps {
    img {
      transform: rotateY(180deg);
    }
  }
  .a-bf-step1-btns button {
    direction: ltr;
  }
  .a-bf-step1  {
    input {
      transform: rotateY(180deg);
    }
  }
  .yourOrderItem_cardMadeFee_p {
    direction: ltr;
    transform: rotateY(360deg);
  }
  .justify-content-lg-between {
    h5 {
      transform: rotateY(180deg);
    }
  }
  #FormInput-email {
    transform: rotateY(180deg);
  }
  .a-bf-checkout-order {
    td {
      transform: rotateY(180deg);
    }
  }
  .a-bf-summary {
    section {
      p:last-child {
        transform: rotateY(180deg);
        direction: ltr;
      }
      h6:last-child {
        transform: rotateY(180deg);
        direction: ltr;
      }
    }
  }
  .orderSuccessOrderNo_p {
    transform: rotateY(180deg);
    direction: ltr;
  }
  .a-qc-querycard {
    #Input1, #Input2 {
      transform: rotateY(180deg);
    }
  }
  .a-qc-result-content {
    h3 {
      transform: rotateY(180deg);
      direction: rtl;
    }
  }
  .a-card-detail {
    h4 {
      text-align: end;
      transform: rotateY(180deg);
      direction: ltr;
    }
    h3 {
      text-align: start !important;
      transform: rotateY(180deg);
      direction: ltr;
    }
  }
  .a-card-select {
    .currentBalance {
      text-align: end;
      direction: ltr;
    }
  }
  .a-card-history-top {
    h4 {
      direction: ltr;
    }
  }
  .align-items-center {
    h2:first-child {
      transform: rotateY(180deg);
      text-align: end;
      direction: ltr;
    }

    .Amt {
      transform: rotateY(180deg);
      direction: ltr;
    }
  }
  .a-m-card-front {
    span {
      transform: rotateY(180deg);
    }
  }
  .a-card-select {
    h5 {
      direction: rtl;
      transform: rotateY(180deg);
    }
  }
  .modal-body {
    section {
      h3 {
        direction: rtl;
        transform: rotateY(180deg);
      }

      h6 {
        direction: ltr;
        transform: rotateY(180deg);
        text-align: end
      }
    }
  }
  .a-m-side-card-top {
    h6, h5 {
      direction: ltr;
      transform: rotateY(180deg);
      text-align: end
    }
  }
  .text-nowrap {
    .validMonthsText {
      direction: ltr;
      transform: rotateY(180deg);
      text-align: end
    }
  }
  .a-m-productDescription, .tab-pane {
    direction: ltr;
    transform: rotateY(180deg);
    text-align: end !important
  }
  .a-bf-steps {
    img {
      left: 0;
    }
  }
  .a-ts-terms {
    p {
      direction: ltr;
      transform: rotateY(180deg);
      text-align: end !important
    }
  }
  .a-form-control {
    #receiverEmail {
      transform: rotateY(180deg);
    }
    input {
      transform: rotateY(180deg);
    }
  }
  .a-bf-summary {
    td:first-of-type {
      .rtl-in-arabic {
        direction: ltr;
        transform: rotateY(360deg);
      }

      .rtl-in-arabic-email {
        direction: rtl;
        transform: rotateY(360deg);
        text-align: end;
        padding: 0 !important;

        p {
          transform: rotateY(0deg);
          direction: ltr;
        }
      }
    }
  }
  .a-m-slogan-content {
    h3 {
      transform: rotateY(180deg);
      direction: rtl;
    }
  }
  .a-m-filter {
    input {
      transform: rotateY(180deg);
      direction: rtl;
    }
  }
  .a-m-card-content {
    button {
      transform: rotateY(180deg);
      direction: rtl;
    }
  }
  .a-m-banner-without-slider {
    .container {
      .center {
        img {
          transform: rotateY(180deg);
        }
      }
    }
  }
  .MAF-banner-container{
    .MAF-banner {
      img {
        transform: rotateY(180deg);
      }
    }
  }
  .a-m-side-card-top {
    img {
      transform: rotateY(180deg);
    }
  }
  .a-ts-message {
    textarea, i {
      transform: rotateY(180deg);
    }
  }
  .support-hint {
    transform: rotateY(180deg);
  }
  .immediately {
    transform: rotateY(180deg);
    direction: rtl !important;
  }
  .selectedDate_p {
    transform: rotateY(180deg);
    direction: ltr !important;
  }
  .MuiPaper-root {
    .MuiList-padding {
      transform: rotateY(180deg);
      
      .immediately {
        transform: rotateY(180deg);
      }
    }
  }
  .MuiPickersBasePicker-container {
    transform: rotateY(180deg);
  }
  .MuiTypography-root {
    direction: ltr;
  }
  .MuiPickersCalendarHeader-transitionContainer {
    transform: rotateY(180deg);
  }
  .MuiSvgIcon-root {
    transform: rotateY(180deg);
  }
  .yourOrderItem_subtotal {
    p {
      transform: rotateY(360deg);
    }
  }
  .yourOrderItem_cardMadeFee_p {
    #yourOrderItem_cardMadeFee_p_span {
      display: inline-block !important;
    }
    #yourOrderItem_cardMadeFee_p_span:last-child {
      transform: rotateY(360deg);
    }
  }
  .a-bf-content-card__title, .a-bf-content-card__desc {
    direction: rtl;
    transform: rotateY(180deg);
  }
  .a-bf-summary {
    table {
      thead {
        span {
          display: inline-block;
          transform: rotateY(180deg);
        }
        th {
          transform: rotateY(180deg);
        }
        th:last-child {
          direction: rtl;
        }
      }
    }
  }
  .a-bf-checkout-order {
    td:last-child {
      direction: rtl;
    }
  }
  .a-bf-confirm {
    span {
      display: inline-block;
      transform: rotateY(180deg);
    }
    #conditionsInput {
      transform: rotateY(180deg);
    }
  }
  .form-check-label {
    p {
      transform: rotateY(180deg);
    }
  }
  .modal-content {
    h5 {
      transform: rotateY(180deg);
    }
    button {
      transform: rotateY(360deg);
      direction: rtl;
    }
  }
  .a-qc-title {
    h3 {
      transform: rotateY(180deg);
      direction: rtl;
    }
  }
  .a-card-detail {
    h5 {
      transform: rotateY(180deg);
      direction: rtl;
    }
  }
  .a-card-info {
    .productImage {
      transform: rotateY(180deg);
    }
  }
  .a-card-tnc {
    p {
      transform: rotateY(180deg) !important;
      direction: ltr !important;
      text-align: end;
    }
  }
  .a-card-history-common {
    .a-card-history-top {
      h5 {
        direction: rtl;
        transform: rotateY(180deg);
      }
    }
  }
  .a-gs-success {
    h2 {
      transform: rotateY(180deg);
    }
  }
  .a-gs-reminder {
    aside {
      h3 {
        transform: rotateY(180deg);
        direction: rtl;
      }
    }
  }
  .a-gs-reminder {
    aside {
      ul {
        li {
          transform: rotateY(180deg);
          direction: rtl;
          text-align: start;
        }
      }
    }
  }
  .a-m-side-card-bottom {
    #Card_delivery_type {
      margin-right: 10px;      
    }
  }
  .a-bf-delivery {
    .a-bf-info section {
      .a-bf-deliverTypeSelector {
        .a-bf-delivery-type {
          .form-check {
            input {
              transform: rotateY(180deg);
            }
          }
        }
      }
    }
  }
  .a-bf-breadcrumb {
    .done {
      transform: rotateY(180deg);
    }
  }
  // mobile
  .input-container {
    transform: rotateY(180deg);
  }
  .a-m-filter-category {
    p {
      transform: rotateY(180deg);
      display: inline-block !important;
      direction: ltr;
    }
  }
  .languageBtn {
    direction: ltr;
  }
  .mobile-country-btn {
    img {
      transform: rotateY(180deg);
    }
  }
  .countryListContent {
    span {
      transform: rotateY(180deg);
      direction: ltr;
    }
    img {
      transform: rotateY(180deg);
    }
  }
  .Card__button {
    a {
      transform: rotateY(360deg);
    }
  }
  .side-card-detail {
    h4 {
      transform: rotateY(360deg);
      text-align: end;
    }
  }
  .m-guestpage-header__back-button {
    transform: rotateY(180deg);
    direction: rtl;
    
    img {
      transform: rotateY(180deg);
    }
  }
  .a-bf-mobile-title {
    transform: rotateY(180deg);
  }
  .a-bf-mobile-subtitle {
    transform: rotateY(180deg);
    text-align: end;
  }
  .a-bf-mobile-description {
    transform: rotateY(180deg);
  }
  .amount-btn-container {
    .position-relative {
      transform: rotateY(180deg);
    }
    .customValue {
      p {
        direction: ltr;
        transform: rotateY(360deg);
      }
    }
    .fixedValue {
      p {
        direction: ltr;
        transform: rotateY(180deg);
      }
      input.quantity-update {
        transform: rotateY(360deg);
      }
    }
  }
  .slider-img-container {
    transform: rotateY(180deg);
  }
  .a-ts-mobile-message {
    textarea, i {
      transform: rotateY(180deg);
      direction: rtl;
    }
  }
  .a-ts-mobile-media {
    transform: rotateY(180deg);
  }
  p.supportedFile {
    transform: rotateY(360deg);
  }
  .mobile-title {
    transform: rotateY(180deg);
    text-align: end;
  }
  .m-bf-content-card__title, .m-bf-content-card__desc {
    transform: rotateY(180deg);
    text-align: end;
  }
  .m-bf-checkout-order {
    p.bold, p.delivery-description {
      direction: ltr;
    }
  }
  .m-bf-checkout-quantit {
    div {
      transform: rotateY(180deg);
    }
  }
  .m-inputQty-container {
    .a-bf-mobile-minus-btn {
      z-index: 10;
    }
    
    input.quantity-update {
      transform: rotateY(180deg);
    }
  }
  .m-bf-checkout-order {
    p.bold {
      direction: rtl;
    }

    div {
      p:last-child {
        direction: ltr;
        text-align: end;
      }
    }
  }
  .a-bf-mobile-paymentform-btn {
    .checkout-total {
      p {
        direction: ltr !important;
      }
    }
  }
  .m-bf-step4-btn {
    .m-bf-confirm {
      transform: rotateY(180deg);
    }
  }
  .a-bf-mobile-payment {
    .a-bf-form {
      .form-check-label {
        transform: rotateY(180deg);
      }
    }
  }
  .m-bf-step4-btn {
    .btn-primary {
      span {
        direction: ltr;
        display: inline-block;
      }
    }
  }
  .a-qc-mobile-header {
    h3 {
      transform: rotateY(180deg);
    }
  }
  .a-qc-mobile-guide {
    button {
      direction: ltr;
      display: inline-block !important;
      text-align: end;
    }
  }
  .a-qc-mobile-navigation {
    span {
      transform: rotateY(180deg);
    }
  }
  .a-qc-mobile-collapse-p {
    margin: 0;
    direction: ltr;
    transform: rotateY(360deg);
    text-align: end;
  }
  .a-qc-mobile-collapse {
    .card-body {
      direction: ltr;
      transform: rotateY(180deg);
      text-align: end;
    }
  }
  .a-card-select {
    h5 {
      direction: ltr;
    }
    h6:last-of-type {
      direction: ltr;
      text-align: end;
    }
  }
  .a-card-history-top {
    h6 {
      em {
        direction: ltr;
        display: inline-block;
      }
    }
  }
  .a-card-history-left {
    span {
      display: inline-block;
      transform: rotateY(180deg);
    }
  }
  .a-qc-mobile-back-navigation {
    img {
      transform: rotateY(180deg);
    }
    h4 {
      transform: rotateY(0deg);
      direction: ltr;
    }
  }
  .homeCardItem__img, .a-m-card-front {
    img {
      transform: rotateY(180deg);
    }
  }
  .a-bf-mobile-step1-btns {
    input.customValue {
      transform: rotateY(180deg);
    }
  }
  .a-bf-mobile-delivery {
    .a-bf-mobile-delivery-type {
      .form-check {
        input[type="checkbox"] {
          transform: rotateY(180deg) !important;
        }
      }
    }
  }
  .a-custom-phone  {
    .MuiOutlinedInput-marginDense:first-child {
      transform: rotateY(180deg);
    }
  }
  .m-bf-checkout-order {
    .m-bf-checkout-description {
      p.bold:first-child {
        direction: ltr;
      }
    }
  }
  .m-bf-checkout-recipient {
    .delivery-description {
      direction: rtl !important;
    }
  }
  .m-bf-checkout-order {
    div.delivery-method {
      p:first-child {
        direction: ltr;
      }
    }
  }
  .m-bf-checkout-order {
    .m-bf-checkout-quantity {
      i {
        transform: rotateY(180deg) !important;
        display: inline-block;
      }
    }
  }
  .m-PaymentForm {
    .a-bf-mobile-paymentform-btn {
      .checkout-total {
        p:first-child {
          direction: rtl !important;
        }
      }
    }
  }
  .a-delivery-date {
    i {
      transform: rotateY(180deg);
      text-align: end;
    }
  }
  .a-ts-mobile-reupload {
    transform: rotateY(180deg);
  }
  .input-container {
    direction: rtl !important;

    input {
      padding-right: 24px !important;
      padding-left: 72px !important;
    }
    .icon-container {
      left: 16px;
      right: auto !important;
    }
  }
  .cropper-container {
    .cropper-view-box {
      transform: rotateY(180deg);
    }
  }
  .MAF-nav-faq {
    img {
      transform: rotateY(180deg);
    }
  }
  .a-bf-checkout-order {
    td {
      h6:last-child {
        transform: rotateY(0deg);
        text-align: end;
        padding: 0 !important;
      }
    }
  }
  .a-n-scrolldown {
    box-sizing: border-box;
    border: 1px solid #fff;
  }
  .MAF-banner {
    z-index: 990 !important;
  }
  .rtl-in-arabic-deliverDate {
    direction: ltr;
  }
}

  // normal and arabic
.MAF_countrySelector, .MuiListItem-root, .MuiSelect-root {
  p {
    margin: 0;
  }
}
.align-items-center {
  .Amt {
    display: inline-block;
    margin: 0;
  }
}
.text-nowrap {
  .validMonthsText {
    margin: 0;
  }
}
.yourOrderItem_subtotal {
  p {
    display: inline-block !important;
  }
}
.countryListContent {
  span {
    display: inline-block;
  }
  .countryListBtn {
    .continueBtn {
      direction: ltr;
    }
  }
}
.text-nowrap {
  p {
    margin: 0
  }
}
.rtl-in-arabic-email {
  p {
    display: inline-block;
  }
}

// common component
@import './common-button';
@import './common-notification';
@import './common-popup';
@import './common-form';

@import './checkout-form';
@import './form';

@import './_b2b-uploadfile-modal';
// srint 4
@import './b2b-information-page';

@import './custom-material-ui';
@import './navigation';
@import './mainPage';
@import './mainPage-modal';
@import './banner';
@import './footer';
@import './buying-friend';
@import './breadcrumb';
@import './template-selector';
@import './query-card';
@import './query-card-result';
@import './query-card-faqs';
@import './shopping-cart';
@import './buying-friend-step3';
@import './buying-friend-step4';

// sprint 3
@import './account-overview';
@import './account-tabs';
@import './account-corporate';
@import './account-my-cards';
@import './account-card-detail';
@import './account-banner';
@import './card-info';
@import './template-selector';
@import './card-history';
@import './account-order-history';
@import './account-inbox';
@import './account-my-wallet';
@import './login-page';
@import './register-form';
@import './about';

// credit-cards-form-style
@import 'react-credit-cards/lib/styles.scss';

@import './_footer-pages-mask';

// sprint 2
@import './guest-buy-success';
@import './register-form';

@import './b2b-register';
@import './transfer-modal';

@import './account-import';
@import './custom-phone-input';
@import './navigation_small';
@import './duplicategifttoast';
@import './delivery-date';

@import './unlock';

// mobile
@import './mobile-pages/template-selector';
@import './mobile-pages/checkout-form';
@import './mobile-pages/guest';
@import './mobile-pages/home';
@import './mobile-pages/home-product-detail';
@import './mobile-pages/payment-form';
@import './mobile-pages/order-failed';
@import './mobile-pages/order-success';
@import './mobile-pages/query-card';
@import './mobile-pages/query-card-result';
@import './mobile-pages/buying-friend-step3';
@import './mobile-pages/account';
@import './mobile-pages/auth';
@import './mobile-components/header';
@import './mobile-components/faqs';
@import './mobile-components/account-my-cards';
@import './mobile-components/account-card-detail';
@import './mobile-components/account-order-history';
@import './mobile-components/account-import';

// rwd template
@media screen and (max-width: 767px) {}

@media screen and (min-width: 768px) and (max-width: 991px) {}

@media screen and (min-width: 992px) and (max-width: 1199px) {}

@media screen and (min-width: 992px) {}