@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@mixin slideShadow {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(179.62deg, rgba(0, 0, 0, 0.4) 0.33%, rgba(0, 0, 0, 0) 34.76%);
  }
}

.m-ProjectDetailModal > .homeProductModal:not(.is-sketlon-mode) {
  .side-card-description {
    opacity: 0;
    animation: fadeInAnimation ease-out 300ms forwards;
    animation-delay: 400ms;
  }

  .side-card-detail {
    opacity: 0;
    animation: fadeInAnimation ease-out 300ms forwards;
    animation-delay: 800ms;
  }

  .homeProductModal__slide-container {
    z-index: auto;
    @include slideShadow;
  }
}

.product-detail-container {
  width: 100%;
  display: flex;
  justify-content: center;
  @include getData(height, product-detail-container-height);

  .product-detail-container {
    width: 90%;
    border-radius: 8px;
    overflow: hidden;
    @include getData(margin-top, product-detail-container-margin-top);
    @include getData(height, product-detail-container-height);

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}

.MAF-homeProductModal__back-button {
  background: #FFFFFF;
  border-radius: 12px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 4px 12px 4px 6px !important;
  width: 80px !important;
  height: 24px !important;
  top: 17px !important;
  left: 7% !important;
  @include getData(color, color2, !important);

  img {
    margin: 0 !important;
  }
}

.m-ProjectDetailModal {
  margin-top: 64px;
  width: 100%;
  background-color: white;
  .line-height-1 {
    line-height: 1;
  }

  .homeProductModal {
    margin-top: 5px;
    outline: 0;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;

    &__back-button {
      @include btnBack;
      top: 0;
      left: 0;
      position: absolute;
      z-index: 4;
    }
    &__slide-container {
      --img-height: 148px;
      @include tablet {
        --img-height: 200px;
      }
      position: relative;
      width: 100%;
      height: 100%;
      min-height: var(--img-height);
      z-index: -1;
    }

    &__slide--sketlon {
      min-height: calc(var(--img-height) + 20px);
    }

    &__img-bg-container--no-img {
      background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #dbdbdb 50%);
      transition: opacity 500ms;
      display: flex;
      width: 100%;
      min-height: calc(var(--img-height) + 20px);
      align-items: center;
      justify-content: center;
    }

    &__img-bg-container {
      position: absolute;
      transition: opacity 500ms;
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .blur-img-bg {
        transition: opacity 500ms;
        opacity: 0;
        position: absolute;
        filter: blur(2px);
        top: 0;
        left: -5px;
        width: calc(100% + 10px);
        height: 100%;
        min-height: calc(var(--img-height) + 20px);
        display: flex;
        align-items: flex-start;
        justify-content: center;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
      .display-img-wrapper {
        transition: opacity 500ms;
        opacity: 0;
        position: absolute;
        z-index: 0;
        width: 100%;
        text-align: center;
      }
      img {
        width: auto;
        height: var(--img-height);
      }
    }

    &__img-slider {
      width: 100%;
      height: 100%;

      position: relative;
      display: flex;
      gap: 6px;
      justify-content: center;
      align-items: flex-end;

      margin-top: -64px;

      .button-slider {
        cursor: pointer;
        background-color: rgba(255, 255, 255, 0.4);

        border: none;
        border-radius: 100px;

        width: 10px;
        height: 10px;

        box-shadow: 0px 0px 4px 0px #00000040;

        &.active {
          background-color: #fff;
          width: 36px;

          border-radius: 100px;
          border: none;
        }
      }
    }

    &__side-card {
      width: 100%;
      @include getData(height, homeProductModal__side-card-height);
      @include getData(min-height, homeProductModal__side-card-height);
      
      gap: 20px;

      background-color: #fff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding: 16px;
      overflow-y: auto;

      @include tablet {
        height: calc(100% - 195px);
        min-height: calc(100% - 195px);

        gap: 32px;
        padding: 32px;
      }

      @include media(mobile_xs, mobile_sm) {
        gap: 24px;
      }

      > .side-card-header {
        @include getData(font-size, side-card-header-font-size);
        display: flex;
        flex-direction: column;
        gap: 8px;
        @include media(mobile_lg, tablet) {
          flex-direction: row;
          justify-content: space-between;
          font-size: 32px;
        }
        > #Card_name {
          font-weight: bold;
          margin-bottom: 0;
          line-height: 1;
          width: 100%;
        }
        > .header-description {
          display: flex;
          flex-direction: column;
          font-size: 12px;
          width: 100%;
          @include media(mobile_lg, tablet) {
            text-align: right;
            font-size: 16px;
          }
          > #Card_where_to_redeem {
            line-height: 1;
            width: 100%;
            color: #828282;
            font-weight: 400;
            margin-bottom: 8px;
          }

          > #Card_range_of_amount {
            @include getData(font-size, Card_range_of_amount-font-size);
            @include getData(font-weight, Card_range_of_amount-font-weight);

            line-height: 1;
            width: 100%;
            color: #333333;
            margin-bottom: 0;
          }
        }
      }

      > .side-card-description {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include getData(font-size, side-card-description-font-size);
        font-weight: 400;
        color: #828282;

        @include media(mobile_lg, tablet) {
          flex-direction: row;
          // align-items: center;
          // gap: 12px;
        }
        p {
          color: #828282;
          margin-bottom: 0;
        }
        > .Card {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 16px;

          @include media(mobile_xs, mobile_sm) {
            flex-direction: row;
            align-items: center;
            gap: 12px;
          }

          &__delivery-option {
            display: flex;
            flex-direction: column;

            @include media(mobile_xs, mobile_sm) {
              gap: 12px;
              flex-direction: row;
            }
            @include media(mobile_lg, tablet) {
              flex: 1 0 25%;
            }

            > .option-container {
              display: flex;
              flex-wrap: wrap;
              gap: 12px;
              @include tablet {
                gap: 16px;
              }
              @include media(mobile_xs, mobile_sm) {
                flex: 1 0 50%;
              }
              .option {
                @include getData(color, gray2);
                display: flex;
                gap: 4px;
                align-items: center;
              }
            }
          }

          &__validity {
            display: flex;
            flex-direction: column;

            @include media(mobile_xs, mobile_sm) {
              flex-direction: row;
              gap: 12px;
            }
            @include tablet {
              flex: 1 0 25%;
            }
            span {
              @include getData(color, gray2);
              display: flex;
              gap: 4px;
            }
          }

          &__button {
            width: 100%;

            display: flex;
            justify-content: center;

            @include media(mobile_lg, tablet) {
              justify-content: flex-end;
            }

            .btn-card {
              max-width: 339px;
              min-width: 120px;
              width: 100%;
              height: 40px;
              border-radius: 100px;
              @include mobile {
                max-width: 100%;
              }
            }

            a.btn-card {
              padding: 10px;
              @include btnPrimary;
              @include btnPrimaryAnmiated;
              font-weight: bold;
              font-size: 16px;
              line-height: 19px;
              position: relative;
              z-index: 1;
              border-radius: 100px;
            }
          }
        }
      }

      > .side-card-detail {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
        grid-auto-rows: minmax(0, auto);

        @include tablet {
          grid-template-columns: repeat(2, 1fr);
        }

        > div {
          color: #828282;
          gap: 8px;

          @include tablet {
            padding: 12px;
            box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);

            &.fill-space {
              grid-column: span 2;
            }
          }

          h4 {
            @include getData(font-size, side-card-detail-h4-font-size);
            @include getData(font-weight, side-card-detail-h4-font-weight);
            margin-bottom: 0;
          }

          .detail-content {
            @include getData(font-size, detail-content-font-size);
            @include getData(color, detail-content-color);
            
            img {
              max-width: 100%;
              background-size: cover;
            }
            p {
              font-size: 16px;
              color: #828282;
              margin-bottom: 0;
            }
            &.readMore {
              height: 50px;
              overflow: hidden;
            }
          }

          > p.button-readMore {
            @include link;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
