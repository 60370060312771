.a-about-main {
  width: 100%;
  height: max-content;
  padding: 144px 0 200px 0;

  @include getData(background-color, bg);
}

.a-about {
  width: 800px;
  img {
    width: 100%;
    height: 437px;
    margin: 0 0 20px 0;
  }
  section {
    h3 {
      margin: 0 0 20px 0;

      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      @include getData(color, gray1);
    }
    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, gray2);
    }
  }
}
