.a-qc-main {
  width: 100%;
  height: max-content;
  @include getData(background-color, bg);
  #queryCardBar {
    width: 840px;
    height: 63px;
    margin: 144px 0 60px 0;
    padding: 10px 0;

    @include getData(background-color, light);
    @include getData(box-shadow, btns1Shadow);
    border-radius: 10px;

    position: sticky;
    top: 20px;
    z-index: 1;
    a {
      width: max-content;
      height: 43px;
      margin: 0 60px 0 0;
      padding: 10px;

      @include getData(background-color, light);
      border-radius: 10px;
      border: none;

      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      @include getData(color, gray3);
      &:focus,
      &:active {
        box-shadow: none;
      }
      &:last-of-type {
        margin: 0;
      }
    }
  }
  .a-qc-querycard {
    width: 840px;
    height: max-content;
    margin: 144px 0 200px 0;
    section {
      width: 100%;
      height: max-content;
      padding: 20px;

      @include getData(background-color, light);
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 4px;
      label {
        margin: 0;

        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
      }
      input {
        width: 800px;

        border-radius: 6px;
        &::placeholder {
          @include getData(color, gray2);
        }
      }
      div {
        &:first-of-type {
          margin: 0 0 12px 0;
          input {
            height: 39px;
          }
        }
        &:last-of-type {
          div {
            margin: 0;
            position: relative;
            input {
              height: 44px;
            }
            button {
              width: max-content;
              height: max-content;
              margin: 0;
              padding: 0;
              @include btnNone;

              position: absolute;
              bottom: 10px;
              right: 25px;
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
    }
    .error {
      i {
        @include getData(color, formControlError, !important);
      }
    }
    .a-qc-query-btn {
      width: 100%;
      height: 63px;
      margin: 32px 0 0 0;
      padding: 22px 0;

      @include btnFilled;
      @include getData(background-color, btn);
      border-radius: 6px;

      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, light);
    }
  }
}

// resued elements
.a-qc-title {
  margin: 0 0 20px 0;
  h3 {
    margin: 0;

    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    @include getData(color, black);
  }
  h4 {
    margin: 12px 0 20px 0;

    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    @include getData(color, gray3);
    a {
      width: max-content !important;
      height: max-content !important;
      margin: 0 0 0 4px;
      padding: 0;

      background-color: transparent !important;
      @include getData(color, btn, !important);
      text-decoration: underline !important;
      @include btnText;
    }
  }
}

// special state
.a-qc-bar-active {
  @include getData(background-color, bg, !important);

  @include getData(color, color2, !important);
}
.a-qc-querycard-bar {
  width: 840px !important;
}
.a-qc-faqs-bar {
  width: 1200px !important;
}
