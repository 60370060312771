.a-a-wallet {
  section {
    width: 100%;
    &:first-of-type {
      padding: 10px;
      margin: 0 0 20px 0;
      @include getData(background-color, light);
      @include getData(box-shadow, btns1Shadow);
      border-radius: 4px;
      .a-a-wallet-total {
        padding: 10px;
      }
      h5 {
        margin: 0 0 10px 0 !important;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
        em {
          margin: 0 0 0 4px !important;

          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, gray3);
        }
      }
      h3 {
        margin: 0 0 14px 0 !important;

        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
        @include getData(color, color2);
      }
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
      }
      i {
        width: 1px;
        height: 32px;
        margin: 10px;

        @include getData(background-color, gray5);
      }
      .d-flex {
        div {
          div {
            padding: 10px;
            margin: 0 4px 0 0;
            a {
              @include btnClear;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              @include getData(color, color2);
            }
          }
        }
      }
      a {
        padding: 10px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, color2);
        @media screen and (min-width: 992px) and (max-width: 1199px) {
          margin: 4px 0 0 0;
        }
      }
    }
    &:last-of-type {
      padding: 20px;

            @include getData(background-color, light);
            @include getData(box-shadow, pagesShadow, !important);
            border-radius: 4px;
            .a-a-wallet-topup {
                // width: 772px;
                h4 {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                    @include getData(color, gray2);
                }
                h5 {
                    margin: 20px 0 8px 0 !important;

          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, gray2);
        }
        .form-check {
          width: 380px;
          min-width: 260px;
          height: 39px;
          padding: 10px 10px 10px 10px;
          margin: 0 0 8px 0;

                    @include getData(box-shadow, pagesShadow, !important);
                    border-radius: 10px;

                    display: flex;
                    align-items: center;

                    input {
                        width: 16px;
                        height: 16px;
                        margin: 0 12px 0 0;

                        @include getData(border, gray5, null, 1px solid);
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: contain;

                        &:checked {
                            @include getData(background-image, mobileRadioBg, !important);
                            background-color: $light;
                        }
                    }
                    label {
                        width: 80%;
                        margin: 0;

                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        @include getData(color, gray2);
                    }
                    &.creditCard {
                        margin: 0 0 8px 0;
                    }
                    &.debitCard,
                    &.creditCard {
                        img {
                            width: 34px;
                            height: 24px;
                            margin: 0 10px 0 0;
                            &:first-of-type {
                                display: inline-block;
                                margin-left: 10px;
                            }
                            &:last-of-type {
                                margin: 0;
                            }
                        }
                    }
                    &.paypal {
                        img {
                            width: 61px;
                            height: 16px;
                        }
                    }
                    &:nth-of-type(2) {
                        // margin: 0 0 8px 12px;
                    }
                    &:nth-of-type(3) {
                        margin: 0 12px 8px 0;
                    }
                    &:nth-last-of-type(4) {
                        margin: 0 0 8px 0;
                    }
                    &:last-of-type {
                        margin: 0;
                    }
                }
                .a-form-control {
                    // margin: 20px 0 0 0 !important;
                    input {
                        height: 39px;
                    }
                }
                
                button {
                    width: 100%;
                    height: 40px;
                    padding: 11.5px 0;
                    margin: 20px 0 0 0;

                    @include btnFilled;
                    @include getData(background-color, btn);
                    border-radius: 4px;

                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    @include getData(color, light);
                }
                
                .usp-widget-content {
                    .usp-flex {
                        .usp-mt-4 {
                            margin-top: 0;
                            
                            .usp-items-stretch {
                                flex-direction: row;
                                
                                .usp-line-item:nth-child(1){
                                    margin: 0;
                                }
                                
                                .usp-line-item {
                                    width: 380px;
                                    min-width: 260px;
                                    height: 39px;

                                    padding: 2px 0px 2px 10px !important;
                                    font-style: normal !important;
                                    font-weight: 500 !important;
                                    @include getData(box-shadow, pagesShadow, !important);
                                    @include getData(background-color, light, !important);
                                    @include getData(color, gray2, !important);
                                    border-radius: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
