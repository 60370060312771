.a-bf-breadcrumb {
  width: max-content;
  height: 63px;
  margin-top: 64px;

  @include getData(background-color, light);
  border-radius: 4px;

  position: sticky;
  top: 20px !important;
  z-index: 3;

  span {
    transition: background-color 0.2s;
    padding: 10px 20px;

    @include getData(background-color, light);
    border-radius: 4px;

    display: flex;
    align-items: center;
    position: relative;

    h6 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 140.62%;
      @include getData(color, gray4);
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 140.62%;
      @include getData(color, gray4);

      &:lang(fr) {
        font-size: 14px;
      }
    }
    &:nth-of-type(odd) img {
      width: 40px;
      height: 40px;
      margin: 0 20px 0 0;
    }
    &:nth-of-type(even) {
      width: 118px;

      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 32px;
        height: 28px;
      }
    }

    .a-bf-breadcrumb-loading {
      position: absolute;
      transition: opacity 0.2s;
    }

    .a-bf-breadcrumb-visible {
      opacity: 1;
    }

    .a-bf-breadcrumb-gone {
      opacity: 0;
    }
  }

  &.w-full {
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    top: 80px !important;
    span {
      &:nth-of-type(even) {
        width: auto;
        flex: 1;
        img {
          object-fit: contain;
        }
      }
    }
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    span {
      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140.62%;
      }
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 140.62%;
      }
      &:nth-of-type(odd) img {
        display: none;
      }
    }
  }
  @media screen and (min-width: 1400px) {
    height: 66px;
    span {
      h6 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140.62%;
      }
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 140.62%;
      }
    }
  }
}

.a-bf-breadcrumb-done {
  h6 {
    font-weight: 500;
    font-size: 14px;
    line-height: 140.62%;
    @include getData(color, gray4, !important);
  }
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 140.62%;
    @include getData(color, gray4, !important);

    &:lang(fr) {
      font-size: 14px;
    }
  }
}

.a-bf-breadcrumb-active {
  @include getData(background-color, color2, !important);
  border-radius: 4px;
  border: none;
  box-shadow: none !important;
  h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140.62%;
    @include getData(color, light, !important);
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140.62%;
    @include getData(color, light, !important);

    &:lang(fr) {
      font-size: 14px;
    }
  }
}

.a-bf-breadcrumb-shadow {
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);
  top: 0px !important;
}
