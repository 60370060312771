.a-b2b-information-section1 {
  width: 100%;
  height: 600px;
  margin-top: 104px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
  }

  div {
    width: 100%;
    height: 100%;
    color: white;
    font-size: 60px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;

    img {
      width: auto;
      height: 81px;
      margin-left: 24px;
    }
  }

  // padding: 40px 34.5px 0 34.5px;
  // @include bgImage(url("/img/B2Binfomation/section1/pic/BG.png"));

  // display: flex;
  // flex-direction: column;
  // align-items: center;

  // overflow: hidden;

  // h1 {
  //     font-style: normal;
  //     font-weight: bold;
  //     font-size: 48px;
  //     line-height: 56px;
  //     @include getData(color, light);
  // }
  // .a-b2b-information-introHowToBuy {
  //     width: 100%;
  //     height: 503px;

  //     display: flex;
  //     @media screen and (min-width: 1469px) {
  //         justify-content: center;
  //         .a-scroll-btns {
  //             display: none;
  //         }
  //     }
  //     .d-flex {
  //         padding: 40px 0 27px 0;
  //         .a-b2b-information-cardBlog {
  //             width: 270px;
  //             min-width: 270px;
  //             height: 100%;
  //             padding: 0 0 8px 14.5px;
  //             margin: 0 16px 0 0;

  //             @include getData(box-shadow, btns1Shadow);
  //             border-radius: 10px;
  //             overflow: hidden;

  //             a {
  //                 @include btnClear;
  //                 height: 36px;
  //                 padding: 8.5px 10px;

  //                 background: rgba(0, 0, 0, 0.6);
  //                 border-radius: 10px;

  //                 display: flex;
  //                 align-items: center;

  //                 font-style: normal;
  //                 font-weight: normal;
  //                 font-size: 16px;
  //                 line-height: 19px;
  //                 @include getData(color, light);

  //                 display: flex;
  //                 align-items: center;
  //                 justify-content: center;
  //                 img {
  //                     width: 24px;
  //                     height: 24px;
  //                     margin: 0 0 0 10px;
  //                 }
  //                 &:hover,
  //                 &:focus,
  //                 &:active {
  //                     background: rgba(0, 0, 0, 0.6) !important;
  //                 }
  //             }
  //             &:first-of-type {
  //                 padding: 0;
  //                 div {
  //                     width: 100%;
  //                     height: 100%;
  //                     padding: 27px 17px 30px 19px;

  //                     @include getData(background, mainLinear);

  //                     display: flex;
  //                     flex-direction: column;
  //                     align-items: center;
  //                     h3 {
  //                         font-style: normal;
  //                         font-weight: 500;
  //                         font-size: 20px;
  //                         line-height: 23px;
  //                         text-align: center;
  //                         @include getData(color, light);
  //                     }
  //                     h6 {
  //                         margin: 12px 0 20px 0 !important;

  //                         font-style: normal;
  //                         font-weight: normal;
  //                         font-size: 14px;
  //                         line-height: 140.62%;
  //                         @include getData(color, light);
  //                         text-align: center;
  //                     }
  //                     a {
  //                         width: 200px;
  //                         height: 60px;
  //                         padding: 21px 0 20px 0;

  //                         background-color: transparent;
  //                         @include getData(border, light, null, 1px solid);
  //                         border-radius: 4px;

  //                         font-style: normal;
  //                         font-weight: bold;
  //                         font-size: 16px;
  //                         line-height: 19px;
  //                         @include getData(color, light);

  //                         display: flex;
  //                         justify-content: center;
  //                         align-items: center;
  //                         &:focus,
  //                         &:active {
  //                             box-shadow: none;
  //                         }
  //                     }
  //                 }
  //             }
  //             &:nth-of-type(2) {
  //                 width: 611px;
  //                 min-width: 611px;

  //                 @include bgImage(url("/img/B2Binfomation/section1/pic/landscape_01.png"));

  //                 display: flex;
  //                 justify-content: flex-start;
  //                 align-items: flex-end;
  //             }
  //             &:nth-of-type(3) {
  //                 @include bgImage(url("/img/B2Binfomation/section1/pic/portrait_01.png"));

  //                 display: flex;
  //                 justify-content: flex-start;
  //                 align-items: flex-end;
  //             }
  //             &:last-of-type {
  //                 margin: 0;

  //                 @include bgImage(url("/img/B2Binfomation/section1/pic/portrait_02.png"));

  //                 display: flex;
  //                 justify-content: flex-start;
  //                 align-items: flex-end;
  //             }
  //         }
  //     }
  // }
}

.a-b2b-information-scrollLeft {
  transform: translateX(0);
  transition: 0.5s;
}

.a-b2b-information-scrollRight {
  transform: translateX(calc(100vw - 1539px));
  transition: 0.5s;
}

.a-b2b-information-section1-gap {
  width: 100%;
  height: 89px;

  @include getData(background-color, b2b);

  display: flex;
  justify-content: center;
  align-items: flex-start;
  img {
    width: 478px;
    height: 143px;
  }
}

.a-b2b-information-section2 {
  width: 100%;
  margin: 40px 0 0 0;
  h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    @include getData(color, b2b);
    text-align: center;
  }
  section {
    padding: 80px 100px 100px 100px;

    display: flex;
    justify-content: space-between;
    div {
      width: 234px;
      height: max-content;

      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 12px;
      div:first-of-type {
        width: 104px;
        height: 104px;
        margin: 0 0 40px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        @include getData(background-color, color2);
        @include getData(color, light);

        svg {
          width: 24px;
          height: 24px;
        }
      }
      h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
        text-align: center;
      }
      h6 {
        margin: 12px 0 20px 0 !important;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140.62%;
        @include getData(color, gray2);
        text-align: center;
      }
      button {
        width: 200px;
        height: 60px;

        background-color: transparent;
        @include getData(border, color2, null, 1px solid);
        border-radius: 4px;

        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, b2b);
        text-align: center;

        display: flex;
        align-items: center;
        justify-content: center;
        &:focus,
        &:active {
          box-shadow: none;
        }
      }
    }
  }
}

.a-b2b-information-section3 {
  width: 100%;
  height: 700px;
  padding: 110px 135px;

  @include getData(background-color, bg);

  display: flex;
  align-items: center;
  justify-content: space-between;
  section {
    width: 422px;
    height: 321px;
    h1 {
      font-style: normal;
      font-weight: 500;
      font-size: 56px;
      line-height: 66px;
      @include getData(color, black);
    }
    h4 {
      margin: 12px 0 32px 0 !important;

      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
      @include getData(color, black);
    }
    a {
      width: 200px;
      height: 60px;

      @include btnFilled;
      @include getData(background-color, btn);
      border-radius: 4px;

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, light);
      text-align: center;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .a-b2b-information-ad {
    width: 670px;
    height: 480px;
    padding: 0 0 40px 46px;

    @include bgImage(url('/img/B2Binfomation/section3/pic/browser.png'));

    position: relative;
    z-index: 0;

    // display: flex;
    // justify-content: flex-start;
    // align-items: flex-end;
    img {
      width: 751.39px;
      height: 587.83px;

      position: absolute;
      top: -110px;
      right: -22px;
      z-index: -2;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      @include getData(color, b2b);

      position: absolute;
      bottom: 40px;
      left: 46px;
      z-index: 1;
      &::before {
        content: '';
        width: 194px;
        height: 14px;

        @include getData(background-color, bg);

        position: absolute;
        top: 25%;
        left: 0;
        z-index: -1;
      }
      &::after {
        content: '';
        width: 145px;
        height: 14px;

        @include getData(background-color, bg);

        position: absolute;
        bottom: 0;
        left: 0;
        z-index: -1;
      }
    }
  }
}

.a-b2b-information-section3-gap {
  width: 100%;
  height: 155px;
  padding: 40px 127px;

  @include getData(background-color, dark);

  display: flex;
  align-items: center;
  justify-content: space-between;
  h2 {
    margin: 0 0 10px 0 !important;

    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 42px;
    @include getData(color, light);
  }
  h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    @include getData(color, light);
  }
  i {
    width: 4px;
    height: 75px;

    @include getData(background-color, color2);
  }
  h3 {
    margin: 0 0 10px 0 !important;

    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    @include getData(color, light);
  }
  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, light);
  }
}

.a-b2b-information-section4 {
  width: 100%;
  padding: 69px 96px 102px 96px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 56px;
    @include getData(color, black);
  }
  h4 {
    width: 506px;
    margin: 32px 0 24px 0 !important;

    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    @include getData(color, black);
  }
  .a-b2b-information-section4-btn {
    width: 240px;
    height: 60px;
    margin: 0 0 54px 0;

    @include getData(background, mainLinear);

    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, light);
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    &:focus,
    &:active {
      @include getData(color, light);
      box-shadow: none !important;
    }
  }
  section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    a {
      width: 360px;
      height: 282px;

      @include getData(box-shadow, btns1Shadow);
      border-radius: 10px;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;

      cursor: pointer;
      div {
        width: 100%;
        height: max-content;
        padding: 20px;

        @include getData(background-color, light);
        @include getData(box-shadow, pagesShadow, !important);
        border-radius: 10px;
        h3 {
          margin: 0 0 4px 0 !important;

          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 28px;
          @include getData(color, gray1);
        }
        h5 {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, gray3);
        }
      }
      &:first-of-type {
        @include bgImage(url('/img/B2Binfomation/section4/pic/paragraph01.png'));
      }
      &:nth-of-type(2) {
        @include bgImage(url('/img/B2Binfomation/section4/pic/paragraph02.png'));
      }
      &:last-of-type {
        @include bgImage(url('/img/B2Binfomation/section4/pic/paragraph03.png'));
      }
    }
  }
}

.a-b2b-information-section5 {
  width: 100%;
  height: 578px;
  padding: 89px 192px 100px 192px;

  @include getData(background-color, b2b);

  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .a-b2b-information-section5-tab {
    width: 478px;
    height: 140px;

    position: absolute;
    top: -51px;
    &:first-of-type {
      left: 43px;
    }
    &:nth-of-type(2) {
      left: 50%;
      transform: translateX(-50%);
    }
    &:last-of-type {
      right: 43px;
    }
  }
  h1 {
    margin: 0 0 60px 0 !important;

    font-style: normal;
    font-weight: bold;
    font-size: 64px;
    line-height: 75px;
    @include getData(color, light);
  }
  section {
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    div {
      width: 234px;
      height: max-content;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 104px;
        height: 104px;
        margin: 0 0 40px 0;
      }
      h5 {
        margin: 0 0 12px 0 !important;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, light);
        text-align: center;
      }
      h6 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 140.62%;
        @include getData(color, light);
        text-align: center;
      }
    }
  }
}

.a-b2b-information-section6 {
  width: 100%;
  padding: 80px 100px 100px 100px;

  h1 {
    @include getData(color, dark);
    font-size: 48px;
  }

  section {
    display: flex;
    justify-content: space-between;

    article {
      width: 24%;
      max-width: 250px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 60px;

      img {
        width: 100%;
        border-radius: 12px;
      }

      button {
        width: 80%;
        max-width: 200px;
        padding: 20px 0;
        margin-top: 20px;
        font-size: 16px;
        text-align: center;
        @include getData(color, b2b);
        @include getData(border, color2, null, 1px solid);
        border-radius: 4px;
        background-color: white;

        &:hover {
          color: white;

          @include getData(background-color, color2);
        }

        &:focus,
        &:active {
          color: white;
          @include getData(background-color, btnPressed);
        }
      }
    }
  }
}

.a-b2b-information-section7 {
  width: 100%;
  padding: 80px 100px 100px 100px;

  h1 {
    @include getData(color, dark);
    font-size: 48px;
  }

  .a-card-all {
    width: 100%;
    height: max-content;

    li {
      display: flex;
      justify-content: center;
    }

    article {
      width: 24%;
      min-width: 252px;
      margin-top: 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .hover {
        transform: rotateY(180deg);
      }

      .a-b2b-information-section7-card {
        height: 283px;
        width: 250px;
        margin: 0 12px 60px 12px;
        border-radius: 10px;
        position: relative;
        transition: transform 1s;
        transform-style: preserve-3d;
        cursor: pointer;

        .a-b2b-information-section7-front {
          width: 100%;
          height: 100%;
          padding: 17px;
          border: 1px solid rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          backface-visibility: hidden;

          h2 {
            font-size: 18px;
            @include getData(color, gray1);
            margin-top: 10px;
          }

          p {
            font-size: 14px;
            @include getData(color, gray3);
          }

          img {
            width: 216px;
            height: 135px;
          }
        }

        .a-b2b-information-section7-back {
          width: 100%;
          height: 100%;
          backface-visibility: hidden;
          transform: rotateY(180deg);
          transition: transform 1s;
          position: absolute;
          top: 0;
          left: 0;

          img {
            width: 100%;
            height: 100%;
          }

          button {
            width: calc(100% - 40px);
            height: 40px;

            position: absolute;
            bottom: 20px;
            left: 20px;

            @include btnPrimary;

            font-weight: bold;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}

// special state

.a-b2b-information-section4-tabs-active {
  position: relative;

  @include getData(box-shadow, pagesShadow, !important);
  &:after {
    content: '';
    width: 380px;
    height: 302px;

    @include getData(border, b2b, null, 4px solid);
    border-radius: 10px;

    position: absolute;
    top: -10px;
    left: -10px;
  }
}
