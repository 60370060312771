.a-card-display {
  height: max-content;
  margin: 0 0 16px 0;

  .carousel-item-style {
    width: auto !important;
  }

  .a-card-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  // background-image: card cover from backend
  border-radius: 10px;
  background: linear-gradient(262.54deg, rgba(255, 255, 255, 0.9) 15.37%, rgba(255, 255, 255, 0.8) 48.76%, rgba(255, 255, 255, 0.9) 66.09%), $backendData;
  backdrop-filter: blur(2px);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  .a-scroll-btns-gradient {
    @media screen and (min-width: 1399px) {
      display: none;
    }
  }
  .a-card-row {
    padding: 10px;
    background-color: transparent;

    overflow-x: auto;
    overflow-y: hidden;
    @include hideScrollbar;
    .a-card-info {
      width: 210px;
      min-width: 210px;
      height: 132px;
      margin: 0 12px 0 0;
      padding: 0;

      border-radius: 10px;
      @include getData(box-shadow, btns1Shadow);
      .productImage {
        width: 100%;
        height: 100%;
      }
    }
    .a-card-detail {
      width: max-content;
      min-width: 197px;
      max-width: 300px;
      height: 132px;
      padding: 10px 20px;
      margin: 0 12px 0 0;

      @include getData(background-color, light);
      @include getData(box-shadow, a-card-detail-box-shadow);
      @include getData(border, a-card-detail-border);
      @include getData(border-radius, a-card-detail-border-radius);
      border-radius: 10px;

      overflow: hidden;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: stretch;
      h4 {
        margin: 0 0 2px 0 !important;

        font-weight: bold !important;
        font-size: 18px !important;
        line-height: 21px !important;
        @include getData(color, gray4, !important);
      }
      h5 {
        margin: 0 !important;

        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        @include getData(color, gray3, !important);
      }
      h6 {
        margin: 4px 0 !important;

        font-weight: normal !important;
        font-size: 12px !important;
        line-height: 14px !important;
        @include getData(color, gray3, !important);
        text-align: end !important;
      }
      h3 {
        margin: 0 !important;

        font-weight: normal !important;
        font-size: 18px !important;
        line-height: 21px !important;
        @include getData(color, color2, !important);
        text-align: end !important;
      }
    }
    .a-card-info-modal-btn {
      width: 152px !important;
      height: 132px;
      padding: 47.5px 20px !important;

      background: linear-gradient(180deg, rgba(255, 109, 98, 0.7) 0%, rgba(244, 40, 58, 0.7) 100%) !important;
      @include getData(box-shadow, btns1Shadow, !important);
      border-radius: 10px !important;
      border: none !important;

      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 112px !important;
        height: max-content !important;
        padding: 10px 0 !important;

        background: linear-gradient(227.38deg, rgba(255, 255, 255, 0.3) 3.37%, rgba(255, 255, 255, 0.1) 49.26%, rgba(255, 255, 255, 0.3) 88.91%) !important;
        backdrop-filter: blur(2px) !important;
        border-radius: 4px !important;

        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        @include getData(color, light, !important);
      }
    }
  }
}

.a-card-list {
  margin: 0 0 12px 0;

  .a-card-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  h4 {
    margin: 0 0 12px 0 !important;

    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    @include getData(color, gray1);
  }
  .react-multi-carousel-item {
    width: 215px !important;
  }
  > div {
    margin-bottom: -6px;
    .a-card-all {
      width: 100%;
      height: max-content;
      padding: 16px 0 16px 15px;
      margin: 0;
      @media screen and (min-width: 992px) and (max-width: 1399px) {
        padding: 16px 0 16px 0;
      }
      .a-card-select {
        @include getData(width, accountProductCardWidth);
        @include getData(min-width, accountProductCardWidth);
        min-height: min-content;
        height: 100%;
        padding: 10px 20px;
        margin: 0 24px 0 0;

        @include getData(background-color, light);
        @include getData(box-shadow, btns1Shadow);
        border-radius: 10px;
        @include getData(border, light, null, 1px solid);
        cursor: pointer;

        &:last-of-type {
          margin: 0;
        }
        .form-check {
          padding: 0;
          justify-content: flex-end;
          margin-right: -10px;
          input {
            margin: 0 0 0 4px;
            @include getData(border, gray4, null, 1px solid);
            &.form-check-input {
              width: 12px;
              height: 12px;
            }
            &:focus,
            &:active {
              box-shadow: none;
              @include getData(border, gray4, null, 1px solid);
            }
            &:checked[type='radio'] {
              @include getData(background-image, chooseOnePressed, !important);
              @include getData(background-color, light);
              @include getData(border, color2, null, 1px solid);
              box-shadow: none;
            }
          }
          label {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray4);
            &.form-check-label {
              cursor: pointer;
            }
          }
        }
        h5 {
          margin: 0 0 10px 0 !important;
          line-height: 19px;
          @include getData(font-weight, a-card-select-h5-font-weight);
          @include getData(font-size, a-card-select-h5-font-size);
          @include getData(color, gray1);
          display: flex;
          align-items: flex-start;

          em {
            margin: 2px 0 0 4px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray2);
            flex-shrink: 0;
          }
        }
        h6 {
          margin: 0 0 4px 0 !important;

          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, a-card-select-h6-color);
          &:last-of-type {
            margin: 0 0 10px 0 !important;
          }

          span {
            margin-right: 4px;
          }
        }

        .locked-warning {
          font-size: 16px;
          padding: 9.5px 0;
          @include getData(font-weight, normal-font-wieght);
          background: #fff3f2;
          @include getData(color, formControlError);
          border-radius: 10px;
          margin-top: 4px;
          text-align: center;
          margin-left: -10px;
          margin-right: -10px;

          &.font-size-12 {
            font-size: 12px;
          }
        }
      }
    }
  }
}

.a-card-content {
  width: 100%;
  height: max-content;
  margin: 0;
}

.a-card-tab {
  margin: 10px 0 16px 28px !important;
  li {
    margin: 0 40px 0 0;
    padding: 0;
    a {
      padding: 0 6px 10px 6px;

      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      @include getData(color, gray2);
      &:hover {
        @include getData(color, gray2);
      }
    }
    img {
      width: 13.53px;
      height: 13.53px;
      margin: 0 0 0 4px;
    }
  }
  .dropdown {
    width: max-content;
    height: max-content;
    margin: 0;
    padding: 0;
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      padding: 10px 40px;

      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border: none;
      border-radius: 4px;

      @include media(mobile, tablet) {
        position: fixed !important;
      }

      li {
        margin: 0 0 8px 0;
        padding: 0;
        &:last-of-type {
          margin: 0;
        }
        a {
          margin: 0;
          padding: 0;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

.a-card-pay {
  width: 100%;
  height: max-content;
  mark {
    @include btnClear;
    margin: 0 0 16px 20px;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    @include getData(color, color2);
  }
  .a-scroll-btns-gradient {
    @media screen and (min-width: 1399px) {
      display: none;
    }
  }
  section {
    height: max-content;
    padding: 20px 16px 20px 36px;

    overflow-x: auto;
    overflow-y: hidden;
    @include hideScrollbar;
    .a-card-pay-common {
      height: max-content;
      padding: 20px;

      @include getData(background-color, light);
      @include getData(box-shadow, btns1Shadow);
      border-radius: 10px;

      .locked-warning {
        @include getData(color, formControlError);
        @include getData(font-weight, normal-font-wieght);
        margin-right: 10px;
        display: inline-flex;
        align-items: baseline;
        img {
          width: 8.4px;
          height: auto;
          margin-right: 5.8px;
        }
      }

      .a-qc-barcode-pin {
        div {
          width: 100px;
          height: 54px;
          margin: 0 4px 0 0;
          padding: 10px;

          @include getData(background-color, bg);
          border-radius: 4px;
          &:last-of-type {
            margin: 0;
          }
          h6 {
            margin: 0 0 6px 0;
            font-weight: normal;
            font-size: 12px;
            letter-spacing: 0.03em;
            @include getData(color, gray2);
            text-align: center;
            &:last-of-type {
              margin: 0;
            }
          }
        }
      }
      span {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.03em;
        @include getData(color, gray2);
        strong {
          margin: 0 0 0 4px;

          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: 0.03em;
          @include getData(color, gray2);
        }
      }
      h6 {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.03em;
        @include getData(color, gray3);
      }
      i {
        width: 205px;
        height: 1px;
        margin: 10px 0;

        @include getData(background-color, gray6);
      }
    }
    .a-card-redeemWithPDF {
      margin-bottom: 15px;
    }
    .a-card-redeemWithPDF-emilBtn {
      height: auto !important;
    }
    .a-card-redeemWithPDF-container {
      background: linear-gradient(180deg, #ffffff 56.77%, rgba(255, 255, 255, 0) 100%), url('/img/account/myCards/redeemWithPDF.png') !important;
    }
    .a-card-topup {
      width: 267px;
      min-width: 267px;
      padding: 20px 20px 140px 20px;
      margin: 0 0 0 24px;

      @include getData(background, topup, null, linear-gradient(180deg, #ffffff 56.77%, rgba(255, 255, 255, 0) 100%) + ',');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      @include getData(box-shadow, btns1Shadow);
      border-radius: 10px;
      h4 {
        margin: 0 0 10px 0 !important;

        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
      }
      h5 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, gray2);
        padding-bottom: 14px;
      }
      h6 {
        margin: 10px 0 !important;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.03em;
        @include getData(color, gray3);
      }
      button {
        width: 176px;
        height: 40px;
        padding: 10px 10px 10px 15px;

        @include getData(background-color, light);
        @include getData(box-shadow, btns1Shadow);
        border-radius: 4px;

        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, btn);
        z-index: 99;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
  .a-card-pay-barcode {
    .a-card-barcode {
      width: 267.3px;
      min-width: 267.3px;
      img {
        width: 227.3px;
        height: 87px;
      }
      .a-card-code {
        margin: 4px 0 10px 0;
        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.03em;
          @include getData(color, black);
        }
        .a-card-btn-set {
          .a-card-btn {
            margin: 0 0 0 8px;
            width: 32px;
            height: 24px;
            padding: 2px 6px;

            background-color: transparent;
            @include getData(border, gray5, null, 1px solid);
            border-radius: 4px;

            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
            }
          }
          em {
            left: -50px !important;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: rgba(255, 255, 255, 0.7);
            padding: 20px 0;
          }
        }
      }
      .a-card-barcode-pin {
        div {
          width: 100px;
          height: 54px;
          margin: 0 4px 0 0;
          padding: 10px;

          @include getData(background-color, bg);
          border-radius: 4px;
          &:last-of-type {
            margin: 0;
          }
          h6 {
            margin: 0 0 6px 0;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.03em;
            @include getData(color, gray2);
            &:last-of-type {
              margin: 0;
            }
          }
        }
      }
    }
  }
  .a-card-pay-qrcode {
    overflow: auto;
    .a-card-qrcode {
      width: 245px;
      min-width: 245px;
      img {
        width: 24px;
        height: 24px;
        margin: 10px 0;
      }
      .a-card-code {
        margin: 4px 0 10px 0;
        h5 {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.03em;
          @include getData(color, black);
        }
        .a-card-btn-set {
          .a-card-btn {
            margin: 0 0 0 8px;
            width: 32px;
            height: 24px;
            padding: 0 !important;

            background-color: transparent;
            @include getData(border, gray5, null, 1px solid);
            border-radius: 4px;

            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
              margin: auto;
            }
          }
          em {
            top: -50px !important;
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            background: rgba(255, 255, 255, 0.7);
            padding: 20px 0;
          }
        }
      }
    }
  }
}

.a-card-btn-group {
  width: 192px;
  height: max-content;
  .a-card-btn-set {
    width: max-content;
    height: max-content;
    .a-card-btn {
      margin: 0 0 10px 0;
      padding: 4px 5px 4px 10px;
    }
    em {
      width: max-content;
      height: 28px;
      padding: 4px 8px 5px 8px;

      @include getData(background-color, gray1);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4), 0px 0px 1px $black;
      border-radius: 4px;

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, light);

      display: block;
      position: absolute;
      top: -35px;
      left: -70%;

      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px !important;
        height: 16px !important;
        margin: 0 6px 0 0 !important;
      }
      &:after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4px 4px 0 4px;
        @include getData(border, gray1, transparent transparent transparent);

        position: absolute;
        bottom: -4px;
        left: 50%;
      }
    }
  }
}

.a-card-btn {
  width: 93px;
  height: 28px;
  padding: 4px 5px 4px 10px !important;

  @include getData(background-color, light, !important);
  @include getData(border, gray4, !important, 1px solid);
  border-radius: 4px !important;

  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  @include getData(color, gray3, !important);

  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  img {
    width: 20px !important;
    height: 20px !important;
  }
  &:hover,
  &:focus,
  &:active {
    @include getData(background-color, light);
    box-shadow: none !important;
    @include getData(border, gray4, null, 1px solid);

    @include getData(color, gray3);
  }
}

.a-scroll-btns-gradient {
  button {
    @include btnClear;
    width: 115px;
    height: 100%;

    background: linear-gradient(270deg, #ffffff 0, rgba(255, 255, 255, 0) 100%);

    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);

    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      box-shadow: none !important;
    }
    img {
      width: 83px;
      height: 83px;
    }
    &:first-of-type {
      left: 0;
      transform: translateY(-50%) rotate(180deg);
    }
    &:last-of-type {
      right: -1px;
      transform: translateY(-50%);
    }
  }
}

.react-multiple-carousel__arrow {
  @include btnClear;
  width: 115px;
  height: 100%;

  background: linear-gradient(270deg, #ffffff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 0 !important;

  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);

  z-index: 1;

  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:before {
    display: none;
  }
  &:after {
    content: '';
    width: 83px;
    height: 83px;

    background-image: url('/img/account/icon/arrow.svg');
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &:focus,
  &:hover {
    background: linear-gradient(270deg, #ffffff 0, rgba(255, 255, 255, 0) 100%);
    box-shadow: none !important;
    &::after {
      background-image: url('/img/account/icon/arrow_right_hover.svg') !important;
    }
  }
  &:active {
    background: linear-gradient(270deg, #ffffff 0, rgba(255, 255, 255, 0) 100%);
    box-shadow: none !important;
    &::after {
      @include getData(background-image, arrowRightPressed, !important);
    }
  }

  &.react-multiple-carousel__arrow--left {
    left: 0;
    transform: translateY(-50%) rotate(180deg);
  }
  &.react-multiple-carousel__arrow--right {
    right: -1px;
    transform: translateY(-50%);
  }
}

.a-card-tnc,
.a-card-howToUse {
  height: 225px;
  max-height: 225px;

  overflow-x: hidden;
  overflow-y: auto;

  @include media(desktop) {
    @include customScrollbar;
  }

  p {
    width: calc(100% - 37px);
    margin: 0 0 0 20px;

    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    @include getData(color, gray3);
  }
}

.a-card-transfer {
  width: 683px;
  height: max-content;
  margin: 0 0 10px 20px;
  @media screen and (min-width: 992px) and (max-width: 1399px) {
    width: 489px;
  }
  .a-card-transfer-title {
    i {
      margin: 0 4px 0 0 !important;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, gray2);
    }
    div {
      h5 {
        margin: 0 0 4px 0 !important;

        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
      }
      h6 {
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, gray3);
      }
    }
  }
  .a-card-transfer-step1 {
    .a-card-transfer-type {
      width: 100%;
      height: max-content;
      margin: 20px 0 0 0;
      padding: 0 0 0 20px;
      .form-check {
        width: 260px;
        height: 39px;
        margin: 0 12px 8px 0;
        padding: 6px 10px;

        @include getData(background-color, light);
        @include getData(box-shadow, pagesShadow, !important);
        border-radius: 10px;

        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: space-between;
        &:last-of-type {
          margin: 0;
        }
        label {
          width: 100%;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, gray2);

          cursor: pointer;
          img {
            width: max-content;
            height: 24px;
          }
        }
        input {
          width: 16px;
          height: 15px;
          margin: 0;

          @include getData(border, gray5, null, 1px solid);
          border-radius: 4px;

          cursor: pointer;
          &:focus {
            @include getData(border, gray5, null, 1px solid);
            box-shadow: none;
          }
          &:active,
          &:checked {
            @include getData(border, gray5, null, 1px solid);
            box-shadow: none;
          }
        }
        &:first-of-type,
        &:nth-of-type(2) {
          img {
            width: 24px;
            height: 24px;
            margin: 0 12px 0 0;
          }
        }
      }
    }
  }
  hr {
    width: 100% !important;
    height: 1px;
    margin: 30px 10px;

    @include getData(background-color, gray6);
  }
  .a-form-control {
    margin: 0 0 12px 20px !important;
  }
  .a-card-transfer-email {
    width: 469px;
    margin: 0 0 0 20px;
    input {
      height: 39px;
    }
  }
  .a-card-transfer-name {
    width: 469px;
    input {
      width: 100%;
    }
  }
  .a-card-transfer-sms {
    width: 100%;
    .MuiSelect-root {
      height: 44px !important;
    }
    .a-bf-form-phone-input {
      width: 303px;
      height: 44px;
    }
    .MuiInputBase-root {
      width: 303px;
      height: 44px;
    }
    .MuiOutlinedInput-input {
      box-sizing: border-box;
    }
    .a-form-control-error {
      margin-left: 20px;
    }
  }
  .a-card-transfer-step2 {
    .a-template-selector {
      margin-left: 20px;
      .a-form-control {
        margin-left: 0 !important;
      }
    }
  }
  .a-card-transfer-btn {
    width: calc(100% - 20px);
    height: 40px;
    margin: 20px 0 0 20px;
    padding: 11.5px 0;

    @include getData(background-color, btn);
    border-radius: 4px;
    @include btnFilled;

    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    @include getData(color, light);
    &:hover,
    &:focus,
    &:active {
      color: white;
    }
  }
}

// special state
.a-card-select-active {
  @include getData(border, color2, !important, 1px solid);
  box-shadow: none !important;
  .form-check {
    label {
      @include getData(color, color2, !important);
    }
  }
}

.a-card-select-disabled {
  .form-check {
    input {
      display: none;
    }
    label {
      @include getData(color, gray5);
    }
  }
}

.a-card-topup-active {
  @include getData(background-color, bg, !important);
  box-shadow: none !important;
  input {
    @include getData(border, color2, !important, 1px solid);
    &:focus,
    &:active,
    &:checked {
      background-color: transparent !important;
      box-shadow: none !important;
    }
    &:checked[type='radio'] {
      @include getData(background-image, chooseOnePressed, !important);
    }
  }
  label {
    @include getData(color, color2, !important);
  }
}

.a-card-transfer-type-active {
  @include getData(background-color, bg, !important);
  box-shadow: none !important;
  label {
    @include getData(color, color2, !important);
  }
  input {
    @include getData(background-color, color2, !important);
    &:focus {
      @include getData(border, gray5, !important, 1px solid);
      box-shadow: none;
    }
    &:active,
    &:checked {
      @include getData(border, color2, !important, 1px solid);
      box-shadow: none;
    }
  }
}

// modals
#blockModal {
  background-color: rgba(255, 255, 255, 0.8);
  .modal-content {
    width: 316px;
    max-width: none;
    padding: 40px 16.33px 18px 16.33px;

    @include getData(background, light);
    @include getData(box-shadow, btns1Shadow);
    border-radius: 4px;
    border: none;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      @include getData(color, black);
      margin: 0 auto 20px !important;
    }
    h5 {
      margin: 12px 0;

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, black);
    }
    h6 {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, color2);
    }
    .a-modal-btn {
      width: 100%;
      height: 42.01px;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      border-radius: 4px;
      &:first-of-type {
        margin: 32px 0 10px 0;
        @include btnFilled;
      }
      &:last-of-type {
        @include getData(background-color, light);
        @include getData(border, btn, null, 1px solid);

        @include getData(color, btn);
      }
    }
  }
}

.modal {
  background-color: rgba(255, 255, 255, 0.8);
}

#topupModal,
.topupModal {
  // background-color: rgba(255, 255, 255, 0.8);
  .modal-content {
    width: 812px;
    // min-width: 812px;
    max-width: none;
    padding: 20px;

    @include getData(background, light);
    @include getData(box-shadow, btns1Shadow);
    border-radius: 4px;
    border: none;

    @include media(mobile, tablet) {
      max-width: 80%;
      min-width: 0;

      gap: 10px;
      border-radius: 20px;

      margin: auto;

      > div.header-content {
        h4 {
          font-weight: 900 !important;
        }
      }

      .a-form-control {
        input.form-control {
          font-weight: 400 !important;
          &::placeholder {
            font-weight: 400 !important;
          }
        }
      }

      button.btn.btn-light {
        @include btnMobilePrimary;
      }

      //usp-widget modification
      .widget-payment-selection {
        width: 100% !important;
        .usp-mt-4 {
          margin-top: 0;
        }

        .usp-widget-content {
          .usp-grid-cols-2 {
            grid-template-columns: repeat(1, minmax(0, 1fr));
          }

          .usp-line-item,
          .protocol-item,
          .protocol-grid-item {
            width: 100% !important;
            height: 44px;

            display: flex;
            gap: 18px !important;

            width: 100%;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;

            border-radius: 10px;
            padding: 12px;

            .form-check-input {
              margin: 0 !important;
            }

            span {
              font-weight: 700 !important;
              @include getData(color, gray2);
            }
          }

          .usp-max-w-icon-size-sm,
          .usp-max-h-icon-size-sm,
          .usp-max-w-size-item-icon,
          .usp-max-h-size-item-icon {
            display: none;
          }
        }

        > div:nth-child(2) {
          .usp-max-w-icon-size-sm,
          .usp-max-h-icon-size-sm,
          .usp-max-w-size-item-icon,
          .usp-max-h-size-item-icon {
            display: block;
            margin: 0;

            img {
              width: 21px;
            }
          }
        }
      }

      #ccexp {
        @include getData(background-color, light, !important);
      }

      .widget-payment-form {
        .usp-outer-wrapper {
          padding: 12px 0 0 0;
        }
        .usp-widget-display-switcher-container-visible {
          .usp-flex.usp-flex-row.usp-gap-x-8.usp-items-end.usp-mb-4 {
            display: none;
          }

          .usp-payment-form-brands {
            display: none;
          }

          .usp-payment-form {
            margin: auto;
            width: 100%;
            min-width: 0;

            @include media(tablet, desktop) {
              max-width: 288px;
            }

            .wpwl-form-card {
              gap: 0;
              width: 100%;
              min-width: 0;

              // @include getData(border, gray4, !important, 1px solid);
              border-radius: 10px;

              margin: auto;

              .wpwl-group {
                border: none;
                height: 70.333px;
                width: 100%;
                border-radius: 0;

                &-cardNumber {
                  border-top-left-radius: 10px;
                  border-top-right-radius: 10px;
                  @include getData(border-bottom, gray6, null, 1px solid);
                }

                &-cardHolder {
                  @include getData(border-bottom, gray6, null, 1px solid);
                }

                &-expiry {
                  padding: 0;
                  border-bottom-left-radius: 10px;
                  iframe {
                    border-radius: 0;
                    @include getData(background-color, gray5);
                    input {
                      width: 100%;
                    }
                  }
                  .wpwl-wrapper-expiry {
                    border-radius: 0;
                    padding: 0;
                    @include getData(background-color, gray5);
                    iframe {
                      @include getData(background-color, bg);
                    }
                  }
                }

                &-cvv {
                  border-bottom-right-radius: 10px;
                }
              }
              .wpwl-group-submit {
                display: none;
              }
              .payment-form-last-row {
                border: none;
                height: 100%;
                // @include getData(background-color, gray4);
                gap: 0;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
              }
            }
          }
        }
      }
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      @include getData(color, gray2);
    }
    img {
      cursor: pointer;
    }
    h5 {
      margin: 20px 0 8px 0 !important;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, gray2);

      @include media(mobile, desktop) {
        margin-top: 0 !important;
      }
    }
    .a-a-import-balance {
      width: 100%;
    }
    .a-form-control {
      input {
        height: 44px;
      }
    }
    .form-check {
      width: 380px;
      min-width: 380px;
      height: 39px;
      padding: 10px 10px 10px 10px;
      margin: 0 0 8px 0;

      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 10px;
      input {
        width: 16px;
        height: 16px;
        margin: 0 12px 0 0;

        @include getData(border, gray5, null, 1px solid);
        &.form-check-input {
          cursor: pointer;
        }
      }
      label {
        width: 80%;
        margin: 0;
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
      }
      &.debitCard,
      &.creditCard {
        margin: 0 0 8px 0;
        img {
          width: 34px;
          height: 24px;
          margin: 0 10px 0 0;
          &:last-of-type {
            margin: 0;
          }
        }
      }
      &.paypal {
        img {
          width: 61px;
          height: 16px;
        }
      }
      &:nth-of-type(2) {
        margin: 0 0 8px 12px;
      }
      &:nth-of-type(3) {
        margin: 0 12px 8px 0;
      }
      &:nth-last-of-type(4) {
        margin: 0 0 8px 0;
      }
      &:last-of-type {
        margin: 0;
      }
    }

    .btn {
      width: 100%;
      height: 40px;
      margin: 20px 0 0 0;

      @include btnFilled;
      border-radius: 4px;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
}

#importGiftModal {
  background-color: rgba(255, 255, 255, 0.8);
  .modal-content {
    width: 572px;
    min-width: 572px;
    max-width: none;
    padding: 20px;

    @include getData(background, light);
    @include getData(box-shadow, btns1Shadow);
    border-radius: 4px;
    border: none;
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      @include getData(color, gray2);
    }
    .a-form-control {
      margin: 20px 0 0 0;
      input {
        height: 39px;
      }
    }
    button {
      width: 100%;
      height: 40px;
      margin: 20px 0 0 0;

      @include btnFilled;
      border-radius: 4px;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
}

.selected-date-modal {
  width: 380px !important;

  &.mobile {
    @include media(mobile, tablet) {
      width: 100vw !important;
      margin: auto !important;
      max-width: none;

      .modal-content {
        box-shadow: none !important;
        border: none;

        .selected-modal-wrap {
          max-height: 100vh !important;
          .title {
            width: 100% !important;
          }

          .selected-modal-btns {
            display: flex;
            flex-direction: column;

            justify-content: center;
            align-items: center;

            gap: 10px;

            padding-top: 32px;

            @include tablet {
              flex-direction: row;
            }

            button {
              margin: 0;

              &:first-of-type {
                @include btnMobilePrimary;
              }
              &:nth-of-type(2) {
                @include btnMobileSecondary;
              }
            }
          }
        }
      }
    }
  }

  .selected-modal-wrap {
    padding: 16px;
    max-height: 80vh;
    display: flex;
    flex-direction: column;

    // manually customize react-date-range library (mobile date-picker)
    .rdrCalendarWrapper {
      width: calc(100% - 4px);
      // max-width: 27.667em;

      display: flex;
      margin: 4px auto;

      box-shadow: 0px 0px 1.67492px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      .rdrMonths.rdrMonthsVertical {
        > .rdrMonth {
          width: 100%;
          // max-width: 27.667em;
          margin: auto;
        }
      }

      .rdrDay {
        &.rdrDayHovered {
          @include getData(color, btn, !important);
        }
        @include getData(color, btn, !important);

        > .rdrStartEdge,
        > .rdrInRange,
        > .rdrEndEdge {
          @include getData(color, btn, !important);
        }
        > .rdrDayStartPreview.rdrDayEndPreview,
        > .rdrDayStartPreview,
        > .rdrDayEndPreview,
        > .rdrDayInPreview {
          @include getData(color, btn, !important);
        }
        > .rdrSelected {
          @include getData(color, btn, !important);
        }

        .rdrDayNumber span:after {
          @include getData(background-color, btn);
        }

        &:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after {
          @include getData(background-color, light);
        }
      }
    }
  }

  .selected-modal-close {
    text-align: right;

    img {
      cursor: pointer;
    }
  }

  .selected-modal-title {
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    text-align: center;
  }

  .selected-modal-btns {
    text-align: center;
    button {
      width: 100%;
      max-width: 344px;
      height: 40px;
      margin: 20px auto 0;

      border-radius: 4px;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      &:first-of-type {
        margin: 32px 0 10px 0;
        @include btnFilled;
      }
      &:last-of-type {
        margin-top: 0;
        @include getData(background-color, light);
        @include getData(border, btn, null, 1px solid);
        @include getData(color, btn);
      }
    }
  }

  .selected-schedule {
    flex: 1;
    overflow: auto;
    padding-bottom: 12px;

    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;

    .MuiFormControl-root {
      .MuiSelect-root {
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        @include getData(color, gray4, !important);
      }
      &:first-of-type {
        .MuiInputBase-root {
          width: 230px !important;
        }
      }
      &:last-of-type {
        width: 100%;
        margin-top: 6px;

        .MuiInputBase-root {
          width: 344px;
          margin: auto;
        }
      }
    }
    i {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray2);
      em {
        margin: 0 0 0 6px;

        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, color2);
      }
    }
    .MuiPickersStaticWrapper-staticWrapperRoot {
      width: 100%;
      height: 100%;

      overflow: visible;
      .MuiPickersBasePicker-container {
        width: 344px;
        height: 368px;
        max-height: none;
        margin: auto;

        display: flex;
        justify-content: stretch;
        align-items: stretch;
        .MuiPickersBasePicker-pickerView {
          width: 100%;
          max-width: none;
          height: 100%;
          padding: 0;

          @include getData(background-color, light);
          box-shadow: 0px 0px 1.6749242544174194px 0px rgba(0, 0, 0, 0.25);
          // @include getData(box-shadow, btns1Shadow);
          border-radius: 10px;
          .MuiPickersCalendarHeader-switchHeader {
            width: 100%;
            height: 100%;
            margin: 0 !important;
            padding: 0;

            display: flex;
            justify-content: center;
            align-items: center;
            .MuiPickersCalendarHeader-transitionContainer {
              width: 100%;
              height: 40px;

              display: flex;
              justify-content: center;
              align-items: center;
              .MuiTypography-root {
                width: 100%;
                height: 40px;

                display: flex;
                justify-content: center;
                align-items: center;

                font-family: Inter;
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 24px;
                @include getData(color, gray1);
              }
            }
            .MuiSvgIcon-root {
              margin: 0 !important;
            }
          }

          .MuiPickersCalendarHeader-daysHeader {
            padding: 0 32px;
            .MuiTypography-root {
              width: 40px !important;
              height: 40px;
              margin: 0;

              display: flex;
              justify-content: center;
              align-items: center;

              font-family: Inter;
              font-style: normal;
              font-weight: 600;
              font-size: 13px;
              line-height: 24px;
              @include getData(color, grey);
            }
          }
          .MuiPickersCalendar-week {
            div {
              width: 40px;
              height: 40px;

              display: flex;
              justify-content: center;
              align-items: center;
              button {
                width: 40px;
                height: 40px;
                margin: 0;

                display: flex;
                justify-content: center;
                align-items: center;
                .MuiTypography-root {
                  font-family: Inter;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 13px;
                  line-height: 24px;
                }
              }
            }
            .MuiPickersDay-daySelected {
              @include getData(background-color, color2);
              .MuiTypography-root {
                @include getData(color, light, !important);
              }
            }
            .MuiPickersDay-dayDisabled {
              p {
                opacity: 0.4;
              }
            }
          }
          .MuiPickersCalendar-transitionContainer {
            margin: 28px 0 0 0;
            min-height: 240px;
          }
        }
      }
    }

    .MuiPickersDay-current {
      @include getData(color, grey);
    }

    .date-from,
    .date-to {
      .title {
        width: 344px;
        margin: 0 auto 6px;
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
      }
    }

    .date-from {
      margin-bottom: 12px;
    }
  }
}
