.a-a-mobile-order {
  padding-bottom: 60px;

  .a-card-tab {
    margin: 0 0 20px 16px;
  }

  #orderHistory > div:nth-child(2) {
    margin: 0 -16px;
    width: 100vw;

    .a-order-history {
      margin: auto;
      padding: 16px;

      display: flex;
      flex-direction: column;
      gap: 26px;

      .a-card-history-common {
        width: 100% !important;

        margin: auto !important;

        @include media(mobile_sm) {
          max-width: 288px;
        }

        .a-card-history-top {
          > div.d-flex {
            &:first-child {
              gap: 12px;

              > h6 {
                font-size: 12px;

                display: flex;
                flex-direction: column !important;

                em {
                  font-size: 14px;

                  margin: 0 !important;
                }
              }
            }
          }
        }

        .a-card-history-middle {
          @include media(mobile_lg, tablet, desktop) {
            flex-direction: row !important;
          }
        }

        &.a-card-history-order {
          .a-card-history-middle {
            flex-direction: column;

            .a-card-history-divider {
              border-width: 1px;
            }

            h2 {
              font-size: 14px;
            }

            h3 {
              width: max-content;
            }
          }

          .a-card-history-bottom {
            button {
              @include getData(color, btn);
            }
          }

          .a-order-history-total {
            h4 {
              @include getData(color, btn);
            }
          }
        }

        &.a-card-history-wallet {
          hr {
            display: none;
          }
        }

        .a-card-history-collapse {
          .a-order-history-deatil-top {
            flex-direction: column;
            gap: 10px;

            @include media(tablet, desktop) {
              flex-direction: row;
            }

            > img {
              margin-right: 0;
            }
          }

          .a-order-history-total {
            flex-direction: column;
            gap: 12px;

            .history-total-container-text {
              display: flex;
              justify-content: space-between;
              gap: 4px;
            }

            @include media(tablet, desktop) {
              flex-direction: row;
              justify-content: space-between;

              .history-total-container-text {
                display: flex;
                justify-content: center;
              }
            }
          }
        }
      }
    }
  }

  .a-form-control {
    flex-direction: column;
    gap: 13px;

    margin: 20px 0;

    select {
      width: 100%;
      min-width: 250px;
      height: 43px;
      padding: 10px 37px 10px 20px;

      @include getData(background-color, light);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      border: none;

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 140.62%;
      @include getData(color, gray1);
      &::placeholder {
        @include getData(color, gray4);
      }
    }
    input {
      height: 43px;
      padding: 10px 20px;

      @include getData(background-color, light, !important);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      border: none;

      @include getData(color, gray4);
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: start !important;
      &::placeholder {
        @include getData(color, gray4);
        font-weight: bold;
        text-align: start !important;
      }
      &:focus {
        border: none !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
      }
    }
    .form-control-date {
      height: 43px;
      margin-right: 12px;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      @include getData(background-color, light, !important);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      border: none;

      @include getData(color, gray4);
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: start !important;

      &.show-date {
        @include getData(color, gray1);
      }

      img {
        height: 100%;
        padding: 3px;
      }
    }

    // @media screen and (min-width: 992px) and (max-width: 1399px) {
    //     select {
    //         width: 200px;
    //         min-width: 200px;
    //         &:last-of-type {
    //             margin: 0;
    //         }
    //     }
    //     input {
    //         width: 412px;
    //         margin: 12px 0 0 0;
    //     }
    //     .form-control-date {
    //         width: 412px;
    //         margin: 12px 0 0 0;
    //     }
    // }
  }
  .a-order-history,
  .a-order-wallet {
    width: 100%;
    height: max-content;
    // max-height: 812px;

    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbar;
  }
}

.a-card-history-order {
  .a-card-history-middle {
    .a-card-history-left {
      img {
        width: 33.6px;
        height: 42px;
        &.productImg {
          width: 72px;
          height: 45px;
        }
      }
    }
  }
}
