.a-a-detail {
  padding: 20px;

  @include getData(background-color, light);
  @include getData(box-shadow, pagesShadow, !important);
  border-radius: 6px;
  section {
    margin: 0 !important;
  }
  .a-a-importGift-btn {
    width: max-content;
    height: 40px;
    padding: 10px 10px 10px 15px;

    @include getData(background-color, light);
    @include getData(box-shadow, btns1Shadow);
    border-radius: 4px;
    border: none;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    @include getData(color, btn);

    display: flex;
    justify-content: space-between;
    align-items: center;

    position: absolute;
    top: -60px;
    right: 0;
    img {
      width: 20px;
      height: 20px;
      margin-left: 36px;
    }
    &:hover,
    &:focus,
    &:active {
      @include getData(color, btn);
      @include getData(background, light);
      border: none !important;
      @include getData(box-shadow, btns1Shadow, !important);
    }
  }
}
