.m-CheckoutForm {
  overflow: scroll;
  height: calc(100% - 130px);
  padding-bottom: 130px;

  @include getData(background-color, bg);
  .m-bf-checkout {
    .guestContent {
      height: 100%;
    }
    section {
      &:first-of-type {
        // img {
        //     width: 30px;
        //     height: 30px;
        //     margin-right: 16px;
        // }
        // h4 {
        //     @include getData(color, gray4);
        //     font-weight: 500;
        //     font-size: 16px;
        //     line-height: 19px;
        // }
      }
      &:last-of-type {
        .m-bf-content {
          :lang(fr) & {
            width: 100%;
          }

          .a-b2b-bf-discount {
            margin: 0 0 32px 0;
            h4 {
              margin: 0 0 15px 0 !important;

              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, gray2);
            }
            .a-b2b-bf-discount-options {
              width: 398px;
              padding: 10px;

              border-radius: 4px;
              h6 {
                margin: 0 0 2px 0 !important;

                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);
                &:last-of-type {
                  margin: 0 !important;
                }
              }
            }
            .a-b2b-bf-discount-btns {
              margin: 24px 0 0 0;
              display: flex;
              align-items: center;
              button {
                width: max-content;
                height: 40px;
                margin: 0 16px 0 0;

                @include getData(background-color, light);
                @include getData(box-shadow, whiteBlankChoose);

                display: flex;
                align-items: center;
                justify-content: center;
                &:last-of-type {
                  margin: 0;
                }
              }
            }
            i {
              width: 691px;
              height: 1px;
              margin: 34px 10px;

              @include getData(background-color, gray6);

              display: flex;
            }
            .a-b2b-bf-discount-bonus {
              img {
                width: 63.64px;
                height: 40px;
                margin: 0 12px 0 0;

                border-radius: 4px;
              }
              div {
                h5 {
                  margin: 0 0 2px 0 !important;

                  font-style: normal;
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, gray1);
                }
                span {
                  margin: 0 10.5px 0 0;

                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, gray3);
                  text-decoration: line-through;
                }
                strong {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 16px;
                  @include getData(color, color2);
                }
              }
            }
            .a-b2b-bf-discount-giftcard {
              .a-form-control {
                margin: 0 0 24px 0;
                label {
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                  @include getData(color, gray2);
                }
                select {
                  width: 530px;
                  padding: 10px 26.5px;

                  border: none;
                  @include getData(background-color, light);
                  @include getData(box-shadow, pagesShadow, !important);
                  border-radius: 4px;

                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                  @include getData(color, gray2);

                  display: flex;
                  align-items: center;
                }
              }
              h5 {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray2);
              }
              h3 {
                margin: 2px 0 24px 0 !important;

                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 23px;
                @include getData(color, color2);
              }
              span {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);
              }
            }
          }
          .m-bf-summary {
            margin-bottom: 15px;
            gap: 8px;

            @include media(tablet, desktop) {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              grid-auto-flow: dense;
              gap: 8px;
            }

            .a-b2b-bf-quote-btn {
              width: 249px;
              height: 40px;
              margin: 0 0 20px 0;

              background-color: transparent;
              @include getData(border, btn, null, 1px solid);
              border-radius: 4px;

              font-style: normal;
              font-weight: bold;
              font-size: 14px;
              line-height: 16px;
              @include getData(color, btn);

              display: flex;
              justify-content: space-between;
              align-items: center;
              img {
                width: 20px;
                height: 20px;
              }
            }
            h5 {
              margin-bottom: 20px !important;

              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, gray2);
            }
            .m-bf-checkout-order {
              width: 100%;
              @include getData(color, gray2);
              margin-bottom: 8px;

              .d-flex {
                width: 100%;
              }
              p {
                font-size: 12px;
                margin: 0;
                font-weight: 500;

                &.bold {
                  font-weight: 600;
                }
                &.delivery-description,
                &.recipient-description {
                  min-width: 35%;                  
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }
              }
              .m-bf-checkout-description {
                padding: 8px 16px;
                @include getData(background-color, light);
                margin-bottom: 2px;

                > div {
                  gap: 4px;

                  &:first-of-type {
                    flex: 1 0 90%;

                    .m-bf-checkout-recipient {
                      gap: 4px;

                      p {
                        @include getData(color, gray3);
                      }

                      div {
                        &.additional-product {
                          gap: 4px;
                        }
                        &:first-child {
                          gap: 4px;
                        }
                      }

                      .additional-remove-btn {
                        font-size: 12px;
                        @include getData(color, color2);
                        text-decoration: underline;
                        font-weight: 400;
                        padding: 0;
                        text-align: left;
                        border: 0;
                        background-color: transparent;
                      }
                    }
                  }
                  &:last-of-type {
                    display: flex;
                    justify-content: center;
                    align-items: flex-start;

                    flex: 1 0 10%;
                    img {
                      margin: 4px;
                      width: 24px;
                      height: 24px;
                    }
                  }
                }
              }
              .m-bf-checkout-quantity {
                @include getData(background-color, light);
                margin-bottom: 2px;
                padding: 10px 16px;

                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);
                text-align: center;

                div {
                  justify-content: space-between;
                }

                i {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, gray3);
                }
              }
              .m-bf-checkout-amount {
                padding: 8px 16px;
                @include getData(background-color, light);
                div {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  gap: 6px;

                  flex-grow: 1;
                  flex-basis: 0;

                  &:last-of-type {
                    text-align: end;
                  }

                  p {
                    &:last-of-type {
                      @include getData(color, gray3);
                      font-weight: 500;
                    }
                  }
                }
              }
              .m-bf-checkout-discount {
                display: flex;
                justify-content: space-between;

                padding: 8px 16px;
                @include getData(background-color, light);
                margin-top: 2px;

                p {
                  @include getData(color, formControlError);
                }
              }
            }
            .m-bf-checkout-letterFee {
              &-qty,
              &-price {
                @include getData(background, light);
                padding: 8px 16px;
                margin-bottom: 2px;

                p {
                  gap: 4px;

                  font-size: 12px;
                  font-weight: 500;
                  @include getData(color, gray2);

                  margin: 0;

                  > span,
                  &.m-bf-letterFee-qty {
                    font-weight: normal;
                    @include getData(color, gray3);
                  }

                  &.letterFee-total {
                    text-align: end;
                  }
                }
              }

              > .m-bf-checkout-letterFee-price > p {
                flex-basis: 33.33%;
              }
            }
            section {
              div {
                width: 250px;
                div {
                  display: flex;
                  justify-content: space-between;
                }

                h6 {
                  font-weight: bold;
                  white-space: nowrap;
                }
              }

              h6 {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, btn);
                white-space: nowrap;
                strong {
                  margin-left: 4px;

                  font-style: normal;
                  font-weight: bold;
                  font-size: 14px;
                  line-height: 16px;
                  @include getData(color, btn);
                }
              }

              p {
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 4px;
                white-space: nowrap;
              }
            }
          }
          .m-bf-step4-btn {
            gap: 10px;

            &:lang(fr) {
              flex-wrap: wrap;
              button {
                // margin-bottom: 16px;
              }
              .m-bf-step4-btn-add {
                img {
                  display: inline-block;
                  margin-left: 24px;
                }
              }
            }
            .m-bf-confirm {
              font-size: 12px;
              @include getData(color, gray3);
              text-align: center;

              a {
                @include getData(color, btn);
                text-decoration: underline !important;
              }
            }
            .a-b2b-bf-btns {
              margin: 15px 0 15px 0;
              display: flex;
              align-items: center;
              button {
                width: max-content;
                height: 40px;

                @include btnFilled;

                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, light);

                display: flex;
                align-items: center;
                &.wallet-btn {
                  h5 {
                    margin-left: 28px;
                  }
                }
                h5 {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 16px;
                  @include getData(color, light);

                  display: flex;
                  align-items: center;
                  img {
                    width: 18px;
                    height: 18px;
                    margin: 0 0 0 4.5px;
                  }
                }
                &:first-of-type {
                  margin: 0 15px 0 0;
                }
                &:last-of-type {
                  text-align: center;
                  min-width: 100%;
                  justify-content: space-between;
                }
              }
            }
            .m-bf-step4-btn-continue {
              margin: 15px 0 15px 0;
              display: flex;
              align-items: center;

              button {
                width: max-content;
                height: 40px;

                @include btnFilled;

                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, light);

                display: block;
                align-items: center;

                &.wallet-btn {
                  h5 {
                    margin-left: 28px;
                  }
                }

                h5 {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 16px;
                  @include getData(color, light);

                  display: flex;
                  align-items: center;

                  img {
                    width: 18px;
                    height: 18px;
                    margin: 0 0 0 4.5px;
                  }
                }

                &:first-of-type {
                  margin: 0 15px 0 0;
                }

                &:last-of-type {
                  text-align: center;
                  min-width: 100%;
                  justify-content: space-between;
                }
              }
            }
            .m-bf-step4-btn-continue {
              margin-top: 12px;
              width: 100%;
              @include btnFilled;
              &.isFirst {
                margin-left: 0;
              }
            }
            .m-bf-step4-btn-payment {
              flex-wrap: wrap;
              button {
                height: 40px;
                margin-right: 15px;
                margin-bottom: 15px;
                padding: 8px 11px 8px 16px;

                @include getData(background, light);
                @include getData(box-shadow, btns1Shadow);
                border: none;
                border-radius: 4px;

                display: flex;
                align-items: center;
                justify-content: space-between;
                &.debitCards,
                &.creditCards {
                  // min-width: 249px;
                  width: fit-content;
                  div {
                    img {
                      width: 34px;
                      height: 24px;
                      margin-right: 10px;
                      &:first-of-type {
                        margin-left: 10px;
                      }
                      &:last-of-type {
                        margin-right: 37px;
                      }
                    }
                  }
                }
                &.debit-card-with-psp {
                  height: auto;
                  width: auto;
                  div {
                    img {
                      width: auto;
                      height: 80px;
                      object-fit: contain;
                      margin-right: 0px;
                      &:first-of-type {
                        margin-left: 0px;
                      }
                      &:last-of-type {
                        margin-right: 0px;
                      }
                    }
                  }
                  img {
                    width: 25px;
                    height: 25px;
                    margin-left: 55px;
                  }
                }
                &.paypal {
                  width: 140px;
                  img {
                    width: 61px;
                    height: 16px;
                    &:last-of-type {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
                &.alipay {
                  width: 140px;
                  img {
                    width: 55.2px;
                    height: 14px;
                    &:last-of-type {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .a-b2b-bf-discount-options-active {
    @include getData(background-color, bg);
  }

  .a-b2b-bf-discount-btns-active {
    @include getData(background-color, color2, !important);
    box-shadow: none !important;
    @include getData(color, light, !important);
  }

  .m-bf-payment-btn {
    display: flex;

    .cancelBtn {
      margin-right: 20px;
      background-color: #fff;
      @include getData(color, btn);
      @include getData(border, btn, null, 1px solid);
    }

    .payBtn {
      @include getData(background-color, btn);
      @include getData(color, light);
    }

    button {
      width: 100%;
      height: 40px;
      font-family: 'Ubuntu';
      // padding: 11.5px 0;
      @include getData(background-color, btn, !important);
      border-radius: 4px;
      @include getData(color, light, !important);
      border: none;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      padding: 0;
    }
  }

  .m-bf-coupon {
    // margin-bottom: 15px;
    padding: 20px 16px;
    @include getData(background, light);

    // @include getData(box-shadow, pagesShadow, !important);
    // border-radius: 4px;
    h5 {
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 6px !important;
      @include getData(color, gray2);
      @include getData(font-weight, normal-font-wieght);
    }

    h6 {
      font-size: 12px;
      line-height: 15px;
      font-weight: 400;
      @include getData(color, gray3);
    }

    .coupon-input {
      margin-top: 12px;
      flex-wrap: wrap;
      gap: 20px;

      input {
        width: 100%;
        padding: 8px 0;
        border: none;
        outline: none;
        @include getData(border-bottom, gray1, null, 1px solid);

        &::placeholder {
          @include getData(color, gray4);
        }
      }
      .field-label {
        font-size: 12px;
      }

      .card-number-field {
        flex: 1 0 auto;
        &--full {
          flex: 1 0 100%;

          @include media(tablet, desktop) {
            flex: 1 0 50% !important;
          }
        }
      }

      .pin-code-field {
        width: 219px;
        flex: 1 0 auto;
      }

      button:not(.MuiIconButton-root) {
        gap: 12px;

        justify-content: space-around;
        align-items: center;

        // flex: 1 0 auto;
        width: max-content;
        // max-width: 104px;
        font-style: normal;
        margin-top: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        border-radius: 4px;
        height: 44px;
        @include btnMobilePrimary;

        span {
          display: inline-block;
          // width: 86px;
        }
      }

      .field-error {
        color: #eb5757 !important;
        border-color: #eb5757 !important;
      }
    }

    .coupon-list {
      padding-left: 0;
      li {
        margin-top: 20px;
        background-color: #c1a3610d;
        width: 100%;
        border-radius: 4px;
        div {
          display: flex;
          gap: 7px;
          flex: 1 0 auto;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          border-radius: 4px;
          margin-bottom: 0 !important;
          min-height: 40px;
          padding: 4px 6px 4px 10px;
          p {
            margin-bottom: 0 !important;
            color: #000;
            background-color: transparent;
            &.coupon-amount {
              span {
                //@include getData(color, navBtn);
                @include getData(color, navBtn);
                font-weight: 600;
                padding: 0;
              }
            }
            &.coupon-description {
              padding: 0;
            }
          }
          img {
            cursor: pointer;
            display: inline-block;
          }
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 80px;
          margin-left: 0;
          padding: 4px 6px;

          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 19px;

          border-radius: 4px;
          background-color: transparent;
          border: none;
          //@include getData(color, navBtn);
          @include getData(color, navBtn);
          &:hover {
            @include getData(background-color, navBtn, !important);
            @include getData(color, light);
            img {
              // filter refrs: https://codepen.io/sosuke/pen/Pjoqqp
              // target: white;
              filter: brightness(0) invert(1);
            }
          }
          &:focus,
          &:active {
            img {
              // filter refrs: https://codepen.io/sosuke/pen/Pjoqqp
              // target: white;
              filter: brightness(0) invert(1);
            }
            @include getData(background-color, btn, !important);
            @include getData(color, light);
          }
        }
      }
    }
  }

  .m-bf-step4-btn {
    &:lang(fr) {
      flex-wrap: wrap;

      button {
        // margin-bottom: 16px;
      }

      .m-bf-step4-btn-add {
        img {
          display: inline-block;
          margin-left: 24px;
        }
      }
    }

    button {
      width: auto;
      min-width: 170px;
      padding: 10px 10px 10px 15px;

      @include getData(background-color, light);
      box-shadow: none;
      border-radius: 4px;

      @include getData(border, btn, null, 1px solid);

      @include getData(color, btn);
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;

      &:disabled {
        opacity: 0.5;
      }

      img {
        width: 20px;
        height: 20px;
      }
    }

    .m-bf-step4-btn-continue {
      margin: 15px 0 15px 0;
      display: flex;
      align-items: center;

      button {
        width: max-content;
        height: 40px;

        @include btnFilled;

        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, light);

        display: block;
        align-items: center;

        &.wallet-btn {
          h5 {
            margin-left: 28px;
          }
        }

        h5 {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          @include getData(color, light);

          display: flex;
          align-items: center;

          img {
            width: 18px;
            height: 18px;
            margin: 0 0 0 4.5px;
          }
        }

        &:first-of-type {
          margin: 0 15px 0 0;
        }

        &:last-of-type {
          text-align: center;
          min-width: 100%;
          justify-content: space-between;
        }
      }
    }

    .m-bf-step4-btn-continue {
      margin-top: 12px;
      width: 100%;
      @include btnFilled;

      &.isFirst {
        margin-left: 0;
      }
    }

    .m-bf-step4-btn-payment {
      flex-wrap: wrap;

      button {
        height: 40px;
        margin-right: 15px;
        margin-bottom: 15px;
        padding: 8px 11px 8px 16px;

        @include getData(background, light);
        @include getData(box-shadow, btns1Shadow);
        border: none;
        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        &.debitCards,
        &.creditCards {
          // min-width: 249px;
          width: fit-content;

          div {
            img {
              width: 34px;
              height: 24px;
              margin-right: 10px;

              &:first-of-type {
                margin-left: 10px;
              }

              &:last-of-type {
                margin-right: 37px;
              }
            }
          }
        }

        &.debit-card-with-psp {
          height: auto;
          width: auto;

          div {
            img {
              width: auto;
              height: 80px;
              object-fit: contain;
              margin-right: 0px;

              &:first-of-type {
                margin-left: 0px;
              }

              &:last-of-type {
                margin-right: 0px;
              }
            }
          }

          img {
            width: 25px;
            height: 25px;
            margin-left: 55px;
          }
        }

        &.paypal {
          width: 140px;

          img {
            width: 61px;
            height: 16px;

            &:last-of-type {
              width: 20px;
              height: 20px;
            }
          }
        }

        &.alipay {
          width: 140px;

          img {
            width: 55.2px;
            height: 14px;

            &:last-of-type {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}

.m-bf-content-card {
  padding: 8px 16px;
  @include getData(background-color, light, !important);
  margin-bottom: 2px;

  .m-bf-content-card__title {
    font-size: 16px;
    font-weight: 500;
  }

  .m-bf-content-card__desc {
    font-size: 12px;
    font-weight: 400;
  }

  > div {
    gap: 4px;

    &:first-of-type {
      flex: 1 0 90%;

      .m-bf-checkout-recipient {
        gap: 4px;

        p {
          @include getData(color, gray3);
        }

        div {
          &.additional-product {
            gap: 4px;
          }
          &:first-child {
            gap: 4px;
          }
        }

        .additional-remove-btn {
          font-size: 12px;
          @include getData(color, color2);
          text-decoration: underline;
          font-weight: 400;
          padding: 0;
          text-align: left;
          border: 0;
          background-color: transparent;
        }
      }
    }
  }
}