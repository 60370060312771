.statement-block {
  display: flex;
  justify-content: center;
  background-color: #fafafa;
  padding-top: 144px;
  padding-bottom: 200px;

  &__container {
    width: 950px;
    max-width: 100%;
    margin: 0 1rem;
  }

  img {
    width: 100%;
  }

  @import './footer-pages';
}
