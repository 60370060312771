nav {
  li:before {
    content: '';
  }
}
.a-n-nav {
  z-index: 4;
  .navBar_popUp {
    // @include getData(background-color, light);
    @include getData(background-color, 'light');
    // @include getData(box-shadow, btns1Shadow);
    @include getData(box-shadow, 'btns1Shadow');
    border-radius: 10px;

    border: none;

    position: absolute;
    z-index: 10 !important;

    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8.615px 11px 8.615px;
      // @include getData(border-color, color2, transparent,   transparent transparent);
      border-color: transparent transparent #fff transparent;
      filter: drop-shadow(1px -2px 5px rgba(0, 0, 0, 0.2));
      position: absolute;
      top: -11px;
      // left: calc(50% - 8.615px);
      // transform: translateX(-50%);
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
    }
    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8.615px 11px 8.615px;
      // @include getData(border-color, color2, transparent,   transparent transparent);
      border-color: transparent transparent #fff transparent;

      position: absolute;
      top: -9px;
      // left: calc(50% - 8.615px);
      // transform: translateX(-50%);
      left: 0;
      right: 0;
      margin: auto;
      z-index: 2;
    }
  }
  .dropdown-toggle {
    &::after {
      display: none;
    }
  }
  #navBar {
    height: 64px;

    // @include getData(background-color, light, !important);
    @include getData(background-color, 'light', !important);
    // @include getData(light, background-color);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    @include media(mobile, tablet) {
      box-shadow: none;
    }

    @include media(desktop) {
      &.mobile-padding {
        padding: 0 16px;

        .a-n-nav-mobile {
          margin-right: 0;
          gap: 16px;
        }
      }
    }

    &.shadow-hidden {
      box-shadow: none;
    }

    position: sticky;
    z-index: 4;
    #navLogo {
      margin-top: 0;
      margin-right: 40px;
      padding: 0;
      @include btnNone;
      // img {
      //     height: 64px;
      // }
      &.navbar-brand-mobile {
        margin: 0;
        // img {
        //     width: auto;
        //     height: 20px;
        // }
      }
      @include media(desktop) {
        margin-left: 40px;
      }
    }

    #navBar_forCustomer {
      position: relative;
      // height: 64px;
      padding: 21px 20px;
      border-radius: 20px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;

      @include getData(background, navB2CNormalBg);
      @include getData(color, navB2CNormalColor);

      &.navBar_disable {
        @include getData(color, btnDisable);
        cursor: default;
        background-color: white;
      }

      &:hover {
        @include getData(background, navB2CHoverBg, !important);
        @include getData(color, navB2CHoverColor, !important);
      }

      &.a-n-buyeGiftcards-scroll {
        @include getData(background, navB2CActiveBg, !important);
        @include getData(color, navB2CActiveColor, !important);
      }
    }

    #navBar_forBusiness {
      position: relative;
      // height: 64px;
      padding: 21px 20px;
      border-radius: 20px;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;

      @include getData(background, navB2BNormalBg, !important);
      @include getData(color, navB2BNormalColor, !important);

      &.navBar_disable {
        @include getData(color, btnDisable);
        cursor: default;
        background-color: white;
      }

      &:hover {
        @include getData(background, navB2BHoverBg, !important);
        @include getData(color, navB2BHoverColor, !important);
      }

      &.a-n-buyeGiftcards-scroll {
        @include getData(background, navB2BActiveBg, !important);
        @include getData(color, navB2BActiveColor, !important);
      }
    }

    #navBar_rightSection {
      margin: 0 40px 0 0;
      .dropdown {
        a {
          @include btnNone;
          &:after {
            display: none;
          }
        }
        .dropdown-menu {
          // padding: 10px 29px;
          padding: 10px 18px 18px 18px;
          border-radius: 10px;
        }
      }
      a {
        padding: 0;
        img {
          width: 24px;
          height: 24px;
        }
      }
      .navBar_popUp {
        top: 50px;
      }
      #navBar_queryCard {
        a {
          height: 64px;
          padding: 21px 20px;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          @include getData(color, btn, !important);
          cursor: pointer;
          @include btnUnderlined;
        }
      }
      #navBar_map {
        margin: 0 0 0 32px;
        font-size: 12px;

        .nav-link {
          flex-direction: column;
        }
        .nav-link img,
        .nav-link span {
          display: flex;
        }
        .nav-link span {
          font-size: 12px;
        }
        .nav-link .narBar-text {
          @include getData(color, gray2);
        }
        .nav-link .narBar-text-hover {
          @include getData(color, color2);
        }
      }
      #navBar_cart {
        .nav-link {
          padding: 0;
          flex-direction: column;
        }
        .nav-link span {
          display: flex;
        }
        .nav-link span:nth-child(2) {
          font-size: 12px;
        }
        .nav-link .narBar-text {
          @include getData(color, gray2);
        }
        .nav-link .narBar-text-hover {
          @include getData(color, color2);
        }
        margin: 0 0 0 32px;
        font-size: 12px;

        #navBar_popUp_cart {
          background-color: transparent;

          border: none;

          position: relative;
          &:focus,
          &:active {
            box-shadow: none;
          }
          mark {
            width: 16px;
            height: 16px;

            @include getData(background-color, btn);
            border-radius: 50%;

            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, light);

            position: absolute;
            top: -6px;
            right: 0;

            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        #navBar_popUp_cart_dropdownMenu_none {
          width: 280px;
          padding: 10px 29px;
          right: initial !important;
          transform: none !important;
          opacity: 0;
          // left: -119.385px !important;
          // top: 64px !important;
          -webkit-animation: cart-dropdown-menu-none 0.3s forwards !important;
          -moz-animation: cart-dropdown-menu-none 0.3s forwards !important;
          //  -o-animation: cart-dropdown-menu-none .3s forwards !important;
          animation: cart-dropdown-menu-none 0.3s forwards !important;

          @-webkit-keyframes cart-dropdown-menu-none {
            from {
              inset: 64px auto auto auto;
            }

            to {
              opacity: 1;
              inset: 74px auto auto auto;
            }
          }
          @-moz-keyframes cart-dropdown-menu-none {
            from {
              inset: 64px auto auto auto;
            }

            to {
              opacity: 1;
              inset: 74px auto auto auto;
            }
          }
          // @-o-keyframes cart-dropdown-menu-none {
          //     from {
          //         inset: 64px auto auto auto;
          //     }

          //     to {
          //         opacity: 1;
          //         inset: 74px auto auto auto;
          //     }
          // }
          @keyframes cart-dropdown-menu-none {
            from {
              inset: 64px auto auto auto;
            }

            to {
              opacity: 1;
              inset: 74px auto auto auto;
            }
          }

          h4 {
            margin-bottom: 0;
            font-weight: 500;
            font-size: 18px;
            line-height: 25px;
          }
          p {
            margin: 12px auto;
            font-weight: normal;
            font-size: 16px;
            line-height: 140.62%;
            @include getData(color, gray2);
            text-align: center;
          }
          a {
            &:first-of-type {
              min-width: 140px;
              width: fit-content;
              height: 40px;
              padding: 10px;
              text-align: center;

              @include getData(background-color, btn);
              border-radius: 4px;
              @include btnFilled;

              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, light);
            }

            &.btn-faq {
              width: max-content;
              height: max-content;
              font-weight: 500;
              font-size: 16px;
              line-height: 140.62%;
              @include btnTextWithIcon;
              img {
                width: 24px;
                height: 24px;
                margin: 0 8px 0 0;
              }
            }
          }
          hr {
            width: 100%;
            height: 1px;
            margin: 12px 0;

            @include getData(background-color, gray4);
          }
        }
        #navBar_popUp_cart_dropdownMenu_none.dropdown-menu.show {
          display: flex;
        }
        #navBar_popUp_cart_dropdownMenu {
          // width: max-content;
          // min-width: 413px;
          // max-width: 600px;
          // height: max-content;
          // max-height: 502px;
          // padding: 20px;
          /*---以上為原本的版本---*/
          width: max-content;
          min-width: 413px;
          max-width: 600px;
          height: max-content;
          max-height: 502px;
          right: initial !important;
          transform: none !important;
          opacity: 0;
          -webkit-animation: cart-dropdown-menu 0.3s forwards !important;
          -moz-animation: cart-dropdown-menu 0.3s forwards !important;
          //  -o-animation: cart-dropdown-menu .3s forwards !important;
          animation: cart-dropdown-menu 0.3s forwards !important;

          @-webkit-keyframes cart-dropdown-menu {
            from {
              inset: 64px auto auto auto;
            }

            to {
              opacity: 1;
              inset: 74px auto auto auto;
            }
          }
          @-moz-keyframes cart-dropdown-menu {
            from {
              inset: 64px auto auto auto;
            }

            to {
              opacity: 1;
              inset: 74px auto auto auto;
            }
          }
          // @-o-keyframes cart-dropdown-menu {
          //     from {
          //         inset: 64px auto auto auto;
          //     }

          //     to {
          //         opacity: 1;
          //         inset: 74px auto auto auto;
          //     }
          // }
          @keyframes cart-dropdown-menu {
            from {
              inset: 64px auto auto auto;
            }

            to {
              opacity: 1;
              inset: 74px auto auto auto;
            }
          }

          main {
            width: 100%;
            height: max-content;
            max-height: 339px;
            // padding: 10px 0 10px 10px;
            padding: 1px 1px 8px 1px;

            overflow-y: auto;
            overflow-x: hidden;
            /* width */
            &::-webkit-scrollbar {
              width: 30px;
              // width: 12px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
              background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
              background: rgba(0, 0, 0, 0.05);
              border-radius: 2px;
              border: 8px solid transparent;
              border-left: 10px solid transparent;
              // border: 1px solid transparent;
              background-clip: padding-box;
            }
            section {
              width: 100%;
              height: max-content;
              // margin: 0 2px 20px 0;
              margin: 1px;
              // padding: 21.5px 20px;
              padding: 21.5px;

              @include getData(background-color, light);
              @include getData(box-shadow, pagesShadow, !important);
              border-radius: 4px;
              // &:last-of-type {
              //     // margin: 0;
              //     // margin: 0 2px 0 0;
              // }
              &:nth-child(1) {
                margin-top: 8px;
              }
              &:not(:first-child) {
                margin-top: 20px;
              }
              .cart-detail {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-start;
              }
              .cart-detail span {
                width: 44px;
                height: 44px;
                margin-right: 12px;

                @include getData(background-color, bg);
                border-radius: 10px;

                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, color2);
                text-align: center;
              }
              .cart-detail img {
                width: 50px;
                height: 40.9px;
                margin-right: 12px;
              }
              .cart-detail div {
                width: max-content;
                // max-width: 234px;
                max-width: 273px;
                // max-width: 310px;
                margin-right: 24px;
                h6 {
                  &:first-of-type {
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 16px;
                    @include getData(color, gray1);
                  }
                  &:nth-of-type(2) {
                    margin: 2px 0 !important;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    @include getData(color, gray1);
                  }
                  &:last-of-type {
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    @include getData(color, gray3, !important);
                  }
                }
              }
              .cart-price {
                width: 100%;
                max-width: 112px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                h6 {
                  // width: max-content;
                  // max-width: 112px;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 16px;
                  @include getData(color, gray1);
                  word-break: break-all;
                }
              }
            }
          }
          .cart-list {
            width: 100%;
          }
          .cart-total-price {
            padding: 0 22px 0 22px;
          }
          h5 {
            margin: 22px 0 20px 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 140.62%;
            @include getData(color, color2);
          }
          hr {
            width: 100%;
            height: 1px;
            margin: 20px 0;

            @include getData(background-color, gray5);
          }
          a {
            width: 100%;
            height: 40px;
            padding: 10px 10px 10px 15px;

            @include btnFilled;
          }
        }
        #navBar_popUp_cart_dropdownMenu.dropdown-menu.show {
          display: flex;
        }
      }
      #navBar_cart .show.dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      #navBar_FAQs {
        margin: 0 0 0 32px;
        font-size: 12px;

        .nav-link {
          flex-direction: column;
        }
        .nav-link img,
        .nav-link span {
          display: flex;
        }
        .nav-link span {
          font-size: 12px;
        }
        .nav-link .narBar-text {
          @include getData(color, gray2);
        }
        .nav-link .narBar-text-hover {
          @include getData(color, color2);
        }
      }
      #navBar_wallet {
        .nav-link {
          padding: 0;
          flex-direction: column;
        }
        .nav-link span {
          display: flex;
        }
        .nav-link span:nth-child(2) {
          font-size: 12px;
        }
        .nav-link .narBar-text {
          @include getData(color, gray2);
        }
        .nav-link .narBar-text-hover {
          @include getData(color, color2);
        }
        margin: 0 0 0 32px;
        font-size: 12px;

        position: relative;
        #navBar_popUp_wallet {
          background-color: transparent;
          border: none;
          &:focus,
          &:active {
            box-shadow: none;
          }
        }
        #navBar_popUp_wallet_dropdownMenu {
          width: 280px;
          height: 248px;
          padding: 20px 36.5px;
          opacity: 0;
          right: initial !important;
          transform: none !important;
          // left: -114.84px !important;
          -webkit-animation: wallet-dropdown-menu 0.3s forwards !important;
          -moz-animation: wallet-dropdown-menu 0.3s forwards !important;
          //  -o-animation: wallet-dropdown-menu .3s forwards !important;
          animation: wallet-dropdown-menu 0.3s forwards !important;

          @-webkit-keyframes wallet-dropdown-menu {
            from {
              top: 64px;
            }

            to {
              opacity: 1;
              top: 74px;
            }
          }
          @-moz-keyframes wallet-dropdown-menu {
            from {
              top: 64px;
            }

            to {
              opacity: 1;
              top: 74px;
            }
          }
          // @-o-keyframes wallet-dropdown-menu {
          //     from {
          //         margin-top: 20px;
          //     }

          //     to {
          //         opacity: 1;
          //         margin-top: 30px;
          //     }
          // }
          @keyframes wallet-dropdown-menu {
            from {
              inset: 64px auto auto auto;
            }

            to {
              opacity: 1;
              inset: 74px auto auto auto;
            }
          }
          section {
            width: 100%;
            height: 84px;
            padding: 20px;

            @include getData(background-color, light);
            @include getData(box-shadow, pagesShadow, !important);
            border-radius: 4px;
            h6 {
              margin: 0 0 4px 0 !important;

              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray4);
            }
            h4 {
              font-weight: 500;
              font-size: 14px;
              line-height: 16px;
              @include getData(color, color2);
            }
          }
          button {
            width: 100%;
            height: 40px;
            margin: 20px 0;
            padding: 10px 10px 10px 15px;

            @include getData(background-color, btn);
            @include getData(box-shadow, btns1Shadow);
            border-radius: 4px;
            @include btnFilled;

            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, light);
            img {
              width: 24px;
              height: 24px;
            }
          }
          hr {
            width: 100%;
            height: 1px;
            @include getData(background-color, gray5);
          }

          a {
            width: max-content;
            margin: auto;
            font-weight: 500;
            font-size: 16px;
            @include getData(color, gray2);
            @include btnText;
            img {
              width: 24px;
              height: 24px;
              margin: 0 8px 0 0;
            }
          }
        }
      }

      #navBar_wallet .show.dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      #navBar_greeting {
        @include getData(border-left, gray4, null, 2px solid);
        height: 36px;
        margin-left: 40px;
        padding-left: 40px;
        display: flex;
        justify-content: start;
        align-items: center;

        a {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          width: 100%;
          min-width: 150px;
          text-align: center;
          @include getData(color, gray1);
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          font-weight: bold;
          &:hover {
            @include getData(color, color2);
          }

          span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            display: inline-block;
            width: 200px;
          }
        }
      }

      #navBar_login {
        #navBar_popUp_login {
          width: 140px;
          height: 40px;
          margin: 0 0 0 40px;

          @include btnPrimary;
          @include btnFilled;

          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
        }
        #navBar_popUp_login_dropdownMenu {
          width: 188px;
          // height: 178px;
          padding: 20px;

          left: 8%;
          li {
            width: 100%;
            height: 27px;
            padding: 4px 8px;
            margin: 0 0 10px 0;

            @include getData(background-color, light);
            @include getData(box-shadow, btns1Shadow);
            border-radius: 4px;

            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, gray1);
            text-align: center;
          }
        }
      }
      .a-n-nav-name {
        cursor: pointer;
      }
      .a-n-nav-logout {
        margin-left: 12px;
      }
    }
    .a-n-nav-mobile {
      @include getData(padding, a-n-nav-mobile-padding, !important);
      @include getData(right, a-n-nav-mobile-right, !important);
      @include getData(position, a-n-nav-mobile-position, !important);
      @include getData(margin-right, a-n-nav-mobile-margin-right, !important);
      min-width: 64px;
      gap: 16px;
      @media screen and (min-width: 992px) and (max-width: 1199px) {
        gap: 32px;
      }
      height: 24px;
      a {
        @include btnNone;
        position: relative;
        padding: 0;
        margin: 0;
        &:first-of-type {
          @media screen and (min-width: 992px) and (max-width: 1199px) {
            // margin-right: 32px;
          }
        }

        img {
          @include getData(margin-left, a-n-nav-mobile-img-margin-left, !important);
        }
      }

      .align-items-center {
        height: 100%;
      }
    }
  }
  #navInfoBar {
    width: 100%;
    height: 40px;

    @include getData(background-color, gray1);

    position: sticky;
    z-index: 5;
    #navInfo_socialMediaSection {
      padding: 10px 0;
      margin: 0 0 0 40px;
      img {
        width: 20px;
        height: 20px;
        margin: 0 20px 0 0;
      }
    }
    #navInfo_contactSection {
      margin: 0 40px 0 0;
      a {
        margin: 0 0 0 6px;

        @include getData(color, light);
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
      }

      #navInfo_telephone,
      #navInfo_email {
        margin: 0 20px 0 0;

        @include btnUnderlined;
        img {
          width: 16px;
          height: 16px;
        }
      }
      #navInfoBar_popUp_language {
        h5 {
          margin-left: 6px;

          @include getData(color, gray1);
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          white-space: nowrap;
        }
        #navInfo_language {
          width: 56.8px;
          height: 23px;
          padding: 4px 8px;

          @include getData(background-color, light);
          @include getData(box-shadow, btns1Shadow);
          border-radius: 4px;

          border: none;
          img {
            width: 18.8px;
            height: 14px;
          }
          &:hover,
          &:focus,
          &:active {
            @include getData(background-color, color2);
          }
        }
        #navInfoBar_popUp_language_dropdownMenu {
          width: 96px;
          min-width: 96px;
          height: auto;
          padding: 20px;

          top: 50% !important;
          left: 35% !important;
          li {
            width: 55.8px;
            height: 23px;
            margin: 0;
            padding: 4px 8px;

            @include getData(background-color, light);
            @include getData(box-shadow, btns1Shadow);
            border-radius: 4px;
            margin-bottom: 10px;
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

#menuModal {
  background-color: rgba(255, 255, 255, 0.8);
  .modal-content {
    width: 180px !important;
    padding: 20px;

    border-radius: 10px;

    position: fixed;
    top: 98px;
    right: 25px;
    &::before {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8.66px 12px 8.66px;
      @include getData(border-color, color2, transparent, transparent transparent);
      position: absolute;
      top: -12px;
      right: 16px;
    }
    p {
      &.menu-text {
        margin-bottom: 8px !important;
      }
    }
    a {
      @include btnNone;
      margin-bottom: 12px;
      padding: 0;

      @include getData(color, gray2);
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;

      &.menu-btn {
        padding-top: 11px;
        padding-bottom: 10px;

        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.4);
        // @include getData(background-color, btn, !important);
        border-radius: 4px;

        text-align: center;
        // @include getData(color, light);
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
      &.menu-btn-last {
        margin-bottom: 0 !important;
      }
      &.menu-btn-2 {
        margin-bottom: 0;
        padding-top: 11px;
        padding-bottom: 10px;
        border-radius: 4px;
        @include getData(background-color, light, !important);

        @include getData(border, btn, null, 1px solid);
        box-sizing: border-box;

        @include getData(color, btn);
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
      &:nth-of-type(7),
      &:last-of-type {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        img {
          margin-right: 8px;
        }
      }
      &:last-of-type {
        margin-bottom: 0;

        text-align: start;
      }
    }
    hr {
      width: 100%;
      height: 1px;
      margin: 12px 0;
      @include getData(background, gray5);
    }
  }
}

#cartModal {
  background-color: rgba(255, 255, 255, 0.8);
  .modal-content {
    width: 280px;
    padding: 10px 29px;

    border-radius: 10px !important;
    h4 {
      margin-bottom: 0;

      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
    }
    p {
      margin: 12px auto;

      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }
    a {
      width: 140px;
      height: 40px;
      padding: 10px 38px;

      @include getData(background-color, btn, !important);
      border-radius: 4px;

      @include getData(color, light, !important);
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
    hr {
      width: 100%;
      height: 1px;

      @include getData(background-color, gray4);
    }
    button {
      height: 24px;
      padding: 0;

      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      @include btnNone;
      @include btnTextWithIcon;
      img {
        margin-right: 8px;
      }
    }
  }
}

#helpModal {
  background-color: rgba(255, 255, 255, 0.8);
  .modal-content {
    width: 280px;
    padding: 10px 21.5px;

    border-radius: 10px;
    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      img {
        margin-right: 8px;
      }
    }
    p {
      margin-top: 12px;
      margin-bottom: 0;

      font-size: 16px;
      line-height: 22px;
      text-align: center;
    }
    hr {
      margin: 12px auto;
      width: 100%;
      height: 1px;

      @include getData(background-color, gray4);
    }
    a,
    strong {
      @include getData(color, btn);
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
    }
    a {
      height: 23px;
      padding: 0;
    }
    h5 {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.a-n-scrollup {
  transform: translateY(-104px);
  transition: 0.3s ease-in-out;
}

.a-n-scrolldown {
  transform: translateY(0);
  transition: 0.3s ease-in-out;
}

.m-n-scrollup {
  transform: translateY(-64px);
  transition: 0.2s ease-in-out;
  height: 0;
}

.m-n-scrolldown {
  transform: translateY(0);
}

.a-n-infoBar-scrollup {
  transform: translateY(-40px);
  transition: 0.3s ease-in-out;
}

.a-n-buyeGiftcards-scroll {
  background-color: rgba(193, 163, 97, 0.05) !important;
  @include getData(color, navBtn, !important);
}

.a-n-query-card {
  @include getData(background-color, lightBg, !important);
  @include getData(color, btn, !important);
  @include getData(border-bottom, btn, null, 2px solid);
}

#navBar_leftSection {
  @media screen and (max-width: 320px) {
    width: 70%;
  }
  a {
    img {
      width: 100%;
    }
  }
}

.custom-border-bottom-container {
  position: relative;
}
.custom-border-bottom {
  height: 4px;
  width: 100%;

  position: absolute;
  bottom: -21px;

  margin: auto;

  border-radius: 100px;

  @include getData(background, navBtnColor);
}

#navBar_leftSection {
    @media screen and (max-width: 320px) {
        width: 70%;
    }
    a {
        img {
            width: 100%;
        }
    }
}

.custom-border-bottom-container {
    position: relative;
}
.custom-border-bottom {
    height: 4px;
    width: 100%;

    position: absolute;
    bottom: -21px;

    margin: auto;
    
    border-radius: 100px;
    
    background: $navBtnColor;
}

.MAF-nav-query {
  display: flex;
  align-items: center;

  a {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #0E5AA7 !important;
  }

  @media screen and (min-width: 992px) {
    border: 1.5px solid #0E5AA7;
    border-radius: 12px;
    padding: 4px 12px;
  }
}
.MAF-navInfoBar_popUp_language {
  display: flex;
  justify-content: center;
}