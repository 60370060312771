.a-a-overview {
  width: 100%;
  padding: 149px 60px 45px 60px;

  @include getData(background-color, light);
  @include getData(box-shadow, btns1Shadow);

  z-index: 1;
  aside {
    overflow: hidden;
    h2 {
      margin: 0 0 10px 0 !important;

      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 37px;
      @include getData(color, gray1);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, gray3);
    }
  }
  section {
    div {
      max-width: 752px;

      overflow-x: auto;
      overflow-y: hidden;

      @include hideScrollbar;
      .a-a-msg {
        width: max-content;
        min-width: 376px;
        height: 110px;
        padding: 20px;
        margin: 0 0 0 16px;

        @include getData(background-color, bg);
        border-radius: 4px;
        &:first-of-type {
          margin: 0;
        }
        h5 {
          margin: 0 0 10px 0 !important;

          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, color2);
          img {
            width: 20px;
            height: 20px;
            margin: 0 6px 0 0;
          }
        }
        h6 {
          margin: 0 0 6px 0 !important;

          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          @include getData(color, gray1);
          &:last-of-type {
            margin: 0;
          }
        }
        i {
          margin: 6px 0 0 0 !important;

          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, gray1);
          text-align: end;

          align-self: flex-end;
        }
      }
      @media screen and (min-width: 992px) and (max-width: 1399px) {
        max-width: 376px;
      }
    }
    .a-scroll-btns {
      button {
        &:first-of-type {
          left: -41.5px !important;
        }
        &:last-of-type {
          right: -41.5px !important;
        }
      }
    }
  }
}
