.a-quotation-popup {
  &__info {
    @include getData(color, gray2);
    line-height: 18.75px;
  }
  &__info-email {
    margin-top: 6px;
    font-weight: bold;
  }
  &__info--disabled {
    opacity: 0.4;
  }

  &__input {
    height: 44px;
  }

  &__or {
    position: relative;
    text-align: center;
    > label {
      background-color: #ffffff;
      @include getData(color, gray4);
      padding: 16px;
      position: relative;
    }
    &:before {
      content: '';
      border-style: solid;
      border-width: 0 0 1px 0;
      position: absolute;
      left: 0;
      top: 50%;
      width: 100%;
      @include getData(border-color, gray4);
    }
  }
}

.a-bf-content-card {
  margin-bottom: 15px;
  padding: 20px;
  @include getData(background, light);
  @include getData(box-shadow, pagesShadow, !important);
  border-radius: 4px;
  &__title-block {
    margin-bottom: 20px;
  }
  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, gray2);
  }
  &__desc {
    margin-top: 6px;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, gray3);
  }
}
