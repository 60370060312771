$Egypt: (
  light: #fff,

  linkDefault: #0e5aa7,
  linkHover: #0f6dcc,
  linkPressed: #0e4dbc,

  btn: #0e5aa7,
  btnHover: #1577da,
  btnPressed: #0d4c8c,
  btnGradient: linear-gradient(258.67deg, #ff6d62 19.09%, #f4283a 78.58%),

  link: #0e5aa7,

  // filter refrs: https://codepen.io/sosuke/pen/Pjoqqp
  // expected: #FF6D62,
  filterVariableHover: invert(67%) sepia(50%) saturate(5733%) hue-rotate(326deg) brightness(110%) contrast(100%),
  // expected: #b70c00,
  filterVariablePressed: invert(9%) sepia(83%) saturate(7498%) hue-rotate(16deg) brightness(87%) contrast(105%),

  navB2CNormalBg: #c1a3610d,
  navB2CNormalColor: #c1a361,
  navB2CHoverBg: #c1a36133,
  navB2CHoverColor: #c1a361,
  navB2CActiveBg: #c1a361,
  navB2CActiveColor: #ffffff,

  navB2BNormalBg: linear-gradient(89.57deg, rgba(79, 57, 142, 0.05) 5.43%, rgba(63, 80, 150, 0.05) 105.37%),
  navB2BNormalColor: #4f398e,
  navB2BHoverBg: linear-gradient(89.57deg, rgba(84, 126, 190, 0.2) 5.43%, rgba(61, 151, 202, 0.2) 105.37%),
  navB2BHoverColor: #4f398e,
  navB2BActiveBg: linear-gradient(89.57deg, #4f398e 5.43%, #3f5096 105.37%),
  navB2BActiveColor: #ffffff,

  navBtnColor: #ffffff,

  navBtn: #c1a361,
  color2: #093A6C,
  discountFontColor: red,
  formControlError: #eb5757,
  btnDisable: #4c4c4c,
  ekdrFeatureBannerBg: none,
  FeatureBannerBg: linear-gradient(180deg, rgba(255, 109, 98, 0.7), rgba(244, 40, 58, 0.7)),
  FeatureBanner2Bg: none,
  FeatureBanner3Bg: none,
  FeatureBanner4Bg: none,
  FeatureBannerH1FontSize: 44px,
  FeatureBanner1PFontSize: 36px,
  orderNewBorder: linear-gradient(180deg, rgba(255, 109, 98, 0.7), rgba(244, 40, 58, 0.7)),
  orderNewColor: linear-gradient(180deg, rgba(255, 109, 98, 0.7), rgba(244, 40, 58, 0.7)),
  templateMoreDropdownMaxHeight: 137px,
  accountProductCardWidth: 191px,

  bg: #f5f5f5,
  dark: #282828,
  blue2: #00aaff,
  blue3: #56ccf2,
  black: #000,
  lightBg: #f2f9ff,

  gray1: #333333,
  gray2: #4f4f4f,
  gray3: #828282,
  gray4: #bdbdbd,
  gray5: #d9d9d9,
  gray6: #f2f2f2,
  gray7: #4c4c4c,
  grey: #19181a,

  navTab: #fefefe,

  btns1Shadow: 0px 2px 14px rgba(0, 0, 0, 0.2),
  pagesShadow: 0px 0px 4px rgba(0, 0, 0, 0.25),
  popupShadow: 0px 0px 10px rgba(0, 0, 0, 0.25),
  mobileBottomNavShadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2),

  mainLinear: linear-gradient(180deg, #0e5aa7 0%, #0e5aa7 100%),
  mainActionLinear: linear-gradient(180deg, #0e5aa7, #0e5aa7),

  facebookColor: #1877f2,

  // sprint 3
  backendData: url('https://picsum.photos/210/132'),
  historyConfirmed: #41bb81,
  historyRequesting: #f2994a,
  historyPending: #f2994a,
  historyCancelled: #d6180b,

  // sprint 4
  b2b: #ff937b,
  whiteBlankChoose: 0px 0px 8px rgba(0, 0, 0, 0.25),

  b2bRegisterIconActive: #6fcf97,

  footerDisplay: flex,

  a-ts-media_height: 123px,
  debitBtn_width: 25px,
  debitBtn_height: 16px,
  //import assets
  chooseOnePressed: url('/img/maf/account/icon/chooseOne_pressed.svg'),
  arrowRightPressed: url('/img/maf/account/icon/arrow_right_pressed.svg'),
  documentPressed: url('/img/maf/buying_flow_1_3/icon/step03/sendby/doc/pressed.svg'),
  topup: #0E5AA7,
  buyingStep4AddActive: url('/img/maf/buying_flow_1_3/icon/step04/quantity/add/active.svg'),
  buyingStep4MinusActive: url('/img/maf/buying_flow_1_3/icon/step04/quantity/minus/active.svg'),
  mobileRadioBg: url('/img/maf/B2C guest Step 1_3/icon/4/mobile_radio_background.svg'),
  a-n-nav-mobile-padding: 0,
  a-n-nav-mobile-right: 0,
  a-n-nav-mobile-position: absolute,
  a-n-nav-mobile-margin-right: 10px,
  a-n-nav-mobile-img-margin-left: 0px,
  root-background-color: #f5f5f5,
  a-m-banner-without-slider-img-width: 85%,
  a-m-banner-without-slider-img-border-radius: 10px,
  a-m-slogan-background-color: #f4f4f4,
  a-m-slogan-height: none,
  a-m-slogan-margin: 24px 0 54px 0,
  cart_mark_mobile-left: none,
  a-card-detail-box-shadow: none,
  a-card-detail-border: 1px solid #C3C3C3,
  a-card-detail-border-radius: 10px,
  a-card-history-confirmedborder-top: none,
  a-card-history-border: 1px solid #979797,
  a-card-history-border-radius: 8px,
  a-m-feature-desktop-margin-top: 88px,
  m-HomePage-background-color: #fff,
  bu-font-family: ("Montserrat", sans-serif),
  textColor: #141414,
  a-gs-success-width: auto,
  a-gs-success-height: auto,
  btnMobileSecondary-color: $btn,
  btnMobileSecondary-hover-border-color: $btn,
  a-m-slogan-content-width-l: 1032px,
  a-m-slogan-content-width-xl: 1298px,
  a-m-filter-category-margin-left: 5px,
  a-m-filter-category-text-align: start,
  a-m-filter-category-margin: 0 16px,
  homeProductModal__side-card-height: auto,
  product-detail-container-height: auto,
  product-detail-container-margin-top: none,
  normal-font-wieght: 700,
  card-header-line-height: 19.5px,
  common-notification-width: 90%,
  common-notification-justify-content: start,
  common-notification-border-radius: 4px,
  common-notification-background-color: #267949,
  common-notification-title-margin-bottom: none,
  a-bf-mobile-title-font-size: 14px,
  side-card-header-font-size: 20px,
  side-card-description-font-size: 12px,
  side-card-detail-h4-font-size: 16px,
  side-card-detail-h4-font-weight: 700,
  Card_range_of_amount-font-size: 16px,
  Card_range_of_amount-font-weight: 700,
  detail-content-font-size: 14px,
  detail-content-color: #000000,
  a-gs-success-bg: #fff,
  a-gs-reminder-background-color: #F1FAFE,
  a-gs-success-h2-color: #000,
  a-gs-success-h3-color: #000,
  a-gs-main-padding: 0,
  a-gs-main-height: 100vh,
  a-gs-success-img-padding: 16px 0,
  a-gs-success-h2-font-size: 16px,
  a-gs-success-img-margin-bottom: 0,
  a-gs-btn-container-btn-width: 343px,
  a-gs-btn-container-btn-border-radius: 12px,
  placeholder-text-align: start,
  a-card-info-max-width: none,
  a-card-info-height: auto,
  a-card-info-grid-column: 1 / span 2,
  description-box-h4-font-size: 14px,
  customValue-p-justify-content: center,
  a-gs-btn-container-button-border-radius: 12px,
  a-gs-btn-container-button-font-size: 14px,
  a-card-select-h5-font-weight: 700,
  a-card-select-h5-font-size: 14px,
  a-card-select-h6-color: #000,
);