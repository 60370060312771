.a-custom-phone {
  .MuiSelect-root {
    border: none !important;
  }
  .a-bf-form-phone-input {
    input {
      margin: 0 0 0 16px;
      border: none !important;
    }
  }

  .a-custom-phone-verify {
    height: 24px;
    padding: 5px 10px;
    font-size: 12px;
    color: white;

    @include getData(background-color, color2);
    border-radius: 4px;
    position: absolute !important;
    right: 10px;
    top: 10px;
    user-select: 'none';
    cursor: pointer;
    &.a-custom-phone-disable {
      @include getData(background-color, btnDisable);
      cursor: default;
    }
  }

  .a-custom-phone-verify-success {
    height: 24px;
    padding-right: 10px;
    font-size: 12px;
    color: white;
    @include getData(background-color, historyConfirmed);
    border-radius: 4px;
    position: absolute !important;
    right: 10px;
    top: 10px;
    user-select: 'none';

    img {
      width: 24px;
      height: 24px;
    }
  }

  .a-page-btn-disabled {
    cursor: default;
  }

  .modal-warn {
    position: fixed !important;
    .modal-content {
      width: 317px;
      min-height: 347px;
      .modal-close {
        position: absolute !important;
        width: 32px !important;
        height: 32px !important;
      }
      .btn {
        height: 42.01px;
        color: white;
        @include getData(background-color, btn);
        border-radius: 4px;
        justify-content: center;

        display: flex;
        align-items: center;
        gap: 8px;

        @include media(mobile, tablet) {
          @include btnMobilePrimary;
        }
      }
      .disable {
        @include getData(background-color, btnDisable);
        cursor: default;
      }
      h5 {
        font-size: 14px;
        margin: 12px 0 0 0 !important;
      }
      div {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 16px !important;
        gap: 8px;
      }
      input {
        width: 64px;
        height: 44px;
        @include getData(caret-color, gray4, !important);
      }
      input:focus {
        @include getData(color, gray4);
        outline: none;
        @include getData(border-color, gray4, !important);

        &.errors {
          @include getData(border-color, formControlError, !important);
        }
      }
      i {
        margin-top: 12px !important;
        color: black !important;

        @include media(mobile, tablet) {
          font-size: 14px !important;
          text-align: center;
        }
      }
      span {
        @include getData(color, formControlError, !important);
      }
      hr {
        width: 100%;
        @include getData(background-color, gray5);
      }
      button {
        position: initial !important;
        color: black !important;

        &:last-of-type {
          width: auto;
          background-color: transparent;
          border: none;
          font-weight: 600 !important;
        }
      }
    }
  }
}
