.a-a-import {
  padding: 20px;

  @include getData(background-color, light);
  @include getData(box-shadow, pagesShadow, !important);
  border-radius: 4px;
  button {
    width: 100%;
    height: 40px;
    padding: 11.5px 0;
    margin: 20px 0 0 0;

    @include btnFilled;
    @include getData(background-color, btn);
    border-radius: 4px;

    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    @include getData(color, light);
    &:hover,
    &:focus,
    &:active {
      @include getData(color, light);
    }
  }

  .a-form-control input {
    height: 44px;
  }
  .a-form-control button.disabled {
    cursor: default;
    background-color: rgba(76, 76, 76, 0.2) !important;
    color: white;
  }
}
