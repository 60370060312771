.m-OrderSuccess.a-gs-main-mobile {
  .a-gs-reminder {
    @include getData(background-color, a-gs-reminder-background-color);

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 16px;

    max-width: 736px;

    padding: 24px;

    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    @include tablet {
      row-gap: 20px;
    }

    @include mobile_lg {
      column-gap: 40px;
    }
    @include media(mobile_lg, tablet) {
      grid-template-columns: repeat(2, 1fr);
    }

    img {
      width: auto;
      height: 278px;

      grid-row: auto / span 2;

      margin-top: auto;
      margin-bottom: auto;
      border-radius: 10px;
      box-shadow: 0px 2px 14px 1px #00000033;

      display: none;

      @include tablet {
        grid-row: auto / span 1;
      }

      @include media(mobile_lg, tablet) {
        display: block;
      }
    }

    aside {
      gap: 16px;

      margin: auto;
      font-size: 14px;
      font-weight: 400;

      h3 {
        font-size: 16px;
        font-weight: 700;
        @include getData(color, a-gs-reminder-h3-color);
      }

      ul {
        display: flex;
        flex-direction: column;
        gap: 16px;

        margin-bottom: 0;

        li {
          font-size: 14px;
        }
      }
    }

    .a-gs-btn-container {
      @include wh100;

      flex-direction: column;
      gap: 16px;

      @include tablet {
        flex-direction: row;
        grid-column: auto / span 2;
      }
      button {
        &:first-of-type {
          @include btnMobileSecondary;
          font-size: 16px;
          width: 100%;
          height: 40px;
        }

        &:last-of-type {
          @include btnMobilePrimary;
          @include getData(border-radius, a-gs-btn-container-button-border-radius);
          @include getData(font-size, a-gs-btn-container-button-font-size);
          
          font-size: 16px;
          width: 100%;
          height: 40px;
        }
      }
    }
  }

  // preserve previous style
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    @include getData(color, black);
  }
  article {
    margin: 20px 0 40px 0;
    padding: 20px;
    ul {
      margin: 0 16px 0 0;
      &:last-of-type {
        margin: 0;
      }
      li {
        margin: 0 0 20px 0;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
}
