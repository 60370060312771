.a-register-info {
  margin: 20px 0 0 0;
  .a-form-control {
    margin: 0 0 12px 0;
    input {
      height: 44px;
    }
    .form-control-disable {
      opacity: 0.7;
    }
    &:first-of-type {
      margin: 20px 0 12px 0;
    }
    .a-bf-form-phone-input {
      width: 80% !important;
    }

    .search-icon {
      margin-right: 8px;
      &.active {
        path {
          @include getData(fill, b2bRegisterIconActive);
          fill-opacity: 1;
        }
      }
    }
    div {
      position: relative;
      button {
        width: 24px;
        height: 24px;
        padding: 0 !important;

        background-color: transparent;
        border: none;

        position: absolute;
        top: 11px;
        right: 11.5px;
        img {
          width: 24px;
          height: 24px;
        }
        &:focus,
        &:active {
          box-shadow: none;
        }
      }
    }
  }
}
.a-register-check {
  padding: 20px 0 0 0;
  div {
    margin: 16px 0 0 0;
    padding: 0;
    ul {
      margin: 0 79px 0 0;
      padding: 0;

      li {
        margin: 0 0 12px 0;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray4);

        display: flex;
        align-items: center;
        span {
          width: 24px;
          height: 24px;
          margin: 0 12px 0 0;

          @include bgImage(url('/img/B2B_loginAndRegister/icon/psw/disable.svg'));

          display: flex;
        }
        &:last-of-type {
          margin: 0;
        }
      }
      &:last-of-type {
        margin: 0;
      }
    }
  }
}

.a-register-check-active {
  @include getData(color, historyConfirmed, !important);
  span {
    background-image: url('/img/B2B_loginAndRegister/icon/psw/active.svg') !important;
  }
}
