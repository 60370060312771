.a-bf-checkout {
  margin-top: 30px;
  height: 100%;
  .guestContent {
    height: 100%;
  }
  section {
    &:first-of-type {
      padding: 10px 0 10px 10px;
      img {
        width: 30px;
        max-height: 30px;
        margin-right: 10px;
      }
      h4 {
        @include getData(color, gray4);
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
      }
    }
    &:last-of-type {
      .a-bf-content {
        width: 752px;
        padding-top: 16px;
        padding-bottom: 16px;
        :lang(fr) & {
          width: 100%;
        }

        .a-b2b-bf-discount {
          margin: 0 0 32px 0;
          h4 {
            margin: 0 0 15px 0 !important;

            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, gray2);
          }
          .a-b2b-bf-discount-options {
            width: 398px;
            padding: 10px;

            border-radius: 4px;
            h6 {
              margin: 0 0 2px 0 !important;

              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray3);
              &:last-of-type {
                margin: 0 !important;
              }
            }
          }
          .a-b2b-bf-discount-btns {
            margin: 24px 0 0 0;
            display: flex;
            align-items: center;
            button {
              width: max-content;
              height: 40px;
              margin: 0 16px 0 0;

              @include getData(background-color, light);
              @include getData(box-shadow, whiteBlankChoose);

              display: flex;
              align-items: center;
              justify-content: center;
              &:last-of-type {
                margin: 0;
              }
            }
          }
          i {
            width: 691px;
            height: 1px;
            margin: 34px 10px;

            @include getData(background-color, gray6);

            display: flex;
          }
          .a-b2b-bf-discount-bonus {
            img {
              width: 63.64px;
              height: 40px;
              margin: 0 12px 0 0;

              border-radius: 4px;
            }
            div {
              h5 {
                margin: 0 0 2px 0 !important;

                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray1);
              }
              span {
                margin: 0 10.5px 0 0;

                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);
                text-decoration: line-through;
              }
              strong {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, color2);
              }
            }
          }
          .a-b2b-bf-discount-giftcard {
            .a-form-control {
              margin: 0 0 24px 0;
              label {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray2);
              }
              select {
                width: 530px;
                padding: 10px 26.5px;

                border: none;
                @include getData(background-color, light);
                @include getData(box-shadow, pagesShadow, !important);
                border-radius: 4px;

                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray2);

                display: flex;
                align-items: center;
              }
            }
            h5 {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, gray2);
            }
            h3 {
              margin: 2px 0 24px 0 !important;

              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 23px;
              @include getData(color, color2);
            }
            span {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray3);
            }
          }
        }
        .a-bf-summary {
          margin-bottom: 15px;
          padding: 20px;

          @include getData(background, light);
          @include getData(box-shadow, pagesShadow, !important);
          border-radius: 4px;
          .a-b2b-bf-quote-btn {
            width: 249px;
            height: 40px;
            margin: 0 0 20px 0;

            background-color: transparent;
            @include getData(border, btn, null, 1px solid);
            border-radius: 4px;

            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, btn);

            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
            }
          }
          h5 {
            margin-bottom: 20px !important;

            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, gray2);
          }
          
          .container-checkout-coupon{
            padding: 16px 0 16px 16px;
            font-size: 12px;
            @include getData(border-bottom, btn, null, 2px solid);
            .checkout-coupon-header{
                display: flex;
                justify-content: space-between;
                p {
                    font-weight: 500;
                    @include getData(color, navBtn);
                    margin-bottom: 4px;
                }
            }
            tr.a-bf-checkout-discount {
              td {
                border: 0 !important;
                padding: 8px 15px 8px 15px !important;
                background-color: #C1A3610D;
                border: 0;
                font-style: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, color2, !important);
                
                &.a-bf-name{
                  text-align: start;
                  p{
                    margin: 0;
                    font-weight: normal;
                    span{
                      @include getData(color, navBtn);
                      font-weight: 700;
                    }
                  }
                }
              }
            }
          }
          table {
            width: 100%;

            @include getData(color, gray2);
            &:last-of-type {
              tfoot {
                tr {
                  &:last-of-type {
                    td {
                      padding: 0 0 26px 0;
                      @include getData(border-bottom, btn, null, 1px solid);
                    }
                  }
                }
              }
            }
            tr {
              td:last-of-type,
              th:last-of-type {
                text-align: end;
                padding-right: 0 !important;
              }
              td {
                &:nth-of-type(3),
                &:nth-of-type(4) {
                  text-align: center;
                }
              }
            }
            thead {
              th {
                padding-right: 12px;
                padding-bottom: 10px;

                vertical-align: top;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, gray2);
                white-space: nowrap;

                @include getData(border-bottom, gray2, null, 1px solid);
                &:first-of-type {
                  width: 170px;
                  span {
                    margin-left: 4px;
                  }
                }
                &:nth-of-type(2) {
                  width: 130px;
                  text-align: center;
                }
                &:nth-of-type(3) {
                  text-align: center;
                }
                &:nth-of-type(4) {
                  text-align: center;
                }
                &:last-of-type {
                  width: 100px;
                }
              }
            }
            .a-bf-checkout-quantity {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray3);
              text-align: center;
              .input-group {
                width: 128px;
                height: 24px;
                margin: 0 0 4px 0;
              }
              button {
                width: 24px;
                height: 24px;
                padding: 0 !important;

                background-color: transparent;
                border: none;
                &:focus,
                &:active {
                  box-shadow: none;
                }
                span {
                  background-repeat: no-repeat;
                  background-position: center center;
                  background-size: 24px 24px;
                }
              }
              .quantityInput {
                @include getData(background-color, light);
              }
              &-add {
                span {
                  width: 24px;
                  height: 24px;
                  @include getData(background-image, buyingStep4AddActive);
                }
                &:disabled {
                  span {
                    background-image: url(../img/buying_flow_1_3/icon/step04/quantity/add/disable.svg);
                  }
                }
              }
              &-minus {
                span {
                  width: 24px;
                  height: 24px;
                  @include getData(background-image, buyingStep4MinusActive);
                }
                &:disabled {
                  span {
                    background-image: url(../img/buying_flow_1_3/icon/step04/quantity/minus/disable.svg);
                  }
                }
              }
              input {
                width: 60px !important;
                height: 18px;
                margin: 0 10px !important;
                padding: 0;

                border: none;
                @include getData(border-bottom, gray4, null, 1px solid);

                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray1);
                text-align: center;
                &:focus,
                &:active {
                  box-shadow: none;
                }
              }

              i {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);
              }
            }
            tbody,
            tfoot {
              tr {
                td {
                  padding: 10px 6px 16px 0 !important;
                  vertical-align: top;

                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, gray3);
                  h6 {
                    margin-bottom: 4px !important;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    @include getData(color, gray3);
                  }
                  &:first-of-type {
                    h6 {
                      padding: 0 0 0 15px;
                      span {
                        margin: 0 0 0 4px;

                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 14px;
                        @include getData(color, gray2);
                      }
                      &:first-of-type {
                        padding-left: 0;
                      }
                      &:last-of-type {
                        margin-bottom: 0;
                      }
                    }
                    h4 {
                      margin: 0 0 4px 0;
                      padding: 0 0 0 15px;

                      font-style: normal;
                      font-weight: normal;
                      font-size: 12px;
                      line-height: 14px;
                      @include getData(color, gray2);
                    }
                    button {
                      margin: 16px 0 0 15px;
                      font-style: normal !important;
                      font-weight: normal !important;
                      font-size: 12px !important;
                      line-height: 14px !important;
                    }
                  }
                }
                &:last-of-type {
                  td {
                    padding: 0 0 26px 0;
                    @include getData(border-bottom, gray4, null, 1px solid);
                  }
                }
              }
              &.a-tb-with-letter-price {
                tr {
                  td {
                    &:nth-of-type(1) {
                      width: 170px;
                    }
                    &:nth-of-type(2) {
                      width: 130px;
                    }
                    &:nth-of-type(3) {
                      width: 100px;
                    }
                    &:nth-of-type(4) {
                      width: 120px;
                    }
                    &:nth-of-type(5) {
                      width: 90px;
                    }
                    &:nth-of-type(6) {
                      width: 100px;
                    }
                  }
                  &:last-of-type {
                    td {
                      padding: 0 0 26px 0;
                      @include getData(border-bottom, gray4, null, 1px solid);
                    }
                  }
                }
              }
              .a-bf-checkout-order {
                td {
                  @include getData(border-top, gray4, null, 1px solid);
                }
              }
              .a-bf-checkout-letterFee {
                td {
                  padding: 10px 6px 16px 0 !important;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, gray3);
                  &:first-of-type {
                    padding-left: 15px !important;
                    @include getData(color, gray2);
                    font-weight: bold;
                  }
                  &:last-of-type {
                    padding-right: 0 !important;
                  }
                }
                .a-bf-name {
                  width: 170px;
                }
                .a-bf-qty {
                  width: 130px;
                  text-align: center;
                }
                .a-bf-three {
                  width: 100px;
                }
                .a-bf-four {
                  width: 120px;
                }
                .a-bf-five {
                  width: 90px;
                }
                .a-bf-six {
                  width: 100px;
                }
              }
              .a-bf-checkout-discount {
                td {
                  font-style: normal;
                  font-weight: bold;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, color2, !important);
                  &:first-of-type {
                    padding-left: 15px !important;
                  }
                }
                &.a-bf-with-letter-price {
                  &:last-of-type {
                    td {
                      @include getData(border-bottom, gray5, null, 1px solid);
                    }
                  }
                }
              }
              .a-bf-checkout-letter-price {
                td {
                  padding: 25px 32px 25px 0 !important;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, gray3);
                  &:first-of-type {
                    padding-left: 15px !important;
                    @include getData(color, gray2);
                    font-weight: bold;
                  }
                  &:last-of-type {
                    padding-right: 0 !important;
                  }
                }
                .a-bf-qty {
                  width: 160px;
                  text-align: center;
                }
                .a-bf-three {
                  width: 92px;
                }
                .a-bf-four {
                  width: 105px;
                }
              }
            }
          }
          section {
            div {
              width: 250px;
              div {
                display: flex;
                justify-content: space-between;
              }

              h6 {
                font-weight: bold;
                white-space: nowrap;
              }
            }

            h6 {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, btn);
              white-space: nowrap;
              strong {
                margin-left: 4px;

                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, btn);
              }
            }

            p {
              font-size: 12px;
              line-height: 14px;
              margin-bottom: 4px;
              white-space: nowrap;
            }
          }
        }
        .a-bf-coupon {
          margin-bottom: 15px;
          padding: 20px;
          @include getData(background, light);
          @include getData(box-shadow, pagesShadow, !important);
          border-radius: 4px;

          h5 {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, gray2);
            margin-bottom: 6px !important;
          }

          h6 {
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray3);
          }

          .coupon-input {
            margin-top: 12px;
            input {
              // flex: 1;
              padding: 8px 0;
              border: none;
              outline: none;
              @include getData(border-bottom, gray1, null, 1px solid);
              &::placeholder {
                @include getData(color, gray4);
              }
            }
            button:not(.MuiIconButton-root) {
              margin-left: 20px;
              width: 140px;
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              border-radius: 4px;
              @include btnFilled;
              span {
                display: inline-block;
                width: 86px;
              }
            }

            button.MuiIconButton-root {
              background-color: transparent !important;
              .MuiTouchRipple-root {
                display: none;
              }
            }
          }
          .coupon-list {
            padding-left: 0;
            li {
              margin-top: 20px;
              background-color: #C1A3610D;
              width: 100%;
              border-radius: 4px;
              div {
                display: flex;
                gap: 4px;
                flex: 1 0 80%;
                align-items: center;
                font-size: 12px;
                font-weight: 400;
                border-radius: 4px;
                margin-bottom: 0 !important;
                min-height: 40px;
                padding: 4px 6px 4px 10px;
                
                p {
                  // @include getData(font-family, buFontFamily);
                  margin-bottom: 0 !important;
                  flex: 1 0 80%;
                  color: #000;
                  background-color: transparent;

                  &.coupon-amount{
                    span{
                      @include getData(color, navBtn);
                      font-weight: 600;
                      padding : 0; 
                    }
                  }
                  &.coupon-description{
                      padding : 0; 
                  }
                }
                img {
                  cursor: pointer;
                  display: inline-block;
                  margin-right: 8px;
                }
              }
            
              button {
                display: flex;
                justify-content: center;
                align-items: center;

                
                width: 80px;
                margin-left: 0;
                padding: 4px 6px;

                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 19px;

                border-radius: 4px;
                background-color: transparent;
                border: none;
                @include getData(color, navBtn);
                
                &:hover {
                  @include getData(background-color, navBtn, !important);
                  @include getData(color, light);
                  img{
                    // filter refrs: https://codepen.io/sosuke/pen/Pjoqqp
                    // target: white;
                    filter: brightness(0) invert(1);
                  }
                }
                &:focus, &:active {
                  @include getData(background-color, btn, !important);
                  @include getData(color, light);
                  img{
                    // filter refrs: https://codepen.io/sosuke/pen/Pjoqqp
                    // target: white;
                    filter: brightness(0) invert(1);
                  }
                }
              }
            }
          }
          .a-bf-step4-btn {
            &:lang(fr) {
              flex-wrap: wrap;
              button {
                margin-bottom: 16px;
              }
              .a-bf-step4-btn-add {
                img {
                  display: inline-block;
                  margin-left: 24px;
                }
              }
            }
            .a-bf-confirm {
              @include getData(border-top, gray5, null, 1px solid);
              margin-top: 25px;
              padding: 25px 0 0;
              @include getData(color, gray3);
              a {
                @include getData(color, color2);
              }
              .form-check-input {
                display: inline-block;
                margin-right: 8px;
                cursor: pointer;
                &:checked {
                  @include getData(background-color, color2);
                  @include getData(border-color, color2);
                }
              }
            }
            button {
              width: auto;
              min-width: 170px;
              padding: 10px 10px 10px 15px;
  
              @include getData(background-color, light);
              box-shadow: none;
              border-radius: 4px;
  
              @include getData(border, btn, null, 1px solid);
  
              @include getData(color, btn);
              font-weight: bold;
              font-size: 14px;
              line-height: 16px;
              &:disabled {
                opacity: 0.5;
              }
              img {
                width: 20px;
                height: 20px;
              }
            }
            .a-b2b-bf-btns {
              margin: 15px 0 15px 0;
              display: flex;
              align-items: center;
              button {
                width: max-content;
                height: 40px;
  
                @include btnFilled;
  
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, light);
  
                display: flex;
                align-items: center;
                &.wallet-btn {
                  h5 {
                    margin-left: 28px;
                  }
                }
                h5 {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 16px;
                  @include getData(color, light);
  
                  display: flex;
                  align-items: center;
                  img {
                    width: 18px;
                    height: 18px;
                    margin: 0 0 0 4.5px;
                  }
                }
                &:first-of-type {
                  margin: 0 15px 0 0;
                }
                &:last-of-type {
                  text-align: center;
                  min-width: 100%;
                  justify-content: space-between;
                }
              }
            }
            .a-bf-step4-btn-continue {
              margin: 15px 0 15px 0;
              display: flex;
              align-items: center;
  
              button {
                width: max-content;
                height: 40px;
  
                @include btnFilled;
  
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, light);
  
                display: block;
                align-items: center;
  
                &.wallet-btn {
                  h5 {
                    margin-left: 28px;
                  }
                }
  
                h5 {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 16px;
                  @include getData(color, light);
  
                  display: flex;
                  align-items: center;
  
                  img {
                    width: 18px;
                    height: 18px;
                    margin: 0 0 0 4.5px;
                  }
                }
  
                &:first-of-type {
                  margin: 0 15px 0 0;
                }
  
                &:last-of-type {
                  text-align: center;
                  min-width: 100%;
                  justify-content: space-between;
                }
              }
            }
            .a-bf-step4-btn-continue {
              margin-top: 12px;
              width: 100%;
              @include btnFilled;
              &.isFirst {
                margin-left: 0;
              }
            }
            .a-bf-step4-btn-payment {
              flex-wrap: wrap;
              button {
                height: 40px;
                margin-right: 15px;
                margin-bottom: 15px;
                padding: 8px 11px 8px 16px;
  
                @include getData(background, light);
                @include getData(box-shadow, btns1Shadow);
                border: none;
                border-radius: 4px;
  
                display: flex;
                align-items: center;
                justify-content: space-between;
                &.debitCards,
                &.creditCards {
                  // min-width: 249px;
                  width: fit-content;
                  div {
                    img {
                      width: 34px;
                      height: 24px;
                      margin-right: 10px;
                      &:first-of-type {
                        margin-left: 10px;
                      }
                      &:last-of-type {
                        margin-right: 37px;
                      }
                    }
                  }
                }
                &.debit-card-with-psp {
                  height: auto;
                  width: auto;
                  div {
                    img {
                      width: auto;
                      height: 80px;
                      object-fit: contain;
                      margin-right: 0px;
                      &:first-of-type {
                        margin-left: 0px;
                      }
                      &:last-of-type {
                        margin-right: 0px;
                      }
                    }
                  }
                  img {
                    width: 25px;
                    height: 25px;
                    margin-left: 55px;
                  }
                }
                &.paypal {
                  width: 140px;
                  img {
                    width: 61px;
                    height: 16px;
                    &:last-of-type {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
                &.alipay {
                  width: 140px;
                  img {
                    width: 55.2px;
                    height: 14px;
                    &:last-of-type {
                      width: 20px;
                      height: 20px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.a-b2b-bf-discount-options-active {
  @include getData(background-color, bg);
}

.a-b2b-bf-discount-btns-active {
  @include getData(background-color, color2, !important);
  box-shadow: none !important;
  @include getData(color, light, !important);
}

.usp-header {
  display: none;
}

.usp-widget-container .usp-gap-4 {
  gap: 0 !important;
}

.usp-widget-content {
  .usp-flex {
    .usp-mt-4 {
      .usp-items-stretch {
        flex-direction: row;

        .usp-line-item:nth-child(1) {
          margin-left: 5px;
          margin-right: 11px;
        }

        // .usp-line-item:nth-child(2){
        //     margin-right: 10px;
        //     margin-left: 2px;
        // }
        .usp-line-item {
          width: 48%;
          padding: 2px 0px 2px 10px !important;
          font-style: normal !important;
          font-weight: 500 !important;
          @include getData(box-shadow, pagesShadow, !important);
          font-family: 'AvenirNextLTPro', 'sana-sans-alt', sans-serif !important;

          .usp-checkbox {
            border: 1px solid #d9d9d9 !important;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;

                        &:checked[type="checkbox"] {
                          @include getData(background-image, mobileRadioBg, !important);
                        }
                    
                    }
                }
            }
        }
    }
}

iframe {
  width: 100%;
}

.a-bf-payment {
  margin-bottom: 15px;
  padding: 20px;

  @include getData(background, light);
  @include getData(box-shadow, pagesShadow, !important);
  border-radius: 4px;

  #walletBtn {
    .form-check-label {
      display: flex;

      h5 {
        margin: -1% 0 0 10%;
      }
    }
  }

  #ingenicoBtn {
    width: 48%;
  }

    #debitBtn {
        img {
            @include getData(width, debitBtn_width);
            @include getData(height, debitBtn_height);
        }
    }

  iframe {
    width: 100%;
  }

  .a-bf-form {
    display: inline-block;
    width: 100%;

    .form-check {
      display: flex;
      align-items: center;
      width: 48%;
      min-width: 260px;
      // height: 39px;
      padding: 10px 10px 10px 10px;
      margin: 16px 5px 8px 5px;
      @include getData(box-shadow, pagesShadow);
      border-radius: 10px;
      cursor: pointer;

      .form-check-input {
        width: 16px;
        height: 16px;
        margin: 0 12px 0 0;
        @include getData(border, gray5, null, 1px solid);

        &:focus,
        &:active,
        &:checked {
          background-color: transparent !important;
          box-shadow: none !important;
        }

        &:checked[type='radio'] {
          @include getData(background-image, mobileRadioBg, !important);
        }
      }

      .form-check-label {
        width: 90%;
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        @include getData(color, gray2);

        p {
          vertical-align: middle;
          margin: 0;
        }

        img {
          vertical-align: middle;
          margin: 0 0 0 10px;
          // width: 100%;
          // height: 100%;
          // min-width: 120px;
          // max-width: 180px;
        }
      }
    }

    #marketPay-form {
      margin: 20px 0 10px 0;
    }

    .a-credit-form-control {
      display: flex;
      .a-form-control {
        margin-bottom: 15px;
        .a-credit-form-img {
          margin: 10px 10px 0 0;
        }
        .a-credit-form-text {
          margin-top: 10px;
          line-height: 0;
          padding-top: 14px;
        }
        #number1 {
          height: 48px;
        }
        #cvc {
          height: 48px;
        }
      }
    }

    .available-text {
      display: flex;
    }

    .expiryDate {
      display: flex;
    }

    .expiry {
      width: 50%;
      margin-right: 8px;
    }

    .rccs__card--front {
      width: 85%;
      height: 85%;
    }

    .rccs {
      margin: 60px auto;
    }

    .divid-sym {
      font-size: 20px;
      margin: 0 6px;
    }
  }
}

.a-bf-payment-btn {
  display: flex;

  .cancelBtn {
    margin-right: 20px;
    background-color: #fff;
    @include getData(color, btn);
    @include getData(border, btn, null, 1px solid);
  }

  .payBtn {
    @include getData(background-color, btn);
    @include getData(color, light);
  }

  button {
    width: 100%;
    height: 40px;
    // padding: 11.5px 0;
    @include getData(background-color, btn, !important);
    border-radius: 4px;
    @include getData(color, light, !important);
    border: none;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    padding: 0;
  }
}

.a-bf-coupon {
  margin-bottom: 15px;
  padding: 20px;
  @include getData(background, light);
  @include getData(box-shadow, pagesShadow, !important);
  border-radius: 4px;

  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, gray2);
    margin-bottom: 6px !important;
  }

  h6 {
    font-size: 12px;
    line-height: 14px;
    @include getData(color, gray3);
  }

  .coupon-input {
    margin-top: 12px;
    // align-items: center;
    input {
      // flex: 1;
      width: 100%;
      padding: 8px 0;
      border: none;
      outline: none;
      @include getData(border-bottom, gray1, null, 1px solid);

      &::placeholder {
        @include getData(color, gray4);
      }
    }
    .field-label {
      font-size: 12px;
    }

    .card-number-field {
      flex-basis: 43.47%;
      &--full {
        flex-basis: 78.5%;
      }
    }

    .pin-code-field {
      flex-basis: 32.43%;
      margin-left: 20px;
    }

    .field-error {
      color: #eb5757 !important;
      border-color: #eb5757 !important;
    }

    button:not(.MuiIconButton-root) {
      margin-left: 20px;
      width: 140px;
      font-style: normal;
      margin-top: 10px;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      border-radius: 4px;
      height: 44px;
      @include btnFilled;

      span {
        display: inline-block;
        width: 86px;
      }
    }
  }

// commented because duplicate

//   .coupon-list {
//     padding-left: 0;

//     li {
//       margin-top: 20px;

//       p {
//         @include getData(font-family, buFontFamily);
//         display: flex;
//         flex: 1;
//         align-items: center;
//         padding: 8px 10px;
//         @include getData(background-color, bg);
//         font-size: 14px;
//         font-weight: 500;
//         @include getData(color, color2);
//         border-radius: 4px;
//         margin-bottom: 0 !important;
//         min-height: 40px;
//       }

//       img {
//         display: inline-block;
//         margin-right: 8px;
//       }
//     }

//     button {
//       margin-left: 20px;
//       width: 140px;
//       font-style: normal;
//       font-weight: 500;
//       font-size: 16px;
//       line-height: 19px;
//       border-radius: 4px;
//       @include getData(background-color, light);
//       @include getData(border, btn, null, 1px solid);
//       @include getData(color, btn);
//     }
//   }
}

.a-bf-step4-btn {
  &:lang(fr) {
    flex-wrap: wrap;

    button {
      margin-bottom: 16px;
    }

    .a-bf-step4-btn-add {
      img {
        display: inline-block;
        margin-left: 24px;
      }
    }
  }

  .a-bf-confirm {
    @include getData(border-top, gray5, null, 1px solid);
    margin-top: 25px;
    padding: 25px 0 0;
    @include getData(color, gray3);

    a {
      @include getData(color, color2);
    }

    .form-check-input {
      display: inline-block;
      margin-right: 8px;
      cursor: pointer;

      &:checked {
        @include getData(background-color, color2);
        @include getData(border-color, color2);
      }
    }
  }

  button {
    width: auto;
    min-width: 170px;
    padding: 10px 10px 10px 15px;

    @include getData(background-color, light);
    box-shadow: none;
    border-radius: 4px;

    @include getData(border, btn, null, 1px solid);

    @include getData(color, btn);
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;

    &:disabled {
      opacity: 0.5;
    }

    img {
      width: 20px;
      height: 20px;
      margin-left: 10px;
    }
  }

  .a-bf-step4-btn-continue {
    margin: 15px 0 15px 0;
    display: flex;
    align-items: center;

    button {
      width: max-content;
      height: 40px;

      @include btnFilled;

      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      @include getData(color, light);

      display: block;
      align-items: center;

      &.wallet-btn {
        h5 {
          margin-left: 28px;
        }
      }

      h5 {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, light);

        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          margin: 0 0 0 4.5px;
        }
      }

      &:first-of-type {
        margin: 0 15px 0 0;
      }

      &:last-of-type {
        text-align: center;
        min-width: 100%;
        justify-content: space-between;
      }
    }
  }

  .a-bf-step4-btn-continue {
    margin-top: 12px;
    width: 100%;
    @include btnFilled;

    &.isFirst {
      margin-left: 0;
    }
  }

  .a-bf-step4-btn-payment {
    flex-wrap: wrap;

    button {
      height: 40px;
      margin-right: 15px;
      margin-bottom: 15px;
      padding: 8px 11px 8px 16px;

      @include getData(background, light);
      @include getData(box-shadow, btns1Shadow);
      border: none;
      border-radius: 4px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      &.debitCards,
      &.creditCards {
        // min-width: 249px;
        width: fit-content;

        div {
          img {
            width: 34px;
            height: 24px;
            margin-right: 10px;

            &:first-of-type {
              margin-left: 10px;
            }

            &:last-of-type {
              margin-right: 37px;
            }
          }
        }
      }

      &.debit-card-with-psp {
        height: auto;
        width: auto;

        div {
          img {
            width: auto;
            height: 80px;
            object-fit: contain;
            margin-right: 0px;

            &:first-of-type {
              margin-left: 0px;
            }

            &:last-of-type {
              margin-right: 0px;
            }
          }
        }

        img {
          width: 25px;
          height: 25px;
          margin-left: 55px;
        }
      }

      &.paypal {
        width: 140px;

        img {
          width: 61px;
          height: 16px;

          &:last-of-type {
            width: 20px;
            height: 20px;
          }
        }
      }

      &.alipay {
        width: 140px;

        img {
          width: 55.2px;
          height: 14px;

          &:last-of-type {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
