.m-AccountPage {
  width: 100%;
  height: calc(100vh - 64px);

  position: relative;

  margin-top: 64px;
  &.navbar-hide {
    margin-top: 0px;
  }

  .m-Account-container {
    width: 100%;
    height: 100%;

    position: fixed;
    z-index: 10;

    @include getData(background-color, light);

    .a-back-btn {
      width: max-content;
      display: flex;
      grid-gap: 12px;
      gap: 12px;
      align-items: center;
      padding: 16px;

      h4 {
        font-size: 16px;
        font-weight: 700;
        line-height: 1 !important;
      }

      img {
        margin: 0 !important;
      }
    }

    .a-card-tab {
      margin-left: 0 !important;
      margin-bottom: 12px !important;

      .nav-item {
        margin-right: 6px;

        a {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          @include getData(color, gray1);
        }
      }
    }

    #cardTabs {
      overflow: auto;

      .a-card-tab.nav {
        overflow: auto;
        width: max-content;
      }
    }

    > a {
      margin: 0;
    }

    .a-a-mobile-content {
      width: 100%;
      height: calc(100%);

      overflow: auto;

      @include getData(box-shadow, popupShadow);
      border-radius: 20px 20px 0px 0px;

      padding: 16px 16px 280px 16px;

      h3 {
        margin: 28px 0 20px 29px !important;

        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 28px;
        @include getData(color, black);
      }
      article {
        height: max-content;

        section {
          .a-form-control {
            margin: 0 0 12px 0;
            input {
              height: 44px;

              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, gray2);
              &.form-control-disable {
                @include getData(color, gray4, !important);
              }
              &::placeholder {
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray4);
              }
            }
            label {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, gray2);

              &.error {
                @include getData(color, formControlError, !important);
              }
            }
            &:last-of-type {
              margin: 0;
            }
            &.password {
              margin: 0;
              div {
                margin: 0;
                position: relative;
                input {
                  height: 44px;
                }
                button {
                  width: max-content;
                  height: max-content;
                  margin: 0;
                  padding: 0;
                  @include btnNone;

                  position: absolute;
                  bottom: 10px;
                  right: 25px;
                  img {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }
        // hr {
        //     width: 691px;
        //     height: 1px;
        //     margin: 10px;

        //     @include getData(background-color, gray4);
        // }
        .a-a-mobile-btn {
          width: 100%;
          height: 44px;
          padding: 14px 0;

          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .a-a-mobile-corporate-container {
        position: absolute;
        right: 16px;
        left: 16px;

        .a-a-mobile-corporate {
          hr {
            width: calc(100% - 20px);
            height: 1px;
            margin: 10px;
            @include getData(background-color, gray4);
          }
          section {
            margin: 0 0 20px 0;
            label {
              font-weight: 400 !important;
            }
            h5 {
              margin: 0 0 8px 0 !important;

              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, gray2);
            }
            > div.d-flex {
              gap: 12px;

              @include media(tablet, desktop) {
                flex-direction: row !important;
                flex-wrap: wrap;
              }

              .form-check {
                width: 100%;
                height: 39px;
                padding: 10px 10px 10px 10px;
                // margin: 0 12px 0 0;

                @include getData(box-shadow, pagesShadow, !important);
                border-radius: 10px;

                @include media(tablet, desktop) {
                  width: 50%;
                  flex: 1 0 48%;
                  margin: 0;
                }

                input {
                  width: 16px;
                  height: 16px;
                  margin: 0 12px 0 0;

                  @include getData(border, gray5, null, 1px solid);
                }
                label {
                  width: 80%;
                  margin: 0;

                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                  @include getData(color, gray2);
                }
                &:last-of-type {
                  margin: 0;
                }

                &.flex-1 {
                  flex: 1;
                }
              }
            }
            .a-a-mobile-corporate-code {
              input {
                @include inputMobileDefault;
                border-color: transparent !important;

                &:focus,
                &:active {
                  @include getData(background-color, gray6, !important);
                }
              }
            }
          }
          .a-a-mobile-corporate-phone {
            select {
              width: 158px;
              height: 44px;
              margin: 0 8px 0 0;

              @include getData(background-color, light);
              @include getData(box-shadow, pagesShadow, !important);
              border-radius: 10px;
            }
            input {
              @include inputMobileDefault;
            }
          }
          .a-a-mobile-corporate-email {
            input {
              @include inputMobileDefault;
            }
          }
        }
        .a-a-mobile-submit-container {
          position: fixed;
          z-index: 11;
          bottom: 0;
          right: 0;

          @include getData(background-color, light);
          @include getData(box-shadow, mobileBottomNavShadow);
          border-radius: 20px 20px 0 0;

          width: 100%;

          padding: 12px;

          button {
            width: 100%;
            height: 44px;
            @include btnMobilePrimary;
          }
        }
      }
      .a-a-mobile-profile-nav {
        padding: 0 20px;
        @media screen and (min-width: 992px) and (max-width: 1399px) {
          width: 601px;
          .a-card-tab {
            width: 100%;
            margin-left: 0 !important;
          }
        }
      }

      .a-a-mobile-profile {
        .phoneNumber {
          margin-bottom: 12px;
          .a-bf-form-phone-input {
            flex: 1;
          }
        }
        section {
          margin: 0 0 20px 0;
          h4 {
            margin: 0 0 20px 0 !important;

            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 21px;
            @include getData(color, gray2);
            &.with-hint {
              margin-bottom: 8px !important;
            }
          }
          label {
            font-weight: 400 !important;
          }
          i {
            display: block;
            width: calc(100% - 20px);
            height: 1px;
            margin: 25px 10px;
            @include getData(background-color, gray6);
          }
          .hint {
            font-size: 14px;
            line-height: 16px;
            @include getData(color, gray3);
            font-weight: 400;
            margin-bottom: 20px !important;
          }
          .swtich-changeable-row {
            margin: 8px 0 0 0;

            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, gray3);

            display: inline-block;
            .btn-clear {
              @include btnClear;
              margin: 0 0 0 4px;
              cursor: pointer;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;

              @include getData(color, link, !important);
              background-clip: text;
              -webkit-background-clip: text;
            }
          }
        }

        .address-info {
          background-color: #fafafa;
          border-radius: 4px;
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          p {
            margin: 2px 0;
            color: #282828;
            font-size: 14px;
            line-height: 16px;
            &.address {
              font-size: 16px;
              font-weight: 700;
            }
          }
          .edit {
            width: 140px;
            height: 44px;
            border: 1px solid #4c4c4c;
            font-size: 16px;
            line-height: 19px;
            font-weight: 700;
            border-radius: 4px;
            background-color: #fafafa;
          }
        }
      }

      .a-a-mobile-profile-btns {
        width: 100%;

        position: fixed;
        z-index: 11;
        bottom: 0;
        right: 0;

        padding: 16px;

        display: flex;
        flex-direction: column;
        gap: 10px;

        @include getData(background-color, light);
        @include getData(box-shadow, mobileBottomNavShadow);
        border-radius: 20px 20px 0 0;

        button {
          width: 100%;
          height: 44px;

          &.a-a-mobile-cancel-btn {
            @include btnMobileSecondary;
          }
          &.a-a-mobile-save-btn,
          &.a-a-mobile-save-btn-active {
            @include btnMobilePrimary;
          }
        }
      }
    }
  }

  .account-update-profile {
    h3 {
      margin: 32px auto 12px !important;
    }
  }

  #g-recaptcha-corporate-form > div > div {
    margin: auto;

    @media screen and (min-width: 325px) and (max-width: 330px) {
      margin-left: -1vw;
    }

    @media screen and (max-width: 324px) {
      margin-left: -2vw;
    }
  }
}

// special state

.a-a-mobile-corporate-active {
  @include getData(background-color, bg, !important);
  box-shadow: none !important;
  input {
    @include getData(border, color2, !important, 1px solid);
    &:focus,
    &:active,
    &:checked {
      background-color: transparent !important;
      box-shadow: none !important;
    }
    &:checked[type='radio'] {
      border: 0 !important;
      @include getData(background-image, mobileRadioBg, !important);
    }
  }
  label {
    @include getData(color, btn, !important);
  }
}

.PasswordVerifyInfo {
  margin-top: 28px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;

  &__description {
    @include getData(color, gray2);
  }

  &__rules {
    margin-top: 2px;
    display: flex;
    flex-wrap: wrap;
    width: 560px;

    @include media(tablet, mobile) {
      width: 100%;
    }
  }

  &__rule {
    margin-top: 14px;
    width: 240px;
    @include getData(color, gray4);
    display: flex;
    align-items: center;

    > img {
      margin-right: 12px;
    }
  }

  &__rule--checked {
    @include getData(color, historyConfirmed);

    &.mobile {
      @include getData(color, btn);
    }
  }
}
