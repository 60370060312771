.a-register-fields-disable {
  opacity: 0.5;
  pointer-events: none;
}

.a-register-hit {
  @include getData(color, gray3);
  font-size: 0.875rem;
  justify-content: center;

  h6 {
    margin-left: 4px;
    @include getData(color, gray2);
  }
}

.a-register-alert {
  display: flex;
  align-items: center;
  .a-register-alert-img {
    width: 24px;
    height: 24px;
  }
  .a-register-alert-message {
    display: inline-block;
    margin-left: 14px;
    @include getData(color, b2b);
    font-size: 0.875rem;
  }
}

.a-form-choose-company {
  .choose-company-title {
    font-size: 1rem;
    @include getData(color, gray2);
    font-weight: 500;
  }

  .choose-company-list-item {
    margin-top: 20px;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    padding: 20px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 20px;
    }
    &.selected {
      @include getData(background, color2);
      box-shadow: none;

      p {
        @include getData(color, light);
      }
    }

    p {
      margin-bottom: 0;
      font-weight: 500;
    }

    .company-name {
      font-size: 1.125rem;
    }

    .company-describe {
      font-size: 0.75rem;
      font-weight: 400;
    }

    .company-address {
      font-size: 0.875rem;
    }
  }
}

.selected-company-info {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

  .company-detail {
    width: 50%;
    margin-bottom: 12px;
    p {
      margin-bottom: 0;
    }
  }

  .detail-title {
    @include getData(color, gray3);
    font-size: 0.875rem;
  }

  .detail-content {
    @include getData(color, gray2);
    font-weight: 500;
  }
}
