.m-Faqs {
  width: 100%;

  margin-top: 64px;
  position: relative;

  > .a-qc-mobile-faqs {
    position: fixed;

    height: 100%;
    width: 100%;

    @include getData(background-color, light);
    margin: 0;

    .a-qc-mobile-header {
      height: 110px;

      @include getData(background, topup);
      background-size: cover;

      display: flex;
      flex-direction: column;
      justify-content: end;
      align-items: center;
      gap: 10px;

      padding: 0 16px;

      h3 {
        @include getData(color, light);
        font-size: 16px;
        @include getData(font-weight, normal-font-wieght);
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      }

      .input-container {
        position: relative;
        width: 100%;
        height: 44px;

        margin-bottom: 10px;

        input {
          width: 100%;
          height: 100%;

          padding-left: 24px;
          padding-right: 72px;

          border: none;
          border-radius: 100px;
          box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);

          @include getData(font-weight, normal-font-wieght);
          @include getData(color, gray2);
          font-size: 14px;

          &:focus,
          &:active {
            outline: none;
          }

          &::placeholder {
            @include getData(color, gray3);
          }
        }

        > .icon-container {
          display: flex;
          gap: 8px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 16px;

          img {
            cursor: pointer;
          }
        }
      }
    }

    .a-qc-mobile-guide {
      width: 100%;
      height: max-content;
      padding: 22px 16px 16px 16px;

      button {
        @include btnClear;
        width: 100%;
        height: max-content;
        margin: 0 0 20px 0;

        display: flex;
        justify-content: start;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        @include getData(font-weight, normal-font-wieght);
        line-height: 16px;
        @include getData(color, gray2);

        &:focus,
        &:active {
          box-shadow: none;
        }
        &:last-of-type {
          margin: 0;
        }
      }
    }

    .a-qc-mobile-faqs-content {
      height: calc(100% - 110px);

      .a-qc-mobile-navigation {
        padding: 16px;
        gap: 13px;

        justify-content: start;
        align-items: center;

        font-size: 14px;
        @include getData(font-weight, normal-font-wieght);
        line-height: 14px;
        @include getData(color, gray2);
      }

      .a-qc-mobile-questions {
        width: 100%;
        height: calc(100% - 110px);
        padding: 0px 25px 12px 16px;
        overflow: auto;

        .a-qc-mobile-collapse {
          padding-bottom: 60px;

          .accordion {
            width: 100%;
            min-width: 44px;

            display: flex;
            align-items: center;

            padding: 10px 0;

            @include getData(border-bottom, gray6, null, 1px solid);

            &:first-child {
              @include getData(border-top, gray6, null, 1px solid);
            }
          }
          .card {
            width: 100%;
            border: none;
          }
          .card-header {
            width: 100% !important;

            @include btnClear;
            max-width: 100%;

            button {
              width: 100% !important;

              display: flex;
              justify-content: space-between;
              align-items: center;

              @include getData(font-weight, normal-font-wieght);
              @include getData(line-height, card-header-line-height);
              font-size: 16px;

              &.a-qc-mobile-collapse-active {
                @include getData(color, btn, !important);
              }
            }
          }
          button {
            max-width: 100%;
            width: max-content;
            height: max-content;
            padding: 0;
            margin: 24px 0 0 36px;
            text-align: left;

            background-color: transparent;
            border: none;

            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, gray2);
            &:focus,
            &:active {
              box-shadow: none;
            }
            &:first-of-type {
              margin: 0;
            }
            span {
              width: 24px;
              height: max-content;
              margin: 0 12px 0 0;

              display: inline-block;
            }
          }
          .card-body {
            margin: 12px 0 0 0;
            padding: 0;

            border: none;

            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, gray3);
          }
        }
      }
    }
  }
}

// spectial state
.a-qc-mobile-guide-active,
.a-qc-mobile-collapse-active {
  @include getData(color, color2, !important);
}
