.a-lp-main {
  width: 100%;
  height: max-content;
  padding: 144px 0 200px 0;

  @include getData(background-color, bg);
  img {
    width: 740px;

    object-fit: cover;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      width: 343px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
      width: 520px;
    }
  }
}

.a-back-btn {
  margin: 0 0 28px 0;
}

.a-lp-loginIndividual {
  width: 870px;
  height: max-content;
  margin: 0 0 200px 0;
  &.w-full {
    width: 95%;
    max-width: 1356px;
    margin-bottom: 20px;
  }
  .error-message {
    padding: 8px 16px;
    font-size: 1.5rem;
    @include getData(color, formControlError);
  }
  article {
    margin: 0 0 0 30px;
    section {
      width: 100%;
      height: max-content;
      padding: 20px;

      @include getData(background-color, light);
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 4px;
      .a-third-party-btns {
        margin: 0 0 20px 0;
      }
      .a-form-control {
        margin: 0 0 12px 0;
          .receiverCity-selector {
              position: relative;
              img {
                  width: 12px;
                  height: 12px;
              }
              input {
                  width: 100%;
                  outline: none;
              }
      
              .receiverCity-list {
                  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25) !important;
                  border-radius: 10px !important;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  @include getData(background, light);
                  max-height: 415px;
                  overflow-x: hidden;
                  overflow-y: scroll;
              }
      
              .select-down-icon {
                  position: absolute;
                  right: 12px;
                  top: 50%;
      
                  &.rotate {
                      transform: rotate(180deg);
                  }
              }
          }
        label {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, gray2);
        }
        input {
          width: 800px;
          height: 44px;

          border-radius: 6px;
        }
        &.a-form-control-password {
          margin: 0;
          div {
            margin: 0;
            position: relative;
            input {
              height: 44px;
            }
            button {
              width: max-content;
              height: max-content;
              margin: 0;
              padding: 0;
              @include btnNone;

              position: absolute;
              bottom: 10px;
              right: 25px;
              img {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
      .a-auth-login-fields-continaer {
        ul {
          justify-content: space-between;
          li {
            width: 49.8%;
            button {
              height: 65px;
              width: 100%;
              @include getData(color, gray3);
              @include getData(background-color, gray6);
              border-top-left-radius: 4px 4px;
              border-top-right-radius: 4px 4px;
              border-width: 0px;

              img {
                margin-right: 8px;
              }
            }
            .active {
              @include getData(color, color2);
              background-color: white !important;
              // box-shadow: 0px -6px 14px -6px rgba(0, 0, 0, 0.2);
              box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
              position: relative;
            }
            .active:after {
              width: 100%;
              height: 16px;
              display: block;
              background-color: white;
              box-shadow: none;
              position: absolute;
              bottom: 0px;
              z-index: 2;
              content: ' ';
              cursor: default;
            }
            img {
              width: 24px;
              height: 24px;
            }
          }
        }
        .a-auth-login-fields {
          position: relative;
          background-color: white;
          padding: 20px 16px;
          box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
          border-bottom-left-radius: 4px 4px;
          border-bottom-right-radius: 4px 4px;
          z-index: 1;
        }
        .a-bf-form-phone-input {
          width: 77% !important;
        }
        input {
          width: 100%;
        }
      }
      hr {
        width: 100%;
        margin-bottom: 20px;
        @include getData(background-color, gray4);
      }
    }
  }

  .a-lp-reset-password-modal {
    input {
      width: 100%;
      height: 44px;
    }

    p {
      text-align: left;
    }
    .modal-warn {
      z-index: 9998 !important;
    }
    .modal-check-email {
      h5 {
        text-align: left;
      }

      span.fw-600 {
        font-weight: 600;
      }
    }
    .modal-content {
      width: 350px;
      button {
        margin: 1rem 0 0 0;
        color: white;
        border: 1px solid transparent !important;
        &.disabled {
          background-color: rgba(76, 76, 76, 0.2) !important;
          cursor: default;
        }
      }
    }
    .a-auth-login-fields {
      width: 100%;
    }
    .modal-entry-text {
      margin-top: 1.5rem;
      text-align: center;
      @include getData(color, color2);
      span {
        cursor: pointer;
      }
    }
    .modal-hr {
      width: 100%;
    }
    .modal-faq {
      width: max-content;
      height: max-content;
      font-weight: 500;
      font-size: 16px;
      line-height: 140.62%;
      color: #4f4f4f;
      background-color: transparent !important;

      &:hover {
        background-color: transparent !important;
        @include getData(color, btn, !important);
      }
      img {
        width: 24px;
        height: 24px;
        margin: 0 8px 0 0;
      }
    }
  }
}

.a-lp-verify {
  width: 561px;
  height: max-content;
  margin: 0 0 0 34px;

  display: flex;
  article {
    width: 100% !important;
    .a-form-control {
      padding: 20px 0 0 0;
      input {
        width: 100% !important;
        height: 44px;
      }
    }
    .a-page-btn {
      height: 44px;
      margin: 8px 0 0 0 !important;
    }
    h6 {
      margin: 8px 0 0 0;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray2);
      text-align: center;

      display: flex;
      justify-content: center;
      align-items: center;
      button {
        @include btnClear;
        margin: 0 0 0 6px;

        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, btn);
        text-decoration: underline;
        &:focus,
        &:active {
          @include getData(color, btn);
        }
      }
    }
  }
}

.a-lp-autoLogin {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 104px;
  margin-top: 136px;
  .error {
    // @include getData(color, formControlError);
    font-size: 20px;
    font-weight: 600;
  }
}

#successModal {
  background-color: rgba(255, 255, 255, 0.8);
  .modal-content {
    width: 316px;
    max-width: none;
    padding: 8px;

    @include getData(background, light);
    @include getData(box-shadow, btns1Shadow);
    border-radius: 4px;
    border: none;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      @include getData(color, black);
    }
    h5 {
      margin: 12px 0 32px 0 !important;

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, black);
      text-align: center;
    }
    .a-modal-close-btn {
      @include btnClear;
      width: 32px;
      height: 32px;
      img {
        width: 32px;
        height: 32px;
      }
    }
    .a-modal-btn {
      width: 283.34px;
      height: 42.01px;
      margin: 0 0 10px 0;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      @include btnFilled;
      border-radius: 4px;
    }
  }
}

// resued elements
.a-lp-title {
  h3 {
    margin: 0;

    font-weight: bold;
    font-size: 30px;
    line-height: 35px;
    @include getData(color, black);
  }
  h4 {
    margin: 12px 0 20px 0 !important;

    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    @include getData(color, gray3);
    a {
      width: max-content !important;
      height: max-content !important;
      margin: 0 0 0 4px;
      padding: 0;

      background-color: transparent !important;
      @include getData(color, btn, !important);
      text-decoration: underline !important;
      @include btnText;
    }
  }
}

.back {
  display: flex;
  font-size: 16px;
  @include getData(color, gray3);
  margin-bottom: 28px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 8px;
  }
}

.a-register-form {
  .society {
    margin-top: 0 !important;
  }
  hr {
    margin: 20px 0 10px;
    @include getData(background, gray6);
    width: 90%;
  }
  .a-bf-form-phone-input {
    flex: 1;
  }
  .a-form-control {
    h5 {
      font-weight: 700;
      font-size: 22px;
      @include getData(color, gray2);
      margin-bottom: 8px !important;
    }
    h6 {
      font-size: 14px;
      font-weight: 400;
      @include getData(color, gray3);
      &.warn {
        @include getData(color, color2, !important);
      }
    }
    a {
      @include getData(color, color2, !important);
      cursor: pointer;
    }
    .form-check-radio {
      label {
        cursor: pointer;
        margin-bottom: 0 !important;
        @include getData(color, gray3, !important);
      }
      input {
        &[type='radio'] {
          cursor: pointer;
          width: 13px;
          height: 13px;
          display: inline-block;
          margin-right: 8px;
          @include getData(border, gray3, !important, 1px solid);
          background-color: transparent !important;
          box-shadow: none !important;
        }
      }
      &.form-check-radio-active {
        label {
          @include getData(color, gray2, !important);
        }
        input {
          @include getData(border, color2, !important, 1px solid);
          &:focus,
          &:active,
          &:checked {
            background-color: transparent !important;
            box-shadow: none !important;
          }

          &:checked[type='radio'] {
            background-image: url(/img/account/icon/chooseOne_pressed.svg) !important;
          }
        }
      }
    }
    .default-address {
      margin-top: 16px !important;
      margin-bottom: 16px !important;
      @include getData(background-color, bg);
      border-radius: 4px;
      padding: 10px;
      opacity: 0.4;
      p {
        font-size: 12px;
        line-height: 14px;
        margin: 2px !important;
        @include getData(color, gray7);
      }
      &.form-check-radio-active {
        opacity: 1;
      }
    }
  }
  .password {
    position: relative;
    button {
      width: 24px;
      height: 24px;
      padding: 0 !important;

      background-color: transparent;
      border: none;

      position: absolute;
      top: 11px;
      right: 11.5px;
      img {
        width: 24px;
        height: 24px;
      }
      &:focus,
      &:active {
        box-shadow: none;
      }
    }
  }
  &.upload-file {
    .a-form-control {
      h5 {
        font-weight: 500;
        font-size: 18px;
      }
    }
  }
  .uploadFile-description {
    width: 100%;
    height: max-content;
    padding: 10px;
    margin: 0 0 12px 0;

    @include getData(background-color, bg);
    border-radius: 4px;
    h6 {
      margin: 0 0 4px 0 !important;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
    }
    ul {
      padding-left: 1.5rem;
      margin: 0;
    }
    li {
      margin: 0 !important;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray2);
    }
  }
  .uploadFile-media {
    width: 100%;
    height: max-content;
    min-height: 123px;
    padding: 0 0 8.71px 0;
    margin: 12px 0 0 0;

    @include getData(border, gray3, null, 1px dashed);
    border-radius: 4px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .uploadFile-media-add-btn {
      padding: 0;
      background-color: transparent;
      border: none;
      &:focus,
      &:active {
        box-shadow: none;
      }
      img {
        width: 92px;
        height: 92px;
      }
    }
    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray3);

      display: block;
    }
  }
  .a-ts-fullscreen {
    background-size: contain !important;
  }
  .a-ts-upload-horizontal,
  .a-ts-upload-vertical {
    img {
      width: 25px;
    }
    button {
      width: 50%;
      display: block;
      margin: 8px auto 0 !important;
    }
  }

  .a-ts-upload-name {
    width: 300px;
    height: 39px;
    padding: 0 10px;
    @include getData(background-color, light);
    @include getData(box-shadow, pagesShadow, !important);
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    img {
      width: 24px;
      height: 24px;
      margin: 0 12px 0 0;
    }
    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, gray2);
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    button {
      @include btnClear;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, color2);
      flex-shrink: 0;
      margin-left: 4px;
    }
  }
}

.b2b-verify {
  display: flex;
  .verify-img {
    margin-right: 20px;
  }
  .a-lp-verify {
    display: block;
  }
  h3 {
    font-size: 30px;
    font-weight: 700;
    @include getData(color, black);
    margin-bottom: 12px !important;
  }
  h5 {
    font-size: 18px;
    font-weight: 400;
    @include getData(color, gray3);
  }
}

.b2b-verify-error {
  width: 411px;
  margin: 60px auto 0;
  text-align: center;

  h3 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 12px;
  }

  h5 {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    @include getData(color, gray3);
  }

  .light {
    @include getData(color, color2);
  }

  button {
    margin-top: 40px;
    width: 328px;
    padding: 11px 0;
    @include btnFilled;
  }
}

.auth-login {
  .error-message {
    @include getData(color, formControlError);
  }
  .a-guest-btn {
    @include btnLoginShadow;
    width: 100%;
    @include getData(background-color, light);
    border: none !important;

    color: rgba(0, 0, 0, 0.54);
    &:hover,
    &:focus,
    &:active {
      @include getData(background-color, light);
      border: none;

      color: rgba(0, 0, 0, 0.54);
    }
  }
}
