$light: #fff;

$linkDefault: #0e5aa7;
$linkHover: #0f6dcc;
$linkPressed: #0e4dbc;

$btn: #0e5aa7;
$btnHover: #1577da;
$btnPressed: #0d4c8c;
$btnGradient: linear-gradient(258.67deg, #ff6d62 19.09%, #f4283a 78.58%);

$link: #0e5aa7;

// filter refrs: https://codepen.io/sosuke/pen/Pjoqqp
// expected: #FF6D62;
$filterVariableHover: invert(67%) sepia(50%) saturate(5733%) hue-rotate(326deg) brightness(110%) contrast(100%);
// expected: #b70c00;
$filterVariablePressed: invert(9%) sepia(83%) saturate(7498%) hue-rotate(16deg) brightness(87%) contrast(105%);

$navB2CNormalBg: #c1a3610d;
$navB2CNormalColor: #c1a361;
$navB2CHoverBg: #c1a36133;
$navB2CHoverColor: #c1a361;
$navB2CActiveBg: #c1a361;
$navB2CActiveColor: #ffffff;

$navB2BNormalBg: linear-gradient(89.57deg, rgba(79, 57, 142, 0.05) 5.43%, rgba(63, 80, 150, 0.05) 105.37%);
$navB2BNormalColor: #4f398e;
$navB2BHoverBg: linear-gradient(89.57deg, rgba(84, 126, 190, 0.2) 5.43%, rgba(61, 151, 202, 0.2) 105.37%);
$navB2BHoverColor: #4f398e;
$navB2BActiveBg: linear-gradient(89.57deg, #4f398e 5.43%, #3f5096 105.37%);
$navB2BActiveColor: #ffffff;

$navBtnColor: #ffffff;

$navBtn: #c1a361;
$color2: #0e5aa7;
$discountFontColor: red;
$formControlError: #eb5757;
$btnDisable: #4c4c4c;
$ekdrFeatureBannerBg: none;
$FeatureBannerBg: linear-gradient(180deg, rgba(255, 109, 98, 0.7), rgba(244, 40, 58, 0.7));
$FeatureBanner2Bg: none;
$FeatureBanner3Bg: none;
$FeatureBanner4Bg: none;
$FeatureBannerH1FontSize: 44px;
$FeatureBanner1PFontSize: 36px;
$orderNewBorder: linear-gradient(180deg, rgba(255, 109, 98, 0.7), rgba(244, 40, 58, 0.7));
$orderNewColor: linear-gradient(180deg, rgba(255, 109, 98, 0.7), rgba(244, 40, 58, 0.7));
$templateMoreDropdownMaxHeight: 137px;
$accountProductCardWidth: 191px;

$bg: #f5f5f5;
$dark: #282828;
$blue2: #00aaff;
$blue3: #56ccf2;
$black: #000;
$lightBg: #f2f9ff;

$gray1: #333333;
$gray2: #4f4f4f;
$gray3: #828282;
$gray4: #bdbdbd;
$gray5: #d9d9d9;
$gray6: #f2f2f2;
$gray7: #4c4c4c;
$grey: #19181a;

$navTab: #fefefe;

$btns1Shadow: 0px 2px 14px rgba(0, 0, 0, 0.2);
$pagesShadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
$popupShadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
$mobileBottomNavShadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);

$mainLinear: linear-gradient(180deg, #0e5aa7 0%, #0e5aa7 100%);
$mainActionLinear: linear-gradient(180deg, #0e5aa7, #0e5aa7);

$facebookColor: #1877f2;

// sprint 3
$backendData: url('https://picsum.photos/210/132');
$historyConfirmed: #41bb81;
$historyRequesting: #f2994a;
$historyPending: #f2994a;
$historyCancelled: #d6180b;

// sprint 4
$b2b: #ff937b;
$whiteBlankChoose: 0px 0px 8px rgba(0, 0, 0, 0.25);

$b2bRegisterIconActive: #6fcf97;

$footerDisplay: flex;

$a-ts-media_height: 123px;
$debitBtn_width: 25px;
$debitBtn_height: 16px;