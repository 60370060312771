.common-notification {
  @include getData(width, common-notification-width);
  @include getData(justify-content, common-notification-justify-content);
  @include getData(border-radius, common-notification-border-radius);
  @include getData(background-color, common-notification-background-color);

  pointer-events: none;
  position: fixed;
  bottom: 32px;
  z-index: 900;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  padding: 20px;
  display: flex;
  align-items: center;
  transition: opacity 0.3s;
  opacity: 1;
  &--hide {
    opacity: 0;
    z-index: -1; // when z-index >= 1, it'll make component covered and untouchable.
  }
  &__text-block {
    margin-left: 24px;
    max-width: 400px;
  }

  &__title {
    @include getData(margin-bottom, common-notification-title-margin-bottom);

    font-weight: bold;
    font-size: 20px;
    line-height: 23.5px;
  }

  &__content {
    font-size: 12px;
    line-height: 14px;
  }
}

.common-notification.common-notification-error {
  background-color: #CE3637;
}