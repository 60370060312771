.a-a-inbox {
  margin: 0 0 0 29px !important;
  @media screen and (min-width: 992px) and (max-width: 1399px) {
    width: 412px !important;
  }
  .a-form-control {
    margin: 0 0 4px 16px;
    select {
      width: 250px;
      min-width: 250px;
      height: 43px;
      padding: 10px 37px 10px 20px;
      margin: 0 12px 0 0;

      @include getData(background-color, light);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      border: none;

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 140.62%;
      @include getData(color, gray4);
    }
    select[name='status'] {
      @include getData(color, gray1);
    }
    input {
      height: 43px;
      padding: 10px 20px;

      @include getData(background-color, light, !important);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      border: none;

      @include getData(color, gray4);
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: start !important;
      &::placeholder {
        @include getData(color, gray4);
        font-weight: bold;
        text-align: start !important;
      }
      &:focus {
        border: none !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1399px) {
      select {
        width: 200px;
        min-width: 200px;
        &:last-of-type {
          margin: 0;
        }
      }
      input {
        width: 50%;
        margin: 12px 0 0 0;
      }
    }
  }
  .a-inbox {
    width: 100%;
    height: max-content;
    // max-height: 812px;
    padding: 16px 0 0 0;

    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbar;
  }
}

.a-card-history-inbox {
  .a-card-history-top {
    img {
      width: 26px;
      height: 26px;
      margin: 0 12px 0 0 !important;
    }
    h6 {
      margin: 0 0 2px 0 !important;

      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray1);
    }
    h4 {
      margin: 0 !important;

      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      @include getData(color, gray3);
    }
    h5 {
      margin: 0 !important;
      min-width: 103px;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray3);
    }
    @media screen and (min-width: 992px) and (max-width: 1399px) {
      h5 {
        margin: 10px 0 0 0 !important;
      }
    }
  }
}
