$lightOpacity: rgba(255, 255, 255, 0.2);

.a-a-banner {
  width: 100%;
  height: 248px;

  @include getData(background-color, color2);
  img {
    width: 375px;
    height: 248px;

    object-fit: cover;
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      width: 306.64px;
    }
    @media screen and (min-width: 1200px) and (max-width: 1399px) {
      width: 284px;
    }
  }
  aside {
    width: max-content;
    padding: 47.76px 0 47.24px 50px;
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 45px;
      @include getData(color, light);
    }
    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 140.62%;
      @include getData(color, light);
    }
    div {
      margin: 34px 0 0 0;
      a {
        width: 200px;
        height: 48px;
        margin: 0 20px 0 0;

        background: $lightOpacity;
        border-radius: 4px;
        @include getData(border, light, null, 1px solid);

        text-align: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 45px;
        @include getData(color, light);
        &:last-of-type {
          margin: 0;
        }
        &:focus,
        &:active {
          box-shadow: none;
        }
      }
      .sendEmail_btn {
        background: #c1372c;
        border: none;
      }
    }
  }
}
