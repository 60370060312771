.m-GuestPage {
  height: 100%;
  width: 100%;

  position: fixed;
  top: 64px;
  bottom: 0;
  z-index: 1;

  background-color: #ffff;

  &.navbar-hide {
    top: 0px;
  }

  .a-bf-mobile-progress-btn {
    width: 100%;
    position: fixed;
    z-index: 1;
    bottom: 0;
    padding: 16px;
    @include getData(background-color, light);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    .d-flex {
      gap: 10px;

      button {
        &:focus {
          outline: none;
        }
        &.btn-primary {
          width: 100%;
          height: 44px;
          outline: none;
          @include btnMobilePrimary;
        }
        &.btn-secondary {
          width: 100%;
          height: 44px;
          outline: none;
          @include btnMobileSecondary;
        }
        margin: auto;
      }
    }
  }

  .mobile-container {
    padding: 0;
    width: 100%;
    height: 100%;

    .m-guestpage-header {
      margin: 0 16px 0 16px;

      &__back-button {
        @include getData(color, gray2, !important);
        font-weight: 600 !important;
        font-size: 18px !important;
        padding: 0 !important;
        @include btnBack;
      }
      .m-breadcrumb-container {
        gap: 8px;
        align-items: center;

        .m-breadcrumb-step,
        .m-breadcrumb-dash {
          @include getData(background-color, gray5);

          &.done {
            @include getData(background-color, btn);
          }
        }

        .m-breadcrumb-step {
          display: flex;
          justify-content: center;
          align-items: center;

          width: 8px;
          height: 8px;

          border-radius: 100%;

          font-size: 12px;
          font-weight: 700;
          text-align: center;

          &.active {
            width: 40px;
            height: 20px;

            border-radius: 100px;

            @include getData(background-color, btn);
          }

          p {
            margin: 0;
            @include getData(color, light);
          }
        }
        .m-breadcrumb-dash {
          width: 8px;
          height: 2px;
          border-radius: 1px;
        }
      }
    }

    .a-bf-mobile-steps {
      display: inline;
      width: 100%;
      min-height: 100vh;

      .a-bf-mobile-detail {
        display: inherit;
        overflow-y: scroll;
        width: 100%;
        // height: calc(100% - 130px);
        // padding-bottom: 130px;

        @include getData(background-color, light);

        .step1_3-container {
          overflow-y: scroll;
          overflow-x: hidden;
          height: calc(100%);
          padding-bottom: 200px;

          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        }

        hr {
          width: 100%;
          height: 1px;
          @include getData(background-color, gray4);
        }

        .a-bf-mobile-amount {
          padding: 16px;
          @include media(mobile_lg, tablet, desktop) {
            padding: 32px;
          }
          .a-bf-mobile-amount-title {
            padding: 10px !important;

            img {
              width: 30px;
              height: 30px;
              margin-right: 16px;
            }

            h4 {
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
            }

            h6 {
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray3);
            }
          }

          .a-bf-mobile-amount-content {
            gap: 20px;
            padding: 0 !important;

            flex-direction: column;

            @include media(desktop, tablet) {
              flex-direction: row;
            }

            .a-bf-mobile-header {
              @include media(desktop, tablet) {
                flex: 1 0 auto;
              }

              .a-bf-mobile-title {
                @include getData(color, textColor);
                @include getData(font-size, a-bf-mobile-title-font-size);
                font-weight: 700;
                text-align: center;

                @include media(tablet, desktop) {
                  text-align: start;
                }
              }

              .a-bf-mobile-subtitle {
                @include getData(color, gray1);
                font-size: 24px;
                font-weight: 700;
                margin-top: 16px;
                margin-bottom: 8px;
              }

              .a-bf-mobile-description {
                font-size: 14px;
                font-weight: 400;
                @include getData(color, gray3);

                @include media(tablet, desktop) {
                  flex-direction: column;
                }

                .amount-range {
                  @include getData(color, gray1);
                  gap: 4px;
                }

                .additional-description {
                  margin-top: 20px;
                  gap: 15px;

                  @include mobile {
                    display: none !important;
                  }

                  h4 {
                    @include getData(color, gray3);
                    font-size: 14px;
                    line-height: 12px;
                    font-weight: 400;
                  }

                  img {
                    width: 16px;
                  }

                  .delivery-option-container {
                    gap: 15px;

                    .option {
                      display: flex;
                      gap: 4px;
                    }
                  }

                  .expiry-container {
                    gap: 4px;

                    p {
                      margin: 0;
                    }
                  }
                }
              }
            }

            .a-bf-mobile-step1 {
              width: 100%;
              height: max-content;

              gap: 20px;

              @include getData(background-color, light);

              @include media(tablet, desktop) {
                &.customValue {
                  flex: 1 0 50%;
                }
                &.fixedValue {
                  flex: 1;
                }
              }

              .amount-btn-container {
                width: 100%;

                display: grid;
                column-gap: 8px;
                row-gap: 8px;

                @include media(mobile_xs, mobile_sm) {
                  grid-template-columns: repeat(3, 1fr);
                }

                @media screen and (max-width: 490px) {
                  .position-relative {
                    width: 105px !important;
                  }
                }

                @include mobile_md {
                  column-gap: 10px;
                  row-gap: 15px;
                  grid-template-columns: repeat(3, 1fr);
                }

                @include mobile_lg {
                  grid-template-columns: repeat(4, 1fr);
                }

                @include tablet {
                  &.customValue {
                    grid-template-columns: repeat(3, 1fr);
                  }
                  &.fixedValue {
                    grid-template-columns: repeat(2, 1fr);
                  }
                }
                @include desktop {
                  &.customValue {
                    grid-template-columns: repeat(3, 1fr);
                  }
                  &.fixedValue {
                    grid-template-columns: repeat(3, 1fr);
                  }
                }
              }

              &.border-shadow {
                padding: 16px;
                box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
                border-radius: 20px 0px 20px 20px;
              }

              .a-bf-mobile-letter {
                margin-top: 12px;

                @media screen and (max-width: 320px) {
                  .a-bf-mobile-letter-CustomSelect {
                    font-size: 12px;
                  }
                }
              }

              .customerId-selector {
                position: relative;

                .customerId-list {
                  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25) !important;
                  border-radius: 10px !important;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  @include getData(background, light);
                  max-height: 415px;
                  overflow-x: hidden;
                  overflow-y: scroll;
                }

                .select-down-icon {
                  position: absolute;
                  right: 12px;
                  top: 50%;

                  &.rotate {
                    transform: rotate(180deg);
                  }
                }
              }

              h5 {
                margin: 0 0 4px 0;

                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray2);
              }

              h6 {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                margin-top: 4px;
                @include getData(color, gray3);
              }

              i {
                // width: 528px;
                height: 1px;
                margin: 25px 10px;

                @include getData(background-color, gray6);
              }

              .a-b2b-bf-step1-redeem {
                h5 {
                  margin: 0 0 15px 0 !important;
                }

                button {
                  &:first-of-type {
                    margin: 0 16px 0 0;
                  }
                }
              }

              .a-b2b-bf-step1-uploadFile {
                h6 {
                  margin: 0 0 6px 0 !important;

                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, gray2);

                  &:last-of-type {
                    margin: 0 !important;
                  }

                  span {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 14px;
                    @include getData(color, color2);
                    cursor: pointer;
                  }
                }

                .a-b2b-bf-step1-uploadFile-description {
                  width: 100%;
                  height: max-content;
                  padding: 10px;
                  margin: 0 0 12px 0;

                  @include getData(background-color, bg);
                  border-radius: 4px;

                  h6 {
                    margin: 0 0 4px 0 !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                  }

                  p {
                    margin: 0 !important;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    @include getData(color, gray2);
                  }
                }

                section {
                  height: 39px;
                  padding: 0 10px;

                  @include getData(background-color, light);
                  @include getData(box-shadow, pagesShadow, !important);
                  border-radius: 10px;

                  display: flex;
                  align-items: center;
                  justify-content: space-between;

                  button {
                    @include btnClear;

                    font-style: normal;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 14px;
                    @include getData(color, color2);
                  }
                }

                .a-b2b-bf-step1-uploadFile-download {
                  margin: 12px 0 0 0;

                  display: flex;

                  section {
                    flex: 1;
                    margin: 0 12px 0 0;

                    &:last-of-type {
                      margin: 0;
                    }

                    h5 {
                      margin: 0 !important;

                      font-style: normal;
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 19px;
                      @include getData(color, gray2);

                      display: flex;
                      align-items: center;

                      img {
                        width: 24px;
                        height: 24px;
                        margin: 0 12px 0 0;
                      }
                    }

                    .dropdown {
                      .dropdown-menu {
                        width: 200px;
                        height: 104px;
                        padding: 20px;

                        background-color: #4c4c4c;
                        border-radius: 10px;
                        border: none !important;

                        transform: translate3d(-35%, 33%, 0) !important;

                        button {
                          width: 100%;
                          height: 27px;

                          @include getData(background-color, light);
                          @include getData(box-shadow, btns1Shadow);

                          font-style: normal;
                          font-weight: normal;
                          font-size: 16px;
                          line-height: 19px;
                          @include getData(color, gray1);

                          display: flex;
                          align-items: center;
                          justify-content: center;

                          &:first-of-type {
                            margin: 0 0 10px 0;
                          }

                          &:hover,
                          &:focus,
                          &:active {
                            @include getData(background-color, color2, !important);
                          }
                        }

                        &:before {
                          content: '';

                          width: 0;
                          height: 0;
                          border-style: solid;
                          border-width: 0 8.66px 12px 8.66px;
                          border-color: transparent transparent #ff6c62 transparent;

                          position: absolute;
                          left: 50%;
                          top: -12px;
                          transform: translateX(-50%);

                          z-index: -1;
                        }
                      }
                    }
                  }
                }

                .a-b2b-bf-step1-uploadFile-media {
                  width: 100%;
                  height: max-content;
                  min-height: 123px;
                  padding: 0 0 8.71px 0;
                  margin: 12px 0 0 0;

                  @include getData(border, gray3, null, 1px dashed);
                  border-radius: 4px;

                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;

                  .a-b2b-bf-step1-uploadFile-media-add-btn {
                    padding: 0;
                    background-color: transparent;
                    border: none;

                    &:focus,
                    &:active {
                      box-shadow: none;
                    }

                    img {
                      width: 92px;
                      height: 92px;
                    }
                  }

                  span {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    @include getData(color, gray3);

                    display: block;
                  }
                }

                .a-b2b-bf-step1-uploadFile-file {
                  h5 {
                    margin: 0 0 8px 0 !important;

                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    @include getData(color, gray2);
                  }

                  a {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 19px;
                    @include getData(color, gray2);
                    text-decoration: underline !important;

                    display: flex;
                    align-items: center;
                  }

                  .a-b2b-bf-step1-uploadFile-errorSummary {
                    height: max-content;
                    padding: 20px;

                    border-radius: 4px;

                    display: block;

                    h5 {
                      margin: 0 0 20px 0 !important;

                      font-style: normal;
                      font-weight: 500;
                      font-size: 16px;
                      line-height: 19px;
                      @include getData(color, gray2);
                    }

                    table {
                      width: 100%;

                      thead {
                        th {
                          padding: 0 0 10px 0;

                          font-style: normal;
                          font-weight: 500;
                          font-size: 14px;
                          line-height: 16px;
                          @include getData(color, gray2);

                          @include getData(border-bottom, gray6, null, 1px solid);
                        }
                      }

                      tbody {
                        tr {
                          h6 {
                            margin: 0 0 4px 0 !important;

                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 14px;
                            @include getData(color, gray2);

                            span {
                              margin: 0 0 0 4px;
                              font-style: normal;
                              font-weight: 500;
                              font-size: 12px;
                              line-height: 14px;
                              @include getData(color, btn);
                            }
                          }

                          td {
                            padding: 16px 20px 0 0 !important;

                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 14px;
                            @include getData(color, gray2);
                            vertical-align: top;

                            &:first-of-type {
                              max-width: 116px;
                              padding-right: 20px !important;

                              h6 {
                                padding: 0 0 0 15px;

                                &:first-of-type {
                                  padding: 0;
                                }
                              }
                            }

                            &:last-of-type {
                              width: 0 !important;
                              padding: 0 !important;
                            }
                          }

                          &:first-of-type {
                            td {
                              padding-top: 10px !important;
                            }
                          }

                          &:last-of-type {
                            td {
                              padding-bottom: 26px !important;
                              @include getData(border-bottom, gray6, null, 1px solid);
                            }
                          }
                        }
                      }
                    }

                    h4 {
                      margin: 12px 0 0 0;

                      font-style: normal;
                      font-weight: normal;
                      font-size: 12px;
                      line-height: 14px;
                      @include getData(color, gray2);
                      text-align: end;

                      strong {
                        margin: 0 0 0 4px;

                        font-style: normal;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 16px;
                        @include getData(color, gray2);
                      }
                    }
                  }
                }
              }
            }
          }

          .fixedAmount-error {
            margin-top: 20px;
            text-align: center;

            @include media(tablet, desktop) {
              text-align: left;
            }
          }
        }

        .a-bf-mobile-personalize {
          padding: 16px;
          // section {
          //     &:first-of-type {
          //         padding: 10px;

          //         img {
          //             width: 30px;
          //             height: 30px;
          //             margin-right: 16px;
          //         }

          //         h4 {
          //             font-weight: 500;
          //             font-size: 18px;
          //             line-height: 21px;
          //             @include getData(color, gray4);
          //         }
          //     }
          // }

          .a-form-control-error {
            display: flex;
            justify-content: center;

            padding: 10px 0 !important;
            margin: auto !important;
          }
        }

        .a-bf-mobile-undone {
          section {
            padding: 10px;

            img {
              width: 30px;
              height: 30px;
              margin-right: 16px;
            }

            h4 {
              @include getData(color, gray4);
              font-weight: 500;
              font-size: 18px;
              line-height: 21px;
            }
          }
        }
      }
    }
  }

  .default-address {
    @include getData(background-color, bg);
    padding: 10px;
    border-radius: 4px;
    opacity: 0.4;

    &.check-active {
      opacity: 1;
    }

    p {
      margin: 0 0 4px;
      font-size: 12px;
      font-weight: 500;

      &:last-of-type {
        margin-bottom: 0;
      }

      &.address {
        @include getData(color, color2, !important);
      }
    }
  }

  #imageModal {
    position: absolute !important;
    background-color: rgba(255, 255, 255, 0.8) !important;

    .modal-dialog {
      margin: auto;
      overflow: hidden;

      .modal-content {
        .d-flex {
          overflow: hidden;

          > img {
            text-align: center;
            max-width: 100vw;
            max-height: 425.53px;

            object-fit: cover;
          }

          button {
            width: 30px;
            height: 30px;
            padding: 0;

            @include btnNone;
            position: absolute;
            top: 10px;
            right: 10px;

            img {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

#uploadModal {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

#imageModal,
.imageModal {
  background-color: rgba(255, 255, 255, 0.8) !important;

  .modal-dialog {
    width: max-content;
    max-width: none;
  }

  .modal-content {
    width: max-content;
    height: max-content;
    padding: 0;

    position: relative;

    button {
      width: 30px;
      height: 30px;
      padding: 0;

      @include btnNone;
      position: absolute;
      top: 10px;
      right: 10px;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.modal-backdrop.show {
  z-index: 0;
}

.redeemable-place {
  &.isUploadFile {
    margin-top: 25px;
  }

  &.last {
    margin-bottom: 0;
  }

  input {
    width: 100%;
    margin: 8px 0 0;
  }

  .or {
    width: 247px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: #bdbdbd;
    font-weight: 500;
    margin: 16px 0;

    &::after,
    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 109px;
      background: #bdbdbd;
    }
  }

  .a-bf-mobile-step1-shelf {
    margin-top: 6px;

    .MuiOutlinedInput-root {
      width: 100%;
    }
  }

  .redeemable-place-input {
    margin-top: 12px;

    div {
      margin-top: 8px !important;
      margin-bottom: 0px !important;
    }

    input {
      height: 44px;
    }
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 12px;
    font-weight: 400;
  }

  hr {
    margin: 30px 0;
  }
}

.b2b-urassaf-selector {
  width: 100%;

  &.isLast {
    padding-bottom: 30px;
  }

  i {
    position: absolute;
    background-color: transparent !important;
    @include getData(color, gray4, !important);
    font-size: 14px !important;
    font-weight: 500 !important;
    left: 16px;
    top: 7px;
    z-index: 1;
  }

  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, gray3);
  }

  .MuiFormControl-root {
    margin-top: 6px;

    .MuiSelect-root {
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      @include getData(color, gray1, !important);
    }

    &:first-of-type {
      .MuiInputBase-root {
        width: 350px !important;
      }
    }
  }
}

.invoicing-address-selector {
  .padding {
    padding-top: 25px;
  }

  .form-check {
    padding: 10px 10px 10px 0px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }

    input {
      width: 14px;
      height: 14px;
      margin: 0 12px 0 0;
      background-color: #fff !important;
      @include getData(border, gray5, null, 1px solid);
    }

    label {
      width: 80%;
      margin: 0;

      font-style: normal;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, gray3);
    }

    &.check-active {
      input {
        @include getData(border, color2, !important, 1px solid);

        &:checked[type='radio'] {
          background-image: url('/img/B2B_loginAndRegister/icon/radius.svg') !important;
        }
      }

      label {
        @include getData(color, gray2);
      }
    }
  }

  .default-address {
    margin-right: 10px;
    @include getData(background-color, bg);
    padding: 10px;
    border-radius: 4px;
    opacity: 0.4;

    &.check-active {
      opacity: 1;
    }

    p {
      margin: 0 0 4px;
      font-size: 12px;
      @include getData(color, gray7);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .form-control {
    width: 100%;
    margin: 0;
  }

  .a-form-control {
    margin-bottom: 15px;

    &.invoicing-country {
      margin-bottom: 0;
    }
  }

  .invoicing-address-phone {
    input {
      width: 100%;
      margin-left: 16px;
    }
  }
}

.a-bf-mobile-detail-done h4 {
  @include getData(color, gray2, !important);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.a-bf-mobile-detail-active {
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    @include getData(color, color2, !important);
  }

  h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, color2, !important);
  }
}

.a-bf-mobile-detail-done {
  h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    @include getData(color, gray2, !important);
  }

  h6 {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, gray2, !important);
  }
}

.a-bf-mobile-tab-active {
  padding: 4px 21.5px 10px 21.5px !important;

  @include getData(color, btn, !important);
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  @include getData(border-bottom, btn, !important, 3px solid);
}

.a-bf-mobile-select-active {
  @include getData(border, color2, !important, 3px solid);
  @include getData(box-shadow, light, !important, inset 0px 0px 0px 2px);
  border-radius: 4px !important;

  img {
    width: 23.71px;
    height: 23.42px;
  }
}

.a-b2b-bf-step1-uploadFile-file-error {
  a {
    @include getData(color, btn, !important);

    span {
      width: 24px;
      height: 24px;
      margin: 0 12px 0 0;

      @include bgImage(url('/img/B2B_buyingFlow/step1/error_doc.svg'));
    }
  }
}

.check-from-loading,
.import-card-loading,
.loading-full-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 9999;
}

.a-bf-mobile-step1-btns {
  width: 100%;
  position: relative;

  input {
    box-shadow: none;

    line-height: 20px;
    text-align: center;

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none !important;
      -webkit-appearance: none;
      margin: 0;
    }

    &.customValue {
      width: 100%;
      height: 40px;

      border-radius: 20px;

      @include inputMobileDefault;
    }
  }

  button {
    &.a-bf-mobile-amount-btn {
      background: rgba(255, 255, 255, 0.5);
      padding: 10px;

      &.fixedValue {
        width: 100%;

        box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
        border-radius: 10px 0px 10px 10px;
        min-width: 105px;
        height: 100px;

        display: flex;
        align-items: flex-start;

        p {
          text-align: left;
          margin: 0;
        }
      }
      &.customValue {
        height: 57px;
        width: 100%;

        @include getData(border, gray5, null, 1px solid);
        border-radius: 15px;

        &.a-bf-mobile-amount-active {
          @include getData(border-color, btn);
        }

        p {
          @include getData(justify-content, customValue-p-justify-content);

          display: flex;
          text-align: left;
          font-weight: 700;
          font-size: 16px;
          margin: 0;

          @media screen and (max-width: 320px) {
            font-size: 14px;
          }
        }

        @include media(mobile_lg, tablet) {
        }
      }
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
}

.m-inputQty-container {
  position: relative;
  width: calc(100% - 20px);
  max-width: 87px;

  &.fixedValue {
    position: absolute;
    bottom: 10px;
    right: 10px;
    max-width: none;
    height: 22px;
  }

  input {
    box-shadow: none;

    line-height: 20px;
    text-align: center;

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:hover,
    &:focus,
    &:active {
      box-shadow: none;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none !important;
      -webkit-appearance: none;
      margin: 0;
    }

    &.quantity-update {
      border: none;
      @include getData(background-color, bg, !important);
      height: 22px;

      border-radius: 100px;

      font-weight: 500;
      font-size: 16px;
    }

    &.customValue {
      width: 100%;
      height: 40px;

      border-radius: 20px;

      @include inputMobileDefault;
    }
  }

  button {
    position: absolute;
    width: 22px;
    height: 22px;
    top: 0;
    padding: 0;
    border: 0;
    border-radius: 100px;
    @include getData(background-color, btn);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      @include getData(background-color, btnHover);
    }

    &:focus,
    &:active {
      @include getData(background-color, btnPressed);
      box-shadow: none;
    }

    &:disabled {
      @include getData(background-color, btnDisable);
      pointer-events: initial;
    }

    &.a-bf-mobile-minus-btn {
      left: 0;
      
      > img {
        height: 9px;
        width: 9px;
      }
    }

    &.a-bf-mobile-add-btn {
      right: 0;
      
      > img {
        height: 10px;
        width: 10px;
      }
    }
  }
}

.m-singleAddButton {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 100px !important;

  height: 22px;
  width: 22px;

  padding: 0 !important;
  border: 0 !important;

  @include getData(background-color, btn);

  > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    height: 10px;
    width: 10px;
  }
}
