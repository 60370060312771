$backendData: url('https://picsum.photos/210/132');
.m-QueryResult {
  width: 100%;
  height: 100%;

  .m-QueryResult-content {
    width: 100%;
    height: 100%;

    @include getData(box-shadow, popupShadow);
    border-radius: 20px 20px 0px 0px;

    h3 {
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      @include getData(color, black);
    }
    .m-QueryResult-main {
      width: 100%;
      height: calc(100% - 120px);

      padding: 20px;

      position: relative;
      overflow-y: auto;

      .a-card-content > #cardTabs {
        margin: 0 -20px;
        padding: 0 20px;
        overflow: auto;

        .a-card-tab.nav {
          width: max-content;
          overflow: auto;
        }
      }

      .a-qc-mobile-card-bg {
        width: 100%;
        height: 152px;
        margin-bottom: 16px;
        padding: 10px;

        // background-image: card cover from backend
        border-radius: 10px;
        background: linear-gradient(262.54deg, rgba(255, 255, 255, 0.9) 15.37%, rgba(255, 255, 255, 0.8) 48.76%, rgba(255, 255, 255, 0.9) 66.09%), $backendData;
        backdrop-filter: blur(2px);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        .a-qc-mobile-card {
          width: 210px;
          height: 132px;
          margin: 0 12px 0 0;
          padding: 10px 20px;

          @include getData(background-color, light);
          @include getData(box-shadow, btns1Shadow);
          border-radius: 10px;
          h4 {
            margin: 0 0 2px 0;

            font-weight: bold;
            font-size: 18px;
            line-height: 21px;
            @include getData(color, gray1);
          }
          h5 {
            margin: 0;

            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, gray3);
          }
          h6 {
            margin: 30px 0 4px 0;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray3);
            text-align: end;
          }
          h3 {
            font-weight: normal;
            font-size: 18px;
            line-height: 21px;
            @include getData(color, color2);
            text-align: end;
          }
          &:first-of-type {
            // background-image: card cover from backend
            @include bgImage($backendData);
            background-size: contain;
          }
        }
        button {
          width: 152px;
          height: 132px;
          padding: 47.5px 20px;

          @include getData(background, mainLinear);
          @include getData(box-shadow, btns1Shadow);
          border-radius: 10px;
          border: none;
          div {
            width: 112px;
            height: 37px;
            padding: 10px 0;

            background: linear-gradient(227.38deg, rgba(255, 255, 255, 0.3) 3.37%, rgba(255, 255, 255, 0.1) 49.26%, rgba(255, 255, 255, 0.3) 88.91%);
            backdrop-filter: blur(2px);
            border-radius: 4px;

            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, light);
          }
        }
      }
      .a-qc-mobile-card-list {
        margin: 0 0 12px 0;
        h4 {
          margin: 0 0 12px 0;

          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          @include getData(color, gray1);
        }
        .a-qc-mobile-list {
          width: 801px;
          height: 148px;
          padding: 12px 17px;
          .a-qc-mobile-select {
            width: 191px;
            height: 124px;
            padding: 10px 10px 20px 10px;

            @include getData(background-color, light);
            @include getData(box-shadow, pagesShadow, !important);
            border-radius: 10px;
            span {
              margin: 0 0 10px 0;
              i {
                margin: 0 4px 0 0;
                font-style: normal !important;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, color2);

                visibility: hidden;
              }
              img {
                width: 16px;
                height: 15px;
              }
            }
            h5 {
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, gray1);
              em {
                margin: 0 0 0 4px;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray2);
              }
            }
            h6 {
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray3);
              &:first-of-type {
                margin: 10px 0 !important;
              }
            }
          }
        }
      }
      .a-qc-mobile-info {
        width: 100%;
        height: max-content;
        margin: 0;
        .a-qc-mobile-pay {
          width: 100%;
          height: max-content;
          margin: 0 0 0 16px;
          padding: 20px 8px;
          .a-qc-mobile-code {
            height: max-content;
            padding: 20px;

            @include getData(background-color, light);
            @include getData(box-shadow, btns1Shadow);
            border-radius: 10px;
            span {
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 0.03em;
              @include getData(color, gray2);
              strong {
                margin: 0 0 0 4px;

                font-style: normal;
                font-weight: bold;
                font-size: 12px;
                line-height: 14px;
                letter-spacing: 0.03em;
                @include getData(color, gray2);
              }
            }
            h6 {
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              letter-spacing: 0.03em;
              @include getData(color, gray3);
            }
            i {
              width: 205px;
              height: 1px;
              margin: 10px 0;

              @include getData(background-color, gray6);
            }
            .a-qc-mobile-code-btn-group {
              width: 194px;
              height: 66px;
              button {
                width: 93px;
                height: 28px;
                margin: 0 0 10px 0;
                padding: 4px 5px 4px 10px;

                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);
                &:focus,
                &:active {
                  box-shadow: none;
                }
                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
          .a-qc-mobile-barcode {
            width: 267.3px;
            img {
              width: 227.3px;
              height: 87px;
            }
            h5 {
              margin: 4px 0 10px 0 !important;

              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.03em;
              @include getData(color, black);
            }
            .a-qc-mobile-barcode-pin {
              div {
                width: 100px;
                height: 54px;
                margin: 0 4px 0 0;
                padding: 10px;

                @include getData(background-color, bg);
                border-radius: 4px;
                &:last-of-type {
                  margin: 0;
                }
                h6 {
                  margin: 0 0 6px 0;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  letter-spacing: 0.03em;
                  @include getData(color, gray2);
                  &:last-of-type {
                    margin: 0;
                  }
                }
              }
            }
          }
          .a-qc-mobile-qrcode {
            width: 245px;
            img {
              width: 124px;
              height: 124px;
              margin: 10px 0;
            }
          }
        }
        .a-qc-mobile-tnc,
        .a-qc-mobile-policy {
          p {
            margin: 16px 0 0 16px;

            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, gray3);
          }
        }
        .a-qc-mobile-transfer {
          width: 100%;
          height: max-content;
          margin: 0 0 10px 16px;
          .a-qc-mobile-transfer-title {
            i {
              margin: 0 4px 0 0;

              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, gray2);
            }
            div {
              h5 {
                margin: 0 0 4px 0 !important;

                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray2);
              }
              h6 {
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);
              }
            }
          }
          .a-qc-mobile-step1 {
            .a-qc-mobile-type {
              width: 552px;
              height: 180px;
              margin: 20px 0 0 0;
              padding: 0 0 0 20px;
              .form-check {
                width: 260px;
                height: 39px;
                margin: 0;
                padding: 6px 10px;

                @include getData(background-color, light);
                @include getData(box-shadow, pagesShadow, !important);
                border-radius: 10px;

                cursor: pointer;
                label {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                  @include getData(color, gray2);

                  cursor: pointer;
                  img {
                    width: max-content;
                    height: 24px;
                  }
                }
                input {
                  @include getData(border, gray5, null, 1px solid);
                  border-radius: 4px;

                  float: right;

                  cursor: pointer;
                  &:focus {
                    @include getData(border, gray5, null, 1px solid);
                    box-shadow: none;
                  }
                  &:active,
                  &:checked {
                    @include getData(border, gray5, null, 1px solid);
                    box-shadow: none;
                  }
                }
                &:first-of-type,
                &:nth-of-type(2) {
                  img {
                    width: 24px;
                    height: 24px;
                    margin: 0 12px 0 0;
                  }
                }
              }
            }
          }
          hr {
            width: 711px;
            height: 1px;
            margin: 30px 10px;

            @include getData(background-color, gray6);
          }
          .a-form-control {
            margin: 0 0 12px 20px;
          }
          .a-qc-mobile-email {
            width: 532px;
            input {
              height: 39px;
            }
          }
          .a-qc-mobile-sms {
            select {
              width: 158px;
              height: 39px;
              margin: 0 8px 0 0;

              @include getData(background-color, light);
              @include getData(box-shadow, pagesShadow, !important);
              border-radius: 10px;
              border: none;
            }
            input {
              width: 364px;
              height: 39px;
            }
          }
          .a-qc-mobile-step2 {
            .a-qc-mobile-template {
              margin: 0 0 0 20px;
            }
          }
          .a-qc-mobile-transfer-btn {
            width: 740px;
            height: 40px;
            margin: 20px 0 0 0;
            padding: 11.5px 0;

            @include getData(background-color, btn);
            border-radius: 4px;
            @include btnFilled;

            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, light);
          }
        }
        .a-qc-mobile-history {
          width: 100%;
          height: max-content;
          max-height: 812px;
          margin: 0 0 10px 0;

          overflow-y: auto;
          overflow-x: hidden;

          /* width */
          &::-webkit-scrollbar {
            width: 13px;
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: transparent;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.05);
            border-radius: 2px;
          }
          .a-qc-mobile-detail {
            width: 754px;
            height: 189px;
            margin: 0 0 16px 16px;
            padding: 10px;

            @include getData(background-color, light);
            @include getData(box-shadow, btns1Shadow);
            // box-shadow: inset $pagesShadow;
            border-radius: 0px 0px 4px 4px;
            @include getData(border-top, gray3, null, 2px solid);
            &:last-of-type {
              margin: 0 0 16px 16px;
            }
            .a-qc-mobile-detail-top {
              margin: 0 0 4px 0;
              padding: 10px;
              h6 {
                margin: 0 0 4px 0;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, gray2);
                em {
                  margin: 0 0 0 4px;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 16px;
                  @include getData(color, gray2);
                }
              }
              h4 {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);
                em {
                  margin: 0 4px;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, gray3);
                }
              }
              h5 {
                margin: 0 0 4px 0;

                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, gray2);
              }
            }
            .a-qc-mobile-detail-middle {
              margin: 0;
              padding: 10px;
              img {
                width: 68.8px;
                height: 43px;
                margin: 0 12px 0 0;

                border-radius: 4px;
              }
              h2 {
                margin: 0 0 4px 0;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray2);
                &:last-of-type {
                  margin: 0;
                }
              }
              span {
                margin: 0 0 10px 0;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray2);
              }
              h3 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, gray2);
                img {
                  width: 16px;
                  height: 16px;
                  margin: 0 0 0 4px;
                }
              }
              i {
                width: 1px;
                height: 32px;
                margin: 0 14px;

                @include getData(background-color, gray5);
              }
              button {
                width: max-content;
                height: 37px;
                padding: 10px;
                margin: 0 0 0 16px;

                background-color: transparent;
                @include getData(border, btn, null, 1px solid);

                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, btn);
                &:focus,
                &:active {
                  box-shadow: none;
                }
              }
            }
            hr {
              width: 728px;
              height: 1px;
              margin: 6px 10px 2px 10px;

              @include getData(background-color, gray6);
            }
            .a-qc-mobile-detail-bottom {
              padding: 10px;
              a {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray4);
              }
              button {
                width: 88px;
                height: max-content;
                padding: 0;

                background-color: transparent;
                border: none;

                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, color2);
                &:focus,
                &:active {
                  box-shadow: none;
                }
                img {
                  width: 16px;
                  height: 16px;
                  margin: 0 0 0 4px;
                }
              }
            }
          }
        }
      }
    }

    .a-qc-mobile-remarks {
      margin: 36px 0 0 0;
      p {
        margin: 16px 0 0 0;

        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        @include getData(color, gray3);
      }
    }
    .a-card-display,
    .a-card-list,
    .a-card-tab,
    .a-card-transfer {
      margin-left: 0 !important;
    }
    .a-card-tnc,
    .a-card-howToUse {
      p {
        margin-left: 0 !important;
      }
    }

    .a-card-tab {
      .nav-item {
        margin-right: 6px;

        a {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          @include getData(color, gray1);
        }
      }
    }

    .a-card-row {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      @include media(mobile_lg, tablet, desktop) {
        grid-template-columns: minmax(150px, 224px) minmax(160px, 280px) minmax(150px, 206px);
      }
      > .a-card-info {
        @include getData(max-width, a-card-info-max-width);
        @include getData(height, a-card-info-height);
        @include getData(grid-column, a-card-info-grid-column);
        
        min-width: 0;
        width: 100%;

        > img {
          border-radius: 8px !important;
        }

        @include media(mobile_lg, tablet, desktop) {
          height: 132px;
        }
      }

      > .a-card-info-modal-btn {
        height: 100px;
        width: 100% !important;
        max-width: none !important;
        min-width: 0;

        @include getData(box-shadow, btns1Shadow, !important);
        border-radius: 20px 20px 20px 0px !important;

        div {
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;

          border-radius: 100px !important;

          @include media(mobile_xs, mobile_sm) {
            background: none !important;
          }
        }

        @include media(mobile_lg, tablet, desktop) {
          order: 3;
          height: 132px;
        }

        @include media(tablet) {
          max-width: 206px !important;
        }
      }

      > .a-card-detail {
        grid-column: 1 / span 2;
        max-width: none;
        min-width: 0;
        width: 100%;

        @include media(mobile_lg, tablet, desktop) {
          grid-column: 2 / 2;
          order: 2;
          max-width: 280px;
        }

        div {
          &:last-child {
            display: flex;
            flex-direction: column;

            h3 {
              margin: 0 !important;
            }
          }
        }
      }
    }

    .a-card-all {
      padding-left: 0;

      > .a-card-select {
        height: 100%;
        gap: 4px;

        h6 {
          margin: 0 !important;
          font-size: 12px;
        }

        h5 {
          margin-bottom: 6px !important;
        }

        > div.d-flex {
          width: 100%;
          align-items: center;
          margin-bottom: 6px;

          gap: 4px;

          h4 {
            @include getData(color, btn);
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            margin: 0 !important;
          }

          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }

    .a-card-history {
      width: 100%;
      @include getData(box-shadow, a-card-detail-box-shadow);
      @include getData(border, a-card-history-border);
      @include getData(border-radius, a-card-history-border-radius);

      .a-card-history-common {
        margin: 0 !important;
        width: 100%;
        box-shadow: none !important;
      }
    }
  }
}

#blockModal,
.warnModal,
.emailModal,
.printModal,
.modal-warn {
  background-color: rgba(255, 255, 255, 0.8);
  .modal-content {
    width: 316px;
    max-width: none;
    padding: 40px 16.33px 18px 16.33px;

    @include getData(background, light);
    @include getData(box-shadow, btns1Shadow);
    border-radius: 4px;
    border: none;

    @include media(tablet, mobile) {
      border-radius: 20px;
    }
    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      @include getData(color, black);
    }
    h5 {
      margin: 12px 0 !important;

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, black);
    }
    h6 {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, color2);
    }
    button {
      width: 100%;
      height: 42.01px;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      border-radius: 4px;
      &:first-of-type {
        color: white !important;
        margin: 32px 0 10px 0;
        @include btnFilled;
        @include media(tablet, mobile) {
          @include btnMobilePrimary;
        }
      }
      &:last-of-type {
        @include getData(background-color, light);
        @include getData(border, btn, null, 1px solid);

        @include getData(color, btn);
        @include media(tablet, mobile) {
          @include btnMobileSecondary;
        }
      }
    }
  }
}

.emailModal,
.warnModal,
.printModal {
  background: none;
  .modal-content {
    margin: auto;
    position: relative;
  }
  .modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }

  button {
    &:first-of-type {
      &:disabled {
        background-color: rgba(76, 76, 76, 0.2) !important;
      }
    }
  }
}

.modal-warn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;

  &.account-update-profile {
    .modal-content {
      button {
        &:last-of-type {
          @include btnFilled;
        }
      }
    }
  }
  .modal-content {
    margin: auto;
    position: relative;

    p {
      text-align: center;
    }
  }
  .modal-icon {
    img {
      width: 100%;
    }
  }
  .faq {
    border-top: 1px solid #e0e0e0;
    width: 50%;
    margin: auto;
    padding-top: 16px;
    img {
      width: 24px;
      height: 24px;
      display: inline-block;
      margin-right: 8px;
    }
  }
  li {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    margin-bottom: 18px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 32px;
    height: 32px;
    cursor: pointer;

    img {
      width: 100%;
    }
  }
  .link {
    text-decoration: underline !important;
    @include getData(color, blue2);
  }
  h3 {
    &.success {
      font-size: 20px;
      line-height: 24px;
      color: #d6180b;
      margin-bottom: 20px !important;
    }
  }
}

.warnModal,
.modal-warn {
  h3,
  h5 {
    text-align: center;
  }
}

.account-register {
  .modal-content {
    width: 433px;

    button {
      &:first-of-type {
        @include getData(color, light, !important);
      }
    }
  }
}

// reused elements
.a-qc-mobile-tab {
  margin: 10px 0 16px 16px !important;
  li {
    margin: 0 40px 0 0;
    padding: 0;
    a {
      padding: 0 6px 10px 6px;

      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      @include getData(color, gray2);
    }
    img {
      width: 13.53px;
      height: 13.53px;
      margin: 0 0 0 4px;
    }
  }
  .dropdown {
    width: max-content;
    height: max-content;
    margin: 0;
    padding: 0;
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
    .dropdown-menu {
      padding: 10px 40px;

      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border: none;
      border-radius: 4px;
      li {
        margin: 0 0 8px 0;
        padding: 0;
        &:last-of-type {
          margin: 0;
        }
        a {
          margin: 0;
          padding: 0;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}

// special state
.a-qc-mobile-select-active {
  @include getData(border, color2, null, 1px solid);
  i {
    visibility: visible !important;
  }
}

.a-qc-mobile-tab-active {
  @include getData(color, btn, !important);
  @include getData(border-bottom, gray3, !important, 2px solid);
}

.a-qc-mobile-type-active {
  @include getData(background-color, bg, !important);
  label {
    @include getData(color, color2, !important);
  }
  input {
    @include getData(background-color, color2, !important);
    &:focus {
      @include getData(border, gray5, !important, 1px solid);
      box-shadow: none;
    }
    &:active,
    &:checked {
      @include getData(border, color2, !important, 1px solid);
      box-shadow: none;
    }
  }
}

.a-qc-mobile-detail-cancelled {
  @include getData(border-top, btn, !important, 2px solid);
  h5 {
    @include getData(color, btn, !important);
  }
}
.a-qc-mobile-detail-requesting {
  @include getData(border-top, historyRequesting, !important, 2px solid);
  h5 {
    @include getData(color, historyRequesting, !important);
  }
}
