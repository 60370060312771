@import './font';

.a-delivery-date {
  padding: 0 !important;
  margin-bottom: 20px;

  .MuiPickersStaticWrapper-staticWrapperRoot {
    p,
    span {
      @include getData(font-family, buFontFamily, !important);
    }
  }

  .MuiFormControl-root {
    .MuiSelect-root {
      width: 100%;
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 16px !important;
      @include getData(color, gray4, !important);
      @include getData(font-family, bu-font-family, !important);
    }

    &:first-of-type {
      .MuiInputBase-root {
        // width: 230px !important;
        width: 100%;
      }
    }

    &:nth-of-type(2) {
      .MuiInputBase-root {
        // width: 131px;
        width: 100%;
      }
    }

    &:last-of-type {
      .MuiInputBase-root {
        // width: 128px;
        width: 100%;
      }
    }
  }

  .MuiFormControl-root:nth-child(2) {
    margin-left: 10px;
  }

  i {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, gray2);
    em {
      margin: 0 0 0 6px;

      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, color2);
    }
  }
  .MuiPickersStaticWrapper-staticWrapperRoot {
    width: 100%;
    height: 100%;
    padding: 20px 20px 0 0;

    overflow: visible;
    .MuiPickersBasePicker-container {
      width: 344px;
      height: 368px;
      max-height: none;

      display: flex;
      justify-content: stretch;
      align-items: stretch;
      .MuiPickersBasePicker-pickerView {
        width: 100%;
        max-width: none;
        height: 100%;
        padding: 0;

        @include getData(background-color, light);
        @include getData(box-shadow, btns1Shadow);
        border-radius: 10px;
        .MuiPickersCalendarHeader-switchHeader {
          width: 100%;
          height: 100%;
          margin: 0 !important;
          padding: 0;

          display: flex;
          justify-content: center;
          align-items: center;
          .MuiPickersCalendarHeader-transitionContainer {
            width: 100%;
            height: 40px;

            display: flex;
            justify-content: center;
            align-items: center;
            .MuiTypography-root {
              @include getData(font-family, bu-font-family, !important);
              width: 100%;
              height: 40px;

              display: flex;
              justify-content: center;
              align-items: center;

              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 24px;
              @include getData(color, gray1);
            }
          }
          .MuiSvgIcon-root {
            margin: 0 !important;
          }
        }

        .MuiPickersCalendarHeader-daysHeader {
          padding: 0 32px;
          .MuiTypography-root {
            @include getData(font-family, bu-font-family, !important);
            width: 40px !important;
            height: 40px;
            margin: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 24px;
            @include getData(color, grey);
          }
        }
        .MuiPickersCalendar-week {
          div {
            width: 40px;
            height: 40px;

            display: flex;
            justify-content: center;
            align-items: center;
            button {
              width: 40px;
              height: 40px;
              margin: 0;

              display: flex;
              justify-content: center;
              align-items: center;
              .MuiTypography-root {
                @include getData(font-family, bu-font-family, !important);
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 24px;
              }
            }
          }
          .MuiPickersDay-daySelected {
            @include getData(background-color, color2);
            .MuiTypography-root {
              @include getData(color, light, !important);
            }
          }
          .MuiPickersDay-dayDisabled {
            p {
              opacity: 0.4;
            }
          }
        }
        .MuiPickersCalendar-transitionContainer {
          margin: 28px 0 0 0;
          min-height: 240px;
        }
      }
    }
  }
}