.a-qc-faqs-bg {
    @include getData(background-color, bg);
    .a-qc-faqs {
        width: max-content;
        height: max-content;
        margin: 144px 0 200px 0;
        .a-qc-guide {
            width: 340px;
            height: max-content;
            margin: 0 20px 0 0;
            padding: 30px 40px 40px 40px;

      @include getData(background-color, light);
      border-radius: 0px 0px 20px 20px;
      button {
        width: max-content;
        height: max-content;
        padding: 3px 0 4px 0;
        margin: 0 0 16px 0;

        background-color: transparent;
        border: none;

        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        @include getData(color, gray3);
        &:focus,
        &:active {
          box-shadow: none;
        }
        &:last-of-type {
          margin: 0;
        }
      }
    }
    .a-qc-questions {
      width: 840px;
      height: max-content;
      padding: 20px 20px 30px 20px;

      @include getData(background-color, light);
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 4px;
      @media screen and (min-width: 992px) and (max-width: 1399px) {
        width: 563px;
      }
      .a-qc-tab {
        margin: 10px 0 16px 0 !important;
        a {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 21px;
          @include getData(color, gray1);
        }
      }
      .a-qc-collapse {
        .accordion {
          margin-top: 24px;
        }
        .card {
          border: none;
        }
        .card-header {
          @include btnClear;
          max-width: 100%;
        }
        button {
          max-width: 100%;
          width: max-content;
          height: max-content;
          padding: 0;
          margin: 24px 0 0 36px;
          text-align: left;

          background-color: transparent;
          border: none;

          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          @include getData(color, gray2);
          &:focus,
          &:active {
            box-shadow: none;
          }
          &:first-of-type {
            margin: 0;
          }
          span {
            width: 24px;
            height: max-content;
            margin: 0 12px 0 0;

            display: inline-block;
          }
        }
        .card-body {
          margin: 12px 0 0 36px;
          padding: 0;

          border: none;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    @include getData(color, gray3);
                    
                    p:last-of-type {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }
}

// spectial state
.a-qc-guide-active,
.a-qc-collapse-active {
  @include getData(color, color2, !important);
}
