main.m-authPage {
  @include getData(background-color, light);
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: calc(100% + 64px);

  padding: 64px 0 0 0;

  &.navbar-hide {
    padding: 0 0 64px 0;
  }

  transition: 0.1s ease-in-out;

  .m-lp-main {
    width: 100%;
    height: calc(100% - 116px);

    overflow: auto;

    @include getData(background-color, bg);

    .m-lp-loginIndividual,
    .m-lp-verify {
      width: 100%;
      height: max-content;

      padding-bottom: 100px;

      .MuiSelect-root {
        justify-content: center;
      }

      &.w-full {
        width: 95%;
        max-width: 1356px;
        margin-bottom: 20px;
      }
      .error-message {
        padding: 8px 16px;
        font-size: 1.5rem;
        @include getData(color, formControlError);
      }
      article {
        padding: 16px 16px 0px;

        section {
          width: 100%;
          height: max-content;

          .a-third-party-btns {
            flex-direction: column;
            margin: 16px 0;

            &:empty {
              margin: 0;
            }

            button {
              width: 100%;
              border-radius: 100px;
            }
          }

          .a-register-check {
            h5 {
              font-size: 14px;
            }
            > .d-flex {
              flex-direction: column;
            }
          }

          .a-auth-login-fields-container {
            ul {
              justify-content: space-between;
              li {
                width: 49.8%;
                button {
                  height: 65px;
                  width: 100%;
                  @include getData(color, gray3);
                  @include getData(background-color, gray6);
                  border-top-left-radius: 4px 4px;
                  border-top-right-radius: 4px 4px;
                  border-width: 0px;

                  img {
                    margin-right: 8px;
                  }
                }
                .active {
                  @include getData(color, color2);
                  background-color: white;
                  // box-shadow: 0px -6px 14px -6px rgba(0, 0, 0, 0.2);
                  box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
                  position: relative;
                }
                .active:after {
                  width: 100%;
                  height: 16px;
                  display: block;
                  background-color: white;
                  box-shadow: none;
                  position: absolute;
                  bottom: 0px;
                  z-index: 2;
                  content: ' ';
                  cursor: default;
                }
                img {
                  width: 24px;
                  height: 24px;
                }
              }
            }
            .a-auth-login-fields {
              .a-custom-phone > label {
                display: none;
              }

              label.form-label {
                font-weight: 400 !important;
              }
            }
            .a-bf-form-phone-input {
              width: 77% !important;
            }
            input {
              width: 100%;
            }
          }
        }

        .a-form-control {
          margin: 0 0 12px 0;
          label {
            font-weight: 400 !important;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, gray2);

            &.error {
              @include getData(color, formControlError, !important);
            }
          }
          input {
            width: 100%;
            height: 44px !important;

            border-radius: 6px;

            &.error {
              @include getData(border, formControlError, !important, 1px solid);
            }
          }

          .a-form-control-error > .custom-message {
            font-weight: 700;
          }
          &.a-form-control-password {
            margin: 0;
            div {
              margin: 0;
              position: relative;
              input {
                height: 44px;
              }
              button {
                width: max-content;
                height: max-content;
                margin: 0;
                padding: 0;
                @include btnNone;

                position: absolute;
                bottom: 10px;
                right: 25px;
                img {
                  width: 24px;
                  height: 24px;
                }
              }
            }
          }

          &.a-custom-phone.authRegister {
            > .d-flex {
              gap: 6px;
              flex-direction: column;
              height: 100% !important;

              .a-custom-phone-verify {
                display: flex;
                align-items: center;

                bottom: 10px;
                top: auto;
              }

              .MuiFormControl-root,
              .MuiInputBase-root {
                width: 100% !important;
              }
            }
          }
        }

        .auth-login {
          .a-guest-btn {
            height: 44px;
            border-radius: 100px;
            margin: 16px 0;
          }
        }
      }

      .m-lp-selector-login {
        gap: 6px;
        font-weight: 500;
        font-size: 16px;

        margin-bottom: 12px;

        > .radio-container {
          position: relative;

          width: max-content;
          height: max-content;

          gap: 6px;

          padding: 4px 8px;

          @include getData(border, gray6, null, 0.5px solid);
          border-radius: 100px;

          &.active {
            border: none;
            @include getData(background-color, bg);

            label {
              @include getData(color, btn);
            }
          }

          input[type='radio'] {
            opacity: 0;

            width: 100%;
            height: 100%;

            position: absolute;
            z-index: 1;
            left: 0;
            top: 0;
            cursor: pointer;

            &:checked + label::before {
              @include getData(background, light);
              @include getData(box-shadow, btn, null, inset 0px 0px 0px 6px);
            }

            + label::before {
              border-radius: 50%;
              border: 1px solid #e3e5e8;
              content: '';
              background: #fff;
              display: inline-block;
              vertical-align: middle;
              width: 24px;
              height: 24px;
              padding: 2px;
              margin-right: 6px;
              text-align: center;
            }
          }

          label {
            position: relative;
            @include getData(color, gray2);
            font-weight: 400;
            white-space: nowrap;
          }
        }
      }

      .a-lp-reset-password-modal {
        input {
          width: 100%;
          height: 44px;
        }

        p {
          text-align: left;
        }
        .modal-warn {
          z-index: 9998 !important;
        }
        .modal-check-email {
          h5 {
            text-align: left;
          }

          span.fw-600 {
            font-weight: 600;
          }
        }
        .modal-content {
          width: 350px;
          button {
            margin: 1rem 0 0 0;
            color: white;
            border: 1px solid transparent !important;
            &.disabled {
              background-color: rgba(76, 76, 76, 0.2) !important;
              cursor: default;
            }
          }
        }
        .a-auth-login-fields {
          width: 100%;
        }
        .modal-entry-text {
          margin-top: 1.5rem;
          text-align: center;
          @include getData(color, color2);
          span {
            cursor: pointer;
          }
        }
        .modal-hr {
          width: 100%;
        }
        .modal-faq {
          width: max-content;
          height: max-content;
          font-weight: 500;
          font-size: 16px;
          line-height: 140.62%;
          color: #4f4f4f;
          background-color: transparent !important;

          &:hover {
            background-color: transparent !important;
            @include getData(color, btn, !important);
          }
          img {
            width: 24px;
            height: 24px;
            margin: 0 8px 0 0;
          }
        }
      }

      .m-lp-title {
        &.verifyEmail {
          h4 {
            @include getData(color, gray3);
            font-size: 18px;
            font-weight: 400;
          }
          h5 {
            @include getData(color, gray1);
            font-size: 14px;
            font-weight: 400;
          }
        }
        > .m-lp-referAuth {
          justify-content: center;
          align-items: center;
          text-align: center;
          font-size: 14px;
          @include getData(color, gray3);
          font-weight: 500;

          a {
            width: max-content;
            margin-top: 4px;
            @include getData(color, btn);
          }
        }
      }

      .a-lp-reset-password-modal > div > span {
        font-size: 16px;
        @include getData(color, btn);
      }
    }

    .m-lp-verify {
      h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;

        @include getData(color, gray2);
        font-size: 12px;
      }
      button {
        &.resend-btn {
          padding: 0;
          border: 0;

          @include getData(color, btn);
          font-weight: 700;
          text-decoration: underline;
          font-size: 12px;
        }

        &.submit-btn {
          margin: 16px 0;

          height: 44px;
          width: 100%;
          @include btnMobilePrimary;
        }
      }
    }

    .m-or-divider {
      width: 100%;

      margin-bottom: 16px !important;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      @include getData(color, gray4);
      font-weight: 400;
      font-size: 12px;

      > hr {
        @include getData(color, gray4);
        width: 109px;
        height: 1px;
      }
    }
  }

  .m-back-btn-wrapper {
    @include getData(background-color, light);

    a {
      width: max-content;
      gap: 10px;

      @include getData(background-color, light, !important);

      @include getData(color, gray2);
      font-weight: 700;

      padding: 14px 8px;
      border: none;
      border-radius: 0;

      // &.navbar-hide {
      //     width: 100%;
      //     position: fixed;
      //     z-index: 10;
      // }
    }
  }

  .m-submit-container {
    position: fixed;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    gap: 10px;

    width: 100%;

    @include getData(background-color, light);

    padding: 16px;

    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    @include getData(box-shadow, mobileBottomNavShadow);

    > button {
      height: 44px;
      width: 100%;
      @include btnMobilePrimary;
    }

    > p.m-tc-register {
      display: flex;
      gap: 4px;
      justify-content: center;

      margin: 0;

      @include getData(color, gray3);
      font-size: 12px;
      font-weight: 400;

      a {
        @include getData(color, link);
        text-decoration: underline !important;
      }
    }
  }
}
