$btnDisabled: rgba(76, 76, 76, 0.2);

.common-button {
  background-color: var(--default-bg);
  border-width: var(--border-width);
  border-style: solid;
  border-color: var(--default-border);
  color: var(--default-text);
  padding: 0 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;

  &--full {
    width: 100%;
    border-radius: 6px;
  }

  &--md {
    min-width: 140px;
    line-height: 40px;
    border-radius: 4px;
  }

  &--hasIcon {
    justify-content: space-between;
  }

  &__icon {
    margin-left: 28px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: var(--hover-bg);
    border-color: var(--hover-border);
    color: var(--hover-text);
  }

  &:focus,
  &:active {
    background-color: var(--pressed-bg);
    border-color: var(--pressed-border);
    color: var(--pressed-text);
  }

  &:disabled {
    background-color: var(--disabled-bg) !important;
    border-color: var(--disabled-border) !important;
    color: var(--disabled-text) !important;

    &:hover,
    &:focus,
    &:active {
      background-color: var(--disabled-bg) !important;
      border-color: var(--disabled-border) !important;
      color: var(--disabled-text) !important;
    }
  }

  &--primary {
    --border-width: 0;
    @include getData(--default-bg, btn);
    @include getData(--default-border, btn);
    @include getData(--default-text, light);
    @include getData(--hover-border, btnHover);
    @include getData(--hover-bg, btnHover);
    @include getData(--hover-text, light);
    @include getData(--pressed-border, btnPressed);
    @include getData(--pressed-bg, btnPressed);
    @include getData(--pressed-text, light);
    @include getData(--disabled-border, btnDisabled);
    @include getData(--disabled-bg, btnDisabled);
    @include getData(--disabled-text, light);

    @include media(tablet, mobile) {
      @include btnMobilePrimary;
    }
  }

  &--primary-outline {
    --border-width: 1px;
    @include getData(--default-border, btn);
    --default-bg: transparent;
    @include getData(--default-text, btn);
    @include getData(--hover-border, btnHover);
    --hover-bg: transparent;
    @include getData(--hover-text, btnHover);
    @include getData(--pressed-border, btnPressed);
    --pressed-bg: transparent;
    @include getData(--pressed-text, btnPressed);
    @include getData(--disabled-border, btnDisabled);
    --disabled-bg: transparent;
    @include getData(--disabled-text, btnDisabled);

    @include media(tablet, mobile) {
      @include btnMobileSecondary;
    }
  }
}

.common-button__gap {
  margin-bottom: 14px;
}
