// material ui select styles

.PrivateNotchedOutline-root-2 {
  top: 0 !important;
}

.MuiOutlinedInput-notchedOutline {
  box-shadow: none !important;
  border: none !important;

  display: flex !important;
  align-items: center !important;
}

.MuiInputBase-root,
.MuiOutlinedInput-root,
.MuiInputBase-formControl,
.MuiInputBase-marginDense,
.MuiOutlinedInput-marginDense {
  display: flex !important;
  align-items: center !important;
}

.MuiSelect-select,
.MuiSelect-selectMenu,
.MuiSelect-outlined,
.MuiInputBase-input,
.MuiOutlinedInput-input,
.MuiInputBase-inputMarginDense,
.MuiOutlinedInput-inputMarginDense {
  display: flex !important;
  align-items: center !important;
}

.MuiSelect-root {
  height: 39px !important;
  padding: 0 12px !important;

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  @include getData(background-color, light, !important);
  border: transparent 1px solid !important;

  display: flex !important;
  align-items: center !important;

  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  @include getData(color, gray2, !important);
  &:focus,
  &:active {
    @include getData(background-color, gray6, !important);
    @include getData(border, gray3, !important, 1px solid);
    box-shadow: none !important;
  }
}

// arrow icon
.MuiSelect-icon {
  top: auto !important;
}

.MuiSelect-iconOutlined {
  right: 0 !important;
}

// select icon
.MuiListItemIcon-root {
  height: 18px !important;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.MuiSvgIcon-root {
  width: 18px !important;
  height: 18px !important;
  margin: 0 10px 0 0 !important;
  padding: 0 !important;

  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

// menu
.MuiPaper-root {
  .MuiList-padding {
    padding: 10px 20px;
  }
}

.MuiPaper-rounded {
  max-height: 202px !important;
  @include getData(background, light);
  border-radius: 10px !important;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;

  @include customScrollbar();
}

.MuiList-root {
  padding: 10px !important;
  // select menu li
  .MuiListItem-root {
    @include getData(font-family, bu-font-family, !important);
    margin: 0 0 8px 0;
    padding: 4px 10px !important;
    border-radius: 4px;
    &:hover {
      @include getData(background-color, color2, !important);
      @include getData(color, light, !important);

      .MuiListItemIcon-root {
        color: white !important;
      }
    }
    &:focus {
      @include getData(background-color, color2, !important);
      @include getData(color, light, !important);

      .MuiListItemIcon-root {
        @include getData(color, color2, !important);
      }
    }
    &:last-of-type {
      margin: 0;
    }
  }
}

.MuiListItem-root.Mui-selected,
.Mui-selected {
  @include getData(background-color, bg, !important);
  @include getData(font-family, bu-font-family, !important);
  @include getData(color, color2, !important);
  &:focus,
  &:active {
    @include getData(background-color, bg, !important);

    @include getData(color, color2, !important);
  }
}
