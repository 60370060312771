$mobile-xs-max: 320px;
$mobile-xs-maxWidth: $mobile-xs-max - 0.02px;
$mobile-sm-max: 376px;
$mobile-sm-maxWidth: $mobile-sm-max - 0.02px;
$mobile-md-max: 551px;
$mobile-md-maxWidth: $mobile-md-max - 0.02px;
$mobile-lg-max: 768px;
$mobile-lg-maxWidth: $mobile-lg-max - 0.02px;
$tablet-max: 992px;
$tablet-maxWidth: $tablet-max - 0.02px;
$mobile-max: 768px;
$mobile-maxWidth: $mobile-max - 0.02px;

@mixin mobile_xs {
  @media screen and (max-width: $mobile-xs-maxWidth) {
    @content;
  }
}

@mixin mobile_sm {
  @media screen and (min-width: $mobile-xs-max) and (max-width: $mobile-sm-maxWidth) {
    @content;
  }
}

@mixin mobile_md {
  @media screen and (min-width: $mobile-sm-max) and (max-width: $mobile-md-maxWidth) {
    @content;
  }
}

@mixin mobile_lg {
  @media screen and (min-width: $mobile-md-max) and (max-width: $mobile-lg-maxWidth) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: $mobile-maxWidth) {
    @content;
  }
}

@mixin tablet {
  @media screen and (min-width: $mobile-lg-max) and (max-width: $tablet-maxWidth) {
    @content;
  }
}

@mixin desktop() {
  @media (min-width: $tablet-max) {
    @content;
  }
}

@mixin media($keys...) {
  @each $key in $keys {
    @if ($key == mobile) {
      @include mobile {
        @content;
      }
    } @else if ($key == mobile_xs) {
      @include mobile_xs {
        @content;
      }
    } @else if ($key == mobile_sm) {
      @include mobile_sm {
        @content;
      }
    } @else if ($key == mobile_md) {
      @include mobile_md {
        @content;
      }
    } @else if ($key == mobile_lg) {
      @include mobile_lg {
        @content;
      }
    } @else if ($key == tablet) {
      @include tablet {
        @content;
      }
    } @else if ($key == desktop) {
      @include desktop {
        @content;
      }
    }
  }
}
