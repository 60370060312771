$asideWidth: 250px;

.a-bf-main {
  .container {
    .a-bf-side {
      width: 100%;
      margin-top: 30px;
      padding: 0 0 0 4px;
      position: relative;
      border-radius: 20px;
      z-index: 2;

      .discountWarning {
        margin-top: 10px;
        @include getData(color, color2);
      }

      @media screen and (min-width: 768px) and (max-width: 1199px) {
        width: 865px;
        padding: 0;
      }
      @media screen and (min-width: 1400px) {
        padding: 0 0 0 16px;
      }
      .a-bf-cart {
        width: $asideWidth;
        height: calc(92vh - 110px);

        position: sticky;
        z-index: 1030;
        top: 110px;
        @media screen and (min-width: 768px) and (max-width: 1199px) {
          width: 100%;
          height: max-content;
        }
        .a-bf-cart-title {
          width: 100%;
          height: 44px;
          margin-bottom: 2px;
          padding: 10px;

          @include getData(background-color, light);
          @include getData(box-shadow, pagesShadow, !important);

          display: flex;
          align-items: center;
          justify-content: flex-start;
          h4 {
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
          }
          img {
            width: 24px;
            height: 24px;
            margin-right: 15px;
          }
        }
        .a-b2b-bf-cart-content {
          padding: 0 !important;

          @include getData(background-color, light);
          @include getData(box-shadow, pagesShadow, !important);
        }
      }
      .a-b2b-bf-cart-wallet {
        height: 103px;
        margin: 0 0 33px 0;
        div {
          width: 100%;
          padding: 19px 19px 23px 19px;

          display: flex;
          align-items: center;
          justify-content: space-between;
          h6 {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray2);
          }
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            @include getData(color, gray1);
          }
        }
      }
      .a-b2b-bf-cart-bonus {
        height: max-content;
        margin: 0 0 33px 0;
        div {
          padding: 16px 10px 20px 10px;
          h6 {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray2);
          }
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            @include getData(color, gray1);
          }
          ul {
            padding: 0;
            li {
              padding: 2px 4px 2px 4px;
              margin: 0 0 2px 0;

              border-radius: 4px;

              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
          hr {
            width: calc(100% - 38px);
            height: 1px;
            margin: 10px 19px;

            @include getData(background-color, gray6);
          }
          h4 {
            margin: 0 0 8px 0 !important;

            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray1);
            a {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, btn);
            }
            &:last-of-type {
              margin: 0;
            }
          }
        }
      }
      .a-bf-cart-order {
        height: max-content;
        max-height: calc(92vh - 110px);

        position: sticky;
        top: 110px;
        @media screen and (min-width: 768px) and (max-width: 1199px) {
          max-height: none;
          position: static;
        }
        .orderItem__active-line {
          height: 2px;
          width: 100%;
          @include getData(background-color, btn);
        }
        #orderItem {
          height: max-content;
          max-height: calc(93vh - 193px);
          border-radius: 20px 20px 0 0;

          @include getData(background, light);
          // @include getData(box-shadow, pagesShadow, !important);
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);

          overflow-x: hidden;
          overflow-y: overlay;

          @include customScrollbar;
          &::-webkit-scrollbar-track {
            // @include getData(background, light);
            background: transparent;
          }
          @media screen and (min-width: 768px) and (max-width: 1199px) {
            height: max-content;
            max-height: 848px;
          }

          .a-bf-cart-active {
            width: 250px !important;
            margin: 0;
            padding: 16px 20px;

            @include getData(background, light);
            // @include getData(box-shadow, pagesShadow, !important);

            .yourOrderItem_newOrderItem {
              @include getData(background, light);
              border-radius: 0 10px 10px 0;
              width: 60px;
              height: 20px;

              position: absolute;
              top: 10.5px;
              left: 0;
              z-index: 1;
              &::after {
                display: block;
                content: '';
                @include getData(background, orderNewBorder);
                width: calc(100% + 2px);
                height: calc(100% + 2px);
                position: absolute;
                border-radius: 0 10px 10px 0;
                top: -1px;
                left: -1px;
                z-index: 0;
              }
              mark {
                border-radius: 0 10px 10px 0;
                background: #fff;
                width: calc(100% - 1px);
                height: 100%;
                position: absolute;
                top: 0;
                left: 1px;
                z-index: 1;
                div {
                  // @include getData(background, orderNewColor);
                  background-clip: text;
                  -webkit-background-clip: text;
                  @include getData(color, btn);
                  // color: transparent;

                  font-style: normal;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 14px;
                  text-align: center;
                }
              }
            }
            .a-bf-cart-image {
              width: 210px;
              height: 159px;
              margin: 0 0 16px 0;

              position: relative;

              display: flex;
              align-items: center;
              justify-content: center;
              .a-bf-cart-thumbnail {
                width: 210px;
                height: max-content;
                margin: 46px 0 14.5px 0;
                border-radius: 4px;

                object-fit: contain;
                &.a-bf-cart-productImgUrl {
                  width: 155px !important;
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                }
                &.a-bf-cart-productImgUrl-digital {
                  width: 106.3px !important;
                  position: absolute;
                  z-index: 2;
                  bottom: 10px;
                  left: 20px;
                  margin: 0 !important;
                  // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
                  box-shadow: 0px 2.9612696170806885px 18.5079345703125px 0px #00000014;
                }
                &.a-bf-cart-templateImgUrl-digital {
                  width: 79.84px !important;
                  position: absolute;
                  transform: matrix(0.98, 0.2, -0.19, 0.98, 0, 0);
                  right: 20px;
                  bottom: 20px;
                  margin: 0 !important;
                  // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
                  box-shadow: 0px 2.9612696170806885px 18.5079345703125px 0px #00000014;
                }
              }
            }
            // h2
            .yourOrderItem_cardName {
              margin: 0 0 2px 0 !important;

              font-style: normal;
              font-weight: bold;
              font-size: 18px;
              line-height: 21px;
              @include getData(color, gray1);
            }
            // h3
            .yourOrderItem_currency {
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 19px;
              @include getData(color, gray1);

              display: flex;
              align-items: center;
              em {
                margin: 0 0 0 2px !important;

                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray1);
              }
            }
            .a-bf-cart-discount {
              margin: 2px 0 0 0;
              i {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, color2);

                display: flex;
                align-items: center;
                &:before {
                  content: '';
                  width: 0;
                  height: 0;
                  margin: 0 6px 0 0;
                  border-style: solid;
                  border-width: 9.33px 6px 0 6px;
                  border-color: #ff6c62 transparent transparent transparent;
                }
              }
              h5 {
                margin: 4px 0 0 0 !important;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray1);

                display: flex;
                align-items: center;
                em {
                  margin: 0 0 0 2px !important;

                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, gray1);

                  display: block;
                }
              }
            }

            .a-bf-cart-amount-quantity {
              margin: 8px 0 12px 0;
              display: flex;
            }

            .a-bf-lg-hr {
              display: none;
            }
            .yourOrderItem_giftTo {
              margin: 12px 0 0 0;
              hr {
                margin: 12px 0;
              }
            }
            .yourOrderItem_quantity {
              width: 50%;

              // margin: 16px 0 12px 0;
              hr {
                margin: 16px 0 0 0;
              }
            }

            .yourOrderItem_amount {
              width: 50%;
              font-size: 16px;

              // margin: 16px 0 12px 0;
              hr {
                margin: 16px 0 0 0;
              }
            }

            .yourOrderItem_deliveryBy {
              margin: 12px 0 0 0;
            }

            .yourOrderItem_sendingTime {
              margin: 12px 0 0 0;

              hr {
                margin: 0 0 12px 0;
              }

              &Detail {
                font-size: 14px;
              }
            }

            .yourOrderItem_deliveryInfo {
              margin: 12px 0 0 0;
            }

            .yourOrderItem_normalLetter {
              margin: 12px 0 0 0;
            }

            div {
              margin: 0 0 4px 0;
              p {
                margin: 0 0 4px 0;

                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);
              }
              hr {
                @include getData(background-color, gray4);
              }
              // h5: with icon
              h5 {
                margin: 0 0 4px 0;

                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, gray1);
                img {
                  width: 24px;
                  height: 24px;
                  margin: 0 4px 0 0;
                }
                &:last-of-type {
                  margin: 0;
                }
              }
              // h6: active without icon & normal with icon & hover with icon
              h6 {
                margin: 0;

                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, gray1);
                img {
                  display: none;
                }
                &:last-of-type {
                  margin: 0;
                }
              }
              // h4: active shown & normal hidden & hover shown
              h4 {
                margin: 0 !important;

                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, gray1);
              }
              // span: active shown & normal hidden & hover shown with icon
              span {
                margin: 0 !important;

                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
                @include getData(color, gray1);
                img {
                  width: 24px;
                  height: 24px;
                  margin: 0 4px 0 0;
                }
              }
            }
            .a-bf-cart-btns {
              width: 100%;
              height: max-content;
              margin: 12px 0 32px 0;

              button {
                width: 48px;
                height: 40px;
                margin: 0 16px 0 0;
                padding: 8px 12px;

                @include getData(background, light);
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
                border-radius: 6px;

                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray1);
                &:last-of-type {
                  margin: 0;
                }
                img {
                  width: 24px;
                  height: 24px;
                  margin: 0;
                }
              }
            }
            .a-bf-cart-addition {
              //   margin: 36px 0 0 0;
              padding: 0;
              i {
                width: 100%;
                height: 14.5px;

                @include getData(border-top, gray4, null, 1px dashed);

                display: block;
              }
              img {
                width: 36px;
                height: 36px;
                margin: 0 8px 0 0;
              }
              div {
                width: 210px;
                margin: 0 !important;
                padding: 10px !important;

                @include getData(background-color, gray6);
                border-radius: 10px;
                div {
                  padding: 0 !important;
                  h6 {
                    margin: 0 0 4px 0 !important;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    @include getData(color, gray1);
                  }
                  h5 {
                    margin: 0 !important;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    @include getData(color, gray1);
                  }
                }
              }
              button {
                margin: 12px 0 0 0;
              }
            }
            @media screen and (min-width: 768px) and (max-width: 1199px) {
              width: 100% !important;

              position: relative !important;
              .a-bf-cart-lg-main {
                display: flex;
                align-items: center;
              }
              .a-bf-cart-lg-detail {
                width: 635px;
              }
              .a-bf-cart-thumbnail {
                width: 95.45px;
                height: max-content;
                margin: 0 14.5px 0 0;

                border-radius: 4px;
              }
              .a-bf-cart-lg-side {
                margin: 0 0 12px 0;

                display: flex;
                div {
                  margin: 0 24px 0 0;
                  &:last-of-type {
                    margin: 0;
                  }
                }
              }
              .a-bf-cart-btns {
                width: 715px;
                margin: 0;

                position: absolute;
                top: 0;

                justify-content: flex-end;
              }

              .a-bf-cart-addition {
                margin-top: 12px;
              }
            }
          }
          .a-bf-cart-normal {
            width: 250px !important;
            margin: 0;
            padding: 20px 20px 0 20px;

            @include getData(background, light);
            @include getData(box-shadow, pagesShadow, !important);
            z-index: 2000 !important;

            @media screen and (min-width: 768px) and (max-width: 1199px) {
              position: relative !important;
            }
            &:hover {
              @include getData(background-color, gray6);
            }
            .a-bf-cart-lg-detail {
              width: 100%;
            }
            .yourOrderItem_cardMadeFee_p {
              display: block !important;
              font-size: 12px !important;
              margin-right: 5px;
              margin-bottom: 0 !important;
            }
            .yourOrderItem_newOrderItem {
              display: none !important;
            }
            .a-bf-cart-thumbnail {
              width: 50px;
              height: max-content;
              margin: 0 8px 0 0;

              border-radius: 4px;

              object-fit: contain;
              &.a-bf-cart-productImgUrl {
                width: 155px !important;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              &.a-bf-cart-productImgUrl-digital {
                width: 110px !important;
                position: absolute;
                z-index: 2;
                bottom: 10px;
                left: 10px;
                margin: 0 !important;
              }
              &.a-bf-cart-templateImgUrl-digital {
                width: 79px !important;
                position: absolute;
                transform: matrix(0.98, 0.2, -0.19, 0.98, 0, 0);
                right: 20px;
                bottom: 20px;
                margin: 0 !important;
              }
            }
            .yourOrderItem_cardName {
              margin: 0 0 2px 0 !important;

              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray1);
            }
            .yourOrderItem_currency {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray3);
              em {
                margin: 0 0 0 2px;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);
              }
            }
            .a-bf-cart-discount {
              margin: 2px 0 0 0;
              i {
                margin: 12px 0 4px 0;

                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, color2);

                display: flex;
                align-items: center;

                &:before {
                  content: '';
                  width: 0;
                  height: 0;
                  margin: 0 6px 0 0;
                  border-style: solid;
                  border-width: 9.33px 6px 0 6px;
                  border-color: #ff6c62 transparent transparent transparent;
                }
              }
              h5 {
                margin: 0 !important;
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);

                display: flex;
                align-items: center;
                em {
                  margin: 0 0 0 2px !important;

                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 14px;
                  @include getData(color, gray3);

                  display: block;
                }
              }
            }
            .a-bf-cart-discount-active {
              em {
                display: none !important;
              }
            }
            .yourOrderItem_subtotal {
              width: 70% !important;
              margin: 8px 0 16px 0 !important;
              font-style: normal;
              font-weight: 700;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray1);
            }
            .a-bf-lg-hr {
              display: none;
            }
            .yourOrderItem_quantity {
              display: none;
            }
            hr,
            p {
              display: none;
            }
            // h5: with icon
            h5 {
              margin: 0 0 4px 0 !important;

              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray1);
              img {
                width: 20.29px;
                height: 20.29px;
                margin: 0 4px 0 0;
              }
            }
            // h6: active without icon & normal with icon & hover with icon
            h6 {
              margin: 0 0 4px 0 !important;

              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              @include getData(color, gray1);

              img {
                width: 20.29px;
                height: 20.29px;
                margin: 0 4px 0 0;
              }
            }
            // h4: active shown & normal hidden & hover shown
            h4 {
              display: none !important;
            }
            // span: active shown & normal hidden & hover shown with icon
            span {
              display: none;
            }
            .a-bf-cart-btns {
              width: 100%;
              height: max-content;
              //   margin: -18px 0 0 0;

              button {
                width: max-content;
                height: 40px;
                margin: 0 16px 0 0;
                padding: 8px 12px;

                @include getData(background, light);
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
                border-radius: 6px;

                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray1);

                &:last-of-type {
                  margin: 0;
                }

                img {
                  width: 24px;
                  height: 24px;
                  margin: 0;
                }
              }
            }
            .a-bf-cart-addition {
              //   margin: 36px 0 0 0;
              padding: 0;

              i {
                width: 100%;
                height: 14.5px;

                border-top: 1px dashed #bdbdbd;

                display: block;
              }

              img {
                width: 36px;
                height: 36px;
                margin: 0 4px 0 0;
              }

              div {
                width: 210px;
                margin: 0 !important;
                padding: 10px !important;

                @include getData(background-color, gray6);
                border-radius: 10px;

                div {
                  padding: 0 !important;

                  h6 {
                    margin: 0 0 4px 0 !important;

                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 14px;
                    @include getData(color, gray1);
                  }

                  h5 {
                    margin: 0 !important;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 16px;
                    @include getData(color, gray1);
                  }
                }
              }
              button {
                margin: 12px 0 0 0;
              }
            }
            @media screen and (min-width: 768px) and (max-width: 1199px) {
              width: 100% !important;

              .yourOrderItem_cardName {
                margin: 0 0 2px 0 !important;

                font-style: normal;
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray1);
              }

              .a-bf-cart-lg-main {
                display: flex;
                align-items: center;
              }

              .a-bf-cart-thumbnail {
                width: 95.45px;
                height: max-content;
                margin: 0 14.5px 0 0;
              }

              .a-bf-lg-hr {
                width: 100%;
                height: 1px;

                @include getData(background-color, gray4);

                display: block;
              }

              .a-bf-cart-lg-detail {
                width: 571px;
              }

              .a-bf-cart-btns {
                width: 683px;
                margin: 0;

                position: absolute;
                top: 0;

                justify-content: flex-end;
              }
              .a-bf-cart-addition {
                margin: 10.5px 0 0 0;
              }
            }
          }
        }

        .yourOrder_totalAmount {
          width: 100%;
          height: max-content !important;
          margin: 2px 0 0 0;
          padding: 12px 10px;

          @include getData(background-color, light);
          border-radius: 0px 0px 20px 20px;
          // @include getData(box-shadow, pagesShadow, !important);
          box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);

          h6 {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray1);
          }
          h5 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, color2);
          }
        }
      }
    }
  }
}

.a-bf-cart-hover {
  width: 250px;
  height: max-content;
  padding: 20px;

  @include getData(background-color, gray1);
  border-radius: 10px;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 272px;
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    right: 45.87px;
  }
  // &::after {
  //     content: "";
  //     width: 0;
  //     height: 0;

  //     border-style: solid;
  //     border-width: 7px 0 7px 12.1px;
  //     border-color: transparent transparent transparent $gray1;

  //     position: absolute;
  //     top: 50%;
  //     right: -18px;
  // }
  .a-bf-cart-image {
    width: 180px;
    height: 164px;
    position: relative;
  }

  .yourOrderItem_newOrderItem {
    display: none !important;
  }
  .a-bf-cart-thumbnail {
    width: 180px !important;
    height: max-content !important;
    margin: 12px 0 16px 0 !important;
    border-radius: 10px;
    object-fit: contain;
  }
  .yourOrderItem_cardName {
    margin: 0 0 2px 0 !important;

    font-style: normal !important;
    font-weight: bold !important;
    font-size: 12px !important;
    line-height: 14px !important;
    @include getData(color, light, !important);
    text-align: center;
  }

  .yourOrderItem_currency {
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 2px !important;
    @include getData(color, light, !important);
    text-align: center;
    em {
      display: none;
    }
  }

  .yourOrderItem_quantity {
    display: none;
  }

  h5 {
    margin: 0 0 4px 0 !important;

    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 16px !important;
    @include getData(color, light, !important);

    img {
      width: 20.29px !important;
      height: 20.29px !important;
      margin: 0 4px 0 0 !important;
    }
  }

  h6 {
    margin: 0 0 4px 0 !important;

    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    @include getData(color, light, !important);

    img {
      width: 20.29px !important;
      height: 20.29px !important;
      margin: 0 4px 0 0 !important;
    }
  }

  .a-bf-cart-hover-hr {
    width: 100%;
    height: 1px;
    margin: 16px 0 16px 0 !important;

    display: block !important;

    @include getData(background-color, gray6, !important);
  }

  .a-bf-cart-hover-brief {
    p {
      display: none;
    }
  }

  .a-bf-cart-hover-detail {
    div {
      margin: 0 0 16px 0 !important;
      &:last-of-type {
        margin: 0;
      }
    }
  }

  .a-bf-cart-product {
    margin: 16px 0 0 0;
    padding: 0;
    i {
      display: none;
    }
    img {
      width: 36px !important;
      height: 36px !important;
      margin: 0 4px 0 0 !important;
    }
    div {
      margin: 0 !important;
      padding: 10px !important;

      @include getData(background-color, gray6, !important);
      border-radius: 10px;
      div {
        padding: 0 !important;
        h6 {
          margin: 0 0 4px 0 !important;

          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, gray1, !important);
        }
        h5 {
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          @include getData(color, gray1, !important);
        }
      }
    }
    button {
      display: none;
    }
  }
}

.a-bf-cart-btn-disabled {
  opacity: 0.1 !important;
}

.bf-card-addition-remove-btn {
  width: max-content !important;
  height: max-content !important;
  padding: 0 !important;

  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  @include btnText;

  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 16px !important;
  // @include getData(color, color2, !important);
  color: #d6180b !important;
  text-decoration: underline !important;
}

.bf-card-details-edit-btn {
  .default {
    width: 30% !important;
  }
  .width-60 {
    width: 60% !important;
  }
  .width-40 {
    width: 40% !important;
  }
  height: max-content !important;
  padding: 0 !important;
  margin: 8px 0 16px 0 !important;

  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  @include btnText;

  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;
  @include getData(color, link, !important);
  text-decoration: none !important;

  &:hover {
    @include getData(color, linkHover, !important);
  }

  &:active {
    @include getData(color, linkPressed, !important);
  }
}

.bf-card-details-delete-btn {
  .default {
    width: 30% !important;
  }
  .width-60 {
    width: 60% !important;
  }
  .width-40 {
    width: 40% !important;
  }
  height: max-content !important;
  padding: 0 !important;
  margin: 8px 0 16px 0 !important;

  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
  @include btnText;

  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 16px !important;
  @include getData(color, formControlError, !important);
  text-decoration: none !important;

  &:hover {
    @include getData(color, formControlError, !important);
  }

  &:active {
    @include getData(color, formControlError, !important);
  }
}

.a-bf-cart-discount-active {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  line-height: 14px !important;
  @include getData(color, gray3, !important);
  text-decoration: line-through !important;
  em {
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 14px !important;
    @include getData(color, gray3, !important);
  }
}

.a-bf-cart-bonus-active {
  @include getData(background-color, bg, !important);
}

.a-n-nav-mobile {
  position: relative;
}

.cart_mark_mobile {
  width: 16px;
  height: 16px;
  font-size: 10px;
  @include getData(background-color, btn);
  border-radius: 50%;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  position: absolute;
  top: -5px;
  @include getData(left, cart_mark_mobile-left);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yourOrderItem_cardMadeFee {
  h6 {
    display: inline-block;
    font-size: 12px;
  }
  em {
    display: inline-block;
    margin: 0 0 0 2px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, gray1);
  }
}

.letterFee_discout {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  @include getData(color, color2);

  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: 0;
    height: 0;
    margin: 0 6px 0 0;
    border-style: solid;
    border-width: 9.33px 6px 0 6px;
    border-color: #ff6c62 transparent transparent transparent;
  }
}

.a-bf-cart-discount-active-salesmanLetterFee {
  p,
  h6 {
    @include getData(color, gray3, !important);
  }
}

.a-n-nav-mobile {
  position: relative;
}

.cart_mark_mobile {
  width: 16px;
  height: 16px;
  font-size: 10px;
  @include getData(background-color, btn);
  border-radius: 50%;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  position: absolute;
  top: -5px;
  left: 8px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.yourOrderItem_cardMadeFee {
  h6 {
    display: inline-block;
    font-size: 12px;
  }
  em {
    display: inline-block;
    margin: 0 0 0 2px !important;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, gray1);
  }
}

.letterFee_discout {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  @include getData(color, color2);

  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: 0;
    height: 0;
    margin: 0 6px 0 0;
    border-style: solid;
    border-width: 9.33px 6px 0 6px;
    border-color: #ff6c62 transparent transparent transparent;
  }
}

.a-bf-cart-discount-active-salesmanLetterFee {
  p,
  h6 {
    @include getData(color, gray3, !important);
  }
}


.a-n-nav-mobile {
    position: relative;
}

.cart_mark_mobile {
    width: 16px;
    height: 16px;
    font-size: 10px;
    background-color: $btn;
    border-radius: 50%;
    font-style: normal;
    font-weight: normal;
    color: #fff;
    position: absolute;
    top: -5px;
    left: 8px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.yourOrderItem_cardMadeFee {
    h6 {
        display: inline-block;
        font-size: 12px;
    }

    em {
        display: inline-block;
        margin: 0 0 0 2px !important;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: $gray1;
    }
}

.letterFee_discout {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: $color2;

    display: flex;
    align-items: center;

    &:before {
        content: "";
        width: 0;
        height: 0;
        margin: 0 6px 0 0;
        border-style: solid;
        border-width: 9.33px 6px 0 6px;
        border-color: #ff6c62 transparent transparent transparent;
    }
}

.a-bf-cart-discount-active-salesmanLetterFee {

    p,
    h6 {
        color: $gray3 !important;
    }
}