.a-m-footer {
  width: 100%;
  height: max-content;
  padding-top: 60px;
  padding-bottom: 40.91px;

  @include getData(background-color, dark);
  @media screen and (min-width: 992px) and (max-width: 1399px) {
    padding-top: 125px;
  }
  @media screen and (min-width: 1400px) {
    padding-top: 95px;
  }
  hr {
    width: 100%;
    max-width: 882px;
    height: 1px;
    margin: 60px auto;

    @include getData(background-color, light);
    opacity: 0.7;
  }
  span {
    margin: auto 10px;
    font-size: 14px;
    line-height: 20px;
    @include getData(color, light);
    a {
      font-weight: bold;
      @include getData(color, blue3);
    }
    @media screen and (max-width: 767px) {
      &:last-of-type {
        margin-bottom: 4px;
      }
    }
    @media screen and (min-width: 768px) {
      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }
  .a-m-link-sm {
    img {
      width: 112.37px;
      height: 78px;

      margin-bottom: 40px;
    }
    .list-group {
      &:first-of-type {
        padding-right: 30px;
      }
      &:last-of-type {
        padding-left: 30px;
      }
      li,
      a {
        margin-bottom: 8px;
        padding: 0;

        @include getData(color, light);
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
      }
      li {
        font-weight: bold;
      }
      a:last-of-type {
        margin-bottom: 0;
      }
    }
    .list-group-item {
      background-color: transparent;
      border: none;
    }
  }
  .a-m-link-md {
    img {
      width: 112.37px;
      height: 78px;

      margin-bottom: 60px;
    }
    a {
      margin-left: 32px;
      margin-right: 32px;

      font-weight: bold;
      font-size: 14px;
      line-height: 20px;
      @include getData(color, light);
    }
  }
  .a-m-link-lg {
    flex-direction: column;
    align-items: center;
    max-width: 90%;
    a {
      img {
        width: 112.37px;
        height: 78px;
        margin: 0 0 60px 0;
      }
    }
    div {
      width: 808px;
      max-width: 100%;
      flex-wrap: wrap;
      display: flex;
      justify-content: space-between;
      a {
        font-weight: bold;
        font-size: 14px;
        line-height: 20px;
        @include getData(color, light);
        white-space: nowrap;
      }

      @media screen and (max-width: 767px) {
        a {
          font-weight: normal;
          margin-bottom: 8px;
        }
        width: 300px;
        display: grid;
        grid-template-columns: repeat(2, auto);
      }
    }
  }

  .a-m-copyright {
    gap: 1.5rem;
    justify-content: center;
    align-items: center;
    span {
      margin: 0;

      @media screen and (max-width: 320px) {
        display: flex;
        flex-direction: column;
      }
    }
    flex-direction: column-reverse;
    gap: 0.5rem;
    // @include media(mobile_xs, mobile_sm) {
    //     flex-direction: column-reverse;
    //     gap: 0.5rem
    // }
  }

  .a-m-contact {
    margin-bottom: 60px;
    img {
      width: 42px;
      height: 42px;
      margin: 11.25px;
      @media screen and (max-width: 767px) {
        margin: 10px;
      }
    }
  }
  @media screen and (width: 320px) {
    hr {
      width: 260px;
    }
  }
  @media screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
    hr {
      max-width: 346px;
    }
    .a-m-contact {
      img {
        width: 40px;
        height: 40px;

        margin: 0;
      }
      a {
        margin: 0 10px;
        &:first-of-type {
          margin-left: 0;
        }
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    padding-top: 60.43px;
    padding-bottom: 40.48px;
    hr {
      max-width: 648px;
    }
  }
}
