.a-bf-delivery {
  label,
  h5,
  h6,
  p {
    @include getData(color, gray2);
  }
  input {
    &::placeholder {
      @include getData(color, gray4);
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }
  }
  select {
    cursor: pointer;
  }
  section {
    &:first-of-type {
      padding: 10px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 16px;
      }
      h4 {
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        @include getData(color, gray4);
      }
    }
    &:last-of-type {
      padding: 0;
      .a-bf-info {
        width: 663px;
        height: max-content;
        padding: 20px 20px 20px 0;
        .a-bf-sender {
          ul {
            border: none;
          }
          .a-bf-navtab-tab {
            width: 176px;
            height: 120px;

            @include getData(background-color, gray6);
            border: none;
            border-bottom: none;

            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, gray3);
            text-align: center;

            position: relative;
            z-index: 2 !important;
            &:hover,
            &:focus,
            &:active {
              outline: none;
              border: none !important;
              border-bottom: none;
            }
            img {
              width: 60px;
              height: 60px;
              margin: 0 0 6px 0;
            }
          }

          .a-bf-navtab-content {
            margin-bottom: 19.5px;
            padding: 23.5px 20px 25px 20px;

            @include getData(background, navTab);
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.2);
            border-radius: 0 4px 4px 4px;

            border: none;

            position: relative;
            z-index: 1;

            .a-bf-guest {
              label {
                margin-bottom: 6px;

                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
              }
              input {
                height: 44px;
                padding-top: 10px;
                padding-bottom: 10px;
              }
              .col-6 {
                &:first-of-type {
                  padding-right: 6px;
                }
                &:last-of-type {
                  padding-left: 6px;
                }
              }
            }
            // whitelisted
            .a-bf-register-whitelisted,
            .a-bf-login-whitelisted {
              section {
                padding: 0;
                &:first-of-type {
                  // fb google apple button groups
                  .col-6 {
                    button {
                      width: 100%;
                      height: 54px;
                      margin-bottom: 24px;
                      padding: 15px 0;

                      @include getData(box-shadow, btns1Shadow);
                      border-radius: 100px;

                      border: none;

                      font-weight: 500;
                      font-size: 16px;
                      line-height: 19px;
                      img {
                        width: 24px;
                        height: 24px;
                        margin-right: 15px;
                      }
                    }
                    &.facebook-btn {
                      padding-right: 12px;
                      button {
                        @include getData(background-color, facebookColor);

                        @include getData(color, light);
                      }
                    }
                    &.google-btn {
                      padding-left: 12px;
                      button {
                        @include getData(background-color, light);

                        color: rgba(0, 0, 0, 0.54);
                      }
                    }
                    &.apple-btn {
                      padding-left: 6px;
                      padding-right: 12px;
                      button {
                        @include getData(background-color, black);

                        @include getData(color, light);
                      }
                    }
                    &.sso-btn {
                      padding-left: 12px;
                      margin-top: 0;
                      button {
                        @include getData(background-color, btn);

                        color: #fff;
                      }
                    }
                  }
                }
                &:last-of-type {
                  margin-top: 24px;
                  label {
                    margin-bottom: 6px;
                  }
                  input {
                    width: 100%;
                    height: 44px;
                    padding-top: 10px;
                    padding-bottom: 10px;
                  }
                }
              }
              h6 {
                padding-top: 16px;
                margin-bottom: 8px !important;

                @include getData(color, gray4);
                text-align: center;
                font-weight: 500;
                font-size: 12px;
                line-height: 14px;

                position: relative;
                &:before,
                &:after {
                  content: '';
                  width: 109px;
                  height: 1px;

                  @include getData(background-color, gray4);

                  position: absolute;
                  top: calc(50% + 8px);
                  left: 37%;
                  transform: translate(-50%, -50%);
                }
                &:after {
                  left: 63%;
                }
              }
            }
            .a-bf-register-whitelisted {
              section {
                &:last-of-type {
                  .col-6 {
                    &:first-of-type {
                      padding-right: 6px;
                    }
                  }
                  .col-12 {
                    margin-top: 12px;
                    margin-bottom: 24px;
                    font-weight: 500;
                  }
                  .a-bf-password-input {
                    img.password-eye {
                      width: 16px;
                      height: 16px;
                      margin: 0;

                      position: absolute;
                      right: 11.5px;
                      bottom: 11.5px;

                      cursor: pointer;
                    }
                  }
                  .a-bf-pwd-rule {
                    width: 50%;
                    margin-top: 12px;
                    @include getData(color, gray4);

                    img {
                      margin-right: 12px;
                    }
                  }
                  .a-bf-pwd-rule-active {
                    @include getData(color, color2);
                  }
                  .a-bf-resend {
                    @include getData(color, color2, !important);
                    padding-left: 8px;
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
            }
            .a-bf-login-whitelisted {
              section:last-of-type {
                .col-12:last-of-type {
                  margin-top: 12px;
                  img.password-eye {
                    width: 16px;
                    height: 16px;
                    margin: 0;

                    position: absolute;
                    right: 11.5px;
                    bottom: 11.5px;

                    cursor: pointer;
                  }
                }
              }
            }
            // member
            .a-bf-register-member,
            .a-bf-login-member {
              label {
                margin-bottom: 6px;
              }
              input {
                width: 100%;
                height: 44px;
                padding-top: 10px;
                padding-bottom: 10px;
              }
            }
            .a-bf-register-member {
              div {
                &:first-of-type {
                  padding-right: 6px;
                }
                &:nth-of-type(2) {
                  padding-left: 6px;
                  img {
                    width: 16px;
                    height: 16px;
                    margin: 0;

                    position: absolute;
                    right: 11.5px;
                    bottom: 11.5px;

                    cursor: pointer;
                  }
                }
                &:nth-of-type(3) {
                  margin-top: 12px;
                  img {
                    width: 16px;
                    height: 16px;
                    margin: 0;

                    position: absolute;
                    right: 11.5px;
                    bottom: 31.5px;

                    cursor: pointer;
                  }
                }
              }
            }
            .a-bf-login-member {
              section {
                padding: 0;
                &:first-of-type {
                  div:last-of-type {
                    margin-top: 12px;
                    img {
                      width: 16px;
                      height: 16px;
                      margin: 0;

                      position: absolute;
                      right: 11.5px;
                      bottom: 11.5px;

                      cursor: pointer;
                    }
                  }
                }
                &:last-of-type {
                  hr {
                    width: 528px;
                    height: 1px;
                    margin: 22px 10px !important;
                  }
                  h4 {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                    @include getData(color, btn);
                    img {
                      width: 24px;
                      height: 24px;
                      margin-right: 12px;
                    }
                    span {
                      margin-left: 12px;
                      font-weight: bold;
                      font-size: 24px;
                      line-height: 28px;
                      @include getData(color, btn);
                    }
                  }
                }
              }
            }
            .a-bf-navtab-content-btn {
              width: 100%;
              height: 40px;
              margin: 12px 0 0 0;
              padding: 10px 10px 10px 15px;

              @include getData(background-color, btn);
              border-radius: 4px;

              @include getData(color, light);
              font-weight: bold;
              font-size: 14px;
              line-height: 16px;
              img {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
        .a-bf-form {
          width: 100%;
          height: max-content;
          padding: 20px;

          @include getData(background, light);
          @include getData(box-shadow, pagesShadow, !important);
          border-radius: 4px;
          hr {
            width: 528px;
            height: 1px;
            margin: 30px 10px;
            position: static;
          }
          h5 {
            margin-bottom: 20px !important;

            @include getData(color, gray2);
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
          }
          mark {
            background-color: transparent;
          }
          .a-b2b-bf-physical-receiverInfo {
            .a-form-control-radio {
              display: flex;
              padding-top: 10px;
              padding-bottom: 10px;

              label {
                  @include getData(color, gray3, !important);
              }

              input {
                  @include getData(border, gray3, !important, 1px solid);
              }
            }

            .check-active {
                input {
                  @include getData(border, color2, !important, 1px solid);
                  &:checked[type='radio'] {
                    &:checked[type="radio"] {
                      background-image: url("/img/B2B_loginAndRegister/icon/radius.svg") !important;
                    }
                }

                label {
                  @include getData(color, gray2, !important);
                }
              }
            }
          }
        }
        .a-form-control {
          margin-top: 12px;
          margin-bottom: 12px;
          .receiverCity-selector {
              position: relative;
              input {
                  width: 100%;
                  outline: none;
              }
              .receiverCity-list {
                  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25) !important;
                  border-radius: 10px !important;
                  position: absolute;
                  width: 100%;
                  z-index: 2;
                  @include getData(background, light);
                  max-height: 415px;
                  overflow-x: hidden;
                  overflow-y: hidden;
                  .receiverCity-li {
                      &:hover {
                          @include getData(background-color, lightBg);
                          @include getData(color, color2);
                      }
                  }
                  .receiverCity-li-selected {
                      @include getData(background-color, lightBg);
                      @include getData(color, color2);
                  }
              }
              
              .select-down-icon {
                  position: absolute;
                  cursor: pointer;
                  width: 11px;
                  right: 15px;
                  top: 39%;
          
                  &.rotate {
                      transform: rotate(180deg);
                  }
              }
          }
          input {
              height: 44px;
          }

          &:first-of-type {
              margin-bottom: 8px;
          }

          &:nth-of-type(7) {
              margin-bottom: 0 !important;
          }
      }

      section {
        padding: 0;

        .a-bf-deliverTypeSelector {
          margin-bottom: 10px;

          h5 {
            margin: 0 0 6px 0;

            @include getData(color, gray2);
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
          }
          h6,
          p {
            margin: 0 0 6px 0 !important;

            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray2);
            &:last-of-type {
              margin: 0 !important;
            }
          }

          .balloon-btn {
            position: relative;
            height: 100%;
          }
          .balloon-container {
            pointer-events: none;
            position: absolute;
            top: -150px;
            left: -20px;
            width: 295px;
            height: 150px;
            flex-direction: column;
            justify-content: end;
          }
          // keep display tooltip
          .balloon-true-container:hover .balloon-container {
            display: flex;
          }
          .balloon-true-container {
            position: absolute;
            width: 100%;
            bottom: -1px;
            pointer-events: auto;
            z-index: 1;
          }
          .balloon-true-container::after {
            position: absolute;
            background-image: url('/img/B2B_buyingFlow/step/tooltip_triangle.svg');
            background-repeat: no-repeat;
            content: '';
            width: 30px;
            height: 30px;
            bottom: -20px;
            left: 45%;
          }
          .balloon-content {
            width: 100%;

            .balloon-text {
              position: relative;
              padding: 14px;
              border-radius: 8px;
              margin-bottom: 10px;
              background-color: #fff;
              @include getData(box-shadow, pagesShadow);

              p {
                @include getData(color, gray2, !important);
                margin: 0;
                padding: 0;
                word-wrap: break-word;
              }
              .tooltip-br {
                content: '';
                margin: 2em;
                display: block;
                font-size: 24%;
              }
            }
            .balloon-text::after {
              position: absolute;
              border-color: #fff transparent transparent transparent;
              border-style: solid solid solid solid;

              /* 設定邊框大小可拼湊出任意形狀的三角形 */
              border-width: 10px 8px;

              bottom: -20px;
              left: 45%;

              /* 必須指定，才能顯示內容 */
              content: '';
              height: 0px;
            }
          }
          .a-bf-delivery-type {
            width: 100%;
            margin: 12px 0 0 0;

            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .form-check {
              width: 49%;
              min-width: 260px;
              height: 44px;
              padding: 0 10px 0 20px;
              margin: 0 0 8px 0;

              @include getData(background, light);
              @include getData(box-shadow, pagesShadow, !important);
              border-radius: 4px;

              cursor: pointer;

              display: flex;
              align-items: center;
              justify-content: space-between;


              input {
                margin: 0;
                border: none;

                float: right;

                cursor: pointer;

                &:focus,
                &:active {
                  box-shadow: none;
                }

                &:not(:checked) {
                  @include getData(background-color, gray6);
                  background-image: url('/img/B2B_loginAndRegister/icon/non-active.svg');
                  background-size: 12px;
                }
              }

              img {
                width: 24px;
                height: 24px;
                margin-right: 12px;
              }

              label {
                width: 100%;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                @include getData(color, gray1);
                cursor: pointer;
              }

              .btn-text-container {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;

                img {
                  width: 20px;
                  height: 20px;
                  margin-left: 5px;
                }
              }

              .btn-text {
                margin-right: 10px !important;
                max-width: 67%;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 19px;
                @include getData('color', gray1);
                cursor: pointer;
              }
            }
          }

          .a-b2b-bf-delivery-file {
            label {
              display: flex;
              align-items: center;

              span {
                min-width: 24px;
                height: 24px;
                margin: 0 12px 0 0;

                @include bgImage(url('/img/buying_flow_1_3/icon/step03/sendby/doc/normal.svg'));

                display: flex;
              }
            }
          }
        }
        &:nth-of-type(2) {
          input {
            height: 44px;
            padding: 10px;
          }
        }
        &:nth-of-type(3) {
          margin: 20px 0 0 0 !important;
          h5,
          label {
            margin-bottom: 6px !important;
          }
          h6 {
            padding-top: 16px;
            margin-bottom: 8px !important;

            @include getData(color, gray4);
            text-align: center;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;

            position: relative;
            &:before,
            &:after {
              content: '';
              width: 109px;
              height: 1px;

              @include getData(background-color, gray4);

              position: absolute;
              top: calc(50% + 8px);
              left: 37%;
              transform: translate(-50%, -50%);
            }
            &:after {
              left: 63%;
            }
          }
        }
      }
      }
      .a-bf-step3-btn {
        margin-top: 15px;

        &:lang(fr) {
          div {
            button {
              img {
                display: inline-block;
                margin-left: 8px;
              }
            }
          }
        }
        div {
          button:not(.common-button) {
            height: 40px;
            margin-bottom: 15px;
            padding: 10px 10px 10px 15px;

            @include getData(background-color, light, !important);
            border-radius: 100px;
            @include getData(border, btn, null, 1px solid);

            @include getData(color, btn);
            font-weight: bold;
            font-size: 14px;
            line-height: 16px;
            &:focus,
            &:active {
              box-shadow: none;
            }
            img {
              width: 20px;
              height: 20px;
              margin-left: 30px;
            }
            &:first-of-type {
              width: auto;
              min-width: 179px;
              margin-right: 15px;
            }
            &:last-of-type {
              width: auto;
              min-width: 159px;
            }
          }
        }

        button:not(.common-button) {
          width: auto;
          height: 40px;
          padding: 10px 10px 10px 15px;
          @include getData(background-color, btn, !important);
          border-radius: 100px;
          @include getData(color, light);
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;

          &:focus,
          &:active {
            box-shadow: none;
          }

          img {
            width: 20px;
            height: 20px;
            margin-left: 30px;
          }
        }
      }
    }
  }
}

.a-b2b-checkout-btn {
  width: 205px;
  height: 40px;
  margin-left: 61px;
  @include btnFilled;
  @include getData(background-color, btn, !important);
  border-radius: 100px;

  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 16px !important;
  @include getData(color, light, !important);

  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

.a-bf-letter {
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiSelect-root {
    display: flex;
    justify-content: space-between;
    span {
      margin-right: 24px;
      @include getData(color, color2);
    }
  }
}

.a-bf-addition {
  h5 {
    margin-bottom: 12px !important;
  }

  .a-bf-addition-wrapper {
    width: max-content;
    max-width: 600px;
    height: max-content;
    padding: 10px !important;

    @include getData(background-color, light);
    @include getData(box-shadow, pagesShadow, !important);
    border-radius: 10px;
    ul {
      // margin: 0 0 0 34px;
      li {
        width: 170px !important;
      }
    }
    .react-multiple-carousel__arrow {
      width: 44px;
      min-width: 44px;
      height: 100%;
      min-height: 100%;
      @include btnClear;
      @include getData(background-color, light, !important);
      z-index: 1;
      top: 50%;
      transform: translateY(-50%) !important;

      &:before {
        display: none;
      }

      &:after {
        content: '';
        width: 12px;
        height: 12px;
        background-image: url('/img/buying_flow_1_3/icon/step03/schedule_delivery/arrow.svg') !important;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      &:hover,
      &:focus,
      &:active {
        @include getData(background-color, light, !important);
      }

      &.react-multiple-carousel__arrow--right {
        &:after {
          top: 50%;
          right: 25%;
          transform: rotate(270deg) translate(10%, -50%);
        }
      }

      &.react-multiple-carousel__arrow--left {
        left: 0;
        &:after {
          top: 50%;
          left: 25%;
          transform: rotate(90deg) translate(-10%, -50%);
        }
      }
    }

    .react-multi-carousel-item {
      margin-left: 10px;
    }

    .a-bf-additional-product {
      min-width: 160px;
      height: 100%;
      margin: 0 10px 0 0;
      padding: 10px;
      cursor: pointer;

      @include getData(background-color, light);
      @include getData(box-shadow, pagesShadow, !important);
      border-radius: 4px;
      border: transparent 1px solid;
      &:last-of-type {
        margin: 0;
      }
      .form-check {
        width: 100% !important;
        height: max-content !important;
        padding: 0px !important;

        box-shadow: none;
        input {
          width: 16px !important;
          height: 16px !important;
          margin: 0 0 0 4px !important;

          @include getData(border, gray4, null, 1px solid);
          background-color: transparent !important;

          &:checked {
            @include getData(background-color, color2);
            border: none;
          }
        }

        p {
          width: calc(100% - 16px) !important;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          margin: 0;
          @include getData(color, gray4);
          text-align: end;
        }
      }

      div {
        position: relative;
        width: 100%;

        &.img-container {
          height: max-content;
          min-height: 160px;
        }

        img {
          width: 140px;
          height: 140px;
          margin: 10px 0;

          &.no-img {
            width: 32px;
            display: flex;
            margin: auto;
          }
        }

        button {
          width: 25px;
          height: 25px;
          padding: 0;

          background-color: transparent;
          border: none;

          position: absolute;
          top: 20px;
          right: 10px;
          &:focus,
          &:active {
            box-shadow: none;
          }
          img {
            width: 25px;
            height: 25px;
            margin: 0;
          }
        }
      }

      h5 {
        margin-bottom: 10px !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 16px !important;
        @include getData(color, gray4, !important);
      }
      h6 {
        padding: 0;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        @include getData(color, color2, !important);
        text-align: start !important;
        &::before,
        &::after {
          display: none;
        }
      }
    }
  }
}

.a-bf-delivery-sms {
  margin-top: 12px;
}

.a-bf-form-phone-input {
  width: 437px !important;
  margin: 0 0 0 160px;
  height: 44px;

  .MuiInputBase-root {
    height: 44px !important;
    border-radius: 10px !important;

    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    @include getData(color, gray4, !important);
    text-align: center !important;
    &::before {
      display: none;
    }
    input,
    fieldset {
      height: 44px !important;
      padding: 0 !important;
      border-radius: 10px !important;
      top: 0 !important;
      &::placeholder {
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 19px !important;
        @include getData(color, gray4, !important);
        text-align: center !important;
        opacity: 1 !important;
      }
    }
  }
}

#mui-component-select-phoneNumber {
  height: 44px !important;
}

// modal
#additionalProductModal {
  background-color: rgba(255, 255, 255, 0.8) !important;
  .modal-dialog {
    width: max-content;
    max-width: none;
  }
  .modal-content {
    width: max-content;
    height: max-content;
    padding: 0;

    position: relative;
    button {
      width: 30px;
      height: 30px;
      padding: 0;

      @include btnNone;
      position: absolute;
      top: 10px;
      right: 10px;

      img {
        width: 30px;
        height: 30px;
      }
    }
  }
}

// special state
.a-bf-navtab-active {
  @include getData(background, light, !important);
  box-shadow: 0px -6px 14px -6px rgba(0, 0, 0, 0.2);
  border-radius: 4px 4px 0px 0px;

  @include getData(color, color2, !important);

  border: none !important;

  position: relative;
  z-index: 2 !important;
}

.a-bf-form-active {
    @include getData(background-color, lightBg, !important);
    box-shadow: none !important;

  box-shadow: none !important;

  cursor: pointer;
  label {
    @include getData(color, color2, !important);

    opacity: 1 !important;
  }

  .form-check-input {
    @include getData(background-color, color2);
    padding: 16px;

        &:checked {
            @include getData(background-color, color2);
            background-image: url("/img/B2B_loginAndRegister/icon/active.svg");
            background-size: 20px;
            border: none !important;
            border-radius: 30px;
            box-shadow: 5px !important;
        }
    }

    .form-check-input-b2c {

        &:checked {
            // background-color: $color2;
            // background-image: url("/img/B2B_loginAndRegister/icon/active.svg");
            // background-size: 20px;
            // border: none !important;
            padding: 1px !important;
            border-radius: 0.25rem !important;
            box-shadow: none !important;
        }
    }
}

.a-bf-addition-active {
  @include getData(border, color2, !important, 1px solid);
  box-shadow: none !important;
  p {
    @include getData(color, color2, !important);
  }
}

.a-bf-addition-disabled {
  opacity: 0.4;
}

.a-b2b-bf-delivery-file-active {
  @include getData(background-color, bg, !important);
  box-shadow: none !important;

  cursor: pointer;
  label {
    @include getData(color, color2, !important);
    span {
      @include getData(background-image, documentPressed, !important);
    }
  }

  p {
    @include getData(color, color2, !important);
  }
  input {
    @include getData(background-color, color2);
    &:checked {
      @include getData(background-color, color2);
    }
  }
}

.salesman-letterPrice {
  width: 100%;
  height: 44px;
  display: flex;

  .salesman-letterPrice-percent-container {
    margin-right: 20px;
    position: relative;
    height: 100%;

    .a-bf-amount-active {
      padding: 10.5px 40px 10.5px 12px !important;
    }

    .salesman-letterPrice-btn-close {
      position: absolute;
      right: 10px;
      top: 25%;
      font-size: 16px;
      line-height: 18px;
      color: #ff6d62;
      border: 1px solid #fff;
      border-radius: 25px;
      background-color: #fff;
    }

    .salesman-letterPrice-percent-btn {
      height: 100%;
      padding: 10.5px 12px;
      background-color: #fff;
      -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      color: #333333;
      font-size: 16px;
      line-height: 19px;
    }

    .salesman-letterPrice-percent-input {
      display: inline-block;
      padding: 0 !important;
      top: 25%;
      width: 45px;
      height: 22px !important;
      outline: none !important;
      @include getData(border, gray4, null, 1px solid);
      border-radius: 3px;
      margin-left: 10px;
    }
  }

  .salesman-letterPrice-or {
    align-items: center;
    display: flex !important;
    margin-right: 20px;
  }

  .salesman-letterPrice-fixed-container {
    position: relative;
    height: 100%;

    .a-bf-amount-active {
      padding: 10.5px 40px 10.5px 12px !important;
    }

    .salesman-letterPrice-btn-close {
      position: absolute;
      right: 10px;
      top: 30%;
      font-size: 16px;
      line-height: 16px;
      color: #ff6d62;
      border: 1px solid #fff;
      border-radius: 25px;
      background-color: #fff;
    }

    .salesman-letterPrice-fixed-btn {
      white-space: nowrap;
      height: 100%;
      padding: 10.5px 12px;
      background-color: #fff;
      -webkit-box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      color: #333333;
      font-size: 16px;
      line-height: 19px;
    }

    .salesman-letterPrice-fixed-input {
      display: inline-block;
      padding: 0 !important;
      top: 25%;
      width: 45px;
      height: 22px !important;
      outline: none !important;
      @include getData(border, gray4, null, 1px solid);
      border-radius: 3px;
      margin-left: 10px;
    }
  }
}

.priceUseOriPrice {
  text-decoration: line-through;
}

.questionIcon {
  height: 100%;
  display: flex;
  align-items: center;

  img {
    margin: 0 !important;
  }
}
.balloon-container {
  display: none;
}
// show tooltip
.questionIcon:hover .balloon-container {
  display: flex !important;
}