.transfer-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  &.show {
    display: flex;
  }

  .modal-content {
    width: 316px;
    padding: 8px;
    background-color: #fff;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    h3 {
      margin: 28px 0 20px 0px !important;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 23px;
      @include getData(color, black);
    }
    h5 {
      margin: 12px 0;

      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      @include getData(color, black);
    }
    h6 {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, color2);
    }
  }

  .a-modal-btn {
    width: 100%;
    height: 42.01px;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    border-radius: 4px;
    &:first-of-type {
      margin: 32px 0 10px 0;
      @include btnFilled;
    }
  }
}
