// $mainActionLinear: #D6180B;
$mobile-filter-input-height: 44px;
$mobile-sm-content-margin-top: ceil($mobile-filter-input-height / 3);
$mobile-md-content-margin-top: ceil($mobile-filter-input-height / 2);

.m-HomePage {
  @include getData(background-color, m-HomePage-background-color, !important);
  --content-margin-top: #{$mobile-md-content-margin-top * -1};
  --slide-controls-position: #{$mobile-md-content-margin-top + 4px};
  width: 100%;
  position: relative;
  margin-top: 64px;
  @include mobile {
    --content-margin-top: #{$mobile-sm-content-margin-top * -1};
    --slide-controls-position: #{$mobile-sm-content-margin-top + 4px};
  }
  //button go-top
  > .goTop-container {
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }

  //mobile feature banner
  div.banner-feature-mobile {
    position: relative;
    width: 100%;
    height: 200px;
    @include media(tablet, desktop) {
      height: 390px;
    }

    //button slider
    .slider-button-container-mobile {
      z-index: 2;
      width: 100%;
      display: flex;
      flex-direction: row;
      position: absolute;
      gap: 4px;
      bottom: var(--slide-controls-position);
      left: 0px;
      justify-content: center;
      .button-slider {
        border: none;
        border-radius: 40px;
        width: 4px;
        height: 4px;
        padding: 0;
        background: rgba(255, 255, 255, 0.4);

        &-active {
          background: white;
          border-radius: 100px;
          border: none;
          width: 14px;
        }
      }
    }

        //banner container
        section {
            position: absolute;
            width: 100%;
            height: 100%;
            transition: opacity 500ms;
            top: 0;
            left: 0;
            
            &.inactive{
                opacity: 0;
            }

            &.active{
                z-index: 0;
                opacity: 1;
            }

            >img{
                display: flex;
                position: absolute;
                z-index: 1;
                top:50%;
                left: 50%;
                transform: translate(-50%, -50%);
                
                width:100%;
                height: 100%;
                max-width: 991px;
                
                object-fit: cover;

        &.auchan-default {
          right: 10%;
          left: auto;
          transform: translate(0, -50%);

          max-width: 60%;

          object-fit: contain;

          @media screen and (max-width: 400px) {
            right: 3%;
          }

          @media screen and (min-width: 768px) and (max-width: 875px) {
            right: 7%;
          }

                    // @media screen and (min-width: 851px) and (max-width: 850px) {
                    //     right: 6%;
                    // }

                }
            }

            >div {
                &:first-of-type {
                    position: absolute;
                    top: 0;
                    z-index: 1;
                    height: 100%;
                    padding: 17px 26px 32px 26px;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    @include bgImage('');
                }

                &.banner-text {
                    height: 100%;
                    position: relative;
                    z-index: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    padding-left: 20px;
                    padding-bottom: 40px;
                    justify-content: flex-end;
                    
                    @media screen and (max-width: 320px) {
                        bottom: 20px;
                    }
    
                    p {
                        @media screen and (max-width: 430px) {
                            font-size: 16px;
                            font-weight: 600;
                            line-height: 0;
                        }
    
                        font-size: 24px;
                        line-height: 10px;
                        color: #fff;
                        font-weight: 600;
                        margin-bottom: 0 !important;
                    }
                }
            }
        }
    }

  //filter and cards product container
  .main-content-container {
    margin-top: var(--content-margin-top);

    //container filter mobile
    > .a-m-filter-container-mobile {
      width: 100%;

      //container input filter
      > .a-m-filter-input {
        gap: 1rem;
        height: $mobile-filter-input-height;
        justify-content: space-around;
        padding: 0 16px;

        .input-container {
          position: relative;
          width: 100%;

          input {
            font-size: 16px;
            width: 100%;
            height: 100%;

            padding-left: 24px;
            padding-right: 72px;

            border: none;
            border-radius: 100px;
            box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);

            &:focus,
            &:active {
              outline: none;
            }
          }

          > .icon-container {
            display: flex;
            gap: 8px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;

            img {
              cursor: pointer;
            }
          }
        }

        //button my cards
        button {
          position: relative;
          min-width: 90px;
          width: max-content;
          font-size: 14px;
          border-radius: 100px;
          box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);
          p {
            margin: auto;
            white-space: nowrap;
            @include getData(background, color2);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .mobile-country-btn {
          padding: 10px;
          box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.2);
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      //category filter
      > .a-m-filter-category {
        word-break: keep-all;
        overflow-y: hidden;
        padding: 24px 0 12px 0;
        @include getData(text-align, a-m-filter-category-text-align);
        @include getData(margin, a-m-filter-category-margin);

        ::-webkit-scrollbar {
          width: 0px;
          background: transparent; /* make scrollbar transparent */
        }

        p {
          word-break: keep-all;
          white-space: nowrap;
          display: inline;
          cursor: pointer;
          margin-bottom: 0;
          font-size: 16px;
          margin-right: 20px;

          &:first-child {
            @include getData(margin-left, a-m-filter-category-margin-left);
          }

          &.category-selected {
            @include getData(color, btn);
            // background: $btn;
            // background-clip: text;
            // -webkit-text-fill-color: transparent;
          }
        }
      }
    }

    //container for product card's container
    .card-container-parent {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      row-gap: 16px;
      column-gap: 25px;

      padding: 12px 16px 24px 16px;
      margin: auto;

      &.single-item {
        grid-template-columns: repeat(1, 1fr) !important;
      }

      .infinite-scroll-loading {
        grid-column: 1/-1;
      }

      @include media(mobile_xs) {
        column-gap: 4px;
        grid-template-columns: repeat(2, 1fr);
      }
      @include media(mobile_sm) {
        column-gap: 14px;
        padding: 24px 0;
        grid-template-columns: repeat(2, 1fr);
      }

      @include media(mobile_md) {
        column-gap: 14px;
        padding: 24px 4px;
        grid-template-columns: repeat(2, 1fr);
      }

      @include media(mobile_lg) {
        grid-template-columns: repeat(3, 1fr);
      }

      @include media(tablet, desktop) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    //loading all brand button
    button.a-load-btn {
      border-radius: 20px;
      font-weight: 500 !important;
      margin-bottom: 24px;
      @include getData(background, mainActionLinear);
    }

    .load-no-card {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      font-size: 12px;
      line-height: 16px;
      @include getData(color, gray3);
      opacity: 0.9;
      padding: 0 16px;
    }
  }

  .MAF-banner-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 39px;

    .MAF-banner {
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      margin: 0 16px;

      img {
        width: 100%;
      }
    }
  }
}

//product card container also used in my-cards
.homeCardItem {
  --card-img-height: 89px;
  display: flex;
  justify-content: space-around;
  width: 165px;
  cursor: pointer;
  margin: auto;
  @include media(mobile_xs, mobile_sm) {
    width: 153px;
    --card-img-height: 76px;
  }

  // card image
  &__img {
    overflow: hidden;
    display: flex;
    border-radius: 6px;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 6px 12px 0px #0000000d;
    margin: auto;
    width: 141px;
    height: var(--card-img-height);
    position: relative;

    @include media(mobile_xs, mobile_sm) {
      width: 120px;
    }

    img {
      margin: auto;
      object-fit: cover;
      width: 100%;
      height: 100%;

      &.homeCardItem__no-img {
        width: 32px;
        height: 32px;
      }
    }
  }

  // card description container
  &__description-container {
    width: 165px;
    height: calc(100% - var(--card-img-height) + 20px);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;

    @include media(mobile_xs, mobile_sm) {
      width: 154px;
    }

    margin-top: -20px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 12px;
    box-shadow: 0px 5.90405px 11.8081px rgba(0, 0, 0, 0.05);

    text-align: center;

    //dashed line
    img {
      object-fit: cover;
      height: 2px;
      width: 100%;
      margin-top: 12px;
      margin-bottom: 8px;
    }
  }

  &__description-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    gap: 8px;
    //title
    h4 {
      @include getData(font-size, description-box-h4-font-size);

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
    }

    //subtitle (redeem method)
    h6 {
      font-weight: normal;
      font-size: 12px;
      color: #828282;
    }
  }
}

//modal container when user clicks How To Use banner
.m-HowToUse-modal {
  padding-top: 15vh;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  overflow-x: auto;

  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  > .close {
    position: fixed;
    right: 2%;
    cursor: pointer;
    font-size: 25px;
  }

  &__card-container {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 48px 20px 0;
    gap: 12px;
    height: fit-content;
  }

  .card-blog-mobile {
    width: 220px;
    min-height: 350px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @include getData(background-color, light);

    img {
      border-radius: 10px 10px 0 0;
      width: 220px;
      height: 217px;
    }
    p {
      margin-left: 24px;
      max-width: 180px;
      font-size: 14px;
      line-height: 16px;
    }
    h3 {
      margin: 12px 24px 8px 24px !important;

      font-size: 24px;
      line-height: 28px;
    }
  }
}

.countryListContainer {
  height: 100vh;
  width: 100vw;
  background: #00000080;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;

  .countryList {
      position: absolute;
      bottom: 0;
      left: 0;
      background: #fff;
      width: 100%;
      border-radius: 24px 24px 0px 0px;
      display: flex;
      justify-content: center;

      .countryList-close {
        position: absolute;
        display: flex;
        justify-content: center;
        top: -24px;
        right: 5%;
        width: 48px;
        height: 48px;

        background: #FFFFFF;
        border-radius: 32px;

        img {
          width: 24px;
        }
      }
      
      .countryListContent {
          margin-top: 40px;
          width: 90%;
          display: flex;
          flex-direction: column;
          align-items: center;

          h5 {
              width: 100%;
              font-size: 16px;
              font-weight: 700;
          }
          p {
              width: 100%;
              font-size: 14px;
              font-weight: 400;
          }
      }

      .countryListRadio {
          width: 90%;
          
          .countryListRadioItem {
              display: block;
              vertical-align: middle;
              margin-bottom: 15px;
              color: #000;
              
              input {
                  margin-right: 15px;
              }
              
              img {
                  margin-right: 15px;
              }

              label {
                  width: 90%;
                  vertical-align: middle;
              }
          }
      }

      .countryListBtn {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;

          .continueBtn {
              border: 2px solid $btn;
              border-radius: 12px;
              width: 100%;
              font-size: 14px;
              font-weight: 700;
              color: #fff;
              padding: 10px 28px;
              background: $btn;
              text-align: center;
          }
      }
  }
}