.m-template-selector {
  width: 100%;

  .a-ts-mobile-mobile-title {
    text-align: center;

    h4 {
      margin: auto;
      font-weight: bold;
      font-size: 18px;
      @include getData(color, textColor);
    }
  }
  .ts-category-container {
    height: 100%;

    padding: 16px 0 21px;
    gap: 8px;

    .ts-category-item {
      margin: 2px;
      @include getData(color, gray2);

      border: none;
      max-width: max-content;
      word-break: keep-all;
      white-space: nowrap;
      flex-grow: 1;
      display: inline;
      cursor: pointer;

      padding: 8px 12px;

      background-color: #fff;
      box-shadow: 0px 0px 4px 0px #4f4f4f33;
      border-radius: 20.5288px;

      font-size: 14px;
      text-align: center;
      gap: 4px;

      > i {
        max-height: 18px;
        border-left: 1px solid rgba(255, 255, 255, 0.3);
      }

      &.ts-category-item-active {
        overflow: visible;
        @include getData(background-color, btn);
        @include getData(color, light);
        box-shadow: 0px 6px 20px 0px #4f4f4f33;
      }
    }
  }
  .a-ts-mobile-photos {
    gap: 24px;

    @include getData(background, light);
    .a-ts-mobile-select {
      margin: 0 -16px;

      &.single-item {
        > .slick-list {
          padding: 10px 0 !important;

          > .slick-track {
            margin: auto !important;
          }
        }
      }
      .slick-list {
        padding: 10px 50px 0px 50px !important;
        > .slick-track {
          width: max-content !important;
          margin: 0;
        }
      }

      .a-ts-mobile-customize-cost {
        position: absolute;
        padding: 9px 12px 0 8px;
        h4 {
          font-style: normal;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, light);

          display: flex;
          align-items: center;
          em {
            margin: 0;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, light);
          }
        }
        div {
          margin: 14px 0 0 0;

          display: flex;
          align-items: center;
          h5 {
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            @include getData(color, light);
          }
          span {
            width: 4px;
            min-width: 4px;
            height: 34px;
            margin: 0 6px 0 0;

            @include getData(background-color, light);
            border-radius: 4px;

            display: block;
          }
        }
      }
      .a-ts-mobile-customize-card {
        @include btnNone;
        width: 120px;
        min-width: 120px;
        height: 169.73px;
        margin: 0 20px 0 0;

        border-radius: 4px !important;

        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        z-index: 1;

        overflow: hidden;
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          border-radius: 4px;

          @include bgImage(url('https://picsum.photos/124/80/?=random2'));
          opacity: 0.8;
          filter: blur(2px);
          background-size: 500%;

          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }
        img {
          width: 124px;
          height: 77.83px;

          border-radius: 4px;
          z-index: 1;
        }
      }
      .a-ts-mobile-style {
        width: 100%;

        overflow: visible;
        transition: 0.5s;
        @include btnNone;
        transform: scale(0.8);

        border-radius: 2.5px;

        gap: 12px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        &:hover {
          border-radius: 2.5px;
        }

        &.a-ts-mobile-style-active {
          // height: 100%;
          // margin: 16.5px;
          @include getData(box-shadow, light, !important, inset 0px 0px 0px 2px);
          border-radius: 4px !important;

          position: relative;

          > button.a-mobile-ts-upload-file-btn {
            margin: auto;
          }
          .a-ts-mobile-style-check {
            width: 40px;
            height: 40px;

            padding: 2px;
            border-radius: 100%;
            @include getData(background-color, light);

            top: -16.5px;
            right: -16.5px;

            position: absolute;
          }
          .a-ts-mobile-customize-card-img {
            width: 100% !important;
          }

          transform: scale(1) !important;
        }
      }
      &-btns {
        width: max-content;
        height: 28.29px;
        padding: 4px !important;

        @include getData(background-color, gray1);
        border-radius: 4px;

        display: flex;
        align-items: center;

        position: absolute;
        right: 4.42px;
        bottom: 4.71px;
        button {
          @include btnClear;
          margin: 0 4px 0 0;
          img {
            width: 20.29px !important;
            height: 20.29px !important;
            margin: 0 !important;
          }
          &:last-of-type {
            margin: 0;
          }
          &:hover,
          &:focus,
          &:active {
            background-color: transparent;
            box-shadow: none !important;
          }
        }
      }

      .a-ts-mobile-mobile-customize-card-content {
        width: 100%;

        overflow: visible;
        transition: 0.5s;

        transform: scale(0.8);

        display: flex !important;
        justify-content: center;
        align-items: center;

        @include media(mobile_xs, mobile_sm, mobile_md) {
          max-width: 258px;
          height: 350px;
        }

        @include media(mobile_lg, tablet, desktop) {
          max-width: 268px;
          height: 380px;
        }

        .a-ts-mobile-customize-cost-media {
          min-width: 258px;
          width: 258px;
          max-width: 60vw;
          height: max-content;

          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);

          border-radius: 4px;
          margin: auto;
          padding: 7px;

          position: relative;

          @include media(mobile_lg, tablet) {
            width: 268px;
          }
          .a-ts-mobile-customize-cost-add {
            width: 100%;
            height: 100%;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            gap: 12px;
            padding: 12px;

            overflow: hidden;
            position: relative;
            z-index: 1;

            &:before {
              content: '';
              position: absolute;
              z-index: -1;
              @include getData(border, gray5, null, 10px dashed);
              top: -8px;
              bottom: -8px;
              left: -8px;
              right: -8px;
            }

            .a-mobile-ts-upload-file-btn {
              width: max-content;
              height: 34px;

              font-size: 12px;

              padding: 6px 14px 6px 6px;

              gap: 8px;

              .upload-icon-container {
                width: 21px;
                min-width: 21px;
                height: 21px;

                img {
                  width: 12px;
                  height: 10.5px;
                }
              }
            }
            > .supported-file-text {
              font-size: 14px;
              @include getData(color, gray4);

              text-align: center;

              @include truncateText(2);
            }
            > .customize-cost-text {
              padding: 0;
              text-align: center;
              font-size: 12px;
              @include getData(color, btn);

              @include truncateText(3);
            }

            .a-form-control-error {
              padding: 0 !important;
            }
          }
          .a-ts-mobile-customize-cost-crop {
            height: 100%;

            @include getData(background-color, black);

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .a-ts-mobile-crop-btns {
              margin: 0 6.5px 7px 6.5px;
            }
          }
          .a-load-container {
            .a-load {
              height: 162px !important;
            }
          }
          .a-ts-mobile-customize-cost-upload {
            height: 162px;
            width: 258px;

            display: flex;
            align-items: flex-end;
          }
          .a-ts-mobile-remove-btn {
            margin: 0 6.5px 7px 6.5px !important;
            @include btnFilled;

            @include getData(background-color, btn, !important);
            border: none !important;

            @include getData(color, light, !important);
          }
          h6 {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            @include getData(color, gray1);
            &:first-of-type {
              margin: 17px 0 0 0;
            }
            &:nth-of-type(2) {
              margin: 4px 0 6px 0;
            }
          }
        }

        &.a-ts-mobile-style-active {
          @include getData(box-shadow, light, !important, inset 0px 0px 0px 2px);
          border-radius: 4px !important;

          position: relative;
          .a-ts-mobile-style-check {
            width: 40px;
            height: 40px;

            padding: 2px;
            border-radius: 100%;
            @include getData(background-color, light);

            top: -16.5px;
            right: -16.5px;

            position: absolute;
            z-index: 2;
          }
          .a-ts-mobile-customize-card-img {
            width: 100% !important;
          }

          transform: scale(1) !important;
        }
      }
    }
    .a-ts-mobile-add {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      gap: 24px;

      @include media(mobile_lg) {
        max-width: 355px;
        margin: auto;
      }

      .a-ts-mobile-view {
        margin: auto;
        width: 225.88px;
        height: 320px;
        position: relative;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .a-ts-mobile-text {
        width: 100%;

        padding: 16px;
        gap: 16px;

        display: flex;
        flex-direction: column;

        box-shadow: 0px 6.19823px 20.6608px rgba(0, 0, 0, 0.2);
        border-radius: 4.13215px;

        @include media(mobile_lg) {
          min-width: 355px;
        }

        @include media(tablet, desktop) {
          flex-direction: row;
          gap: 22px;
          max-width: 700px;
        }

        h5 {
          margin: 0 0 8px 0 !important;

          font-weight: bold;
          font-size: 16px;
          line-height: 91.19%;
          @include getData(color, gray1);
          span {
            margin: 0 0 0 8px;

            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
          }
        }
        h6 {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          @include getData(color, gray1);
          &:first-of-type {
            margin: 0 0 4px 0 !important;
          }
          &:nth-of-type(2) {
            margin: 0 0 6px 0 !important;
          }
          &:last-of-type {
            margin: 0 0 12px 0 !important;
          }
        }
        .message-container {
          width: 100%;

          .a-ts-mobile-message {
            width: 100%;
            height: 186px;
            padding: 15px;

            box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
            border-radius: 7.53448px;

            display: flex;
            flex-direction: column;

            &.focused {
              @include getData(border, gray3, null, 1px solid);
            }

            textarea {
              width: 100%;
              height: 100%;

              margin-bottom: 10px;

              @include btnNone;

              resize: none;
              cursor: text;

              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 16px;
              @include getData(color, gray2);
              &::placeholder {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 16px;
                @include getData(color, gray2);
              }
            }
            div {
              position: relative;
              button {
                @include btnMobileSecondary;
                width: 100%;
                min-height: 45.5px;
                font-size: 16px;
              }
              i {
                font-style: normal;
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                @include getData(color, gray3);

                position: absolute;
                bottom: 0;
                right: 0;
              }
            }
          }
        }

        .upload-container {
          width: 100%;

          .a-ts-mobile-reupload {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;

            .a-mobile-ts-upload-file-btn {
              height: 53px;
              width: 258px;
            }
            > p {
              @include getData(color, gray3);
              margin: 0;
            }
          }
        }

        .a-ts-mobile-media {
          height: 184px;

          border-radius: 4.13215px;

          overflow: hidden;
          position: relative;
          z-index: 1;

          &:before {
            content: '';
            position: absolute;
            z-index: -1;
            @include getData(border, gray5, null, 12px dashed);
            top: -9px;
            bottom: -9px;
            left: -9px;
            right: -9px;
          }

          .d-flex {
            gap: 15px;
            justify-content: center;
            align-items: center;

            > p {
              margin: 0;
              font-size: 14px;
              @include getData(color, gray3);

              text-align: center;
            }

            > .a-form-control-error {
              padding: 0 !important;
            }
          }

          .dropdown-toggle {
            &:after {
              display: none;
            }
          }
          .a-mobile-ts-upload-file-btn {
            height: 53.35px;
            width: 200.84px;
          }
        }
      }
    }
    .a-ts-mobile-add-btn {
      padding: 0;
      background-color: transparent;
      border: none;
      &:focus,
      &:active {
        box-shadow: none;
      }
      img {
        width: 92px;
        height: 92px;
      }
    }
    .slider-img-container {
      margin: auto;

      position: relative;
      width: 100%;
      max-height: 100%;

      img {
        border-radius: 2.5px;

        width: 100%;
        height: 100%;

        object-fit: cover;
        overflow: hidden;
      }

      .a-ts-mobile-bg-image {
        width: 100%;
        height: 100%;

        min-height: 162px;
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;

        object-fit: cover;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
    .a-ts-mobile-fg-image {
      border-radius: 2.5px;
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .a-ts-mobile-blur {
      filter: blur(2px);
    }
  }

  .a-ts-mobile-send-physical {
    gap: 6px;
    h4 {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 12px;
      font-weight: normal;
    }
    img {
      margin-left: 6px;
    }
  }

  .a-ts-mobile-terms {
    width: 100%;
    min-height: 106px;
    margin: 14.5px 0 16px 0;
    // padding: 15.5px 37px 15px 36px;

    @include getData(background, light);

    // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    h6 {
      margin-bottom: 3.5px !important;

      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      @include getData(color, gray2);
    }
    p {
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray2);
    }
  }

  .react-multiple-carousel__arrow {
    @include btnClear;
    width: 83px;
    height: 83px;

    background-image: url('/img/account/icon/arrow_right_normal.svg');
    background-position: center center;
    background-size: contain;

    opacity: 1;
    &:before {
      display: none;
    }
    &::after {
      background-image: none;
    }
    &:hover {
      background-image: url('/img/account/icon/arrow_right_hover.svg') !important;
      box-shadow: none !important;
      opacity: 1;
    }
    &:focus,
    &:active {
      @include getData(background-image, arrowRightPressed, !important);
      box-shadow: none !important;
      opacity: 1;
    }
  }

  .react-multiple-carousel__arrow--left {
    // transform: rotate(180deg);
    left: -14px;
  }
  .react-multiple-carousel__arrow--right {
    right: -14px;
  }

  // cropper
  .cropper-container {
    border-radius: 4px;
    overflow: hidden;
    .cropper-view-box {
      outline: 1px solid #fff;
    }
    .cropper-point {
      display: none;
    }
    > h4 {
      margin: 0 auto;
    }
  }

  .a-load-container {
    .a-load {
      width: 100%;
      height: 140px !important;
      padding: 0 !important;

      @include getData(background-color, black);
      border-radius: 4px;
    }
  }
}

.popup-category-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .category-list {
    font-size: 16px;
    line-height: 20px;
    @include getData(font-weight, normal-font-wieght);
    @include getData(color, gray1);

    &:hover {
      @include getData(color, btnHover);
    }

    &:focus,
    &:active {
      @include getData(color, btnPressed);
    }

    &.chosen {
      position: relative;
      color: #4c4c4c33;

      &::before {
        content: '•';
        font-size: 16px;
        position: absolute;
        left: -12px;
        top: 0px;
      }
    }
  }
}

.template-physical-progress-btn {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 16px;
  @include getData(background-color, light);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

  button {
    &.btn-primary {
      width: 100%;
      height: 44px;
      outline: none;
      @include btnMobilePrimary;
    }
  }
}

.tnc-mobile-container {
  padding: 10px;
  @include getData(box-shadow, pagesShadow, !important);

  gap: 21px;

  &.open {
    @include getData(background-color, gray6);

    .a-ts-mobile-terms {
      @include getData(background-color, gray6);
    }
  }

  .a-ts-mobile-terms {
    margin: 0;
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
    }
  }
  h4 {
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
  }
  img {
    transition: 0.25s;
    &.open {
      transform: rotate(180deg);
    }
  }
}

.a-ts-mobile-mobile-customize-text {
  width: 100%;
  height: max-content;
  gap: 12px;

  h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 91.19%;
    @include getData(color, gray1);
    span {
      margin-left: 8px;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      @include getData(color, gray1);
    }
  }
  h6 {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, gray1);
    span {
      margin-left: 8px;
    }
  }

  .input-container {
    display: flex;
    flex-direction: column;

    @include media(tablet, desktop) {
      flex-direction: row;
      gap: 12px;
    }

    > .a-form-control {
      width: 100%;

      label {
        font-size: 16px !important;
        font-weight: normal !important;
      }
      input {
        @include getData(background-color, bg, !important);
        height: 44px;
        border-radius: 10px;

        @include inputMobileDefault;
      }
    }
  }
}

.a-ts-mobile-upload {
  width: 300px !important;
  height: max-content !important;
  margin: 0 !important;
  padding: 20px !important;

  @include getData(background-color, gray4, !important);
  border-radius: 10px !important;
  border: none !important;

  position: absolute;
  top: -144px !important;
  left: -104px !important;
  transform: none !important;
  &::after {
    content: '';
    position: absolute;
    bottom: -16.5px;
    left: 50%;
    transform: translateX(-50%);

    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 6.06px 0 6.06px;
    @include getData(border, gray1, transparent transparent transparent);
  }
  h5 {
    margin: 0;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, light, !important);
  }
  h6 {
    margin: 0 0 12px 0 !important;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, light, !important);
    &:first-of-type {
      margin: 6px 0 4px 0 !important;
    }
  }
  i {
    margin: 12px 0 0 0;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    @include getData(color, light);

    display: block;
  }
  .a-ts-mobile-upload-file-btn {
    width: 260px !important;
    height: 39px;
    margin: 0;

    @include getData(background-color, color2, !important);
    border-radius: 10px;
    border: none !important;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    @include getData(color, light, !important);
    &:hover {
      @include getData(background-color, btnHover);
      @include getData(color, light);
    }
    &:focus,
    &:active {
      @include getData(background-color, btnPressed);
      box-shadow: none !important;
      @include getData(color, light);
    }
  }
}

.a-ts-mobile-upload-horizontal {
  width: 100%;
  height: max-content;

  border: none;
  background-color: transparent;

  .a-ts-mobile-fullscreen {
    width: 100%;
    height: 100%;

    > img {
      width: 100%;
      height: 100%;

      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover !important;
    }
  }
}

.a-ts-mobile-upload-vertical {
  width: 100%;

  border: none;
  background-color: transparent;

  .a-ts-mobile-fullscreen {
    height: 202px;
  }
}

.a-ts-mobile-fullscreen {
  padding: 5px;

  overflow: hidden !important;

  > img {
    @include getData(box-shadow, pagesShadow, !important);
    border-radius: 4px;
  }

  .check-icon {
    position: relative;

    img {
      position: absolute;
      top: 5px;
      right: 5px;

      width: 25px;
      height: 25px;
      @include btnNone;
    }
  }
}

.a-mobile-ts-crop-btns {
  display: flex;
  gap: 12px;

  button {
    width: 100%;
    height: 45.5px;

    &.btn-secondary {
      @include btnMobileSecondary;
    }
    &.btn-primary {
      @include btnMobilePrimary;
    }
  }
}

.a-mobile-ts-remove-btn {
  width: 100%;
  height: 53px;
  @include btnMobilePrimary;

  &:focus,
  &:active {
    box-shadow: none !important;
  }
}

.a-mobile-ts-upload-file-btn {
  width: 200.84px;
  height: 53.35px;

  // margin: auto;

  display: flex;
  justify-content: space-between;
  align-items: center;
  @include btnMobilePrimary;

  padding: 12px;

  font-size: 16px;

  .upload-icon-container {
    width: 33px;
    min-width: 33px;
    height: 33px;
    border-radius: 100%;
    background-color: #fff;
    position: relative;

    img {
      height: 16.5px;
      width: 18.8px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  p {
    color: #fff;
    width: 100%;
    margin: 0;
  }
}

.a-ts-mobile-upload-file-btn-loading {
  background: rgba(255, 109, 98, 0.4) !important;

  display: flex;
  align-items: center;
  img {
    width: 20px !important;
    height: 20px !important;
    margin: 0 0 0 12px;
  }
}

// special state

.a-ts-mobile-tab-active {
  @include getData(color, btn, !important);

  @include getData(border-bottom, gray3, !important, 2px solid);
  border-radius: 0px !important;
  span {
    background-image: url(/img/main_page/icon/arrow/down_pressed.svg);
  }
}

.modal-cropper {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9999;

  padding: 34px;

  .cropper-container {
    height: 100%;

    display: flex;
    flex-direction: column;
    gap: 27px;
    padding-top: 10vh;

    margin: auto;

    .cropper-loading-text {
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      justify-content: center;

      gap: 24px;
      > .text-container {
        display: flex;
        align-items: center;
        justify-content: center;

        background: rgba(0, 0, 0, 0.6);
        border-radius: 100px;
        padding: 10px 20px;

        > p {
          font-weight: bold;
          font-size: 16px;
          @include getData(color, light);

          text-align: center;
          margin: 0;

          @include truncateText(2);
        }
      }

      > .prep-img {
        width: 225.88px;
        height: 320px;

        background: url(/img/B2C_guest_step_2/pic/loading_bg.png);
        background-position: center;
        /* Add */

        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.25));
        border-radius: 4px;
      }
    }

    > .crop-instruction {
      padding: 6px 12px;
      width: 100%;
      border-radius: 100px;
      background: #ffffffb2;

      h4 {
        text-align: center;
        @include getData(color, btn);
        font-weight: bold;
        font-size: 20px;

        @include truncateText(2);
      }
    }
    //cropper-react class

    .cropper-dashed {
      display: none;
    }
    .cropper-center {
      display: none;
    }
    .cropper-view-box {
      border: 1px solid #fff;
    }
  }
}
