.a-a-order {
  margin: 0 0 0 29px !important;
  @media screen and (min-width: 992px) and (max-width: 1399px) {
    width: 412px !important;
  }
  .a-card-tab {
    margin: 0 0 20px 16px;
  }
  .a-form-control {
    margin: 0 0 20px 16px;
    select {
      width: 250px;
      min-width: 250px;
      height: 43px;
      padding: 10px 37px 10px 20px;
      margin: 0 12px 0 0;

      @include getData(background-color, light);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      border: none;

      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 140.62%;
      @include getData(color, gray1);
      &::placeholder {
        @include getData(color, gray4);
      }
    }
    input {
      height: 43px;
      padding: 10px 20px;

      @include getData(background-color, light, !important);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      border: none;

      @include getData(color, gray4);
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: start !important;
      &::placeholder {
        @include getData(color, gray4);
        font-weight: bold;
        text-align: start !important;
      }
      &:focus {
        border: none !important;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25) !important;
      }
    }
    .form-control-date {
      height: 43px;
      margin-right: 12px;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      @include getData(background-color, light, !important);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;

      border: none;

      @include getData(color, gray4);
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      text-align: start !important;

      &.show-date {
        @include getData(color, gray1);
      }

      img {
        height: 100%;
        padding: 3px;
      }
    }
    @media screen and (min-width: 992px) and (max-width: 1399px) {
      select {
        width: 200px;
        min-width: 200px;
        &:last-of-type {
          margin: 0;
        }
      }
      input {
        width: 412px;
        margin: 12px 0 0 0;
      }
      .form-control-date {
        width: 412px;
        margin: 12px 0 0 0;
      }
    }
  }
  .a-order-history,
  .a-order-wallet {
    width: 100%;
    height: max-content;
    // max-height: 812px;

    overflow-y: auto;
    overflow-x: hidden;
    @include customScrollbar;
  }
}

.a-card-history-order {
  .a-card-history-middle {
    .a-card-history-left {
      img {
        width: 33.6px;
        height: 42px;
        &.productImg {
          width: 72px;
          height: 45px;
        }
      }
    }
  }
}

.a-card-history-collapse {
  .card-body {
    padding: 0;
    .a-order-history-deatil-bottom {
      flex-wrap: wrap;
      .a-order-history-payment-info {
        padding: 14px 14px 24px 0;
        .a-order-history-deatil-see-more {
          h6:first-child {
            padding-left: 4px;
          }
          h6:last-child {
            color: red;
            cursor: pointer;
          }
        }
      }
      li {
        padding-left: 16px;
      }
    }
    .a-order-history-deatil {
      h2 {
        margin: 0 0 4px 0 !important;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, gray2);
        &:last-of-type {
          margin: 0 !important;
          @include getData(color, gray1);
        }
      }
      @media screen and (min-width: 992px) and (max-width: 1399px) {
        margin: 0 0 4px 0;
        h2 {
          margin: 12px 0 0 0 !important;

          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          @include getData(color, gray2);
        }
      }
      .a-order-history-deatil-top {
        padding: 10px 0 14px 0;
        &:first-of-type {
          padding: 14px 0;
        }
        margin: 0;
        img {
          width: 68.8px;
          height: 43px;
          margin: 0 12px 0 0;

          border-radius: 4px;

          object-fit: cover;
        }
      }
      .a-order-history-deatil-delivery {
        padding: 14px 0;
        h3 {
          margin: 0 !important;
          font-size: 14px;
          line-height: 16px;
          @include getData(color, gray1);
        }
        div {
          margin-top: 4px;
          font-size: 12px;
          @include getData(color, gray1);
          line-height: 14px;
          &:first-of-type {
            margin-top: 12px;
          }
        }
      }
      hr {
        width: 100%;
        margin: 6px 10px;
        @include getData(border, gray4, null, 1px dashed);
      }
    }
    .a-order-history-total {
      margin-top: 14px;
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        @include getData(color, color2);
      }
      @media screen and (min-width: 992px) and (max-width: 1399px) {
        padding: 16px 10px 0 0;
        h4 {
          margin: 0 0 12px 0;

          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          @include getData(color, color2);
          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }
}
